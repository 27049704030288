.field {
  &__wrap {
    position: relative; }
  &__input {
    width: 100%;
    height: 38px;
    padding: 0 15px;
    background: $wh;
    border: 1px solid #F1F1F5;
    border-radius: 15px;
    @include f;
    font-size: 14px;
    letter-spacing: .1px;
    color: $bl;
    @include placeholder {
      color: $gray; } }
  &__view {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 48px;
    height: 100%;
    font-size: 0;
    cursor: pointer;
    transition: opacity .25s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    .icon {
      font-size: 11px;
      fill: $gray;
      transition: fill .25s; }
    &:hover .icon {
      fill: $gray-light; } }
  &__line {
    display: block;
    position: absolute;
    top: 19px;
    left: 14px;
    width: 20px;
    height: 1px;
    transform: rotate(-45deg);
    border-radius: 1px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 0;
      height: 100%;
      background: $gray;
      transition: width .25s; } }
  &__view.active &__line:before {
    width: 100%; }
  &_icon &__input {
    padding-right: 48px; } }

@include dark {
  .field {
    &__input {
      border-color: $dark3;
      background: $dark3;
      color: $wh; } } }
