.field2 {
  position: relative;
  &__wrap {
    position: relative; }
  &__label {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 2;
    background: $white;
    font-size: 14px;
    letter-spacing: .1px;
    color: $gray;
    pointer-events: none;
    transition: all .25s; }
  &__input {
    width: 100%;
    height: 40px;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: $border;
    border-radius: 0;
    @include f;
    font-size: 14px;
    font-weight: 500;
    color: $dark4;
    transition: all .25s;
    @include placeholder {
      color: $gray; }
    &:focus {
      border-color: $blue-light; } }
  &__select {
    min-width: 100%;
    height: 40px;
    padding: 0 20px 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: $border;
    border-radius: 0;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat 100% 50% / 11px 7px;
    @include f;
    font-size: 14px;
    font-weight: 500;
    color: $dark4;
    transition: all .25s;
    @include m {
      height: 42px; }
    &::-ms-expand {
      display: none; }
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000; } }
  &_select &__select {
    font-size: 0; }
  &_select.active &__select {
    font-size: 14px; }
  &.active &__label {
    transform: translateY(-19px);
    font-size: 12px; } }

@include dark {
  .field2 {
    &__label {
      background: $dark2; }
    &__input,
    &__select {
      border-color: $dark3;
      color: #F1F1F5; } } }

