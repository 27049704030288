.profile {
  border-radius: 15px;
  overflow: hidden;
  background: $white;
  @include m {
    border-radius: 0; }
  &__bg {
    position: relative;
    @include cover; }
  &__edit {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95px;
    height: 38px;
    padding-top: 1px;
    border-radius: 10px;
    border: 1px solid $white;
    background: rgba($dark, .5);
    @include fp;
    font-size: 12px;
    font-weight: 600;
    color: $white;
    transition: all .25s;
    .icon {
      display: none; }
    @include m {
      top: 15px;
      right: 15px;
      width: 110px; }
    &:hover {
      background: $wh;
      color: $dark;
      .icon {
        fill: $dark; } } }
  &__user {
    position: relative;
    z-index: 2;
    display: flex;
    margin: -110px 25px 0;
    @include m {
      display: block;
      text-align: center;
      margin: -65px 0 0; } }
  &__ava {
    flex-shrink: 0;
    width: 170px;
    height: 170px;
    margin-right: 25px;
    border: 6px solid $white;
    border-radius: 50%;
    background: $white;
    font-size: 0;
    @include m {
      width: 130px;
      height: 130px;
      margin: 0 auto 10px;
      border-width: 5px; } }
  &__ava &__pic {
    width: 100%;
    min-height: 100%;
    border-radius: 50%; }
  &__details {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    @include m {
      display: block;
      padding: 0; } }
  &__title {
    color: $white;
    @include m {
      font-size: 16px;
      color: $dark; } }
  &__login {
    font-size: 16px;
    color: #D5D5DC;
    @include m {
      margin-left: 5px;
      font-size: 14px;
      color: $gray; } }
  &__options {
    position: relative;
    z-index: 3;
    display: flex;
    margin-top: -60px;
    padding-left: calc(33.5% + 30px);
    @include t {
      padding-left: 215px; }
    @include m {
      margin-top: 15px;
      padding: 0;
      border-top: 1px solid #F1F1F5;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none; } } }
  &__option {
    @include m {
      &:first-child {
        padding-left: 20px; }
      &:last-child {
        padding-right: 20px; } } }
  &__link {
    position: relative;
    display: block;
    padding: 10px 16px 12px;
    text-align: center;
    @include fp;
    @include mac {
      padding: 10px 12px; }
    @include t {
      padding: 10px; }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0;
      background: $blue;
      border-radius: 3px 3px 0 0;
      transition: height .25s; }
    &.active {
      &:before {
        height: 3px; }
      @include m {
        .profile__category,
        .profile__number {
          color: $blue; } } } }
  &__text {
    display: none;
    @include m {
      display: block;
      margin-top: 5px;
      line-height: (22/14);
      color: $dark4; } }
  &__category {
    margin-bottom: 4px;
    letter-spacing: .2px;
    line-height: (21/14);
    font-weight: 700;
    color: $gray;
    transition: color .25s; }
  &__link:hover &__category {
    color: $gray-light; }
  &__number {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    color: $dark4;
    transition: color .25s; }
  &__link:hover &__number {
    color: $gray; }
  &_user &__bg {
    height: 260px;
    @include t {
      height: 200px; }
    @include m {
      height: 130px;
      border-radius: 15px 15px 0 0; } }
  &_user &__edit {
    @include m {
      width: 32px;
      height: 32px;
      font-size: 0;
      .icon {
        display: inline-block;
        font-size: 16px;
        fill: $white; }
      &:hover {
        .icon {
          fill: $dark; } } } }
  &_group &__bg {
    height: 200px;
    @include m {
      height: 150px; } }
  &_hide {
    @include t {
      display: none; } }
  &_show {
    display: none;
    @include t {
      display: block;
      border-radius: 15px 15px 0 0;
      @include nl {
        margin: 0; } } }
  &_show &__bg {
    @include t {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 66px;
        background: linear-gradient(180deg, rgba(122, 122, 135, 0.0001) 0%, #171725 131.54%); } } }
  @include nl {
    margin-bottom: 20px;
    @include m {
      margin-bottom: 5px; } } }

@include dark {
  .profile {
    background: $dark2;
    &__title {
      color: $wh; }
    &__number {
      color: $border; }
    &__link {
      &:before {
        background: $blue-dark; }
      &:hover .profile__number {
        color: $gray-light; }
      &.active {
        .profile__category,
        .profile__number {
          color: $blue-dark; } } }
    &__login {
      @include m {
        color: $wh; } }
    &__text {
      color: $border; }
    &__options {
      border-color: $dark3; } } }

