.overview {
  &__card {
    padding: 25px 25px 30px;
    border-radius: 20px;
    background: $white;
    @include x {
      padding: 20px 20px 25px; }
    @include mac {
      padding: 20px 15px; }
    &_p20 {
      padding: 20px;
      @include x {
        padding: 20px; }
      @include mac {
        padding: 20px 15px; } }
    &.overview__card_p0 {
      padding: 0; }
    &.overview__card_location {
      display: flex;
      @include m {
        padding: 15px;
        flex-direction: column-reverse; } }
    @include nl {
      margin-bottom: 20px; } }
  &__list {
    display: flex;
    margin: 0 -10px;
    @include d {
      flex-wrap: wrap;
      margin-top: -20px; }
    @include m {
      margin-top: -10px; }
    @include nl {
      margin-bottom: 20px;
      @include m {
        margin-bottom: 10px; } } }
  &__list &__card {
    flex: 0 0 calc(25% - 20px);
    width: calc(25% - 20px);
    margin: 0 10px;
    padding: 20px 25px 30px;
    @include x {
      padding: 20px 20px 25px; }
    @include d {
      flex: 0 0 calc(50% - 20px);
      width: calc(50% - 20px);
      margin: 20px 10px 0;
      padding: 15px; }
    @include m {
      flex: 0 0 calc(50% - 10px);
      width: calc(50% - 10px);
      margin: 10px 5px 0; } }
  &__title {
    @include fp;
    font-size: 16px;
    font-weight: 600; }
  &__main {
    margin-bottom: 20px;
    @include d {
      margin-bottom: 10px; }
    @include m {
      display: flex;
      align-items: center;
      margin-bottom: 5px; } }
  &__main &__title {
    @include m {
      margin-right: 8px;
      font-size: 13px; } }
  &__main &__status {
    display: none;
    @include m {
      display: block;
      font-size: 13px;
      .icon {
        font-size: 14px; } } }
  &__number {
    @include fp;
    font-size: 28px;
    font-weight: 600;
    @include mac {
      font-size: 24px; } }
  &__flex {
    display: flex; }
  &__list &__flex {
    align-items: center;
    margin-bottom: 5px; }
  &__list &__number {
    margin-right: 14px;
    @include m {
      margin: 0;
      font-size: 22px; } }
  &__flex &__status {
    @include m {
      display: none; } }
  &__info {
    color: $dark5;
    @include mac {
      font-size: 12px; } }
  &__status {
    @include fp;
    font-size: 16px;
    font-weight: 600;
    .icon {
      position: relative;
      bottom: -2px;
      margin-left: 3px;
      font-size: 16px;
      fill: $gray; }
    &_up {
      color: $green; }
    &_down {
      color: $red;
      .icon {
        fill: $red; } } }
  &__row {
    display: flex;
    margin: 0 -10px;
    @include m {
      display: block;
      margin: 0; }
    @include nl {
      margin-bottom: 20px;
      @include m {
        margin-bottom: 10px; } }
    &_tablet-album {
      @include d {
        display: block;
        margin: 0; }
      .overview__col {
        @include d {
          width: 100%;
          margin: 0; }
        @include nl {
          @include d {
            margin-bottom: 20px; }
          @include m {
            margin-bottom: 10px; } } }
      @include nl {
        @include d {
          margin-bottom: 20px; }
        @include m {
          margin-bottom: 10px; } } } }
  &__col {
    margin: 0 10px;
    @include m {
      margin: 0; }
    &_w25 {
      flex: 0 0 calc(25% - 20px);
      width: calc(25% - 20px);
      @include mac {
        flex: 0 0 calc(33% - 20px);
        width: calc(33% - 20px); }
      @include m {
        width: 100%; } }
    &_w33 {
      flex: 0 0 calc(33% - 20px);
      width: calc(33% - 20px);
      @include m {
        width: 100%; } }
    &_w50 {
      flex: 0 0 calc(50% - 20px);
      width: calc(50% - 20px);
      @include m {
        width: 100%; } }
    &_w67 {
      flex: 0 0 calc(67% - 20px);
      width: calc(67% - 20px);
      @include m {
        width: 100%; } }
    &_w75 {
      flex: 0 0 calc(75% - 20px);
      width: calc(75% - 20px);
      @include mac {
        flex: 0 0 calc(67% - 20px);
        width: calc(67% - 20px); }
      @include m {
        width: 100%; } }
    @include nl {
      @include m {
        margin-bottom: 10px; } } }
  &__head {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    @include m {
      margin-bottom: 15px; }
    @include a {
      display: block; }
    &_mb30 {
      margin-bottom: 30px;
      @include x {
        margin-bottom: 20px; }
      @include m {
        margin-bottom: 15px; } } }
  &__legend {
    display: flex;
    &_column {
      flex-wrap: wrap;
      margin: -10px -5px 0; }
    &_group {
      flex-direction: column; } }
  &__head &__legend {
    margin-left: auto;
    @include m {
      margin: 10px 0 0; } }
  &__parameter {
    display: flex;
    align-items: center;
    color: $dark4;
    @include t {
      font-size: 13px; }
    @include m {
      font-size: 14px; } }
  &__legend_column &__parameter {
    flex: 0 0 calc(50% - 10px);
    width: calc(50% - 10px);
    margin: 10px 5px 0; }
  &__legend_group &__parameter {
    @include nl {
      margin-bottom: 20px;
      @include m {
        margin-bottom: 10px; } } }
  &__head &__legend {
    padding-top: 3px; }
  &__head &__parameter {
    @include nl {
      margin-right: 30px;
      @include d {
        margin-right: 15px; } } }
  &__bg {
    flex-shrink: 0;
    width: 10px;
    height: 12px;
    margin-right: 10px;
    border-radius: 6px;
    @include d {
      margin-right: 5px; }
    @include m {
      margin-right: 10px; } }
  &__value {
    padding-left: 5px;
    font-weight: 700;
    color: $dark4; }
  &__chart {
    &_sales-figures {
      height: 382px;
      margin: 0 -5px 0 -15px;
      @include m {
        height: 250px;
        margin: -10px -5px -10px -15px; }
      .apexcharts-tooltip.apexcharts-theme-light {
        padding: 10px 15px;
        border: none;
        border-radius: 8px;
        box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1); }
      .apexcharts-tooltip-series-group {
        padding: 0;
        align-items: center;
        @include nl {
          margin-bottom: 8px; } }
      .apexcharts-tooltip-series-group.apexcharts-active,
      .apexcharts-tooltip-series-group:last-child {
        margin: 0;
        padding: 0; }
      .apexcharts-tooltip-text-value,
      .apexcharts-tooltip-text-z-value {
        @include fp;
        font-size: 16px;
        font-weight: 600;
        color: $dark;
        &:before {
          content: "$"; } }
      .apexcharts-tooltip-text-label {
        font-size: 14px;
        color: $dark5; } }
    &_visitors {
      height: 60px;
      @include m {
        height: 80px; } }
    &_sales-report {
      height: 242px;
      margin: -10px -5px -20px -18px;
      @include x {
        height: 255px; }
      @include mac {
        height: 252px; }
      .apexcharts-tooltip.apexcharts-theme-light {
        padding: 10px;
        border: none;
        background: $wh;
        border-radius: 8px;
        box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1); }
      .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
        background: none;
        border: none;
        font-family: Poppins, sans-serif !important;
        font-size: 12px;
        font-weight: 600;
        color: $dark; }
      .apexcharts-tooltip-title {
        margin-bottom: 7px;
        padding: 0; }
      .apexcharts-tooltip-y-group {
        padding: 0; }
      .apexcharts-tooltip-series-group {
        padding: 0;
        @include nl {
          margin-bottom: 8px; } }
      .apexcharts-tooltip-series-group.apexcharts-active, .apexcharts-tooltip-series-group:last-child {
        padding-bottom: 0; }
      .apexcharts-tooltip-marker {
        width: 6px;
        height: 8px;
        margin-right: 6px;
        border-radius: 6px; }
      .apexcharts-tooltip-text-label {
        color: $gray; }
      .apexcharts-tooltip-text-value {
        color: $dark;
        &:before {
          content: "$"; } } }
    &_visitors-all {
      position: relative;
      height: 260px;
      margin: 15px 0 5px;
      @include x {
        height: 240px; }
      @include mac {
        height: 210px; }
      @include m {
        height: 180px;
        margin: 15px 0; }
      .apexcharts-pie-area {
        stroke: inherit; }
      .overview__details {
        position: absolute;
        top: 46%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        @include m {
          top: 50%; } } } }
  &__group {
    @include m {
      display: flex;
      align-items: center; } }
  &__el {
    display: flex;
    align-items: center;
    @include nl {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #F1F1F5;
      @include m {
        margin: 0 auto 0 0;
        padding-bottom: 0;
        border: none; } } }
  &__el &__chart {
    position: relative;
    width: 115px;
    height: 100px;
    margin-left: -15px;
    margin-right: 10px;
    font-size: 0;
    @include x {
      margin-right: 0; }
    @include m {
      width: 70px;
      height: 60px; } }
  &__el &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 0;
    .icon {
      font-size: 24px;
      @include m {
        font-size: 16px; }
      &-goal {
        fill: $blue; }
      &-briefcase {
        fill: $green; } } }
  &__el &__number {
    font-size: 24px;
    @include m {
      font-size: 18px; } }
  &__bottom {
    display: flex;
    margin-top: 30px;
    @include m {
      margin-top: 5px; } }
  &__bottom &__details {
    margin-right: auto; }
  &__bottom &__status {
    padding-top: 5px; }
  &__bottom &__number {
    font-size: 24px; }
  &__details_flex {
    @include m {
      display: flex;
      align-items: center; } }
  &__years {
    margin-top: 10px;
    color: $dark5;
    @include m {
      margin: 0 0 0 10px; } }
  &__charts {
    display: flex;
    margin-left: -25px;
    padding-right: 30px;
    @include x {
      justify-content: space-around;
      padding: 0; }
    @include mac {
      margin-left: -10px; }
    @include t {
      margin-left: 0; } }
  &__box {
    display: flex;
    align-items: center;
    @include x {
      flex-direction: column;
      text-align: center; }
    @include nl {
      margin-right: auto;
      @include x {
        margin: 0; } } }
  &__charts &__chart {
    position: relative;
    flex-shrink: 0;
    width: 120px;
    height: 100px; }
  &__percent,
  &__counter {
    @include fp;
    font-weight: 600; }
  &__charts &__percent {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 18px; }
  &__charts &__details {
    margin-left: -5px; }
  &__charts &__counter {
    margin-bottom: 4px;
    font-size: 16px; }
  &__note {
    display: flex;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #F1F1F5;
    font-size: 12px;
    line-height: (22/12);
    color: $gray;
    @include x {
      margin-top: 15px;
      padding-top: 15px;
      line-height: 1.4; }
    .icon {
      position: relative;
      bottom: -2px;
      flex-shrink: 0;
      margin-right: 10px;
      font-size: 16px;
      fill: $gray; } }
  &__control {
    display: flex;
    align-items: center; }
  &__control &__title {
    margin: 0 auto; }
  &__arrow {
    font-size: 0;
    .icon {
      font-size: 24px;
      fill: $gray;
      transition: fill .25s; }
    &:hover .icon {
      fill: $gray-light; } }
  &__cell {
    padding: 25px;
    @include x {
      padding: 20px; }
    @include mac {
      padding: 20px 15px; }
    @include m {
      padding: 0; }
    &:first-child {
      flex-shrink: 0;
      width: 265px;
      border-right: 1px solid #F1F1F5;
      @include mac {
        width: 200px; }
      @include m {
        width: 100%;
        border: none; } }
    &:nth-child(2) {
      flex-grow: 1; } }
  &__cell &__title {
    margin-bottom: 49px;
    @include x {
      margin-bottom: 25px; }
    @include mac {
      margin-bottom: 15px; } }
  &__cell &__flex {
    display: flex;
    align-items: center; }
  &__flag {
    flex-shrink: 0;
    width: 28px;
    margin-left: 10px;
    font-size: 0; }
  &__flag &__pic {
    width: 100%;
    border-radius: 2px; }
  &__cell &__info {
    margin-bottom: 40px;
    @include mac {
      margin-bottom: 25px; }
    @include m {
      margin-bottom: 15px; } }
  &__cell &__legend {
    max-width: 185px;
    @include m {
      max-width: 100%; } }
  &__cell &__value {
    margin-left: auto; }
  &__map > div {
    @include x {
      height: 335px !important; }
    @include mac {
      height: 300px !important; }
    @include m {
      height: 220px !important; } }
  .jqvmap-zoomin,
  .jqvmap-zoomout {
    left: auto;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: 1px solid $border;
    background: $white;
    font-size: 0;
    transition: opacity .25s;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 12px;
      height: 2px;
      background: $gray;
      border-radius: 1px; }
    &:hover {
      opacity: .85; } }
  .jqvmap-zoomin {
    top: 0;
    right: 0; }
  .jqvmap-zoomout {
    top: 34px;
    right: 0;
    @include m {
      top: 29px; }
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 2px;
      height: 12px;
      background: $gray;
      border-radius: 1px; } } }

@include dark {
  .overview {
    &__card {
      background: $dark2; }
    &__title {
      color: $wh; }
    &__number {
      color: $wh; }
    &__info {
      color: $gray; }
    &__parameter {
      color: $gray; }
    &__el {
      border-color: $dark4; }
    &__counter {
      color: $wh; }
    &__box .overview__info {
      color: $dark5; }
    &__note {
      border-color: $dark4; }
    .apexcharts-gridline {
      stroke: #3D3D49; }
    .apexcharts-xcrosshairs.apexcharts-active,
    .apexcharts-ycrosshairs.apexcharts-active {
      stroke: $dark4; }
    .apexcharts-xaxis line {
      stroke: #3D3D49; }
    &__chart_sales-figures {
      .apexcharts-tooltip.apexcharts-theme-light {
        background: $dark3;
        box-shadow: none; }
      .apexcharts-tooltip-text-value,
      .apexcharts-tooltip-text-z-value {
        color: $wh; }
      .apexcharts-tooltip-text-label {
        color: $gray-light; } }
    &__chart_visitors {
      .apexcharts-tooltip.apexcharts-theme-light {
        border: none;
        background: $dark3;
        box-shadow: none; }
      .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
        border-color: $dark4;
        background: $dark3;
        color: $wh; }
      .apexcharts-tooltip-text-value,
      .apexcharts-tooltip-text-z-value {
        color: $wh; }
      .apexcharts-tooltip-text-label {
        color: $gray-light; } }
    &__chart_sales-report {
      .apexcharts-tooltip.apexcharts-theme-light {
        background: $dark3;
        box-shadow: none; }
      .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
        color: $wh; }
      .apexcharts-tooltip-text-label {
        color: $gray-light; }
      .apexcharts-tooltip-text-value {
        color: $wh;
        &:before {
          content: "$"; } } }
    &__chart_visitors-all {
      .apexcharts-pie-area {
        stroke: inherit; } }
    &__value {
      color: $gray-light; }
    &__legend_column .overview__parameter {
      color: $gray-light; }
    &__cell {
      border-color: $dark4; }
    &__cell .overview__parameter {
      color: $gray-light; }
    &__cell .overview__info {
      color: $gray-light; }
    .jqvmap-zoomin,
    .jqvmap-zoomout {
      border-color: $dark5;
      background: $dark2;
      &:before,
      &:after {
        background: $gray-light; } } } }




