.forums {
  &__row {
    position: relative;
    display: flex;
    @include t {
      flex-direction: column-reverse; } }
  &__col {
    &:first-child {
      flex-grow: 1;
      padding-right: 40px;
      @include mac {
        padding-right: 25px; }
      @include t {
        padding: 0; }
      .forums__title.title_big {
        @include t {
          display: none; } } }
    &:nth-child(2) {
      flex-shrink: 0;
      width: 263px;
      @include mac {
        width: 240px; }
      @include t {
        width: 100%;
        margin-bottom: 40px; }
      @include m {
        margin-bottom: 30px; }
      .forums__title.title_big {
        display: none;
        @include t {
          display: block; } } } }
  &__col > &__title {
    margin-bottom: 34px;
    padding-right: 70px;
    @include t {
      margin-bottom: 25px;
      padding: 0;
      text-align: center; }
    @include m {
      margin-bottom: 20px; } }
  &__item {
    display: flex;
    align-items: center;
    padding: 15px 30px 15px 15px;
    background: $white;
    border-radius: 20px;
    @include mac {
      padding: 15px; }
    @include m {
      display: block;
      padding: 0 20px 25px; }
    @include nl {
      margin-bottom: 30px;
      @include t {
        margin-bottom: 25px; }
      @include m {
        margin-bottom: 20px; } } }
  &__preview {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 230px;
    height: 170px;
    margin-right: 25px;
    padding: 20px 15px;
    border-radius: 20px;
    border: 1px solid #F1F1F5;
    font-size: 0;
    @include mac {
      width: 200px;
      margin-right: 15px;
      padding: 15px 10px; }
    @include m {
      width: 100%;
      height: 175px;
      margin: 0 0 15px;
      padding: 20px;
      border: none;
      border-radius: 0; } }
  &__pic {
    max-width: 100%;
    max-height: 100%; }
  &__details {
    flex-grow: 1; }
  &__info {
    margin-bottom: 7px;
    @include fp;
    font-size: 18px;
    font-weight: 600;
    color: $dark;
    transition: color .25s; }
  &__item:hover &__info {
    color: $blue-dark; }
  &__item &__text {
    margin-bottom: 24px;
    line-height: (24/14);
    color: $gray;
    @include mac {
      margin-bottom: 15px; } }
  &__line {
    display: flex;
    align-items: center; }
  &__user {
    display: flex;
    align-items: center;
    margin-right: auto;
    .ava {
      width: 36px;
      height: 36px;
      margin-right: 15px;
      font-size: 16px; } }
  &__man {
    margin-bottom: 4px;
    @include fp;
    font-size: 12px;
    font-weight: 600;
    color: $blue; }
  &__counter {
    display: flex;
    font-size: 12px;
    line-height: 1.15;
    color: $gray;
    li {
      @include nf {
        margin-left: 5px;
        @include m {
          display: none; }
        &:before {
          content: "";
          display: inline-block;
          position: relative;
          top: -2px;
          width: 3px;
          height: 3px;
          margin-right: 5px;
          background: #D8D8D8; } } } }
  &__actions {
    display: flex;
    align-items: center; }
  &__action {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    border: 1px solid $border;
    font-size: 0;
    .icon {
      font-size: 20px;
      fill: $gray;
      transition: fill .25s; }
    &:hover {
      .icon {
        fill: $gray-light; } }
    @include nl {
      margin-right: 15px; } }
  &__filters {
    position: absolute;
    top: 0;
    right: 303px;
    width: 46px;
    height: 46px;
    border-radius: 10px;
    background: $white;
    font-size: 0;
    @include mac {
      right: 265px; }
    @include t {
      position: static;
      flex-shrink: 0;
      margin-left: 15px; }
    @include m {
      height: 42px; }
    .icon {
      font-size: 18px;
      fill: $gray;
      transition: fill .25s; }
    &:hover .icon {
      fill: $gray-light; } }
  &__top {
    margin-bottom: 30px;
    @include t {
      display: flex; }
    @include m {
      margin-bottom: 25px; } }
  &__search {
    position: relative;
    @include t {
      flex-grow: 1; } }
  &__input {
    width: 100%;
    height: 46px;
    padding: 0 45px 0 13px;
    border-radius: 10px;
    @include f;
    font-size: 14px;
    color: $dark;
    @include m {
      height: 42px; }
    @include placeholder {
      color: $gray; } }
  &__open {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 38px;
    font-size: 0;
    @include t {
      width: 45px; }
    .icon {
      font-size: 20px;
      fill: $gray;
      transition: fill .25s; }
    &:hover .icon {
      fill: $gray-light; } }
  &__accord {
    margin-bottom: 40px;
    padding: 30px 20px;
    border-radius: 20px;
    background: $blue;
    @include t {
      margin-bottom: 30px; }
    @include m {
      margin-bottom: 30px;
      padding: 15px 20px; } }
  &__head {
    @include fp;
    font-size: 16px;
    font-weight: 600;
    color: $wh;
    @include m {
      position: relative;
      padding-right: 30px;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 12px;
        height: 7px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M6 4.586L10.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5A1 1 0 1 1 1.707.293L6 4.586z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / auto 100%;
        transition: transform .25s; } } }
  &__accord.active &__head:before {
    @include m {
      transform: translateY(-50%) rotate(180deg); } }
  &__body {
    padding-top: 12px;
    @include rmin(768) {
      display: block !important;
      height: 100% !important;
      padding-top: 12px !important; }
    @include m {
      display: none; } }
  &__accord &__text {
    margin-bottom: 25px;
    line-height: (24/14);
    color: $wh;
    @include m {
      margin-bottom: 20px; } }
  &__btn {
    min-width: 100%; }
  &__flex {
    display: flex;
    align-items: center;
    margin-bottom: 17px; }
  &__popular &__title {
    padding-left: 10px;
    @include m {
      padding: 0; } }
  &__view {
    display: none;
    @include m {
      display: inline-block;
      margin-left: auto;
      @include fp;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: .8px;
      color: $blue; } }
  &__links {
    @include t {
      display: flex;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;
      margin: 0 -25px;
      &::-webkit-scrollbar {
        display: none; }
      &:before,
      &:after {
        content: "";
        flex-shrink: 0;
        width: 25px;
        height: 60px; } }
    @include m {
      margin: 0 -20px;
      &:before,
      &:after {
        width: 20px;
        height: 50px; } } }
  &__link {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #D5D5DC;
    white-space: nowrap;
    font-size: 16px;
    color: $dark;
    transition: border-color .25s;
    @include m {
      padding: 5px 15px 5px 10px;
      border-radius: 12px; }
    &:hover {
      border-color: $blue-light; }
    @include nl {
      margin-bottom: 15px;
      @include t {
        margin: 0 10px 0 0; } } }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    margin-right: 15px;
    border-radius: 12px;
    background: rgba($blue, .1);
    .icon {
      font-size: 24px;
      fill: $blue-light; } } }

@include dark {
  .forums {
    &__title {
      color: $wh; }
    &__filters {
      background: $dark2;
      &:hover {
        background: $dark3;
        .icon {
          fill: $wh; } } }
    &__item {
      background: $dark2;
      border: 1px solid $dark3; }
    &__preview {
      border-color: $dark3; }
    &__info {
      color: $wh; }
    &__man {
      color: $wh; }
    &__action {
      border-color: $dark5;
      &:hover {
        background: $dark5;
        .icon {
          fill: $wh; } } }
    &__input {
      background: $dark2;
      color: $wh; }
    &__link {
      border-color: $dark4;
      color: $wh;
      &:hover {
        border-color: $blue-light; } }
    &__icon {
      background: rgba($blue-light, .1);
      .icon {
        @include m {
          fill: $gray; } } }
    &__view {
      color: #1E75FF; } } }



