.files {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -15px 0 0 -20px;
    @include d {
      margin: -10px 0 0 -15px; }
    @include m {
      margin: -6px -3px 0; } }
  &__item {
    display: flex;
    align-items: center;
    flex: 0 0 190px;
    max-width: 190px;
    border-radius: 15px;
    transition: opacity .25s;
    &_doc {
      background: rgba(#C4DBFF, .15);
      .files__icon {
        background: rgba($blue-light, .1);
        .icon {
          fill: $blue-light; } } }
    &_video {
      background: rgba(#FEF2D5, .15);
      .files__icon {
        background: rgba($yellow, .1);
        .icon {
          fill: $yellow; } } }
    &_audio {
      background: rgba(#E2F1D2, .15);
      .files__icon {
        background: rgba($green-dark, .1);
        .icon {
          fill: $green-dark; } } }
    &:hover {
      opacity: .75; } }
  &__list &__item {
    margin: 15px 0 0 20px;
    @include d {
      flex: 0 0 180px;
      max-width: 180px;
      margin: 10px 0 0 15px; }
    @include m {
      flex: 0 0 calc(50% - 6px);
      max-width: calc(50% - 6px);
      margin: 6px 3px 0; } }
  & > &__item {
    @include m {
      max-width: 100%; }
    @include nl {
      margin-bottom: 20px;
      @include d {
        margin-bottom: 15px; }
      @include m {
        margin-bottom: 10px; } } }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    border-radius: 15px;
    font-size: 0;
    .icon {
      font-size: 24px; } }
  &__details {
    flex: 0 0 calc(100% - 56px);
    width: calc(100% - 56px);
    padding: 0 10px; }
  &__name {
    max-width: 102px;
    @include text-overflow;
    @include fp;
    font-size: 14px;
    line-height: (21/14);
    color: $dark;
    @include m {
      max-width: 100%; } }
  &__size {
    font-size: 12px;
    line-height: (22/12);
    color: $gray; } }

@include dark {
  .files {
    &__item {
      &_doc {
        background: rgba(#C4DBFF, .1);
        .files__icon {
          background: $blue-light;
          .icon {
            fill: $wh; } } }
      &_video {
        background: rgba(#FEF2D5, .2);
        .files__icon {
          background: $yellow;
          .icon {
            fill: $wh; } } }
      &_audio {
        background: rgba(#E2F1D2, .2);
        .files__icon {
          background: $green-dark;
          .icon {
            fill: $wh; } } } }
    &__name {
      color: $wh; } } }


