.sidebar2 {
  position: fixed;
  top: 80px;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 250px;
  padding-bottom: 70px;
  background: $white;
  @include fp;
  font-weight: 500;
  @include d {
    top: 0;
    z-index: 10;
    width: 280px;
    padding: 80px 0 70px;
    transform: translateX(-100%);
    transition: transform .25s;
    &.visible {
      transform: translateX(0); } }
  @include t {
    padding: 70px 0; }
  @include m {
    padding: 70px 0 65px; }
  &__wrapper {
    padding: 20px 20px 0;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none; } }
  &__top {
    display: none;
    @include d {
      display: flex;
      height: 80px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      align-items: center;
      flex-shrink: 0;
      padding: 0 20px;
      border-bottom: 1px solid $border; }
    @include t {
      height: 70px; } }
  &__close {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin: 0 15px 0 -4px;
    font-size: 0;
    .icon {
      font-size: 18px;
      fill: $gray;
      @include m {
        font-size: 16px; } } }
  &__top &__logo {
    font-size: 0; }
  &__top &__pic {
    width: 112px;
    &_white {
      display: none; } }
  &__profile {
    display: flex;
    align-items: center;
    margin: 0 -10px 20px;
    padding: 12px;
    background: $wh;
    border-radius: 15px;
    border: 1px solid $border;
    color: $dark;
    transition: border-color .25s;
    @include m {
      margin-bottom: 15px; }
    .ava {
      margin-right: 10px; }
    &:hover {
      border-color: darken($border, 10); } }
  &__user {
    margin-bottom: 5px;
    font-weight: 600; }
  &__login {
    @include f;
    font-size: 12px;
    color: $gray; }
  &__nav {
    display: flex;
    flex-direction: column;
    margin: 0 -20px 45px;
    @include m {
      margin-bottom: 30px; } }
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    height: 52px;
    padding: 0 55px 0 20px;
    color: $dark;
    transition: all .25s;
    @include m {
      height: 44px; }
    .icon {
      margin-right: 20px;
      font-size: 24px;
      fill: $gray;
      transition: fill .25s; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 32px;
      border-radius: 0 3px 3px 0;
      background: $blue;
      opacity: 0;
      transition: opacity .25s; }
    &:hover,
    &.active {
      color: $blue;
      .icon {
        fill: $blue; } }
    &.active {
      &:before {
        opacity: 1; } } }
  &__item &__counter {
    right: 20px; }
  &__category {
    margin-bottom: 24px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: .93px;
    text-transform: uppercase;
    color: $gray;
    @include m {
      margin-bottom: 15px; } }
  &__menu {
    margin-right: -5px; }
  &__counter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    min-width: 28px;
    padding: 0 6px;
    border-radius: 12px;
    background: $red;
    text-align: center;
    @include f;
    font-weight: 400;
    line-height: 22px;
    color: $white; }
  &__link {
    position: relative;
    display: flex;
    align-items: center;
    height: 32px;
    padding-right: 40px;
    color: $dark;
    transition: color .25s;
    &:hover {
      color: $blue; }
    @include nl {
      margin-bottom: 20px;
      @include m {
        margin-bottom: 15px; } } }
  &__letters {
    flex-shrink: 0;
    width: 32px;
    border-radius: 10px;
    text-align: center;
    line-height: 32px;
    color: $white; }
  &__link &__text {
    flex: calc(100% - 47px);
    width: calc(100% - 47px);
    padding-left: 15px;
    @include text-overflow; }
  .switch {
    position: absolute;
    left: 20px;
    bottom: 20px;
    @include m {
      bottom: 15px; } } }

@include dark {
  .sidebar2 {
    background: $dark2;
    box-shadow: inset -1px 0px 0px $dark3;
    &__top {
      border-color: $dark4; }
    &__pic_black {
      display: none; }
    &__pic_white {
      display: inline-block; }
    &__profile {
      border-color: $dark4;
      background: $dark3;
      color: $wh; }
    &__user {
      color: $wh; }
    &__item {
      color: $gray-light;
      .icon {
        fill: $gray-light; }
      &:before {
        background: $wh; }
      &:hover,
      &.active {
        color: $wh;
        .icon {
          fill: $wh; } } }
    &__link {
      color: $wh;
      &:hover {
        color: $blue-light; } } } }




