.code {
  padding: 50px 540px 50px 60px;
  @include w {
    padding: 40px 490px 40px 40px; }
  @include mac {
    padding: 40px 430px 40px 25px; }
  @include t {
    padding: 30px 360px 30px 20px; }
  @include m {
    padding: 30px 20px; }
  &__container {
    max-width: 100%; }
  &__content {
    h1,h2 {
      @include fp;
      font-weight: 600; }
    h1 {
      margin-bottom: 24px;
      font-size: 28px;
      @include t {
        font-size: 25px; }
      @include m {
        margin-bottom: 14px;
        font-size: 21px; } }
    h2 {
      margin-bottom: 12px;
      font-size: 18px; }
    p {
      font-size: 16px;
      line-height: (26/16);
      color: $dark5;
      @include m {
        font-size: 14px; }
      @include nl {
        margin-bottom: 24px;
        @include m {
          margin-bottom: 20px; } } }
    a {
      border-bottom: 1px solid transparent;
      font-weight: 500;
      color: $blue;
      transition: border-color .25s;
      &:hover {
        border-color: $blue; } }
    hr {
      border: none;
      height: 1px;
      background: #F1F1F5;
      color: #F1F1F5;
      @include nl {
        margin-bottom: 30px;
        @include m {
          margin-bottom: 20px; } } }
    table {
      width: 100%;
      @include nf {
        margin-top: 30px; }
      @include nl {
        margin-bottom: 30px; }
      th,
      td {
        white-space: nowrap; }
      th {
        height: 40px;
        padding: 0 15px;
        vertical-align: middle;
        background: #F1F1F5;
        text-align: left;
        @include fp;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: $gray;
        @include m {
          height: 30px;
          padding: 0 10px;
          font-size: 11px; }
        &:first-child {
          border-radius: 12px 0 0 12px;
          @include m {
            border-radius: 5px 0 0 0; } }
        &:last-child {
          border-radius: 0 12px 12px 0;
          @include m {
            border-radius: 0 5px 0 0; } } }
      td {
        height: 70px;
        padding-left: 15px;
        border-bottom: 1px solid #F1F1F5;
        vertical-align: middle;
        font-size: 16px;
        color: $gray;
        @include m {
          height: 46px;
          padding-left: 10px;
          font-size: 12px; }
        &:first-child {
          padding-left: 5px; }
        &:last-child {
          padding-right: 15px;
          @include m {
            padding-right: 10px; }
          @include m {
            border: none; } } } }
    .table-wrap {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      @include m {
        border: 1px solid #F1F1F5;
        border-radius: 5px 5px 0 0; } } }
  &__status {
    display: inline-block;
    padding: 0 15px;
    background: $wh;
    border-radius: 12px;
    font-size: 14px;
    line-height: 34px;
    font-weight: 500;
    @include m {
      padding: 0 10px;
      border-radius: 8px;
      font-size: 12px;
      line-height: 26px; }
    &_blue {
      color: $blue-light; }
    &_yellow {
      color: $yellow; }
    &_green {
      color: $green-dark; }
    &_red {
      color: $red; } }
  .hljs {
    padding: 15px 20px;
    border-radius: 20px;
    @include f;
    font-size: 14px;
    line-height: 1.5; }
  &__content &__box {
    position: relative;
    @include nf {
      margin-top: 30px;
      @include m {
        margin-top: 25px; } }
    @include nl {
      margin-bottom: 30px;
      @include m {
        margin-bottom: 25px; } } }
  &__full {
    display: none;
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 0;
    @include m {
      display: inline-block; }
    .icon {
      font-size: 24px;
      fill: $gray; } }
  &__wrap {
    position: fixed;
    top: 70px;
    right: 0;
    bottom: 0;
    width: 465px;
    padding-top: 50px;
    background: $dark4;
    border-radius: 20px;
    @include mac {
      width: 400px; }
    @include t {
      width: 340px; }
    @include m {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      border-radius: 0;
      z-index: 30;
      transform: scale(.75);
      visibility: hidden;
      opacity: 0;
      transition: all .25s;
      &.show {
        transform: scale(1);
        visibility: visible;
        opacity: 1; } } }
  &__close {
    display: none;
    @include m {
      display: inline-block;
      position: absolute;
      top: 5px;
      right: 5px;
      width: 40px;
      height: 40px;
      font-size: 0;
      .icon {
        font-size: 18px;
        fill: $white; } } }
  &__nav {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 40px;
    border-radius: 20px;
    background: $dark5;
    @include t {
      padding: 0 30px; }
    @include m {
      padding: 0 50px 0 20px;
      border-radius: 0; } }
  &__link {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    @include fp;
    font-size: 16px;
    font-weight: 600;
    color: $gray;
    transition: color .25s;
    @include m {
      font-size: 14px; }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 0;
      height: 4px;
      margin: 0 auto;
      border-radius: 4px 4px 0 0;
      background: $blue-light;
      transition: width .25s; }
    &:hover {
      color: $white; }
    &.active {
      color: $white;
      &:before {
        width: 100%; } }
    @include nl {
      margin-right: 30px;
      @include t {
        margin-right: 25px; }
      @include m {
        margin-right: 20px; } } }
  &__body {
    max-height: 100%;
    padding: 30px;
    overflow: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    @include mac {
      padding: 25px 20px; } }
  &__body &__text {
    font-size: 12px;
    line-height: (22/12);
    color: $gray;
    @include nl {
      margin-bottom: 20px; } }
  &__body &__box {
    @include nl {
      margin-bottom: 20px; } } }

@include dark {
  .code {
    &__content {
      h1,h2 {
        color: $wh; }
      p {
        color: $wh; }
      a {
        color: $blue-dark;
        &:hover {
          border-color: $blue-dark; } }
      hr {
        background: $dark3;
        border-color: $dark3; }
      table {
        th {
          background: $dark3; }
        td {
          border-color: $dark3;
          color: $dark5; } }
      .table-wrap {
        @include m {
          border-color: $dark3; } } }
    &__status {
      background: $dark2; }
    &__nav {
      background: $dark3; }
    &__link {
      color: $dark5;
      &:hover,
      &.active {
        color: $wh; } }
    &__wrap {
      background: $dark2; } } }

