.page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
  @include m {
    padding-top: 60px; }
  &__wrapper {
    display: flex;
    flex-grow: 1;
    background: $wh;
    @include t {
      display: block; } }
  &__container {
    width: 100%;
    padding: 40px 40px 40px 290px;
    @include w {
      padding: 30px 30px 30px 280px; }
    @include mac {
      padding: 30px 25px 30px 275px; }
    @include d {
      padding: 25px 20px 25px 270px; }
    @include t {
      width: 100%;
      padding: 25px 20px; }
    @include m {
      padding: 20px; } } }

@include dark {
  .page {
    &__wrapper {
      background: $dark1; } } }
