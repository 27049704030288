.place {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -20px -10px 0;
    @include m {
      margin: -15px 5px 25px; } }
  &__item {
    flex: 0 0 calc(25% - 20px);
    width: calc(25% - 20px);
    margin: 20px 10px 0;
    border-radius: 15px;
    background: $white;
    overflow: hidden;
    @include mac {
      flex: 0 0 calc(33.333% - 20px);
      width: calc(33.333% - 20px); }
    @include m {
      flex: 0 0 calc(50% - 10px);
      width: calc(50% - 10px);
      margin: 10px 5px 0; } }
  &__preview {
    font-size: 0; }
  &__pic {
    width: 100%; }
  &__item:hover &__title {
    color: $blue-dark; }
  &__body {
    padding: 15px;
    @include m {
      padding: 10px; } }
  &__title {
    margin-bottom: 10px;
    @include fp;
    line-height: (22/14);
    font-weight: 600;
    color: $dark4;
    transition: color .25s;
    @include m {
      line-height: 1.3; } }
  &__line {
    display: flex;
    align-items: center;
    margin-bottom: 6px; }
  &__price {
    margin-right: 14px;
    @include fp;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: $blue;
    @include m {
      margin-right: auto; } }
  &__rating {
    display: flex;
    margin-top: -2px;
    .icon {
      font-size: 16px;
      fill: $gray;
      @include m {
        fill: $orange; }
      @include nl {
        margin-right: 1px; }
      &:not(:first-child) {
        @include m {
          display: none; } } } }
  &__counter {
    display: none;
    @include m {
      display: block;
      margin-left: 5px;
      @include fp;
      font-size: 14px;
      font-weight: 500;
      color: $orange; } }
  &__info {
    line-height: 1.15;
    color: $gray;
    @include m {
      font-size: 12px; } }
  &__time {
    @include nf {
      margin-left: 5px;
      &:before {
        content: "";
        display: inline-block;
        position: relative;
        top: -3px;
        width: 4px;
        height: 4px;
        margin-right: 5px;
        border-radius: 50%;
        background: $gray-light;
        @include m {
          top: -2px; } } } } }

@include dark {
  .place {
    &__item {
      background: $dark2; }
    &__title {
      color: $border;
      @include m {
        color: $wh; } }
    &__price {
      color: $blue-dark; }
    &__rating .icon {
      @include m {
        fill: $yellow; } }
    &__counter {
      @include m {
        color: $yellow; } } } }



