@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap');
@mixin f {
  font-family: 'Roboto', sans-serif; }
@mixin fp {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5; }

html {
  // height: 100%
  &.no-scroll {
    overflow: hidden; } }

body {
  min-width: 375px;
  @include f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.3;
  color: $dark;
  &.no-scroll {
    overflow: hidden; } }

.out {
  overflow: hidden; }

.tooltip {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0); }

.tooltipster-sidetip.tooltipster-shadow.tooltipster-shadow-customized .tooltipster-box {
  background: $dark4;
  border: none;
  border-radius: 7px;
  box-shadow: none; }
.tooltipster-sidetip.tooltipster-shadow.tooltipster-shadow-customized .tooltipster-content {
  padding: 2px 10px;
  @include f;
  font-size: 12px;
  line-height: (22/12);
  color: $wh; }

.title {
  @include fp;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
  @include d {
    font-size: 22px; }
  &_sm {
    font-size: 18px;
    @include d {
      font-size: 18px; } }
  &_big {
    font-size: 28px;
    @include d {
      font-size: 24px; } } }

@include dark {
  .tooltipster-sidetip.tooltipster-shadow.tooltipster-shadow-customized .tooltipster-box {
    background: $dark3; } }

.desktop {
  &-hide {
    @include d {
      display: none; } }
  &-show {
    display: none;
    @include d {
      display: block; } } }

.tablet {
  &-hide {
    @include t {
      display: none; } }
  &-show {
    display: none;
    @include t {
      display: block; } } }

.mobile {
  &-hide {
    @include m {
      display: none; } }
  &-show {
    display: none;
    @include m {
      display: block; } } }

.bg {
  &-green {
    background: $green; }
  &-blue-light {
    background: $blue-light; }
  &-orange {
    background: $orange; }
  &-red {
    background: $red; } }









