.courses2 {
  padding: 20px;
  border-radius: 20px;
  background: $white;
  @include m {
    border-radius: 0; }
  &__top {
    margin-bottom: 20px;
    .switch1 {
      display: none; }
    @include m {
      display: flex;
      align-items: center;
      .switch1 {
        position: relative;
        bottom: -2px;
        display: inline-block; } } }
  &__title {
    text-align: center;
    @include m {
      margin-right: auto;
      text-align: left; } }
  &__item {
    display: block;
    @include m {
      display: flex;
      border: 1px solid #F1F1F5;
      border-radius: 10px; }
    @include nl {
      margin-bottom: 20px;
      @include m {
        margin-bottom: 10px; } } }
  &__preview {
    position: relative;
    z-index: 2;
    font-size: 0;
    @include m {
      flex-shrink: 0;
      width: 80px; } }
  &__pic {
    width: 100%;
    border-radius: 20px;
    @include m {
      height: 100%;
      object-fit: cover;
      border-radius: 10px; } }
  &__body {
    margin-top: -40px;
    padding: 55px 20px 20px;
    border: 1px solid #F1F1F5;
    border-radius: 20px;
    text-align: center;
    @include m {
      flex-grow: 1;
      margin: 0;
      padding: 5px 10px;
      text-align: left;
      border-radius: 0;
      border: none; } }
  &__info {
    margin-bottom: 7px;
    @include fp;
    font-weight: 600;
    color: $dark5;
    transition: color .25s; }
  &__item:hover &__info {
    color: $dark; }
  &__details {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: $gray;
    @include m {
      justify-content: flex-start; } }
  &__view {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 5px;
      font-size: 16px;
      fill: $gray; }
    @include nf {
      position: relative;
      margin-left: 5px;
      padding-left: 8px;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #D8D8D8; } } }
  @include nl {
    margin-bottom: 20px;
    @include m {
      margin-bottom: 10px; } } }

@include dark {
  .courses2 {
    background: $dark2;
    &__title {
      color: $wh; }
    &__item {
      @include m {
        border-color: $dark3;
        background: $dark3;
        border-radius: 10px; } }
    &__item:hover .courses2__info {
      color: $blue-light; }
    &__body {
      border-color: $dark3;
      background: $dark3;
      @include m {
        background: none;
        border: none; } }
    &__info {
      color: #D5D5DC; } } }
