.projects {
  &__container {
    &.cell {
      display: flex;
      align-items: flex-start;
      margin-right: -40px;
      overflow: auto;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      @include w {
        margin-right: -30px; }
      @include mac {
        margin-right: -25px; }
      @include t {
        margin: 0 -20px; }
      .projects__section {
        flex-shrink: 0;
        &:first-child {
          @include t {
            padding-left: 20px; } }
        &:last-child {
          padding-right: 40px;
          @include w {
            padding-right: 30px; }
          @include mac {
            padding-right: 25px; }
          @include t {
            padding-right: 20px; } }
        @include nl {
          margin-right: 20px; } }
      .projects__inner {
        width: 302px; }
      .projects__group {
        max-height: 700px;
        padding: 0 5px 20px;
        overflow: auto;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        @include t {
          max-height: 740px; }
        @include m {
          max-height: calc(100vh - 261px); } }
      .card__preview {
        margin: -5px -5px 10px; }
      .card__head {
        margin-bottom: 15px; }
      .card__title {
        @include text-overflow; }
      .card__parameters {
        margin-bottom: 10px; }
      .card__scale {
        margin-bottom: 10px; }
      .card .options {
        display: none; } }
    &.line {
      .projects__head .options {
        right: 30px;
        @include x {
          right: 20px; } }
      .projects__section {
        @include nl {
          margin-bottom: 20px; } }
      .projects__section_empty {
        display: none; }
      .projects__group {
        padding: 0 5px 5px; }
      .projects__add {
        display: none; }
      .card {
        display: flex;
        align-items: center;
        padding: 17px 25px;
        @include x {
          padding: 15px; } }
      .card__preview {
        display: none; }
      .card__head {
        flex-grow: 1;
        padding-right: 30px;
        @include mac {
          padding-right: 20px; } }
      .card__parameters {
        flex: 0 0 258px;
        padding-right: 20px;
        @include x {
          flex: 0 0 245px; }
        @include mac {
          padding-right: 15px; } }
      .card__scale {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        flex: 0 0 242px;
        margin-right: 50px;
        @include x {
          flex: 0 0 200px;
          margin-right: 30px; }
        @include mac {
          flex: 0 0 170px;
          margin-right: 20px; } }
      .card__percent {
        flex-shrink: 0;
        width: 42px;
        margin: 0;
        text-align: right; }
      .card__line {
        flex-grow: 1; }
      .users {
        flex: 0 0 116px;
        margin: 0 45px 0 0;
        @include x {
          margin-right: 30px; }
        @include mac {
          flex: 0 0 100px;
          margin-right: 20px; } }
      .users__item {
        margin: 0;
        @include nl {
          margin-right: 10px; } } } }
  &__inner {
    border: 1px solid $border;
    border-radius: 23px;
    @include m {
      border-radius: 20px; } }
  &__head {
    position: relative;
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0 60px 0 20px; }
  &__category {
    @include text-overflow;
    @include fp;
    font-size: 16px;
    font-weight: 600;
    color: $dark5; }
  &__head .options {
    position: absolute;
    top: 50%;
    right: 25px;
    z-index: 2;
    transform: translateY(-50%); }
  &__add {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    border-radius: 0 0 23px 23px;
    border-top: 1px solid $border;
    background: $white;
    transition: background .25s;
    @include m {
      border-radius: 0 0 19px 19px; }
    .icon {
      font-size: 18px;
      fill: $gray;
      transition: fill .25s; }
    &:hover {
      background: $border;
      .icon {
        fill: $blue; } } }
  .card {
    @include nl {
      margin-bottom: 10px; } } }

@include dark {
  .projects {
    &__inner {
      border-color: $dark4; }
    &__category {
      color: $gray; }
    &__add {
      background: $dark2;
      border-color: $dark4;
      .icon {
        fill: $wh; }
      &:hover {
        background: $dark4; } } } }

