.users2 {
  display: none;
  padding: 15px 0;
  background: $white;
  overflow: auto;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  @include m {
    display: flex; }
  &::-webkit-scrollbar {
    display: none; }
  &__item {
    padding: 0 10px;
    .ava {
      width: 48px;
      height: 48px;
      margin-bottom: 5px;
      font-size: 18px; }
    &:first-child {
      padding-left: 20px; }
    &:last-child {
      padding-right: 20px; } }
  &__add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-bottom: 5px;
    border-radius: 50%;
    border: 1px solid #F1F1F5;
    font-size: 0;
    .icon {
      font-size: 17px;
      fill: $blue; } }
  &__text {
    margin: 0 -5px;
    text-align: center;
    @include fp;
    font-size: 11px;
    font-weight: 500;
    color: $dark; }
  @include nl {
    margin-bottom: 5px; } }

@include dark {
  .users2 {
    background: $dark2;
    &__add {
      background: $white; }
    &__text {
      color: $wh; }
    .ava__pic {
      border: 1px solid $wh; } } }


