.sidebar4 {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  padding: 78px 0 70px;
  background: #FCFCFE;
  box-shadow: inset 1px 0px 0px $border;
  @include fp;
  font-weight: 500;
  @include d {
    z-index: 10;
    width: 280px;
    transform: translateX(-100%);
    transition: transform .25s;
    &.visible {
      transform: translateX(0); } }
  @include m {
    padding: 60px 0 65px; }
  &__wrapper {
    padding-top: 20px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch; }
  &__top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 78px;
    padding: 0 25px;
    box-shadow: inset 0px -1px 0px $border;
    @include d {
      padding: 0 20px; }
    @include m {
      height: 60px; }
    &_sm {
      height: 70px;
      @include m {
        height: 60px; } } }
  &__close {
    display: none;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin: 0 15px 0 -4px;
    font-size: 0;
    @include d {
      display: inline-block; }
    .icon {
      font-size: 18px;
      fill: $gray;
      @include m {
        font-size: 16px; } } }
  &__top &__logo {
    font-size: 0; }
  &__top &__pic {
    width: 112px;
    &_white {
      display: none; } }
  a.sidebar4__item,
  &__head {
    position: relative;
    display: flex;
    align-items: center;
    height: 52px;
    color: $dark;
    transition: color .25s;
    @include m {
      height: 44px; }
    .icon {
      margin-right: 20px;
      font-size: 24px;
      fill: $gray;
      transition: fill .25s;
      &-search {
        margin: 0 22px 0 2px;
        font-size: 20px; } }
    &:hover,
    &.active {
      color: $blue;
      .icon {
        fill: $blue; } } }
  &__item {
    padding: 0 20px;
    @include nl {
      margin-bottom: 10px; } }
  &__head {
    position: relative;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    cursor: pointer; }
  &__body {
    padding: 10px 0 0 44px; }
  &__link {
    position: relative;
    display: flex;
    align-items: center;
    height: 52px;
    padding-right: 25px;
    font-weight: 400;
    color: $dark;
    transition: color .25s;
    &:hover,
    &.active {
      color: $blue; }
    @include nl {
      margin-bottom: 10px; } }
  &__number {
    position: absolute;
    top: 50%;
    right: 0;
    width: 15px;
    text-align: center;
    transform: translateY(-50%);
    @include f;
    font-weight: 500;
    color: $gray-light; }
  .switch {
    position: absolute;
    left: 20px;
    bottom: 20px;
    @include m {
      bottom: 15px; } }
  &_hide {
    display: none;
    @include d {
      display: block; } } }

@include dark {
  .sidebar4 {
    background: $dark2;
    box-shadow: none;
    &__top {
      box-shadow: inset 0px -1px 0px $dark3; }
    &__pic_black {
      display: none; }
    &__pic_white {
      display: inline-block; }
    a.sidebar4__item,
    &__head {
      color: $gray-light;
      .icon {
        fill: $gray-light; }
      &:hover {
        color: $blue-light;
        .icon {
         fill: $blue-light; } } }
    &__link {
      color: $white;
      &:hover,
      &.active {
        color: $blue-light; } }
    &__number {
      color: $gray-light; } } }




