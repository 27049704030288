.history {
  &__row {
    display: flex;
    align-items: flex-start;
    margin: 0 -15px;
    @include w {
      margin: 0 -10px; }
    @include mac {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      margin: 0 -30px;
      &:before,
      &:after {
        content: "";
        display: block;
        flex-shrink: 0;
        width: 30px;
        height: 1px; } }
    @include t {
      margin: 0 -20px;
      &:before,
      &:after {
        width: 20px; } } }
  &__col {
    flex: 0 0 calc(25% - 30px);
    width: calc(25% - 30px);
    margin: 0 15px;
    padding: 20px;
    border-radius: 10px;
    background: $white;
    @include w {
      flex: 0 0 calc(25% - 20px);
      width: calc(25% - 20px);
      margin: 0 10px; }
    @include x {
      padding: 20px 15px; }
    @include mac {
      flex: 0 0 330px;
      width: 330px;
      margin: 0; }
    @include m {
      border-radius: 20px; }
    @include nl {
      @include mac {
        margin-right: 20px; }
      @include m {
        margin-right: 15px; } } }
  &__top {
    display: flex;
    margin-bottom: 20px;
    padding-left: 5px;
    @include m {
      margin-bottom: 15px;
      padding: 0; }
    .options2 {
      margin-top: 5px;
      margin-left: 20px;
      flex-shrink: 0; } }
  &__title {
    @include fp;
    font-size: 16px;
    font-weight: 600;
    color: $dark; }
  &__top &__title {
    margin-right: auto;
    color: $dark4; }
  &__group {
    @include nl {
      margin-bottom: 20px; } }
  &__card {
    position: relative;
    padding: 20px;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
    transition: border-color .25s;
    @include m {
      padding: 15px; }
    .options2 {
      position: absolute;
      top: 25px;
      right: 15px; }
    .options2__btn .icon {
      font-size: 3px; }
    &:hover {
      border-color: darken(#F1F1F5, 10); }
    @include nl {
      margin-bottom: 20px;
      @include m {
        margin-bottom: 10px; } } }
  &__logo {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    box-shadow: 0 0 0 3px #FAFAFB;
    font-size: 0; }
  &__card &__logo {
    margin-bottom: 20px;
    @include m {
      margin-bottom: 15px; } }
  &__logo &__pic {
    width: 100%;
    min-height: 100%;
    border-radius: 10px; }
  &__card &__title {
    margin-bottom: 7px; }
  &__details {
    @include nl {
      margin-bottom: 16px; } }
  &__company,
  &__date {
    font-weight: 500;
    color: $dark4; }
  &__work {
    color: $gray; }
  &__work,
  &__user &__time {
    @include nf {
      margin-left: 5px;
      &:before {
        content: "";
        position: relative;
        top: -4px;
        display: inline-block;
        width: 3px;
        height: 3px;
        margin-right: 3px;
        border-radius: 50%;
        background: #D8D8D8; } } }
  &__line {
    display: flex;
    @include nl {
      margin-bottom: 16px; } }
  &__place {
    margin-right: auto;
    color: $dark4; }
  &__added {
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: $gray;
    .icon {
      margin-right: 3px;
      font-size: 16px;
      fill: $gray; } }
  &__card &__time {
    margin-right: auto;
    line-height: 23px;
    color: $gray-light; }
  &__status {
    display: inline-block;
    padding: 0 8px;
    border-radius: 5px;
    background: #F1F1F5;
    @include fp;
    line-height: 23px;
    font-size: 10px;
    font-weight: 500;
    color: $dark5; }
  &__item {
    position: relative;
    display: flex;
    padding: 20px;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
    transition: border-color .25s;
    @include m {
      padding: 15px; }
    &:hover {
      border-color: darken(#F1F1F5, 10); }
    @include nl {
      margin-bottom: 20px;
      @include m {
        margin-bottom: 10px; } } }
  &__item &__logo {
    flex-shrink: 0;
    margin-right: 20px;
    @include m {
      margin-right: 15px; } }
  &__item &__title {
    margin-bottom: 6px;
    padding-right: 20px; }
  &__close {
    position: absolute;
    top: 22px;
    right: 20px;
    font-size: 0;
    transition: opacity .25s;
    @include m {
      right: 15px; }
    .icon {
      font-size: 10px;
      fill: $red; }
    &:hover {
      opacity: .75; } }
  &__list {
    margin-bottom: 35px;
    padding-top: 5px;
    @include m {
      margin-bottom: 25px; } }
  &__variant {
    display: flex;
    @include nl {
      margin-bottom: 15px; } }
  &__variant &__wrap {
    margin-right: auto; }
  &__actions {
    display: flex;
    flex-shrink: 0;
    margin-left: 20px;
    padding-top: 7px; }
  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    border-radius: 5px;
    border: 2px solid $gray;
    font-size: 0;
    transition: all .25s;
    .icon {
      font-size: 8px;
      fill: $gray;
      transition: fill .25s; }
    &:hover {
      border-color: $gray-light;
      .icon {
        fill: $gray-light; } }
    &_accept.active {
      border-color: $green;
      background: $green;
      .icon {
        fill: $white; } }
    &_reject.active {
      border-color: $red;
      background: $red;
      .icon {
        fill: $white; } }
    @include nl {
      margin-right: 16px; } }
  &__category {
    margin-bottom: 5px;
    font-size: 12px;
    color: $gray; }
  &__variant &__info {
    font-weight: 500;
    color: $dark4; }
  &__btns {
    display: flex;
    margin: 0 -8px; }
  &__btns &__btn {
    flex: 0 0 calc(50% - 16px);
    min-width: calc(50% - 16px);
    margin: 0 8px; }
  &__user {
    display: flex;
    padding: 20px;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
    transition: border-color .25s;
    @include m {
      padding: 15px; }
    &:hover {
      border-color: darken(#F1F1F5, 10); }
    @include nl {
      margin-bottom: 20px;
      @include m {
        margin-bottom: 10px; } } }
  &__user &__logo {
    position: relative;
    margin-right: 20px;
    @include m {
      margin-right: 15px; } }
  &__brand {
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    width: 20px;
    height: 20px; }
  &__brand &__pic {
    border-radius: 50%; }
  &__user &__title {
    margin-bottom: 12px; }
  &__option {
    display: flex;
    .icon {
      margin-right: 5px;
      font-size: 16px;
      fill: $gray; }
    @include nl {
      margin-bottom: 15px; } }
  &__user &__time,
  &__user &__text {
    color: $dark5; } }

@include dark {
  .history {
    &__col {
      background: $dark2; }
    &__title {
      color: $border; }
    &__company {
      color: $border; }
    &__work,
    &__user .history__time {
      @include nf {
        &:before {
          background: $gray; } } }
    &__place {
      color: $gray-light; }
    &__card .history__time {
      color: $gray; }
    &__logo {
      box-shadow: 0 0 0 3px $dark4; }
    &__status {
      background: $dark3;
      color: #D5D5DC; }
    &__item .history__work {
      color: $dark5; }
    &__variant .history__info {
      color: $wh; }
    &__card,
    &__item,
    &__user {
      border-color: $dark3;
      &:hover {
        border-color: $dark4; } }
    &__date {
      color: $border; }
    &__user .history__work,
    &__user .history__text {
      color: $gray-light; } } }



