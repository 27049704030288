.statistics {
  &__body {
    padding: 25px;
    @include x {
      padding: 20px; }
    @include mac {
      padding: 20px 15px; }
    @include m {
      padding: 15px; } }
  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 33px;
    @include m {
      margin-bottom: 20px; }
    &_mb22 {
      margin-bottom: 22px; } }
  &__title,
  &__man {
    @include fp;
    font-weight: 600; }
  &__title {
    margin-right: auto;
    font-size: 16px; }
  .options2 {
    margin-right: -5px; }
  &__man {
    margin-bottom: 2px;
    font-size: 14px; }
  &__item {
    display: flex;
    align-items: center;
    @include nl {
      margin-bottom: 28px;
      @include m {
        margin-bottom: 20px; } } }
  .ava {
    width: 42px;
    height: 42px;
    font-size: 16px; }
  &__details {
    flex-grow: 1;
    padding: 0 15px;
    @include mac {
      padding: 0 10px; }
    @include d {
      padding: 0 20px; }
    @include m {
      padding: 0 15px; } }
  &__actions {
    display: flex;
    flex-shrink: 0; }
  &__action {
    font-size: 0;
    .icon {
      font-size: 20px;
      fill: $gray;
      transition: fill .25s; }
    &:hover .icon {
      fill: $gray-light; }
    @include nl {
      margin-right: 20px;
      @include mac {
        margin-right: 10px; } } }
  &__id {
    font-size: 12px;
    color: $gray; }
  &__foot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    border-top: 1px solid #F1F1F5;
    @include d {
      height: 50px; } }
  &__view {
    @include fp;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .8px;
    text-transform: uppercase;
    color: $blue;
    transition: color .25s;
    &:hover {
      color: $blue-light; } }
  &__inner {
    margin: 0 -15px;
    @include mac {
      margin: 0 -10px; } }
  &__table {
    display: table;
    width: 100%;
    @include m {
      display: block; } }
  &__row {
    display: table-row;
    @include m {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 15px; }
    &_head {
      @include m {
        display: none; } }
    @include nl {
      @include m {
        border-bottom: 1px solid #F1F1F5; } } }
  &__cell {
    display: table-cell;
    vertical-align: middle;
    height: 59px;
    padding: 0 10px;
    @include mac {
      padding: 0 5px; }
    @include m {
      height: auto;
      padding: 0; }
    &:first-child {
      padding-left: 15px;
      @include mac {
        padding-left: 10px; }
      @include m {
        order: 1;
        flex: 0 0 calc(100% - 82px);
        padding: 0 10px 0 0; } }
    &:nth-child(2) {
      color: $dark4;
      @include m {
        order: 3;
        padding-top: 15px;
        color: $gray; } }
    &:nth-child(3) {
      @include fp;
      white-space: nowrap;
      @include m {
        order: 4;
        margin-left: auto;
        padding-top: 15px; } }
    &:last-child {
      padding-right: 15px;
      @include mac {
        padding-right: 10px; }
      @include m {
        order: 2;
        flex: 0 0 82px;
        padding: 0; } }
    @include nf {
      text-align: right; } }
  &__row_head &__cell {
    height: 38px;
    background: $wh;
    @include fp;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: .8px;
    text-transform: uppercase;
    color: $dark4;
    &:first-child {
      border-radius: 10px 0 0 10px; }
    &:last-child {
      border-radius: 0 10px 10px 0; } }
  &__line {
    display: inline-flex;
    align-items: center;
    @include fp;
    color: $dark; }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 15px;
    border-radius: 50%;
    font-size: 0;
    @include m {
      width: 21px;
      height: 21px;
      margin-right: 7px; }
    .icon {
      font-size: 16px;
      fill: $white;
      @include m {
        font-size: 12px; }
      &-check {
        font-size: 8px;
        @include m {
          font-size: 7px; } }
      &-close {
        font-size: 10px;
        @include m {
          font-size: 8px; } } } }
  &__status {
    display: inline-block;
    min-width: 82px;
    padding: 0 7px;
    border-radius: 5px;
    text-align: center;
    line-height: 26px;
    @include m {
      width: 82px;
      padding: 0; }
    &_completed {
      background: rgba($green, .1);
      color: $green; }
    &_pickup {
      background: rgba($blue-light, .1);
      color: $blue-light; }
    &_declined {
      background: rgba($red, .1);
      color: $red; } } }

@include dark {
  .statistics {
    &__title {
      color: $wh; }
    &__man {
      color: $wh; }
    &__foot {
      border-color: $dark4; }
    &__view {
      font-weight: 600;
      color: $wh;
      &:hover {
        color: $blue-light; } }
    &__row {
      @include m {
        border-color: transparent; } }
    &__row_head .statistics__cell {
      background: $dark3;
      color: $gray; }
    &__cell {
      color: $wh;
      &:nth-child(2) {
        color: $gray; } }
    &__line {
      color: $wh; } } }


