.page1 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
  background: $white;
  &__wrapper {
    display: flex;
    flex-grow: 1;
    &_block {
      display: block; } }
  &__wrap {
    display: flex;
    width: 600px;
    flex: 0 0 600px;
    padding-left: 250px;
    border-right: 1px solid #F1F1F5;
    transition: padding .25s;
    @include mac {
      width: 350px;
      flex: 0 0 350px;
      padding-left: 0; }
    @include d {
      width: 320px;
      flex: 0 0 320px; }
    @include t {
      display: block;
      width: 100%;
      flex: 0 0 100%; } }
  &__container {
    display: flex;
    height: calc(100vh - 70px);
    overflow: auto;
    @include t {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 20;
      height: 100%;
      overflow: visible;
      background: $white;
      visibility: hidden;
      opacity: 0;
      transition: all .25s; }
    &.show {
      visibility: visible;
      opacity: 1; } }
  &.toggle .sidebar1 {
    @include rmin(1366) {
      width: 64px; }
    @include mac {
      transform: translateX(0); } }
  &.toggle &__wrap {
    @include rmin(1366) {
      padding-left: 64px; } }
  &.toggle .messenger {
    @include rmin(1366) {
      padding-left: 64px; } }
  &.toggle .sidebar1__write {
    @include rmin(1366) {
      font-size: 0;
      .icon {
        margin-right: 0; } } }
  &.toggle .sidebar1__head {
    @include rmin(1366) {
      display: none; } }
  &.toggle .sidebar1__chat {
    @include rmin(1366) {
      padding-left: 23px; } }
  &.toggle .sidebar1__status {
    @include rmin(1366) {
      margin-left: 4px; } }
  &.toggle .sidebar1__list {
    @include rmin(1366) {
      padding: 0 17px; } }
  &.toggle .sidebar1__item:before {
    @include rmin(1366) {
      left: 10px;
      right: 10px; } }
  &.toggle .sidebar1__profile:before {
    @include rmin(1366) {
      position: absolute;
      top: 5px;
      right: -2px; } }
  &.toggle .chat {
    @include rmin(1366) {
      display: none; } }
  &.toggle .messages__select {
    @include rmin(1366) {
      display: none; } }
  &.toggle .messages__variants {
    @include rmin(1366) {
      display: flex; } }
  &.toggle .messages__wrap {
    @include rmin(1366) {
      padding-left: 20px;
      padding-right: 20px; } } }

@include dark {
  .page1 {
    background: $dark2;
    &__wrap {
      border-color: $dark3; }
    &__container {
      @include t {
        background: $dark2; } } } }
