.friends {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -30px -15px 0;
    @include d {
      margin: -20px -10px 0; }
    @include t {
      display: block;
      margin: 0; } }
  &__item {
    flex: 0 0 calc(50% - 30px);
    width: calc(50% - 30px);
    margin: 30px 15px 0;
    border-radius: 15px;
    overflow: hidden;
    background: $white;
    @include d {
      flex: 0 0 calc(50% - 20px);
      width: calc(50% - 20px);
      margin: 20px 10px 0; }
    @include t {
      width: 100%;
      margin: 0; }
    @include nl {
      @include t {
        margin-bottom: 20px; }
      @include m {
        margin-bottom: 5px; } } }
  &__bg {
    height: 100px;
    @include cover;
    @include m {
      display: none; } }
  &__body {
    display: flex;
    min-height: 146px;
    padding: 15px 20px;
    @include t {
      min-height: auto; } }
  &__ava {
    flex-shrink: 0;
    width: 92px;
    height: 92px;
    margin: -36px 17px 0 -3px;
    border-radius: 50%;
    border: 3px solid $white;
    font-size: 0;
    @include m {
      width: 56px;
      height: 56px;
      margin: 0 10px 0 0;
      border: none; } }
  &__pic {
    width: 100%;
    min-height: 100%;
    border-radius: 50%; }
  &__details {
    position: relative;
    flex-grow: 1; }
  &__user {
    margin-bottom: 3px;
    padding-right: 90px;
    @include fp;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    @include m {
      font-size: 16px; } }
  &__login {
    margin-bottom: 8px;
    line-height: 1.15;
    color: $gray; }
  &__text {
    font-size: 16px;
    line-height: (26/16);
    color: $dark4;
    @include m {
      font-size: 14px; } }
  &__status {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    padding-top: 1px;
    border: 1px solid rgba($blue, .5);
    border-radius: 10px;
    text-align: center;
    @include fp;
    font-size: 12px;
    font-weight: 600;
    line-height: 25px;
    color: $blue;
    transition: all .25s;
    @include m {
      line-height: 23px; }
    &:hover {
      opacity: .75; }
    &.active {
      background: $blue;
      border-color: $blue;
      opacity: 1;
      color: $wh; } } }

@include dark {
  .friends {
    &__item {
      background: $dark2; }
    &__user {
      color: $wh; }
    &__login {
      color: $dark5; }
    &__text {
      color: $gray; }
    &__status:not(.active) {
      border-color: rgba($wh, .5);
      color: $wh;
      @include m {
       background: $dark3;
       border-color: $dark3; } } } }

