.filters {
  flex-shrink: 0;
  width: 280px;
  @include t {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 20;
    width: 330px;
    padding: 30px 20px;
    background: $wh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateX(-100%);
    transition: all .25s;
    &.show {
      transform: translateX(0); } }
  @include m {
    width: 100%; }
  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 12px; }
  &__stage {
    @include fp;
    font-size: 16px;
    font-weight: 600; }
  &__item > &__stage {
    margin-bottom: 17px; }
  &__back {
    display: none;
    margin-left: auto;
    @include fp;
    font-size: 14px;
    text-decoration: underline;
    color: $blue;
    @include t {
      display: inline-block; } }
  &__info {
    margin-bottom: 15px;
    line-height: 1.15;
    color: $gray; }
  &__item {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $border; }
  &__dropdown {
    position: relative;
    z-index: 2;
    @include nl {
      margin-bottom: 20px; } }
  &__head {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    height: 38px;
    padding: 0 40px 0 15px;
    background: $white;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
    color: $dark4;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    .icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      font-size: 16px;
      fill: #D5D5DC; } }
  &__body {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% - 10px);
    padding: 20px 15px 15px;
    border: 1px solid #F1F1F5;
    background: $white;
    box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
    border-radius: 0 0 10px 10px;
    transform: translateY(-10px);
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
    &_users {
      padding: 20px 5px 10px; } }
  &__dropdown.active &__body {
    transform: translateY(0);
    visibility: visible;
    opacity: 1; }
  &__body &__link {
    display: block;
    padding-right: 40px;
    line-height: 1.15;
    color: $dark5;
    transition: color .25s;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 17px;
      transform: translateY(-50%);
      width: 11px;
      height: 8px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none'%3E%3Cpath fill-rule='evenodd' d='M1.803 2.648c-.262-.26-.684-.257-.943.005s-.257.684.005.943L4.748 7.44c.274.27.72.254.973-.038l5.45-6.3c.24-.278.2-.7-.068-.94s-.7-.2-.94.068L5.18 5.99 1.803 2.648z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat 0 0 / 100% auto; }
    &:hover,
    &.active {
      color: $dark; }
    &.active {
      &:before {
        opacity: 1; } }
    @include nl {
      margin-bottom: 15px; } }
  &__variants {
    display: flex;
    flex-direction: column; }
  &__variants .checkbox {
    @include nl {
      margin-bottom: 15px; } }
  &__line {
    display: flex;
    align-items: center;
    margin-bottom: 17px; }
  &__add {
    margin-left: auto;
    @include fp;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .8px;
    color: $blue;
    transition: color .25s;
    &:hover {
      color: $blue-light; } }
  .ava {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    font-size: 10px; }
  &__user {
    position: relative;
    display: flex;
    align-items: center;
    height: 34px;
    padding: 0 30px 0 10px;
    border-radius: 4px;
    color: $dark5;
    transition: all .25s;
    &:hover,
    &.active {
      background: rgba($blue, .05); }
    &.active {
      .checkbox {
        opacity: 1; } }
    @include nl {
      margin-bottom: 4px; } }
  &__user .checkbox {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity .25s; }
  &__tags {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 10px 15px;
    border-radius: 10px;
    background: $white;
    border: 1px solid #F1F1F5; }
  &__tag {
    position: relative;
    margin: 10px 5px 0;
    padding: 0 24px 0 12px;
    border-radius: 12px;
    border: 1px solid $border;
    line-height: 24px;
    color: $dark4; }
  &__close {
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translateY(-50%);
    font-size: 0;
    .icon {
      font-size: 10px;
      fill: $gray;
      transition: fill .25s; }
    &:hover .icon {
      fill: $gray-light; } }
  &__doc {
    display: flex;
    align-items: center; }
  &__load {
    position: relative;
    overflow: hidden;
    @include nl {
      margin-right: 15px; } }
  &__load &__input {
    position: absolute;
    font-size: 140px;
    opacity: 0;
    cursor: pointer; }
  &__file {
    padding: 1px 14px 0;
    border-radius: 10px;
    border: 1px solid rgba($blue, .5);
    @include fp;
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    color: $blue; }
  &__status {
    color: $gray; }
  &__btns {
    display: flex;
    .btn_gray {
      flex-shrink: 0;
      min-width: 115px; }
    .btn_blue-light {
      flex-grow: 1;
      min-width: auto;
      margin-left: 15px; } } }

@include dark {
  .filters {
    @include t {
      background: $dark1; }
    &__stage {
      color: $wh; }
    &__back {
      color: $gray; }
    &__add {
      color: #1E75FF;
      &:hover {
        color: $blue-light; } }
    &__head {
      background: $dark2;
      border-color: $dark3;
      color: $wh; }
    &__body {
      background: $dark2;
      border-color: $dark3; }
    &__body .filters__link:hover,
    &__body .filters__link.active {
      color: $wh; }
    &__item {
      border-color: $dark3; }
    &__tags {
      border-color: $dark3;
      background: $dark2; }
    &__tag {
      border-color: $dark4;
      color: $wh; }
    &__file {
      border-color: rgba($wh, .5);
      color: $wh; }
    &__user {
      &:hover,
      &.active {
        background: $dark4;
        color: $wh; } } } }

