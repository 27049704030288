.dropdown {
  position: relative;
  min-width: 200px;
  &__head {
    position: relative;
    z-index: 6;
    display: flex;
    align-items: center;
    padding: 0 40px 0 12px;
    border-radius: 10px;
    height: 38px;
    box-shadow: 0px 0px 7px rgba(41, 41, 50, 0.1);
    background: $white;
    transition: opacity .25s;
    &:before,
    &:after {
      content: "";
      position: absolute; }
    &:before {
      top: 0;
      right: 28px;
      bottom: 0;
      width: 1px;
      background: #F1F1F5; }
    &:after {
      top: 50%;
      right: 9px;
      transform: translateY(-50%);
      @include arr(11,7,$gray,b);
      transition: transform .25s; }
    &:hover {
      opacity: .8; } }
  &__text {
    margin-right: 5px;
    color: $dark5; }
  &__category {
    margin-right: 12px;
    font-weight: 500;
    color: $dark4; }
  &__body {
    position: absolute;
    top: calc(100% - 10px);
    left: 0;
    right: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 10px 15px;
    background: $white;
    border: 1px solid #F1F1F5;
    box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
    border-radius: 0px 0px 8px 8px;
    transform: translateY(10px);
    visibility: hidden;
    opacity: 0;
    transition: all .25s; }
  &.active &__head:after {
    transform: translateY(-50%) rotate(180deg); }
  &.active &__body {
    transform: translateY(0);
    visibility: visible;
    opacity: 1; }
  .checkbox {
    @include nl {
      margin-bottom: 15px; } } }

@include dark {
  .dropdown {
    &__head {
      background: $dark2;
      &:before {
        background: $dark4; } }
    &__text {
      color: $gray; }
    &__category {
      color: #D5D5DC; }
    &__body {
      background: $dark3;
      border-color: $dark4; } } }

