.sidebar5 {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 15;
  width: 250px;
  overflow: hidden;
  padding: 70px 0 120px;
  background: $dark;
  box-shadow: inset -1px 0px 0px $border;
  @include d {
    top: 0;
    transform: translateX(-100%);
    transition: transform .25s;
    &.visible {
      transform: translateX(0); } }
  @include m {
    width: 270px;
    padding: 60px 0 125px; }
  &__wrapper {
    max-height: 100%;
    padding: 20px 0 0;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    @include m {
      padding-top: 20px; }
    &::-webkit-scrollbar {
      display: none; } }
  &__top {
    display: flex;
    height: 70px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    flex-shrink: 0;
    padding: 0 25px;
    @include d {
      padding: 0 20px; }
    @include m {
      height: 60px;
      padding: 0 20px; } }
  &__close {
    display: none;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin: 0 15px 0 -4px;
    font-size: 0;
    @include d {
      display: inline-block; }
    .icon {
      font-size: 18px;
      fill: $gray;
      @include m {
        font-size: 16px; } } }
  &__top &__logo {
    font-size: 0; }
  &__top &__pic {
    width: 112px; }
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    height: 52px;
    padding: 0 20px;
    @include fp;
    font-weight: 500;
    color: $white;
    @include m {
      height: 44px; }
    .icon {
      flex-shrink: 0;
      margin-right: 20px;
      font-size: 24px;
      fill: $gray;
      transition: fill .25s;
      &-search {
        margin-left: 2px;
        font-size: 20px; } }
    &:hover,
    &.active {
      background: rgba(#D5D5DC, .1);
      .icon {
        fill: $white; } }
    &.active {
      font-weight: 600; }
    @include nl {
      margin-bottom: 10px; } }
  &__logout {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background: $orange;
    @include fp;
    font-weight: 600;
    color: $wh;
    transition: background .25s;
    @include m {
      height: 50px; }
    .icon {
      margin-right: 9px;
      font-size: 18px;
      fill: $wh; }
    &:hover {
      background: darken($orange, 10); } }
  .switch {
    position: absolute;
    left: 23px;
    bottom: 80px;
    @include m {
      left: 20px;
      bottom: 75px; } } }

@include dark {
  .sidebar5 {
    background: $dark2;
    box-shadow: inset -1px 0px 0px $dark3;
    // &__item
    //   color: $gray
    //   .icon
    //     fill: $gray
    //   &.active
    //     background: rgba($blue-light, .1)
    //     color: $blue-light
    //     .icon
 } }    //       fill: $blue-light




