.article {
  &__content {
    height: 144px;
    overflow: hidden;
    @include m {
      height: 72px; }
    p {
      font-size: 14px;
      line-height: (24/14);
      @include nl {
        margin-bottom: 23px;
        @include m {
          margin-bottom: 13px; } } } }
  &__more {
    position: relative;
    display: inline-block;
    border-bottom: 1px solid transparent;
    margin-top: 20px;
    @include fp;
    font-size: 14px;
    line-height: 1;
    color: $blue;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include m {
      margin-top: 10px; }
    &:hover {
      border-color: $blue; } } }

@include dark {
  .article {
    &__content {
      color: $gray; }
    &__more {
      color: $blue-dark; } } }
