.messages {
  &__write {
    display: none;
    justify-content: center;
    align-items: center;
    height: 48px;
    margin: 10px 15px;
    border-radius: 10px;
    background: $white;
    box-shadow: 0px 4px 25px rgba(196, 203, 216, 0.25);
    white-space: nowrap;
    font-weight: 600;
    color: $dark4;
    transition: all .25s;
    @include m {
      display: flex; }
    .icon {
      margin-right: 10px;
      font-size: 24px;
      fill: $gray; } }
  &__head {
    display: flex;
    align-items: center;
    height: 78px;
    padding: 10px 20px;
    border-bottom: 1px solid #F1F1F5;
    @include m {
      height: 68px;
      padding: 5px 15px; } }
  &__select {
    min-width: 104px;
    height: 38px;
    padding: 0 40px 0 11px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid $border;
    border-radius: 10px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat calc(100% - 16px) 50% / 11px 7px;
    @include f;
    font-size: 14px;
    letter-spacing: .1px;
    color: $dark4;
    transition: all .25s;
    @include t {
      min-width: 250px; }
    @include m {
      flex-grow: 1;
      min-width: auto;
      margin-right: 20px; }
    &::-ms-expand {
      display: none; }
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000; } }
  &__variants {
    display: none;
    flex-grow: 1;
    margin-right: 20px;
    border: 1px solid $border;
    border-radius: 10px;
    overflow: hidden; }
  &__variants &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    text-align: center;
    @include fp;
    line-height: 36px;
    color: $dark;
    transition: opacity .25s;
    &:before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 10px;
      border-radius: 50%; }
    &.purple {
      &:before {
        background: $purple; }
      &.active {
        background: rgba($purple, .1); } }
    &.green {
      &:before {
        background: $green-dark; }
      &.active {
        background: rgba($green-dark, .1); } }
    &.blue {
      &:before {
        background: $blue; }
      &.active {
        background: rgba($blue, .1); } }
    &.yellow {
      &:before {
        background: $yellow; }
      &.active {
        background: rgba($yellow, .1); } }
    &:hover {
      opacity: .8; }
    &.active {
      opacity: 1; } }
  &__refresh {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-left: auto;
    font-size: 0;
    .icon {
      font-size: 24px;
      fill: $gray;
      transition: fill .25s; }
    &:hover .icon {
      fill: $gray-light; } }
  &__wrap {
    padding: 5px 10px 15px;
    height: calc(100vh - 148px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transition: padding .25s;
    @include m {
      height: calc(100vh - 206px); } }
  &__box {
    position: relative;
    padding: 5px 0;
    @include nf {
      border-top: 1px solid #F1F1F5; } }
  &__item {
    position: relative;
    display: flex;
    padding: 15px;
    border-radius: 15px;
    transition: background .25s;
    &:hover {
      background: rgba($blue, .05); }
    &.active {
      background: $blue-dark;
      .messages__user,
      .messages__time,
      .messages__place {
        color: $white; }
      .messages__add .icon {
        fill: $white; }
      .messages__text {
        color: rgba($white, .7); } } }
  .ava {
    width: 42px;
    height: 42px;
    margin-right: 10px;
    font-size: 16px; }
  .ava__pic {
    border: 1px solid #F1F1F5; }
  &__details {
    flex-grow: 1; }
  &__line {
    display: flex;
    margin-bottom: 5px; }
  &__user {
    margin-right: auto;
    color: $blue;
    transition: color .25s; }
  &__time {
    margin-left: 20px;
    padding-top: 1px;
    font-size: 12px;
    color: $dark5;
    transition: color .25s; }
  &__add {
    position: absolute;
    top: 67px;
    left: 26px;
    font-size: 0;
    .icon {
      font-size: 20px;
      fill: $gray; } }
  &__place {
    margin-bottom: 9px;
    @include text-overflow;
    @include fp;
    font-weight: 600;
    color: $dark;
    transition: color .25s; }
  &__text {
    max-height: 48px;
    overflow: hidden;
    line-height: (24/14);
    color: $gray; } }

@include dark {
  .messages {
    background: $dark2;
    &__write {
      background: $dark3;
      box-shadow: 0px 4px 25px rgba(35, 36, 36, 0.25); }
    &__write .messages__text {
      color: $wh; }
    &__head {
      border-color: $dark3; }
    &__select {
      border-color: #4F4F5A;
      border-radius: 10px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%23b5b5be'/%3E%3C/svg%3E") no-repeat calc(100% - 16px) 50% / 11px 7px;
      color: $gray-light; }
    &__refresh .icon {
      fill: $gray-light; }
    &__refresh:hover .icon {
      fill: $white; }
    &__box {
      border-color: $dark3; }
    &__item:hover {
      background: $dark3; }
    &__place {
      color: $wh; }
    &__variants {
      border: none;
      box-shadow: inset 0 0 0 1px $dark4; }
    &__variants .messages__link {
      color: $gray-light;
      &.purple,
      &.green,
      &.blue,
      &.yellow {
        &.active {
          color: $wh;
          &:before {
            background: #F9F9FA; } } }
      &.purple {
        &.active {
          background: $purple; } }
      &.green {
        &.active {
          background: $green-dark; } }
      &.blue {
        &.active {
          background: $blue; } }
      &.yellow {
        &.active {
          background: $yellow; } } } } }




