.users {
  position: relative;
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  margin: -10px 0 0 -10px;
  font-size: 0;
  @include mac {
    margin: -8px 0 0 -8px; }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin: 10px 0 0 10px;
    border-radius: 50%;
    overflow: hidden;
    @include fp;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    text-transform: uppercase;
    color: $white;
    @include mac {
      width: 26px;
      height: 26px;
      margin: 8px 0 0 8px;
      font-size: 11px; }
    &_counter {
      border: 1px solid $border;
      font-size: 12px;
      font-weight: 400;
      color: $dark5;
      @include mac {
        font-size: 11px; } }
    &:first-child {
      z-index: 6; }
    &:nth-child(2) {
      z-index: 5; }
    &:nth-child(3) {
      z-index: 4; }
    &:nth-child(4) {
      z-index: 3; }
    &:nth-child(5) {
      z-index: 2; }
    &:nth-child(6) {
      z-index: 1; } }

  &__pic {
    width: 100%;
    min-height: 100%; }
  &_sm &__item {
    width: 26px;
    height: 26px;
    font-size: 11px; } }

@include dark {
  .users {
    &__item {
      border: 1px solid $dark4;
      &_counter {
        border-color: $wh;
        color: $wh; } } } }

