.switch1 {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  font-size: 0;
  transition: opacity .25s;
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  &__in {
    display: inline-flex; }
  &__text {
    @include f;
    font-size: 12px;
    color: $dark5;
    @include nl {
      margin-right: 12px; } }
  &__box {
    position: relative;
    display: block;
    flex-shrink: 0;
    width: 32px;
    height: 16px;
    background: $wh;
    border: 1px solid #F1F1F5;
    border-radius: 8px;
    transition: all .25s;
    &:before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      bottom: 0;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: $green-dark;
      transition: all .25s; } }
  &__input:checked + &__in &__box:before {
    transform: translate(100%); }
  @include hover {
    opacity: .85; } }

@include dark {
  .switch1 {
    &__text {
      color: $gray; }
    &__box {
      background: $dark5;
      border-color: $dark5; } } }
