.sidebar7 {
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 250px;
  padding-bottom: 70px;
  background: $white;
  @include fp;
  font-weight: 500;
  @include t {
    top: 0;
    z-index: 10;
    width: 280px;
    padding: 70px 0;
    transform: translateX(-100%);
    transition: transform .25s;
    &.visible {
      transform: translateX(0); } }
  &__wrapper {
    padding: 30px 20px 0;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    @include t {
      padding-top: 20px; } }
  &__top {
    display: none;
    @include t {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 70px;
      padding: 0 20px;
      border-bottom: 1px solid $border; } }
  &__close {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin: 0 15px 0 -4px;
    font-size: 0;
    .icon {
      font-size: 18px;
      fill: $gray;
      @include m {
        font-size: 16px; } } }
  &__top &__logo {
    font-size: 0; }
  &__top &__pic {
    width: 112px;
    &_white {
      display: none; } }
  &__box {
    @include nl {
      margin-bottom: 30px; } }
  &__category {
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $gray; }
  &__menu {
    display: flex;
    flex-direction: column;
    margin: 0 -20px auto; }
  &__item {
    padding: 0 20px;
    @include nl {
      margin-bottom: 10px;
      @include d {
        margin: 0; } } }
  &__item[href],
  &__head {
    position: relative;
    display: flex;
    align-items: center;
    height: 52px;
    color: $dark;
    transition: color .25s;
    @include w {
      height: 48px; }
    @include m {
      height: 44px; }
    .icon {
      margin-right: 20px;
      font-size: 24px;
      fill: $gray;
      transition: fill .25s; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 32px;
      border-radius: 0 3px 3px 0;
      background: $blue;
      opacity: 0;
      transition: opacity .25s; }
    &.active {
      &:before {
        opacity: 1; } } }
  &__item[href]:hover,
  &__item[href].active {
    color: $blue;
    .icon {
      fill: $blue; } }
  &__head {
    position: relative;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    cursor: pointer;
    transition: color .25s;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 12px;
      height: 7px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M6 4.586L10.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5A1 1 0 1 1 1.707.293L6 4.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat 0 0 / 100% auto;
      transition: transform .25s; }
    &:before {
      left: -20px; }
    &:hover {
      color: $blue;
      .icon {
        fill: $blue; } } }
  &__item.active &__head {
    opacity: 1;
    color: $blue;
    .icon {
      fill: $blue; }
    &:before {
      opacity: 1; }
    &:after {
      transform: translateY(-50%) rotate(180deg); } }
  &__counter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    min-width: 28px;
    padding: 0 5px;
    border-radius: 12px;
    background: $red;
    text-align: center;
    @include f;
    font-weight: 400;
    line-height: 22px;
    color: $white; }
  &__body {
    display: none;
    padding-left: 44px; }
  &__item.active &__body {
    display: block; }
  &__link {
    display: flex;
    align-items: center;
    height: 52px;
    font-weight: 400;
    color: $dark;
    transition: opacity .25s;
    @include w {
      height: 48px; }
    @include m {
      height: 44px; }
    &:hover,
    &.active {
      color: $blue; } }
  &__number {
    margin-top: -1px;
    margin-left: auto;
    padding-left: 15px;
    @include f;
    letter-spacing: 0;
    font-weight: 500;
    color: $gray-light; }
  .switch {
    position: absolute;
    left: 20px;
    bottom: 20px;
    @include m {
      bottom: 15px; } }
  .users1 {}
  &_hide {
    display: none;
    @include t {
      display: flex; } } }

@include dark {
  .sidebar7 {
    background: $dark2;
    box-shadow: inset -1px 0px 0px $dark4;
    &__top {
      border-color: $dark4; }
    &__pic_black {
      display: none; }
    &__pic_white {
      display: inline-block; }
    &__category {
      color: $dark5; }
    &__item[href],
    &__head {
      color: $gray-light;
      &:before {
        background: $wh; } }
    &__head:hover {
      color: $wh;
      .icon {
        fill: $wh; } }
    &__item[href]:hover,
    &__item[href].active {
      color: $wh;
      .icon {
        fill: $wh; } }
    &__item.active .sidebar7__head {
      color: $wh;
      .icon {
        fill: $wh; } }
    &__link {
      color: $gray-light;
      &:hover,
      &.active {
        color: $wh; } } } }




