.icon-activity {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-add {
  width: 0.93em;
  height: 1em;
  fill: #92929D;
}
.icon-archive {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-arrow-bottom {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-arrow-circle {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-arrow-down {
  width: 1.71em;
  height: 1em;
  fill: #92929D;
}
.icon-arrow-next {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-arrow-prev {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-arrow-right {
  width: 0.63em;
  height: 1em;
  fill: #0062FF;
}
.icon-arrow-top {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-arrow-up {
  width: 1.71em;
  height: 1em;
  fill: #92929D;
}
.icon-arrows {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-attachment-thin {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-attachment {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-audio {
  width: 1em;
  height: 1em;
  fill: #82C43C;
}
.icon-bell {
  width: 0.9em;
  height: 1em;
  fill: #92929D;
}
.icon-block {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-book {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-briefcase-1 {
  width: 1em;
  height: 1em;
  fill: #3DD598;
}
.icon-briefcase {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-burger {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-calendar-1 {
  width: 1em;
  height: 1em;
  fill: #A461D8;
}
.icon-calendar {
  width: 1.07em;
  height: 1em;
  fill: #92929D;
}
.icon-case {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-chart-1 {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-chart {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-chat {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-check-circle {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-check-sm-thick {
  width: 1.25em;
  height: 1em;
  fill: #92929D;
}
.icon-check-sm {
  width: 1.33em;
  height: 1em;
  fill: #92929D;
}
.icon-check-square {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-check {
  width: 1.27em;
  height: 1em;
  fill: #696974;
}
.icon-clock {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-close-circle {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-close-sm {
  width: 1em;
  height: 1em;
  fill: #B5B5BE;
}
.icon-close-square {
  width: 1em;
  height: 1em;
  fill: #B5B5BE;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-comment {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-content {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-dashboard {
  width: 1em;
  height: 1em;
  fill: #0062FF;
}
.icon-date {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-delivery {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-doc-1 {
  width: 0.83em;
  height: 1em;
  fill: #0062FF;
}
.icon-doc-2 {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-doc-empty {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-doc {
  width: 1em;
  height: 1em;
  fill: #50B5FF;
}
.icon-dots {
  width: 4.5em;
  height: 1em;
  fill: #92929D;
}
.icon-download-doc {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-download {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-draft {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-dropbox {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-edit {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-email {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-emoticon {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-event {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-eye {
  width: 1.17em;
  height: 1em;
  fill: #92929D;
}
.icon-favorite {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-file {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-filter {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-filters-1 {
  width: 1.2em;
  height: 1em;
  fill: #92929D;
}
.icon-filters {
  width: 1.16em;
  height: 1em;
  fill: #92929D;
}
.icon-forward {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-friends-request {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-friends {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-full-screen {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-get-location {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-gif {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-gift {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-goal {
  width: 1em;
  height: 1em;
  fill: #0062FF;
}
.icon-google-drive {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-home {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-image {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-inbox {
  width: 1em;
  height: 1em;
  fill: #0062FF;
}
.icon-info {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-leaderboard {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-library {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-like-thumb {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-like {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-logout {
  width: 0.9em;
  height: 1em;
  fill: #92929D;
}
.icon-messages {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-minimize {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-minus {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-moon {
  width: 1em;
  height: 1em;
  fill: #0062FF;
}
.icon-next {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-phone-call {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-photo {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-place {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-play {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-plus {
  width: 1em;
  height: 1em;
  fill: #FAFAFB;
}
.icon-profile {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-project {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-refresh {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-relationship {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-repeat {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-reply-big {
  width: 1.11em;
  height: 1em;
  fill: #92929D;
}
.icon-reply {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-salary {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-sale {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-saved {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-schedule {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-sent {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-settings-player {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-settings {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-share {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-sort-1 {
  width: 1em;
  height: 1em;
  fill: #FF974A;
}
.icon-sort-2 {
  width: 1em;
  height: 1em;
  fill: #50B5FF;
}
.icon-sorting-1 {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-sorting-2 {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-sorting-3 {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-sorting-4 {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-spam-1 {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-spam {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-star {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-student {
  width: 1em;
  height: 1em;
  fill: #50B5FF;
}
.icon-sun {
  width: 1em;
  height: 1em;
  fill: #F1F1F5;
}
.icon-table {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-task {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-text {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-trash {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-triangle-down {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-triangle-right {
  width: 1em;
  height: 1em;
  fill: #D5D5DC;
}
.icon-video {
  width: 1em;
  height: 1em;
  fill: #FFC542;
}
.icon-view-border {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-view {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-world {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-write {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
.icon-zoom {
  width: 1em;
  height: 1em;
  fill: #92929D;
}
