.header7 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 70px;
  padding: 5px 30px 5px 25px;
  background: $white;
  box-shadow: inset 0px -1px 0px $border;
  @include t {
    padding: 5px 20px; }
  @include m {
    padding: 0 20px; }
  &__burger {
    display: none;
    font-size: 0;
    @include t {
      display: inline-block;
      margin-right: 25px; }
    @include m {
      position: absolute;
      top: 52%;
      left: 20px;
      width: 50px;
      height: 42px;
      transform: translateY(-50%);
      z-index: 2;
      margin: 0; }
    .icon {
      font-size: 32px;
      fill: $gray;
      @include m {
        font-size: 24px; } } }
  &__logo {
    margin-right: 133px;
    font-size: 0;
    @include mac {
      margin-right: 100px; }
    @include d {
      margin-right: 40px; }
    @include m {
      display: none; } }
  &__logo &__pic {
    width: 112px;
    &_white {
      display: none; } }
  &__search {
    position: relative;
    width: 280px;
    margin-right: auto;
    @include d {
      width: 230px; }
    @include m {
      flex-grow: 1;
      width: auto;
      margin-right: 10px; } }
  &__input {
    width: 100%;
    height: 38px;
    padding-left: 30px;
    background: none;
    border: none;
    @include f;
    font-size: 14px;
    color: $dark;
    @include m {
      height: 42px;
      padding: 0 15px 0 50px;
      border-radius: 10px;
      background: $wh; }
    @include placeholder {
      color: $gray; } }
  &__open {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 20px;
    font-size: 0;
    @include m {
      display: none; }
    .icon {
      font-size: 18px;
      fill: $gray;
      transition: fill .25s; }
    &:hover .icon {
      fill: $gray-light; } }
  &__control {
    display: flex;
    align-items: center; }
  &__notifications {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 30px;
    font-size: 0;
    @include m {
      margin-right: 10px; }
    .icon {
      font-size: 22px;
      fill: $gray;
      transition: fill .25s; }
    &:before {
      content: "";
      position: absolute;
      top: -2px;
      right: 1px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $red;
      opacity: 0;
      transition: opacity .25s; }
    &:hover .icon {
      fill: $gray-light; }
    &.active {
      &:before {
        opacity: 1; } } }
  &__user {
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    transition: opacity .25s;
    .icon {
      margin-left: 10px;
      font-size: 16px;
      fill: $gray;
      @include m {
        display: none; } }
    &:hover {
      opacity: .8; } }
  &__box {
    padding-left: 10px;
    line-height: 1;
    @include m {
      display: none; } }
  &__man {
    margin-bottom: 3px;
    @include fp;
    font-size: 14px;
    font-weight: 600;
    color: $dark; }
  &__post {
    font-size: 12px;
    color: $gray; }
  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 8;
    background: rgba($dark, .3);
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    &.visible {
      visibility: visible;
      opacity: 1; } }
  &_settings &__logo {
    margin-right: 40px;
    @include d {
      margin-right: 25px; } }
  &_settings &__search {
    @include d {
      width: 190px; } } }

@include dark {
  .header7 {
    background: $dark2;
    box-shadow: inset 0px -1px 0px $dark4;
    &__pic_black {
      display: none; }
    &__pic_white {
      display: inline-block; }
    &__bg {
      background: rgba($gray, .3); }
    &__input {
      @include m {
        background: $dark3; } }
    &__user .icon {
      fill: $gray-light; }
    &__man {
      color: $wh; }
    &__post {
      color: $dark5; } } }

