.popular {
  border-radius: 15px;
  overflow: hidden;
  background: $white;
  @include m {
    border-radius: 0; }
  &__body {
    padding: 15px 20px 30px;
    @include m {
      padding: 15px 20px 20px; } }
  &__title {
    margin-bottom: 20px;
    @include fp;
    font-weight: 500;
    line-height: (21/14); }
  &__item {
    display: flex;
    @include nl {
      margin-bottom: 20px;
      @include m {
        margin-bottom: 15px; } } }
  &__preview {
    flex-shrink: 0;
    width: 130px;
    font-size: 0;
    @include m {
      width: 70px; } }
  &__pic {
    width: 100%;
    border-radius: 15px;
    @include m {
      border-radius: 10px; } }
  &__details {
    flex-grow: 1;
    padding-left: 15px;
    @include d {
      padding-left: 10px; } }
  &__head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 4px;
    .ava {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      font-size: 11px;
      @include m {
        display: none; } } }
  &__user {
    margin-right: 8px;
    @include fp;
    font-weight: 500;
    line-height: 1.5;
    color: $dark;
    @include m {
      font-size: 12px; } }
  &__time {
    font-size: 12px;
    color: $gray;
    &:before {
      content: "";
      display: inline-block;
      position: relative;
      top: -2px;
      width: 4px;
      height: 4px;
      margin-right: 5px;
      border-radius: 50%;
      background: $gray-light; } }
  &__text {
    line-height: (24/14);
    color: $dark5;
    @include m {
      font-size: 12px; } }
  &__foot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 49px;
    border-top: 1px solid #F1F1F5;
    @include m {
      height: 40px; } }
  &__all {
    @include fp;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .8px;
    color: $blue;
    transition: color .25s;
    &:hover {
      color: $blue-light; } }
  @include nl {
    margin-bottom: 20px;
    @include m {
      margin-bottom: 10px; } } }

@include dark {
  .popular {
    background: $dark2;
    &__title {
      color: $wh; }
    &__user {
      color: $wh; }
    &__text {
      color: #D5D5DC; }
    &__foot {
      border-color: $dark3; }
    &__all {
      color: $wh;
      &:hover {
        color: $blue-light; } } } }

