.gallery {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -30px -10px 0;
    @include t {
      margin-top: -20px; }
    @include m {
      display: block;
      margin: -10px -5px 0;
      padding: 10px 10px 20px;
      @include clr; } }
  &__preview {
    position: relative;
    flex: 0 0 calc(33.333% - 20px);
    width: calc(33.333% - 20px);
    margin: 30px 10px 0;
    border-radius: 15px;
    overflow: hidden;
    font-size: 0;
    cursor: pointer;
    @include t {
      flex: 0 0 calc(50% - 20px);
      width: calc(50% - 20px);
      margin-top: 20px; }
    @include m {
      float: left;
      width: calc(33.33333% - 10px);
      margin: 10px 5px 0; }
    &:first-child {
      @include m {
        width: calc(100% - 10px);
        .gallery__options {
          visibility: visible;
          opacity: 1; } } }
    &:nth-child(8) {
      @include m {
        width: calc(66.66666% - 10px); } }
    &.show {
      @include m {
        width: calc(100% - 10px); } } }
  &__options {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px 50px 20px 20px;
    background: linear-gradient(180deg, rgba(122, 122, 135, 0.0001) -6.26%, #171725 89.85%);
    visibility: hidden;
    opacity: 0;
    transition: all .25s; }
  &__preview:hover &__options {
    visibility: visible;
    opacity: 1;
    @include m {
      visibility: hidden;
      opacity: 0; } }
  &__title {
    margin-bottom: 3px;
    @include fp;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
    color: $white; }
  &__links {
    display: flex;
    flex-wrap: wrap;
    li {
      @include nf {
        position: relative;
        margin-left: 5px;
        padding-left: 9px;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: $white; } } } }
  &__link {
    font-size: 14px;
    color: $white;
    transition: opacity .25s;
    &:hover {
      opacity: .85; } }
  &__pic {
    width: 100%; }
  &__download {
    position: absolute;
    top: 20px;
    right: 20px;
    .icon {
      font-size: 28px;
      fill: $gray;
      transition: fill .25s; }
    &:hover .icon {
      fill: $wh; } }
  .sorting1__title {
    @include m {
      display: none; } }
  .sorting1__variants .sorting1__text {
    @include m {
      display: none; } } }

@include dark {
  .gallery {
    &__link {
      color: $wh; } } }
