.sidebar3 {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 15;
  width: 70px;
  overflow: hidden;
  padding-bottom: 70px;
  box-shadow: inset -1px 0px 0px $border;
  background: $white;
  @include t {
    top: 0;
    width: 330px;
    padding: 70px 0;
    transform: translateX(-100%);
    transition: transform .25s;
    &.visible {
      transform: translateX(0); } }
  @include m {
    width: 100%;
    padding: 60px 0 65px; }
  &__wrapper {
    max-height: 100%;
    padding: 30px 0 0;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    @include m {
      padding-top: 20px; }
    &::-webkit-scrollbar {
      display: none; } }
  &__preview {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    font-size: 0;
    @include t {
      display: none; } }
  &__top {
    display: none;
    @include t {
      display: flex;
      height: 70px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      align-items: center;
      flex-shrink: 0;
      padding: 0 20px;
      border-bottom: 1px solid $border; }
    @include m {
      height: 60px; } }
  &__close {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin: 0 15px 0 -4px;
    font-size: 0;
    .icon {
      font-size: 18px;
      fill: $gray;
      @include m {
        font-size: 16px; } } }
  &__top &__logo {
    font-size: 0; }
  &__top &__pic {
    width: 112px; }
  &__top &__pic,
  &__preview &__pic {
    &_white {
      display: none; } }
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    height: 52px;
    white-space: nowrap;
    @include fp;
    font-weight: 500;
    font-size: 16px;
    color: $dark;
    transition: all .25s;
    @include m {
      height: 44px; }
    .icon {
      flex-shrink: 0;
      margin: 0 23px;
      font-size: 24px;
      fill: $gray;
      transition: fill .25s;
      @include t {
        margin-left: 20px; } }
    &:hover .icon {
      fill: $blue-light; }
    &.active {
      background: rgba($blue-light, .05);
      color: $blue-light;
      .icon {
        fill: $blue-light; } } }
  .switch {
    position: absolute;
    left: 23px;
    bottom: 20px;
    @include m {
      left: 20px;
      bottom: 15px; } }
  &_hide {
    display: none;
    @include t {
      display: block; } } }

@include dark {
  .sidebar3 {
    background: $dark2;
    box-shadow: inset -1px 0px 0px $dark3;
    &__top {
      border-color: $dark4; }
    &__pic_black {
      display: none; }
    &__pic_white {
      display: inline-block; }
    &__item {
      color: $gray;
      .icon {
        fill: $gray; }
      &:hover {
        .icon {
          fill: $wh; } }
      &.active {
        background: rgba($blue-light, .1);
        color: $blue-light;
        .icon {
          fill: $blue-light; } } } } }




