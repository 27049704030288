.page5 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
  background: $wh;
  @include m {
    padding-top: 60px; }
  &__wrapper {
    display: flex;
    flex-grow: 1;
    padding-left: 250px;
    @include d {
      padding: 0; } }
  &__container {
    width: 100%;
    padding: 50px 60px;
    @include w {
      padding: 50px 30px; }
    @include mac {
      padding: 40px 25px; }
    @include m {
      padding: 30px 20px; } } }

@include dark {
  .page5 {
    background: $dark1; } }
