@mixin w {
  @media only screen and (max-width: "1599px") {
    @content; } }
@mixin x {
  @media only screen and (max-width: "1439px") {
    @content; } }
@mixin mac {
  @media only screen and (max-width: "1365px") {
    @content; } }
@mixin d {
  @media only screen and (max-width: "1199px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }
@mixin a {
  @media only screen and (max-width: "639px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "474px") {
    @content; } }

$bl: #000000;
$dark: #171725;
$blue: #0062FF;
$blue-light: #50B5FF;
$yellow: #FFC542;
$green: #3DD598;
$green-dark: #82C43C;
$orange: #FF974A;
$red: #FC5A5A;
$purple: #A461D8;
$pink: #FF9AD5;
$wh: #FAFAFB;
$white: #ffffff;
$gray: #92929D;
$gray-light: #B5B5BE;
$border: #E2E2EA;

$dark1: #13131A;
$dark2: #1C1C24;
$dark3: #292932;
$dark4: #44444F;
$dark5: #696974;
$blue-dark: #1E75FF;



