@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
  background: none;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input {
  appearance: none;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.icon-activity {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-add {
  width: 0.93em;
  height: 1em;
  fill: #92929D;
}

.icon-archive {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-arrow-bottom {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-arrow-circle {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-arrow-down {
  width: 1.71em;
  height: 1em;
  fill: #92929D;
}

.icon-arrow-next {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-arrow-prev {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-arrow-right {
  width: 0.63em;
  height: 1em;
  fill: #0062FF;
}

.icon-arrow-top {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-arrow-up {
  width: 1.71em;
  height: 1em;
  fill: #92929D;
}

.icon-arrows {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-attachment-thin {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-attachment {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-audio {
  width: 1em;
  height: 1em;
  fill: #82C43C;
}

.icon-bell {
  width: 0.9em;
  height: 1em;
  fill: #92929D;
}

.icon-block {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-book {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-briefcase-1 {
  width: 1em;
  height: 1em;
  fill: #3DD598;
}

.icon-briefcase {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-burger {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-calendar-1 {
  width: 1em;
  height: 1em;
  fill: #A461D8;
}

.icon-calendar {
  width: 1.07em;
  height: 1em;
  fill: #92929D;
}

.icon-case {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-chart-1 {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-chart {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-chat {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-check-circle {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-check-sm-thick {
  width: 1.25em;
  height: 1em;
  fill: #92929D;
}

.icon-check-sm {
  width: 1.33em;
  height: 1em;
  fill: #92929D;
}

.icon-check-square {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-check {
  width: 1.27em;
  height: 1em;
  fill: #696974;
}

.icon-clock {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-close-circle {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-close-sm {
  width: 1em;
  height: 1em;
  fill: #B5B5BE;
}

.icon-close-square {
  width: 1em;
  height: 1em;
  fill: #B5B5BE;
}

.icon-close {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-comment {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-content {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-dashboard {
  width: 1em;
  height: 1em;
  fill: #0062FF;
}

.icon-date {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-delivery {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-doc-1 {
  width: 0.83em;
  height: 1em;
  fill: #0062FF;
}

.icon-doc-2 {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-doc-empty {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-doc {
  width: 1em;
  height: 1em;
  fill: #50B5FF;
}

.icon-dots {
  width: 4.5em;
  height: 1em;
  fill: #92929D;
}

.icon-download-doc {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-download {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-draft {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-dropbox {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-edit {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-email {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-emoticon {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-event {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-eye {
  width: 1.17em;
  height: 1em;
  fill: #92929D;
}

.icon-favorite {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-file {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-filter {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-filters-1 {
  width: 1.2em;
  height: 1em;
  fill: #92929D;
}

.icon-filters {
  width: 1.16em;
  height: 1em;
  fill: #92929D;
}

.icon-forward {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-friends-request {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-friends {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-full-screen {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-get-location {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-gif {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-gift {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-goal {
  width: 1em;
  height: 1em;
  fill: #0062FF;
}

.icon-google-drive {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-home {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-image {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-inbox {
  width: 1em;
  height: 1em;
  fill: #0062FF;
}

.icon-info {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-leaderboard {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-library {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-like-thumb {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-like {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-logout {
  width: 0.9em;
  height: 1em;
  fill: #92929D;
}

.icon-messages {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-minimize {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-minus {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-moon {
  width: 1em;
  height: 1em;
  fill: #0062FF;
}

.icon-next {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-phone-call {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-photo {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-place {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-play {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-plus {
  width: 1em;
  height: 1em;
  fill: #FAFAFB;
}

.icon-profile {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-project {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-refresh {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-relationship {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-repeat {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-reply-big {
  width: 1.11em;
  height: 1em;
  fill: #92929D;
}

.icon-reply {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-salary {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-sale {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-saved {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-schedule {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-search {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-sent {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-settings-player {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-settings {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-share {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-sort-1 {
  width: 1em;
  height: 1em;
  fill: #FF974A;
}

.icon-sort-2 {
  width: 1em;
  height: 1em;
  fill: #50B5FF;
}

.icon-sorting-1 {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-sorting-2 {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-sorting-3 {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-sorting-4 {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-spam-1 {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-spam {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-star {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-student {
  width: 1em;
  height: 1em;
  fill: #50B5FF;
}

.icon-sun {
  width: 1em;
  height: 1em;
  fill: #F1F1F5;
}

.icon-table {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-task {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-text {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-trash {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-triangle-down {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-triangle-right {
  width: 1em;
  height: 1em;
  fill: #D5D5DC;
}

.icon-video {
  width: 1em;
  height: 1em;
  fill: #FFC542;
}

.icon-view-border {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-view {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-world {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-write {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

.icon-zoom {
  width: 1em;
  height: 1em;
  fill: #92929D;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.tooltipster-fall, .tooltipster-grow.tooltipster-show {
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-base {
  display: flex;
  pointer-events: none;
  position: absolute;
}

.tooltipster-box {
  flex: 1 1 auto;
}

.tooltipster-content {
  box-sizing: border-box;
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
}

.tooltipster-ruler {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
}

.tooltipster-fade {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  -ms-transition-property: opacity;
  transition-property: opacity;
}

.tooltipster-fade.tooltipster-show {
  opacity: 1;
}

.tooltipster-grow {
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-backface-visibility: hidden;
}

.tooltipster-grow.tooltipster-show {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-swing {
  opacity: 0;
  -webkit-transform: rotateZ(4deg);
  -moz-transform: rotateZ(4deg);
  -o-transform: rotateZ(4deg);
  -ms-transform: rotateZ(4deg);
  transform: rotateZ(4deg);
  -webkit-transition-property: -webkit-transform,opacity;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
}

.tooltipster-swing.tooltipster-show {
  opacity: 1;
  -webkit-transform: rotateZ(0);
  -moz-transform: rotateZ(0);
  -o-transform: rotateZ(0);
  -ms-transform: rotateZ(0);
  transform: rotateZ(0);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 1);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -moz-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -ms-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -o-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
}

.tooltipster-fall {
  -webkit-transition-property: top;
  -moz-transition-property: top;
  -o-transition-property: top;
  -ms-transition-property: top;
  transition-property: top;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-fall.tooltipster-initial {
  top: 0 !important;
}

.tooltipster-fall.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  top: 0 !important;
  opacity: 0;
}

.tooltipster-slide {
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  -ms-transition-property: left;
  transition-property: left;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-slide.tooltipster-initial {
  left: -40px !important;
}

.tooltipster-slide.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  left: 0 !important;
  opacity: 0;
}

@keyframes tooltipster-fading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tooltipster-update-fade {
  animation: tooltipster-fading .4s;
}

@keyframes tooltipster-rotating {
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0);
  }
}

.tooltipster-update-rotate {
  animation: tooltipster-rotating .6s;
}

@keyframes tooltipster-scaling {
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.tooltipster-update-scale {
  animation: tooltipster-scaling .6s;
}

.tooltipster-sidetip .tooltipster-box {
  background: #565656;
  border: 2px solid #000;
  border-radius: 4px;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-box {
  margin-top: 8px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-box {
  margin-right: 8px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-box {
  margin-left: 8px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-box {
  margin-bottom: 8px;
}

.tooltipster-sidetip .tooltipster-content {
  color: #fff;
  line-height: 18px;
  padding: 6px 14px;
}

.tooltipster-sidetip .tooltipster-arrow {
  overflow: hidden;
  position: absolute;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow {
  height: 10px;
  margin-left: -10px;
  top: 0;
  width: 20px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow {
  height: 20px;
  margin-top: -10px;
  right: 0;
  top: 0;
  width: 10px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow {
  height: 20px;
  margin-top: -10px;
  left: 0;
  top: 0;
  width: 10px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow {
  bottom: 0;
  height: 10px;
  margin-left: -10px;
  width: 20px;
}

.tooltipster-sidetip .tooltipster-arrow-background, .tooltipster-sidetip .tooltipster-arrow-border {
  height: 0;
  position: absolute;
  width: 0;
}

.tooltipster-sidetip .tooltipster-arrow-background {
  border: 10px solid transparent;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: #565656;
  left: 0;
  top: 3px;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
  border-left-color: #565656;
  left: -3px;
  top: 0;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
  border-right-color: #565656;
  left: 3px;
  top: 0;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #565656;
  left: 0;
  top: -3px;
}

.tooltipster-sidetip .tooltipster-arrow-border {
  border: 10px solid transparent;
  left: 0;
  top: 0;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: #000;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border {
  border-left-color: #000;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border {
  border-right-color: #000;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #000;
}

.tooltipster-sidetip .tooltipster-arrow-uncropped {
  position: relative;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-uncropped {
  top: -10px;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-uncropped {
  left: -10px;
}

.tooltipster-sidetip.tooltipster-shadow .tooltipster-box {
  border: none;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.1);
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-bottom .tooltipster-box {
  margin-top: 6px;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-left .tooltipster-box {
  margin-right: 6px;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-right .tooltipster-box {
  margin-left: 6px;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-top .tooltipster-box {
  margin-bottom: 6px;
}

.tooltipster-sidetip.tooltipster-shadow .tooltipster-content {
  color: #8d8d8d;
}

.tooltipster-sidetip.tooltipster-shadow .tooltipster-arrow {
  height: 6px;
  margin-left: -6px;
  width: 12px;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-left .tooltipster-arrow, .tooltipster-sidetip.tooltipster-shadow.tooltipster-right .tooltipster-arrow {
  height: 12px;
  margin-left: 0;
  margin-top: -6px;
  width: 6px;
}

.tooltipster-sidetip.tooltipster-shadow .tooltipster-arrow-background {
  display: none;
}

.tooltipster-sidetip.tooltipster-shadow .tooltipster-arrow-border {
  border: 6px solid transparent;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: #fff;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-left .tooltipster-arrow-border {
  border-left-color: #fff;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-right .tooltipster-arrow-border {
  border-right-color: #fff;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #fff;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-bottom .tooltipster-arrow-uncropped {
  top: -6px;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-right .tooltipster-arrow-uncropped {
  left: -6px;
}

/*
 RichText: WYSIWYG editor developed as jQuery plugin

 @name RichText
 @author https://github.com/webfashionist - Bob Schockweiler - richtext@webfashion.eu

 Copyright (C) 2020 Bob Schockweiler ( richtext@webfashion.eu )

 This program is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published
 by the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 This program is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
.richText {
  position: relative;
  background-color: #FAFAFA;
  border: #EFEFEF solid 1px;
  color: #333333;
  width: 100%;
}

.richText .richText-form {
  font-family: Calibri,Verdana,Helvetica,sans-serif;
}

.richText .richText-form label {
  display: block;
  padding: 10px 15px;
}

.richText .richText-form input[type="text"], .richText .richText-form input[type="file"], .richText .richText-form input[type="number"], .richText .richText-form select {
  padding: 10px 15px;
  border: #999999 solid 1px;
  min-width: 200px;
  width: 100%;
}

.richText .richText-form select {
  cursor: pointer;
}

.richText .richText-form button {
  margin: 10px 0;
  padding: 10px 15px;
  background-color: #3498db;
  border: none;
  color: #FAFAFA;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.richText .richText-toolbar {
  min-height: 20px;
  border-bottom: #EFEFEF solid 1px;
}

.richText .richText-toolbar .richText-length {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 13px;
  vertical-align: middle;
  line-height: 34px;
}

.richText .richText-toolbar .richText-length .black {
  color: #000;
}

.richText .richText-toolbar .richText-length .orange {
  color: orange;
}

.richText .richText-toolbar .richText-length .red {
  color: red;
}

.richText .richText-toolbar ul {
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.richText .richText-toolbar ul li {
  float: left;
  display: block;
  list-style: none;
}

.richText .richText-toolbar ul li a {
  display: block;
  padding: 10px 13px;
  border-right: #EFEFEF solid 1px;
  cursor: pointer;
  -webkit-transition: background-color 0.4s;
  -moz-transition: background-color 0.4s;
  transition: background-color 0.4s;
}

.richText .richText-toolbar ul li a .fa, .richText .richText-toolbar ul li a .fas, .richText .richText-toolbar ul li a .far, .richText .richText-toolbar ul li a svg {
  pointer-events: none;
}

.richText .richText-toolbar ul li a .richText-dropdown-outer {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: default;
}

.richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown {
  position: relative;
  display: block;
  margin: 3% auto 0 auto;
  background-color: #FAFAFA;
  border: #EFEFEF solid 1px;
  min-width: 100px;
  width: 300px;
  max-width: 90%;
  -webkit-box-shadow: 0 0 5px 0 #333;
  -moz-box-shadow: 0 0 5px 0 #333;
  box-shadow: 0 0 5px 0 #333;
}

.richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown .richText-dropdown-close {
  position: absolute;
  top: 0;
  right: -23px;
  background: #FFF;
  color: #333;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  width: 20px;
}

.richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown {
  list-style: none;
}

.richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li {
  display: block;
  float: none;
  font-family: Calibri,Verdana,Helvetica,sans-serif;
}

.richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li a {
  display: block;
  padding: 10px 15px;
  border-bottom: #EFEFEF solid 1px;
}

.richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li a:hover {
  background-color: #FFFFFF;
}

.richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li.inline {
  margin: 10px 6px;
  float: left;
}

.richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li.inline a {
  display: block;
  padding: 0;
  margin: 0;
  border: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 10px 0 #999;
  -moz-box-shadow: 0 0 10px 0 #999;
  box-shadow: 0 0 10px 0 #999;
}

.richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li.inline a span {
  display: block;
  height: 30px;
  width: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.richText .richText-toolbar ul li a .richText-dropdown-outer div.richText-dropdown {
  padding: 10px 15px;
}

.richText .richText-toolbar ul li a:hover {
  background-color: #FFFFFF;
}

.richText .richText-toolbar ul li[data-disable="true"] {
  opacity: 0.1;
}

.richText .richText-toolbar ul li[data-disable="true"] a {
  cursor: default;
}

.richText .richText-toolbar ul li:not([data-disable="true"]).is-selected .richText-dropdown-outer {
  display: block;
}

.richText .richText-toolbar ul:after {
  display: block;
  content: "";
  clear: both;
}

.richText .richText-toolbar:last-child {
  font-size: 12px;
}

.richText .richText-toolbar:after {
  display: block;
  clear: both;
  content: "";
}

.richText .richText-editor {
  padding: 20px;
  background-color: #FFFFFF;
  border-left: #FFFFFF solid 2px;
  font-family: Calibri,Verdana,Helvetica,sans-serif;
  height: 300px;
  outline: none;
  overflow-y: scroll;
  overflow-x: auto;
}

.richText .richText-editor ul, .richText .richText-editor ol {
  margin: 10px 25px;
}

.richText .richText-editor table {
  margin: 10px 0;
  border-spacing: 0;
  width: 100%;
}

.richText .richText-editor table td, .richText .richText-editor table th {
  padding: 10px;
  border: #EFEFEF solid 1px;
}

.richText .richText-editor:focus {
  border-left: #3498db solid 2px;
}

.richText .richText-initial {
  margin-bottom: -4px;
  padding: 10px;
  background-color: #282828;
  border: none;
  color: #33FF33;
  font-family: Monospace,Calibri,Verdana,Helvetica,sans-serif;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  min-height: 400px;
  height: 400px;
}

.richText .richText-help {
  float: right;
  display: block;
  padding: 10px 15px;
  cursor: pointer;
}

.richText .richText-undo,
.richText .richText-redo {
  float: left;
  display: block;
  padding: 10px 15px;
  border-right: #EFEFEF solid 1px;
  cursor: pointer;
}

.richText .richText-undo.is-disabled,
.richText .richText-redo.is-disabled {
  opacity: 0.4;
}

.richText .richText-help-popup a {
  color: #3498db;
  text-decoration: underline;
}

.richText .richText-help-popup hr {
  margin: 10px auto 5px auto;
  border: none;
  border-top: #EFEFEF solid 1px;
}

.richText .richText-list.list-rightclick {
  position: absolute;
  background-color: #FAFAFA;
  border-right: #EFEFEF solid 1px;
  border-bottom: #EFEFEF solid 1px;
}

.richText .richText-list.list-rightclick li {
  padding: 5px 7px;
  cursor: pointer;
  list-style: none;
}

.jqvmap-label {
  position: absolute;
  display: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #292929;
  color: white;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  padding: 3px;
  pointer-events: none;
}

.jqvmap-pin {
  pointer-events: none;
}

.jqvmap-zoomin, .jqvmap-zoomout {
  position: absolute;
  left: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #000000;
  padding: 3px;
  color: white;
  width: 10px;
  height: 10px;
  cursor: pointer;
  line-height: 10px;
  text-align: center;
}

.jqvmap-zoomin {
  top: 10px;
}

.jqvmap-zoomout {
  top: 30px;
}

.jqvmap-region {
  cursor: pointer;
}

.jqvmap-ajax_response {
  width: 100%;
  height: 500px;
}

html.no-scroll {
  overflow: hidden;
}

body {
  min-width: 375px;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.3;
  color: #171725;
}

body.no-scroll {
  overflow: hidden;
}

.out {
  overflow: hidden;
}

.tooltip {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-shadow-customized .tooltipster-box {
  background: #44444F;
  border: none;
  border-radius: 7px;
  box-shadow: none;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-shadow-customized .tooltipster-content {
  padding: 2px 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 1.83333;
  color: #FAFAFB;
}

.title {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
}

@media only screen and (max-width: 1199px) {
  .title {
    font-size: 22px;
  }
}

.title_sm {
  font-size: 18px;
}

@media only screen and (max-width: 1199px) {
  .title_sm {
    font-size: 18px;
  }
}

.title_big {
  font-size: 28px;
}

@media only screen and (max-width: 1199px) {
  .title_big {
    font-size: 24px;
  }
}

body.dark .tooltipster-sidetip.tooltipster-shadow.tooltipster-shadow-customized .tooltipster-box {
  background: #292932;
}

@media only screen and (max-width: 1199px) {
  .desktop-hide {
    display: none;
  }
}

.desktop-show {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .desktop-show {
    display: block;
  }
}

@media only screen and (max-width: 1023px) {
  .tablet-hide {
    display: none;
  }
}

.tablet-show {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .tablet-show {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-hide {
    display: none;
  }
}

.mobile-show {
  display: none;
}

@media only screen and (max-width: 767px) {
  .mobile-show {
    display: block;
  }
}

.bg-green {
  background: #3DD598;
}

.bg-blue-light {
  background: #50B5FF;
}

.bg-orange {
  background: #FF974A;
}

.bg-red {
  background: #FC5A5A;
}

.btn {
  min-width: 120px;
  height: 38px;
  padding-top: 1px;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 600;
  transition: all .25s;
}

.btn__pic,
.btn .icon, .btn__text {
  display: inline-block;
  vertical-align: middle;
}

.btn__pic:first-child,
.btn .icon:first-child {
  margin-right: 12px;
}

.btn .icon-plus {
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .btn__hide {
    display: none;
  }
}

.btn_blue {
  background: #0062FF;
  color: #FAFAFB;
}

.btn_blue .icon {
  fill: #FAFAFB;
}

.btn_blue:hover {
  background: #0054db;
}

.btn_blue-light {
  background: #50B5FF;
  color: #FAFAFB;
}

.btn_blue-light .icon {
  fill: #FAFAFB;
}

.btn_blue-light:hover {
  background: #27a4ff;
}

.btn_border-gray {
  border: 1px solid #F1F1F5;
  background: #ffffff;
  color: #696974;
}

.btn_border-gray:hover {
  background: #92929D;
  border-color: #92929D;
  color: #ffffff;
}

.btn_gray {
  background: #F1F1F5;
  color: #696974;
}

.btn_gray .icon {
  font-size: 16px;
  fill: #696974;
}

.btn_gray:hover {
  background: #e2e2ea;
}

.btn_yellow {
  background: #FFC542;
  color: #FAFAFB;
}

.btn_yellow .icon {
  fill: #FAFAFB;
}

.btn_yellow:hover {
  background: #ffb50f;
}

.btn_big {
  height: 48px;
}

@media only screen and (max-width: 767px) {
  .btn_big {
    height: 42px;
  }
}

.btn_white {
  background: #ffffff;
  color: #0062FF;
}

.btn_white:hover {
  opacity: .95;
}

.btn_wide {
  min-width: 100%;
}

a.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

body.dark .btn_blue {
  background: #1E75FF;
}

body.dark .btn_blue:hover {
  background: #005ef4;
}

body.dark .btn_border-gray {
  background: #44444F;
  border-color: #44444F;
  color: #FAFAFB;
}

body.dark .btn_border-gray:hover {
  background: #313139;
  border-color: #313139;
}

body.dark .btn_gray {
  background: #4F4F5A;
  color: #FAFAFB;
}

body.dark .btn_gray .icon {
  fill: #FAFAFB;
}

body.dark .btn_gray:hover {
  background: #3e3e47;
}

body.dark .btn_yellow:hover {
  background: #f5a900;
}

.switch {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0;
  transition: opacity .25s;
}

.switch__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch__in {
  display: inline-block;
}

.switch__box {
  position: relative;
  display: block;
  width: 56px;
  height: 32px;
  background: #F1F1F5;
  border-radius: 16px;
  transition: all .25s;
}

.switch__box:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 6px solid #0062FF;
  transition: all .25s;
}

.switch__icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 0;
}

.switch__icon .icon {
  font-size: 24px;
  fill: #0062FF;
}

.switch__icon .icon-moon {
  display: none;
}

.switch__input:checked + .switch__flex .switch__box:before {
  transform: translate(24px, -50%);
}

.switch_theme .switch__in {
  padding-left: 48px;
}

.switch_theme .switch__input:checked + .switch__in .switch__box:before {
  width: 6px;
  border-width: 3px;
  border-radius: 3px;
  transform: translate(31px, -50%);
  background: #0062FF;
}

.switch_theme .switch__input:checked + .switch__in .icon-moon {
  display: inline-block;
}

.switch_theme .switch__input:checked + .switch__in .icon-sun {
  display: none;
}

@media (hover: hover) {
  .switch:hover {
    opacity: .8;
  }
}

body.dark .switch__box {
  background: #000000;
}

.switch1 {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0;
  transition: opacity .25s;
}

.switch1__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch1__in {
  display: inline-flex;
}

.switch1__text {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #696974;
}

.switch1__text:not(:last-child) {
  margin-right: 12px;
}

.switch1__box {
  position: relative;
  display: block;
  flex-shrink: 0;
  width: 32px;
  height: 16px;
  background: #FAFAFB;
  border: 1px solid #F1F1F5;
  border-radius: 8px;
  transition: all .25s;
}

.switch1__box:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #82C43C;
  transition: all .25s;
}

.switch1__input:checked + .switch1__in .switch1__box:before {
  transform: translate(100%);
}

@media (hover: hover) {
  .switch1:hover {
    opacity: .85;
  }
}

body.dark .switch1__text {
  color: #92929D;
}

body.dark .switch1__box {
  background: #696974;
  border-color: #696974;
}

.login {
  position: relative;
  background: #0062FF;
  color: #92929D;
}

.login__container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 30px 20px 90px;
}

@media only screen and (max-width: 767px) {
  .login__container {
    padding: 40px 20px;
  }
}

.login__wrap {
  width: 100%;
  max-width: 360px;
}

.login__head {
  margin-bottom: 43px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .login__head {
    margin-bottom: 20px;
  }
}

.login__logo {
  display: inline-block;
  font-size: 0;
}

.login__logo .login__pic {
  width: 168px;
}

@media only screen and (max-width: 767px) {
  .login__logo .login__pic {
    width: 140px;
  }
}

.login__form {
  background: #ffffff;
  border-radius: 20px;
}

.login__body {
  padding: 30px 20px 20px;
}

.login__title {
  margin-bottom: 32px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .login__title {
    margin-bottom: 28px;
  }
}

.login__title_sm {
  margin-bottom: 22px;
}

@media only screen and (max-width: 767px) {
  .login__title_sm {
    margin-bottom: 18px;
  }
}

.login__field:not(:last-child) {
  margin-bottom: 15px;
}

.login__status {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.login__progress {
  display: flex;
  flex-shrink: 0;
  width: 212px;
  margin-right: 10px;
}

.login__line {
  flex: 1;
  height: 4px;
  border-radius: 2px;
  background: #E2E2EA;
}

.login__line:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.login__line:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.login__line:not(:last-child) {
  margin-right: 3px;
}

.login__status .login__text {
  font-size: 12px;
}

.login__note {
  max-width: 265px;
  margin: 0 auto 20px;
  text-align: center;
  font-size: 12px;
  line-height: 1.66667;
}

.login__link {
  border-bottom: 1px solid transparent;
  font-size: 12px;
  font-weight: 500;
  color: #0062FF;
  transition: border-color .25s;
}

.login__link:hover {
  border-color: #0062FF;
}

.login__note .login__link {
  font-weight: 400;
}

.login__or {
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
}

.login__btn {
  min-width: 100%;
}

.login__foot {
  border-radius: 0 0 20px 20px;
  padding: 15px;
  background: #FAFAFB;
  text-align: center;
}

.login__btns .login__btn:not(:last-child) {
  margin-bottom: 10px;
}

.login__links {
  display: flex;
  justify-content: center;
}

.login__form .login__links {
  margin-top: 15px;
  padding-top: 20px;
  border-top: 1px solid #F1F1F5;
}

.login__links li:not(:first-child) {
  position: relative;
  padding-left: 8px;
}

.login__links li:not(:first-child):before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #0062FF;
}

.login__links li:not(:last-child) {
  margin-right: 5px;
}

.login__bottom {
  margin-top: 25px;
}

.login__bottom .login__links li:before {
  background: #FAFAFB;
}

.login__bottom .login__link {
  border-bottom: 1px solid transparent;
  color: #FAFAFB;
  transition: border-color .25s;
}

.login__bottom .login__link:hover {
  border-color: #FAFAFB;
}

.login .switch {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .login .switch {
    display: none;
  }
}

.login .switch .icon-sun {
  fill: #F1F1F5;
}

body.dark .login {
  color: #FAFAFB;
}

body.dark .login__container {
  background: #13131A;
}

body.dark .login__form {
  background: #1C1C24;
}

body.dark .login__title {
  color: #FAFAFB;
}

body.dark .login__line {
  background: #44444F;
}

body.dark .login__status .login__text {
  color: #696974;
}

body.dark .login__link {
  color: #1E75FF;
}

body.dark .login__link:hover {
  border-color: #1E75FF;
}

body.dark .login__foot {
  background: #292932;
}

body.dark .login__foot .login__link {
  color: #FAFAFB;
}

body.dark .login__foot .login__link:hover {
  border-color: #FAFAFB;
}

body.dark .login__form .login__links {
  border-color: #44444F;
}

body.dark .login__links li:before {
  background: #FAFAFB;
}

body.dark .login__links .login__link {
  color: #FAFAFB;
}

body.dark .login__links .login__link:hover {
  border-color: #FAFAFB;
}

.field__wrap {
  position: relative;
}

.field__input {
  width: 100%;
  height: 38px;
  padding: 0 15px;
  background: #FAFAFB;
  border: 1px solid #F1F1F5;
  border-radius: 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  letter-spacing: .1px;
  color: #000000;
}

.field__input::placeholder {
  color: #92929D;
}

.field__view {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 48px;
  height: 100%;
  font-size: 0;
  cursor: pointer;
  transition: opacity .25s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.field__view .icon {
  font-size: 11px;
  fill: #92929D;
  transition: fill .25s;
}

.field__view:hover .icon {
  fill: #B5B5BE;
}

.field__line {
  display: block;
  position: absolute;
  top: 19px;
  left: 14px;
  width: 20px;
  height: 1px;
  transform: rotate(-45deg);
  border-radius: 1px;
}

.field__line:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 0;
  height: 100%;
  background: #92929D;
  transition: width .25s;
}

.field__view.active .field__line:before {
  width: 100%;
}

.field_icon .field__input {
  padding-right: 48px;
}

body.dark .field__input {
  border-color: #292932;
  background: #292932;
  color: #FAFAFB;
}

.field1 {
  position: relative;
}

.field1__wrap {
  position: relative;
}

.field1__label {
  position: absolute;
  top: 15px;
  left: 11px;
  z-index: 2;
  padding: 0 5px;
  background: #ffffff;
  font-size: 14px;
  letter-spacing: .1px;
  color: #B5B5BE;
  pointer-events: none;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .field1__label {
    top: 12px;
  }
}

.field1__input, .field1__textarea {
  width: 100%;
  border: 1px solid #E2E2EA;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  letter-spacing: .1px;
  color: #44444F;
  transition: all .25s;
}

.field1__input::placeholder, .field1__textarea::placeholder {
  color: #B5B5BE;
}

.field1__input:focus, .field1__textarea:focus {
  border-color: #50B5FF;
}

.field1__input {
  height: 48px;
  padding: 0 15px;
}

@media only screen and (max-width: 767px) {
  .field1__input {
    height: 42px;
  }
}

.field1__textarea {
  height: 96px;
  padding: 15px;
  resize: none;
}

@media only screen and (max-width: 767px) {
  .field1__textarea {
    height: 84px;
    padding: 12px 15px;
  }
}

.field1__select {
  min-width: 100%;
  height: 48px;
  padding: 0 40px 0 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #E2E2EA;
  border-radius: 10px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%23b5b5be'/%3E%3C/svg%3E") no-repeat calc(100% - 16px) 50%/11px 7px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  letter-spacing: .1px;
  color: #44444F;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .field1__select {
    height: 42px;
  }
}

.field1__select::-ms-expand {
  display: none;
}

.field1__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.field1__note {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  color: #44444F;
}

.field1__view {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 48px;
  height: 100%;
  font-size: 0;
  cursor: pointer;
  transition: opacity .25s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.field1__view .icon {
  font-size: 11px;
  fill: #92929D;
  transition: fill .25s;
}

.field1__view:hover .icon {
  fill: #B5B5BE;
}

.field1__line {
  display: block;
  position: absolute;
  top: 24px;
  left: 14px;
  width: 20px;
  height: 1px;
  transform: rotate(-45deg);
  border-radius: 1px;
}

@media only screen and (max-width: 767px) {
  .field1__line {
    top: 21px;
  }
}

.field1__line:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 0;
  height: 100%;
  background: #B5B5BE;
  transition: width .25s;
}

.field1__view.active .icon {
  fill: #B5B5BE;
}

.field1__view.active .field1__line:before {
  width: 100%;
}

.field1_icon .field1__input {
  padding-right: 48px;
}

.field1_email .field1__input {
  padding-right: 150px;
}

.field1_textarea {
  font-size: 0;
}

.field1_select .field1__select {
  font-size: 0;
}

.field1_select.active .field1__select {
  font-size: 14px;
}

.field1.active .field1__label {
  transform: translateY(-23px);
  background: #ffffff;
  font-size: 12px;
  color: #50B5FF;
}

@media only screen and (max-width: 767px) {
  .field1.active .field1__label {
    transform: translateY(-20px);
  }
}

.field1.error .field1__label {
  color: #FC5A5A;
}

.field1.error .field1__input {
  border-color: #FC5A5A;
}

body.dark .field1__label {
  background: #292932;
  color: #92929D;
}

body.dark .field1__input, body.dark .field1__textarea, body.dark .field1__select {
  border-color: #292932;
  background: #292932;
  color: #FAFAFB;
}

body.dark .field1__input::placeholder, body.dark .field1__textarea::placeholder {
  color: #92929D;
}

body.dark .field1__select {
  background: #292932 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat calc(100% - 16px) 50%/11px 7px;
}

body.dark .field1__note {
  color: #FAFAFB;
}

body.dark .field1.active .field1__label {
  background: #1C1C24;
  color: #92929D;
}

body.dark .field1.active .field1__input,
body.dark .field1.active .field1__textarea,
body.dark .field1.active .field1__select {
  border-color: #92929D;
  background: transparent;
}

body.dark .field1.error .field1__label {
  color: #FC5A5A;
}

body.dark .field1.error .field1__input {
  border-color: #FC5A5A;
}

.field2 {
  position: relative;
}

.field2__wrap {
  position: relative;
}

.field2__label {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 2;
  background: #ffffff;
  font-size: 14px;
  letter-spacing: .1px;
  color: #92929D;
  pointer-events: none;
  transition: all .25s;
}

.field2__input {
  width: 100%;
  height: 40px;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: #E2E2EA;
  border-radius: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #44444F;
  transition: all .25s;
}

.field2__input::placeholder {
  color: #92929D;
}

.field2__input:focus {
  border-color: #50B5FF;
}

.field2__select {
  min-width: 100%;
  height: 40px;
  padding: 0 20px 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: #E2E2EA;
  border-radius: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat 100% 50%/11px 7px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #44444F;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .field2__select {
    height: 42px;
  }
}

.field2__select::-ms-expand {
  display: none;
}

.field2__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.field2_select .field2__select {
  font-size: 0;
}

.field2_select.active .field2__select {
  font-size: 14px;
}

.field2.active .field2__label {
  transform: translateY(-19px);
  font-size: 12px;
}

body.dark .field2__label {
  background: #1C1C24;
}

body.dark .field2__input, body.dark .field2__select {
  border-color: #292932;
  color: #F1F1F5;
}

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
}

@media only screen and (max-width: 767px) {
  .page {
    padding-top: 60px;
  }
}

.page__wrapper {
  display: flex;
  flex-grow: 1;
  background: #FAFAFB;
}

@media only screen and (max-width: 1023px) {
  .page__wrapper {
    display: block;
  }
}

.page__container {
  width: 100%;
  padding: 40px 40px 40px 290px;
}

@media only screen and (max-width: 1599px) {
  .page__container {
    padding: 30px 30px 30px 280px;
  }
}

@media only screen and (max-width: 1365px) {
  .page__container {
    padding: 30px 25px 30px 275px;
  }
}

@media only screen and (max-width: 1199px) {
  .page__container {
    padding: 25px 20px 25px 270px;
  }
}

@media only screen and (max-width: 1023px) {
  .page__container {
    width: 100%;
    padding: 25px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .page__container {
    padding: 20px;
  }
}

body.dark .page__wrapper {
  background: #13131A;
}

.page1 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
  background: #ffffff;
}

.page1__wrapper {
  display: flex;
  flex-grow: 1;
}

.page1__wrapper_block {
  display: block;
}

.page1__wrap {
  display: flex;
  width: 600px;
  flex: 0 0 600px;
  padding-left: 250px;
  border-right: 1px solid #F1F1F5;
  transition: padding .25s;
}

@media only screen and (max-width: 1365px) {
  .page1__wrap {
    width: 350px;
    flex: 0 0 350px;
    padding-left: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .page1__wrap {
    width: 320px;
    flex: 0 0 320px;
  }
}

@media only screen and (max-width: 1023px) {
  .page1__wrap {
    display: block;
    width: 100%;
    flex: 0 0 100%;
  }
}

.page1__container {
  display: flex;
  height: calc(100vh - 70px);
  overflow: auto;
}

@media only screen and (max-width: 1023px) {
  .page1__container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    height: 100%;
    overflow: visible;
    background: #ffffff;
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
  }
}

.page1__container.show {
  visibility: visible;
  opacity: 1;
}

@media only screen and (min-width: 1366px) {
  .page1.toggle .sidebar1 {
    width: 64px;
  }
}

@media only screen and (max-width: 1365px) {
  .page1.toggle .sidebar1 {
    transform: translateX(0);
  }
}

@media only screen and (min-width: 1366px) {
  .page1.toggle .page1__wrap {
    padding-left: 64px;
  }
}

@media only screen and (min-width: 1366px) {
  .page1.toggle .messenger {
    padding-left: 64px;
  }
}

@media only screen and (min-width: 1366px) {
  .page1.toggle .sidebar1__write {
    font-size: 0;
  }
  .page1.toggle .sidebar1__write .icon {
    margin-right: 0;
  }
}

@media only screen and (min-width: 1366px) {
  .page1.toggle .sidebar1__head {
    display: none;
  }
}

@media only screen and (min-width: 1366px) {
  .page1.toggle .sidebar1__chat {
    padding-left: 23px;
  }
}

@media only screen and (min-width: 1366px) {
  .page1.toggle .sidebar1__status {
    margin-left: 4px;
  }
}

@media only screen and (min-width: 1366px) {
  .page1.toggle .sidebar1__list {
    padding: 0 17px;
  }
}

@media only screen and (min-width: 1366px) {
  .page1.toggle .sidebar1__item:before {
    left: 10px;
    right: 10px;
  }
}

@media only screen and (min-width: 1366px) {
  .page1.toggle .sidebar1__profile:before {
    position: absolute;
    top: 5px;
    right: -2px;
  }
}

@media only screen and (min-width: 1366px) {
  .page1.toggle .chat {
    display: none;
  }
}

@media only screen and (min-width: 1366px) {
  .page1.toggle .messages__select {
    display: none;
  }
}

@media only screen and (min-width: 1366px) {
  .page1.toggle .messages__variants {
    display: flex;
  }
}

@media only screen and (min-width: 1366px) {
  .page1.toggle .messages__wrap {
    padding-left: 20px;
    padding-right: 20px;
  }
}

body.dark .page1 {
  background: #1C1C24;
}

body.dark .page1__wrap {
  border-color: #292932;
}

@media only screen and (max-width: 1023px) {
  body.dark .page1__container {
    background: #1C1C24;
  }
}

.page2 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 80px;
  background: #FAFAFB;
}

@media only screen and (max-width: 1023px) {
  .page2 {
    padding-top: 70px;
  }
}

.page2__wrapper {
  display: flex;
  flex-grow: 1;
  padding: 0 300px 0 250px;
  transition: padding .25s;
}

@media only screen and (max-width: 1599px) {
  .page2__wrapper {
    padding-right: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .page2__wrapper {
    padding: 0;
  }
}

.page2__container {
  width: 100%;
  padding: 30px;
}

@media only screen and (max-width: 1365px) {
  .page2__container {
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .page2__container {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .page2__container {
    padding: 0;
  }
}

.page2__nav {
  display: none;
}

@media only screen and (max-width: 767px) {
  .page2__nav {
    display: flex;
    margin-bottom: 5px;
    padding: 15px 20px;
    background: #ffffff;
  }
}

.page2__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 0;
  transition: background .25s;
}

.page2__link .icon {
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.page2__link.active {
  background: rgba(0, 115, 255, 0.1);
}

.page2__link.active .icon {
  fill: #0062FF;
}

.page2__link.yes {
  position: relative;
}

.page2__link.yes:before {
  content: "";
  position: absolute;
  top: 8px;
  right: 11px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #FC5A5A;
}

.page2__link:not(:last-child) {
  margin-right: auto;
}

.page2__row {
  display: flex;
  margin: 0 -15px;
}

@media only screen and (max-width: 1365px) {
  .page2__row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media only screen and (max-width: 1023px) {
  .page2__row {
    display: block;
    margin: 0;
  }
}

.page2__row_post {
  margin-top: -10px;
}

@media only screen and (max-width: 1023px) {
  .page2__row_post {
    margin-top: 0;
  }
}

.page2__col {
  margin: 0 15px;
}

@media only screen and (max-width: 1365px) {
  .page2__col {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 1023px) {
  .page2__col {
    margin: 0;
  }
}

.page2__col_w35 {
  flex: 0 0 calc(35.5% - 30px);
  width: calc(35.5% - 30px);
}

@media only screen and (max-width: 1365px) {
  .page2__col_w35 {
    flex: 0 0 calc(35.5% - 20px);
    width: calc(35.5% - 20px);
  }
}

@media only screen and (max-width: 1023px) {
  .page2__col_w35 {
    width: 100%;
  }
}

.page2__col_w65 {
  flex: 0 0 calc(64.5% - 30px);
  width: calc(64.5% - 30px);
}

@media only screen and (max-width: 1365px) {
  .page2__col_w65 {
    flex: 0 0 calc(64.5% - 20px);
    width: calc(64.5% - 20px);
  }
}

@media only screen and (max-width: 1023px) {
  .page2__col_w65 {
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .page2__col:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .page2__col:not(:last-child) {
    margin-bottom: 10px;
  }
}

.page2__flex {
  display: flex;
  margin: 0 -10px;
}

@media only screen and (max-width: 767px) {
  .page2__flex {
    display: block;
    margin: 0;
  }
}

.page2__flex:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .page2__flex:not(:last-child) {
    margin-bottom: 10px;
  }
}

.page2__cell {
  flex: 0 0 calc(50% - 20px);
  width: calc(50% - 20px);
  margin: 0 10px;
}

@media only screen and (max-width: 767px) {
  .page2__cell {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .page2__cell:not(:last-child) {
    margin-bottom: 10px;
  }
}

.page2.toggle .page2__wrapper {
  padding-right: 0;
}

.page2.toggle .users1 {
  transform: translateX(100%);
}

@media only screen and (max-width: 1599px) {
  .page2.toggle .users1 {
    transform: translateX(0);
  }
}

body.dark .page2 {
  background: #13131A;
}

body.dark .page2__nav {
  background: #1C1C24;
}

body.dark .page2__link.active {
  background: rgba(146, 146, 157, 0.2);
}

body.dark .page2__link.active .icon {
  fill: #ffffff;
}

.page3 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
  background: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  .page3 {
    padding-top: 60px;
  }
}

.page3__wrapper {
  display: flex;
  flex-grow: 1;
  padding-left: 70px;
  transition: padding .25s;
}

@media only screen and (max-width: 1023px) {
  .page3__wrapper {
    padding: 0 0 10px;
  }
}

.page3__container {
  width: 100%;
}

.page3__row {
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .page3__row {
    display: block;
  }
}

.page3__col:first-child {
  flex: 0 0 calc(100% - 300px);
  width: calc(100% - 300px);
  padding-right: 40px;
}

@media only screen and (max-width: 1599px) {
  .page3__col:first-child {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .page3__col:first-child {
    margin-bottom: 20px;
    width: 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .page3__col:first-child {
    margin-bottom: 10px;
  }
}

.page3__col:nth-child(2) {
  flex-shrink: 0;
  width: 300px;
}

@media only screen and (max-width: 1023px) {
  .page3__col:nth-child(2) {
    width: 100%;
  }
}

.page3_big {
  padding-top: 80px;
}

@media only screen and (max-width: 1023px) {
  .page3_big {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .page3_big {
    padding-top: 60px;
  }
}

.page3_big .page3__wrapper {
  padding-left: 0;
}

.page3_stream .page3__wrapper {
  padding-left: 400px;
}

@media only screen and (max-width: 1365px) {
  .page3_stream .page3__wrapper {
    padding-left: 70px;
  }
}

@media only screen and (max-width: 1023px) {
  .page3_stream .page3__wrapper {
    padding-left: 0;
  }
}

.page3_stream .page3__container {
  padding: 40px;
}

@media only screen and (max-width: 1599px) {
  .page3_stream .page3__container {
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .page3_stream .page3__container {
    padding: 0;
  }
}

.page3.toggle .page3__wrapper {
  padding-left: 70px;
}

@media only screen and (max-width: 1023px) {
  .page3.toggle .page3__wrapper {
    padding-left: 0;
  }
}

.page3.toggle .courses1 {
  transform: translateX(-100%);
}

@media only screen and (max-width: 1365px) {
  .page3.toggle .courses1 {
    transform: translateX(0);
  }
}

body.dark .page3 {
  background: #13131A;
}

.page4 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 78px;
  background: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  .page4 {
    padding-top: 60px;
  }
}

.page4__wrapper {
  flex-grow: 1;
}

.page4__wrapper_pl250 {
  padding-left: 250px;
}

@media only screen and (max-width: 1199px) {
  .page4__wrapper_pl250 {
    padding: 0;
  }
}

.page4__wrapper_pd80 {
  padding: 0 80px;
}

@media only screen and (max-width: 1439px) {
  .page4__wrapper_pd80 {
    padding-right: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .page4__wrapper_pd80 {
    padding: 0;
  }
}

.page4__wrapper_bg {
  position: relative;
}

.page4__wrapper_bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 392px;
  background: #ffffff;
}

.page4__sidebar {
  position: fixed;
  top: 78px;
  left: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  background: #0062FF;
}

@media only screen and (max-width: 1199px) {
  .page4__sidebar {
    display: none;
  }
}

.page4__sidebar .switch {
  position: absolute;
  left: 29px;
  bottom: 20px;
}

.page4__sidebar .switch__in {
  padding-left: 0;
}

.page4__sidebar .switch__icon {
  position: static;
  transform: translateY(0);
}

.page4__sidebar .switch__icon .icon-sun {
  fill: #FAFAFB;
}

.page4__sidebar .switch__box {
  display: none;
}

.page4__nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -50px;
}

.page4__item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 42px;
  height: 42px;
  border-radius: 10px;
  background: rgba(250, 250, 251, 0.1);
  font-size: 0;
  transition: all .25s;
}

.page4__item .icon {
  font-size: 20px;
  fill: #FAFAFB;
}

.page4__item .icon-search {
  font-size: 17px;
}

.page4__item.active {
  background: rgba(23, 23, 37, 0.1);
}

.page4__item:not(:last-child) {
  margin-bottom: 30px;
}

.page4__counter {
  position: absolute;
  right: -5px;
  bottom: -5px;
  min-width: 16px;
  padding: 0 3px;
  border-radius: 8px;
  background: #FC5A5A;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: #FAFAFB;
}

.page4__tooltip {
  position: absolute;
  top: 50%;
  transform: translate(3px, -50%);
  left: calc(100% + 30px);
  padding: 2px 10px;
  border-radius: 4px;
  background: #292932;
  white-space: nowrap;
  font-size: 12px;
  line-height: 1.83333;
  color: #FAFAFB;
  opacity: 0;
  pointer-events: none;
  transition: all .25s;
}

.page4__tooltip:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 4px 4px 4px 0;
  border-color: transparent #292932 transparent transparent;
}

.page4__item:hover .page4__tooltip {
  transform: translate(0, -50%);
  opacity: 1;
}

.page4__wrapper > .switch {
  position: absolute;
  left: 29px;
  bottom: 20px;
}

@media only screen and (max-width: 1199px) {
  .page4__wrapper > .switch {
    display: none;
  }
}

.page4_theme .page4__wrapper {
  padding-bottom: 60px;
}

@media only screen and (max-width: 1199px) {
  .page4_theme .page4__wrapper {
    padding: 0;
  }
}

.page4_pt70 {
  padding-top: 70px;
}

.page4_white {
  background: #ffffff;
}

body.dark .page4 {
  background: #13131A;
}

body.dark .page4__sidebar {
  background: #1C1C24;
  box-shadow: inset -1px 0 0px #292932;
}

body.dark .page4__wrapper_bg:before {
  background: #1C1C24;
}

body.dark .page4__item {
  background: #292932;
}

body.dark .page4__tooltip {
  background: #44444F;
}

body.dark .page4__tooltip:before {
  border-color: transparent #44444F transparent transparent;
}

.page5 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
  background: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  .page5 {
    padding-top: 60px;
  }
}

.page5__wrapper {
  display: flex;
  flex-grow: 1;
  padding-left: 250px;
}

@media only screen and (max-width: 1199px) {
  .page5__wrapper {
    padding: 0;
  }
}

.page5__container {
  width: 100%;
  padding: 50px 60px;
}

@media only screen and (max-width: 1599px) {
  .page5__container {
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 1365px) {
  .page5__container {
    padding: 40px 25px;
  }
}

@media only screen and (max-width: 767px) {
  .page5__container {
    padding: 30px 20px;
  }
}

body.dark .page5 {
  background: #13131A;
}

.page6 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
  background: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  .page6 {
    padding-top: 60px;
  }
}

.page6__wrapper {
  flex-grow: 1;
  padding: 30px 30px 90px;
}

@media only screen and (max-width: 1023px) {
  .page6__wrapper {
    padding: 30px 20px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .page6__wrapper {
    padding: 25px 20px 30px;
  }
}

.page6__center {
  max-width: 1400px;
  margin: 0 auto;
}

.page6__filters {
  position: relative;
  padding-right: 120px;
  background: #ffffff;
  border-bottom: 1px solid #F1F1F5;
  border-radius: 10px;
  overflow: hidden;
}

@media only screen and (max-width: 1365px) {
  .page6__filters {
    padding-right: 100px;
  }
}

@media only screen and (max-width: 1023px) {
  .page6__filters {
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #F1F1F5;
  }
}

@media only screen and (max-width: 767px) {
  .page6__filters {
    display: block;
    padding: 15px 15px 0;
  }
}

.page6__filters:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .page6__filters:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .page6__filters.show {
    padding: 15px;
  }
  .page6__filters.show .page6__field:not(:first-child) {
    display: block;
  }
  .page6__filters.show .btn_gray {
    display: none;
  }
}

.page6__fieldset {
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .page6__fieldset {
    flex-wrap: wrap;
    margin: -20px -10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .page6__fieldset {
    display: block;
    margin: -10px 0 0;
  }
}

.page6__field {
  position: relative;
  border-right: 1px solid #F1F1F5;
}

.page6__field:first-child {
  flex: 0 0 34%;
  width: 34%;
}

@media only screen and (max-width: 1023px) {
  .page6__field:first-child {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .page6__field:first-child {
    width: 100%;
  }
}

.page6__field:nth-child(2) {
  flex: 0 0 24%;
  width: 24%;
}

@media only screen and (max-width: 1023px) {
  .page6__field:nth-child(2) {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .page6__field:nth-child(2) {
    width: 100%;
  }
}

.page6__field:nth-child(3) {
  flex: 0 0 19%;
  width: 19%;
}

@media only screen and (max-width: 1023px) {
  .page6__field:nth-child(3) {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .page6__field:nth-child(3) {
    width: 100%;
  }
}

.page6__field:nth-child(4) {
  flex: 0 0 23%;
  width: 23%;
  border: none;
}

@media only screen and (max-width: 1023px) {
  .page6__field:nth-child(4) {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .page6__field:nth-child(4) {
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .page6__field {
    border: none;
    margin: 20px 10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .page6__field {
    margin: 10px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .page6__field:not(:first-child) {
    display: none;
  }
}

.page6__input {
  width: 100%;
  height: 70px;
  padding: 0 20px 0 56px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #171725;
}

@media only screen and (max-width: 1365px) {
  .page6__input {
    height: 60px;
    padding: 0 15px 0 40px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1023px) {
  .page6__input {
    height: 42px;
    border-radius: 10px;
    border: 1px solid #F1F1F5;
  }
}

.page6__input::placeholder {
  color: #B5B5BE;
}

.page6__field .page6__icon {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  font-size: 0;
}

@media only screen and (max-width: 1365px) {
  .page6__field .page6__icon {
    left: 0;
    width: 40px;
    text-align: center;
  }
}

.page6__field .page6__icon .icon {
  font-size: 16px;
  fill: #92929D;
}

.page6__field .page6__icon .icon-search {
  font-size: 14px;
}

.page6__filters .btn_blue {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: 70px;
  border-radius: 0 10px 10px 0;
  font-size: 16px;
}

@media only screen and (max-width: 1365px) {
  .page6__filters .btn_blue {
    width: 100px;
    height: 60px;
  }
}

@media only screen and (max-width: 1023px) {
  .page6__filters .btn_blue {
    position: static;
    width: 100%;
    height: 42px;
    margin-top: 20px;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .page6__filters .btn_blue {
    margin-top: 10px;
    font-size: 14px;
  }
}

.page6__filters .btn_gray {
  display: none;
}

@media only screen and (max-width: 767px) {
  .page6__filters .btn_gray {
    display: inline-block;
    min-width: calc(100% + 30px);
    margin: 15px -15px 0;
    border-radius: 0 0 20px 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .page6__filters .btn_gray .icon {
    margin-left: 10px;
    font-size: 6px;
  }
}

.page6__wrapper > .switch {
  position: absolute;
  left: 29px;
  bottom: 20px;
}

@media only screen and (max-width: 1023px) {
  .page6__wrapper > .switch {
    display: none;
  }
}

body.dark .page6 {
  background: #13131A;
}

body.dark .page6__filters {
  background: #1C1C24;
  border-color: #292932;
}

body.dark .page6__field {
  border-color: #292932;
}

body.dark .page6__input {
  background: #1C1C24;
  color: #FAFAFB;
}

@media only screen and (max-width: 1023px) {
  body.dark .page6__input {
    border-color: #292932;
    background: #292932;
  }
}

body.dark .page6__input::placeholder {
  color: #92929D;
}

@media only screen and (max-width: 1023px) {
  body.dark .page6__input::placeholder {
    color: #696974;
  }
}

body.dark .page6 .btn_gray {
  background: #292932;
  color: #E2E2EA;
}

body.dark .page6 .btn_gray:hover {
  background: #30303a;
}

body.dark .page6 .btn_gray .icon {
  fill: #E2E2EA;
}

.page7 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
}

.page7__wrapper {
  display: flex;
  flex-grow: 1;
  background: #FAFAFB;
}

@media only screen and (max-width: 1023px) {
  .page7__wrapper {
    display: block;
  }
}

.page7__container {
  width: 100%;
  padding: 40px 40px 40px 290px;
}

@media only screen and (max-width: 1599px) {
  .page7__container {
    padding: 30px 30px 30px 280px;
  }
}

@media only screen and (max-width: 1365px) {
  .page7__container {
    padding: 30px 25px 30px 275px;
  }
}

@media only screen and (max-width: 1199px) {
  .page7__container {
    padding: 25px 20px 25px 270px;
  }
}

@media only screen and (max-width: 1023px) {
  .page7__container {
    width: 100%;
    padding: 25px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .page7__container {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .page7 .sorting1 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .page7 .sorting1 .dropdown {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .page7 .sorting1__row {
    position: relative;
    display: block;
    padding: 0;
    background: none;
  }
}

@media only screen and (max-width: 767px) {
  .page7 .sorting1__title {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .page7 .sorting1__options .sorting1__btn {
    min-width: 38px;
    height: 38px;
    padding: 0;
    font-size: 0;
  }
  .page7 .sorting1__options .sorting1__btn .icon {
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .page7 .sorting1__options {
    position: absolute;
    top: 5px;
    right: 0;
  }
}

.page7__sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  width: 330px;
  border-right: 1px solid #E2E2EA;
}

@media only screen and (max-width: 1023px) {
  .page7__sidebar {
    z-index: 15;
    transform: translateX(-100%);
    transition: transform .25s;
  }
  .page7__sidebar.visible {
    transform: translateX(0);
  }
}

.page7__sidebar .switch {
  position: absolute;
  left: 29px;
  bottom: 20px;
}

.page7__sidebar .switch__in {
  padding-left: 0;
}

.page7__sidebar .switch__icon {
  position: static;
  transform: translateY(0);
}

.page7__sidebar .switch__icon .icon-sun {
  fill: #FAFAFB;
}

.page7__sidebar .switch__box {
  display: none;
}

.page7__col {
  position: relative;
}

.page7__col:first-child {
  flex: 0 0 80px;
  width: 80px;
  padding: 70px 0 70px;
  background: #0062FF;
}

.page7__col:nth-child(2) {
  flex-grow: 1;
  padding: 70px 0 30px;
  background: #ffffff;
}

.page7__burger {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70px;
  font-size: 0;
  transition: opacity .25s;
}

.page7__burger .icon {
  font-size: 32px;
  fill: #ffffff;
}

.page7__burger:hover {
  opacity: .85;
}

.page7__inner {
  width: 100%;
  padding: 0 320px 40px 350px;
  transition: padding .25s;
}

@media only screen and (max-width: 1365px) {
  .page7__inner {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 1199px) {
  .page7__inner {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .page7__inner {
    padding: 0 20px 40px;
  }
}

.page7__top {
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1199px) {
  .page7__top {
    height: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .page7__top {
    height: 62px;
  }
}

.page7__info {
  margin-left: 10px;
  padding-left: 15px;
  border-left: 1px solid #D8D8D8;
  line-height: 30px;
  color: #696974;
}

@media only screen and (max-width: 767px) {
  .page7__info {
    display: none;
  }
}

.page7__box {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.page7__actions {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .page7__actions {
    margin-left: 20px;
  }
}

.page7__action {
  display: flex;
  align-items: center;
  color: #44444F;
  transition: color .25s;
}

.page7__action .icon {
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.page7__action_profile {
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .page7__action_profile {
    margin-right: 15px;
  }
}

.page7__action:not(:last-child):not(.page7__action_profile) {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .page7__action:not(:last-child):not(.page7__action_profile) {
    margin-right: 15px;
  }
}

.page7__action:hover {
  color: #92929D;
}

.page7__action:hover .icon {
  fill: #B5B5BE;
}

.page7__col:first-child .page7__wrap {
  padding: 15px 19px 0;
}

.page7__col:nth-child(2) .page7__wrap {
  padding: 20px 20px 0;
}

.page7__team {
  display: block;
  position: relative;
  font-size: 0;
  opacity: .5;
  transition: opacity .25s;
}

.page7__team:hover {
  opacity: .75;
}

.page7__team.active {
  opacity: 1;
}

.page7__team_add {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  border: 1px dashed #ffffff;
  border-radius: 10px;
  opacity: 1;
}

.page7__team_add .icon {
  font-size: 18px;
  fill: #ffffff;
}

.page7__team:not(:last-child) {
  margin-bottom: 20px;
}

.page7__team .page7__pic {
  width: 100%;
  min-height: 42px;
  border-radius: 10px;
  border: 1px solid #E2E2EA;
}

.page7__counter {
  position: absolute;
  display: inline-block;
  border-radius: 12px;
  padding: 0 5px;
  background: #FC5A5A;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.page7__team .page7__counter {
  top: -10px;
  right: -10px;
  min-width: 22px;
}

.page7__head {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid #E2E2EA;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}

.page7__group:not(:last-child) {
  margin-bottom: 30px;
}

.page7__category {
  margin-bottom: 9px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #92929D;
}

.page7__menu {
  margin: 0 -10px;
}

.page7__menu .page7__link {
  position: relative;
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 45px 0 10px;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: #171725;
  transition: all .25s;
}

.page7__menu .page7__link:hover, .page7__menu .page7__link.active {
  background: rgba(0, 98, 255, 0.05);
  color: #0062FF;
}

.page7__menu .page7__link.active {
  font-weight: 600;
}

.page7__menu .page7__counter {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  min-width: 28px;
}

.page7 .users1 {
  top: 70px;
}

@media only screen and (max-width: 1599px) {
  .page7 .users1 {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 1365px) {
  .page7 .users1 {
    transform: translateX(100%);
  }
}

.page7__center {
  max-width: 1210px;
  width: 100%;
  margin: 0 auto;
  padding: 30px;
}

@media only screen and (max-width: 1023px) {
  .page7__center {
    padding: 20px 20px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .page7__center {
    padding: 15px 15px 30px;
  }
}

.page7__center .switch {
  margin-top: 40px;
}

@media only screen and (max-width: 1023px) {
  .page7__center .switch {
    display: none;
  }
}

.page7__nav {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .page7__nav {
    position: static;
    justify-content: center;
    margin: 0 -20px;
    background: #ffffff;
    transform: translateX(0);
  }
}

.page7__nav .page7__link {
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
  color: #171725;
  transition: all .25s;
}

@media only screen and (max-width: 1023px) {
  .page7__nav .page7__link {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .page7__nav .page7__link {
    height: 56px;
    font-size: 16px;
  }
}

.page7__nav .page7__link:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1px;
  width: 0;
  height: 3px;
  margin: 0 auto;
  border-radius: 3px 3px 0 0;
  background: #0062FF;
  transition: width .25s;
}

.page7__nav .page7__link:hover, .page7__nav .page7__link.active {
  color: #0062FF;
}

.page7__nav .page7__link.active {
  font-weight: 600;
}

.page7__nav .page7__link.active:before {
  width: 100%;
}

.page7__nav .page7__link:not(:last-child) {
  margin-right: 50px;
}

.page7_messages .header7 {
  left: 330px;
}

@media only screen and (max-width: 1023px) {
  .page7_messages .header7 {
    left: 0;
  }
}

.page7_messages .header7__logo {
  display: none;
}

.page7_messages .page7__wrapper {
  background: #ffffff;
}

.page7.toggle .page7__inner {
  padding-right: 30px;
}

@media only screen and (max-width: 1199px) {
  .page7.toggle .page7__inner {
    padding-right: 20px;
  }
}

.page7.toggle .users1 {
  transform: translateX(100%);
}

@media only screen and (max-width: 1365px) {
  .page7.toggle .users1 {
    transform: translateX(0);
  }
}

body.dark .page7__wrapper {
  background: #13131A;
}

@media only screen and (max-width: 767px) {
  body.dark .page7 .sorting1__row {
    background: none;
  }
}

body.dark .page7__sidebar {
  border-color: #44444F;
}

body.dark .page7__col:first-child {
  background: #13131A;
}

body.dark .page7__col:nth-child(2) {
  background: #1C1C24;
}

body.dark .page7__team_add {
  border-color: rgba(255, 255, 255, 0.5);
}

body.dark .page7__head {
  border-color: #44444F;
  color: #FAFAFB;
}

body.dark .page7__category {
  color: #696974;
}

body.dark .page7__menu .page7__link {
  color: #B5B5BE;
}

body.dark .page7__menu .page7__link:hover, body.dark .page7__menu .page7__link.active {
  background: rgba(213, 213, 220, 0.05);
  color: #FAFAFB;
}

body.dark .page7__title {
  color: #FAFAFB;
}

body.dark .page7__info {
  border-color: #92929D;
  color: #92929D;
}

body.dark .page7__action {
  color: #92929D;
}

body.dark .page7__action .icon {
  fill: #92929D;
}

body.dark .page7__action:hover {
  color: #FAFAFB;
}

body.dark .page7__action:hover .icon {
  fill: #FAFAFB;
}

@media only screen and (max-width: 1023px) {
  body.dark .page7__nav {
    background: #1C1C24;
  }
}

body.dark .page7__nav .page7__link {
  color: #B5B5BE;
}

body.dark .page7__nav .page7__link:before {
  background: #1E75FF;
}

body.dark .page7__nav .page7__link:hover, body.dark .page7__nav .page7__link.active {
  color: #1E75FF;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 70px;
  padding: 5px 20px 5px 25px;
  box-shadow: inset 0px -1px 0px #E2E2EA;
  background: #ffffff;
}

@media only screen and (max-width: 1199px) {
  .header {
    padding: 5px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header {
    height: 60px;
    padding: 5px 20px;
  }
}

.header__burger {
  display: none;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .header__burger {
    display: inline-block;
    margin-right: 20px;
  }
}

.header__burger .icon {
  font-size: 32px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .header__burger .icon {
    font-size: 24px;
  }
}

.header__logo {
  font-size: 0;
}

.header__logo .header__pic {
  width: 112px;
}

.header__logo .header__pic_white {
  display: none;
}

.header__title {
  display: none;
}

@media only screen and (max-width: 767px) {
  .header__title {
    display: block;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    font-size: 18px;
    font-weight: 600;
  }
}

.header__group {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.header__search {
  position: relative;
  flex-shrink: 0;
  width: 86px;
  margin-right: 20px;
  transition: width .25s;
}

@media only screen and (max-width: 767px) {
  .header__search {
    position: static;
    width: auto;
    font-size: 0;
  }
}

.header__search.active {
  width: 240px;
}

@media only screen and (max-width: 767px) {
  .header__search.active {
    width: auto;
  }
}

@media only screen and (max-width: 767px) {
  .header__wrap {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 0;
    overflow: hidden;
    transition: height .25s;
  }
}

.header__search.active .header__wrap {
  height: 38px;
}

.header__input {
  width: 100%;
  height: 38px;
  padding: 0 10px 0 42px;
  background: #F1F1F5;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 600;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .header__input {
    padding: 0 20px;
    border-radius: 0;
    border-bottom: 1px solid #E2E2EA;
  }
}

.header__input::placeholder {
  color: #696974;
}

.header__open {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 42px;
  padding-left: 3px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .header__open {
    position: relative;
    width: auto;
    padding: 0;
  }
}

.header__open .icon {
  font-size: 20px;
  fill: #92929D;
  transition: fill .25s;
}

.header__open:hover .icon {
  fill: #B5B5BE;
}

.header__btn {
  min-width: 86px;
  margin-right: 20px;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .header__btn {
    display: none;
  }
}

.header__notification {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  font-size: 0;
  transition: opacity .25s;
}

@media only screen and (max-width: 767px) {
  .header__notification {
    display: none;
  }
}

.header__notification .icon {
  font-size: 21px;
  fill: #92929D;
  transition: fill .25s;
}

.header__notification:before {
  content: "";
  position: absolute;
  top: -3px;
  right: -2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #FC5A5A;
  opacity: 0;
  transition: opacity .25s;
}

.header__notification:hover .icon {
  fill: #B5B5BE;
}

.header__notification.active:before {
  opacity: 1;
}

.header__notification:not(:last-child) {
  margin-right: 20px;
}

.header__profile {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #B5B5BE;
  font-size: 0;
  transition: opacity .25s;
}

.header__profile:hover {
  opacity: .8;
}

.header__profile .header__pic {
  width: 100%;
  min-height: 32px;
  border-radius: 50%;
}

.header__bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background: rgba(23, 23, 37, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
}

@media only screen and (max-width: 1023px) {
  .header__bg.show {
    visibility: visible;
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .header:not(.header_empty) .header__logo {
    display: none;
  }
}

.header_empty .header__burger {
  display: none;
}

body.dark .header {
  background: #1C1C24;
  box-shadow: inset 0px -1px 0px #292932;
}

@media only screen and (max-width: 767px) {
  body.dark .header {
    box-shadow: none;
  }
}

body.dark .header__pic_black {
  display: none;
}

body.dark .header__pic_white {
  display: inline-block;
}

body.dark .header__input {
  background: #4F4F5A;
  border-color: #44444F;
  color: #FAFAFB;
}

body.dark .header__input::placeholder {
  color: #FAFAFB;
}

body.dark .header__title {
  color: #FAFAFB;
}

body.dark .header__bg {
  background: rgba(146, 146, 157, 0.3);
}

.header1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 70px;
  padding: 5px 20px 5px 15px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #E2E2EA;
}

@media only screen and (max-width: 767px) {
  .header1 {
    padding: 5px 20px;
  }
}

.header1__burger {
  display: inline-block;
  margin-right: 15px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .header1__burger {
    margin-right: 10px;
  }
}

.header1__burger .icon {
  font-size: 32px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .header1__burger .icon {
    font-size: 24px;
  }
}

.header1__logo {
  margin-right: 75px;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .header1__logo {
    margin-right: 40px;
  }
}

.header1__logo .header1__pic {
  width: 112px;
}

.header1__logo .header1__pic_white {
  display: none;
}

.header1__title {
  display: none;
}

@media only screen and (max-width: 767px) {
  .header1__title {
    display: block;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    font-size: 18px;
    font-weight: 600;
  }
}

.header1__group {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.header1__notification {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 20px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .header1__notification {
    display: none;
  }
}

.header1__notification .icon {
  font-size: 21px;
  fill: #92929D;
  transition: fill .25s;
}

.header1__notification:before {
  content: "";
  position: absolute;
  top: -3px;
  right: -2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #FC5A5A;
  opacity: 0;
  transition: opacity .25s;
}

.header1__notification:hover .icon {
  fill: #B5B5BE;
}

.header1__notification.active:before {
  opacity: 1;
}

.header1__profile {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  font-size: 0;
  transition: opacity .25s;
}

.header1__profile:hover {
  opacity: .9;
}

.header1__profile .header1__pic {
  width: 100%;
  min-height: 32px;
  border-radius: 50%;
}

.header1__bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background: rgba(23, 23, 37, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: all .2s;
}

.header1__bg.show {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 1365px) {
  .header1__bg.visible {
    z-index: 13;
    visibility: visible;
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .header1:not(.header_empty) .header1__logo {
    display: none;
  }
}

body.dark .header1 {
  background: #1C1C24;
  box-shadow: inset 0px -1px 0px #292932;
}

body.dark .header1__pic_black {
  display: none;
}

body.dark .header1__pic_white {
  display: inline-block;
}

body.dark .header1__bg {
  background: rgba(146, 146, 157, 0.3);
}

.header2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 5px 25px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #E2E2EA;
}

@media only screen and (max-width: 1365px) {
  .header2 {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .header2 {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 1023px) {
  .header2 {
    height: 70px;
  }
}

.header2__burger {
  display: none;
  margin-right: 15px;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .header2__burger {
    display: inline-block;
  }
}

@media only screen and (max-width: 767px) {
  .header2__burger {
    margin-right: 10px;
  }
}

.header2__burger .icon {
  font-size: 32px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .header2__burger .icon {
    font-size: 24px;
  }
}

.header2__logo {
  margin-right: 143px;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .header2__logo {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .header2__logo {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header2__logo {
    display: none;
  }
}

.header2__logo .header2__pic {
  width: 112px;
}

.header2__logo .header2__pic_white {
  display: none;
}

.header2__search {
  position: relative;
  width: 500px;
  margin-right: auto;
}

@media only screen and (max-width: 1199px) {
  .header2__search {
    width: 400px;
  }
}

@media only screen and (max-width: 1023px) {
  .header2__search {
    width: 270px;
  }
}

@media only screen and (max-width: 767px) {
  .header2__search {
    width: auto;
    flex-grow: 1;
    margin-right: 10px;
  }
}

.header2__input {
  width: 100%;
  height: 50px;
  padding: 0 45px 0 15px;
  background: #FAFAFB;
  border: 1px solid #F1F1F5;
  border-radius: 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .header2__input {
    height: 42px;
  }
}

.header2__input::placeholder {
  color: #92929D;
}

.header2__open {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 44px;
  font-size: 0;
  transition: opacity .25s;
}

.header2__open .icon {
  font-size: 20px;
  fill: #92929D;
  transition: fill .25s;
}

.header2__open:hover .icon {
  fill: #B5B5BE;
}

.header2__group {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 25px;
}

@media only screen and (max-width: 767px) {
  .header2__group {
    margin-right: 10px;
  }
}

.header2__link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.header2__link .icon {
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.header2__link .icon-bell {
  font-size: 21px;
}

.header2__link:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #FC5A5A;
  opacity: 0;
  transition: opacity .25s;
}

.header2__link:hover .icon {
  fill: #B5B5BE;
}

.header2__link.active {
  opacity: 1;
}

.header2__link.active:before {
  opacity: 1;
}

.header2__link_bell:before {
  right: 2px;
}

.header2__link:not(:last-child) {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .header2__link:not(:last-child) {
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header2__link:not(:nth-child(2)) {
    display: none;
  }
}

.header2__profile {
  position: relative;
  flex-shrink: 0;
  font-size: 0;
  transition: opacity .25s;
}

@media only screen and (max-width: 767px) {
  .header2__profile {
    padding: 0;
  }
}

.header2__profile:hover {
  opacity: .8;
}

.header2__profile .header2__pic {
  width: 34px;
  min-height: 34px;
  border-radius: 50%;
}

.header2__bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background: rgba(23, 23, 37, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: all .2s;
}

.header2__bg.show {
  visibility: visible;
  opacity: 1;
}

.header2__bg.visible {
  z-index: 13;
  visibility: visible;
  opacity: 1;
}

body.dark .header2 {
  background: #1C1C24;
  box-shadow: inset 0px -1px 0px #292932;
}

body.dark .header2__pic_black {
  display: none;
}

body.dark .header2__pic_white {
  display: inline-block;
}

body.dark .header2__bg {
  background: rgba(146, 146, 157, 0.3);
}

body.dark .header2__input {
  background: #292932;
  border-color: #292932;
  color: #FAFAFB;
}

body.dark .header2__profile:before {
  border-color: #B5B5BE transparent transparent transparent;
}

.header3 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 70px;
  padding: 5px 30px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #E2E2EA;
}

@media only screen and (max-width: 1023px) {
  .header3 {
    padding: 5px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header3 {
    height: 60px;
    padding: 0 20px;
  }
}

.header3__burger {
  display: none;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .header3__burger {
    display: inline-block;
    margin-right: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .header3__burger {
    margin-right: 15px;
  }
}

.header3__burger .icon {
  font-size: 32px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .header3__burger .icon {
    font-size: 24px;
  }
}

.header3__logo {
  display: none;
  margin-right: 80px;
  font-size: 0;
}

@media only screen and (max-width: 1365px) {
  .header3__logo {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .header3__logo {
    display: inline-block;
  }
}

.header3__logo .header3__pic {
  width: 112px;
}

.header3__logo .header3__pic_white {
  display: none;
}

.header3__nav {
  display: flex;
  margin-right: auto;
}

@media only screen and (max-width: 1023px) {
  .header3__nav {
    display: none;
  }
}

.header3__link {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  line-height: 1.5;
  font-weight: 600;
  color: #696974;
  transition: color .25s;
}

.header3__link:hover, .header3__link.active {
  color: #1E75FF;
}

.header3__link:not(:last-child) {
  margin-right: 48px;
}

@media only screen and (max-width: 1365px) {
  .header3__link:not(:last-child) {
    margin-right: 32px;
  }
}

@media only screen and (max-width: 1199px) {
  .header3__link:not(:last-child) {
    margin-right: 16px;
  }
}

.header3__notifications {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-left: auto;
  font-size: 0;
}

.header3__notifications .icon {
  font-size: 21px;
  fill: #92929D;
  transition: fill .25s;
}

.header3__notifications:before {
  content: "";
  position: absolute;
  top: -2px;
  right: 1px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #FC5A5A;
  opacity: 0;
  transition: opacity .25s;
}

.header3__notifications:hover .icon {
  fill: #B5B5BE;
}

.header3__notifications.active:before {
  opacity: 1;
}

.header3__notifications:not(:last-child) {
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .header3__notifications:not(:last-child) {
    margin-right: 15px;
  }
}

.header3__user {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  color: #171725;
  transition: opacity .25s;
}

@media only screen and (max-width: 767px) {
  .header3__user {
    padding-right: 0;
    font-size: 0;
  }
}

.header3__user:hover {
  opacity: .8;
}

.header3__ava {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  margin-right: 7px;
  border: 3px solid #F1F1F5;
  border-radius: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .header3__ava {
    margin-right: 0;
    font-size: 14px;
  }
}

.header3__ava img {
  width: 32px;
  min-height: 32px;
  border-radius: 10px;
}

.header3__bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background: rgba(23, 23, 37, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: all .2s;
}

.header3__bg.visible {
  z-index: 13;
  visibility: visible;
  opacity: 1;
}

.header3_full {
  height: 80px;
  box-shadow: none;
}

@media only screen and (max-width: 1023px) {
  .header3_full {
    height: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .header3_full {
    height: 60px;
  }
}

.header3_full .header3__logo {
  display: inline-block;
}

body.dark .header3 {
  background: #1C1C24;
  box-shadow: inset 0px -1px 0px #292932;
}

body.dark .header3__pic_black {
  display: none;
}

body.dark .header3__pic_white {
  display: inline-block;
}

body.dark .header3__bg {
  background: rgba(146, 146, 157, 0.3);
}

body.dark .header3__link {
  color: #B5B5BE;
}

body.dark .header3__link:hover {
  color: #ffffff;
}

body.dark .header3__link.active {
  color: #50B5FF;
}

body.dark .header3__user {
  color: #FAFAFB;
}

body.dark .header3_full {
  box-shadow: none;
}

.header4 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 78px;
  padding: 5px 25px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #E2E2EA;
}

@media only screen and (max-width: 1023px) {
  .header4 {
    padding: 5px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header4 {
    height: 60px;
    padding: 0 20px;
  }
}

.header4__burger {
  display: none;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .header4__burger {
    display: inline-block;
    margin-right: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .header4__burger {
    margin-right: 15px;
  }
}

.header4__burger .icon {
  font-size: 32px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .header4__burger .icon {
    font-size: 24px;
  }
}

.header4__logo {
  margin-right: auto;
  font-size: 0;
}

.header4__logo_empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 78px;
  flex-shrink: 0;
}

@media only screen and (max-width: 1023px) {
  .header4__logo_empty {
    display: inline-block;
    width: auto;
    height: auto;
  }
}

.header4__logo_blue {
  background: #0062FF;
}

@media only screen and (max-width: 1199px) {
  .header4__logo_blue {
    background: none;
  }
}

.header4__logo .header4__pic {
  width: 112px;
}

.header4__logo .header4__pic_white {
  display: none;
}

.header4__logo_empty .header4__pic {
  width: 32px;
}

@media only screen and (max-width: 1023px) {
  .header4__logo_empty .header4__pic {
    width: 112px;
  }
}

@media only screen and (max-width: 1199px) {
  .header4__logo_empty .header4__pic_white {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .header4__logo_empty .header4__pic_black {
    display: inline-block;
  }
}

@media only screen and (max-width: 1023px) {
  .header4__logo_empty .header4__pic_black {
    display: none;
  }
}

.header4__logo_empty .header4__pic_big {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .header4__logo_empty .header4__pic_big {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .header4__logo_empty .header4__pic_big.header4__pic_black {
    display: inline-block;
  }
}

.header4__logo_blue .header4__pic_white {
  display: inline-block;
}

.header4__logo_blue .header4__pic_black {
  display: none;
}

.header4__logo_blue .header4__pic_big {
  display: none;
}

.header4__search {
  position: relative;
  width: 500px;
  margin-right: 60px;
}

@media only screen and (max-width: 1199px) {
  .header4__search {
    width: 400px;
    margin-right: 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .header4__search {
    width: 300px;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .header4__search {
    display: none;
  }
}

.header4__input {
  width: 100%;
  height: 50px;
  padding: 0 45px 0 15px;
  background: #FAFAFB;
  border: 1px solid #F1F1F5;
  border-radius: 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #171725;
}

.header4__input::placeholder {
  color: #92929D;
}

.header4__open {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 44px;
  font-size: 0;
}

.header4__open .icon {
  font-size: 20px;
  fill: #92929D;
  transition: fill .25s;
}

.header4__open:hover .icon {
  fill: #B5B5BE;
}

.header4__control {
  display: flex;
  align-items: center;
}

.header4__notifications, .header4__logout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.header4__notifications .icon, .header4__logout .icon {
  font-size: 22px;
  fill: #92929D;
  transition: fill .25s;
}

.header4__notifications:before, .header4__logout:before {
  content: "";
  position: absolute;
  top: -2px;
  right: 1px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #FC5A5A;
  opacity: 0;
  transition: opacity .25s;
}

.header4__notifications:hover .icon, .header4__logout:hover .icon {
  fill: #B5B5BE;
}

.header4__notifications.active:before, .header4__logout.active:before {
  opacity: 1;
}

.header4__notifications:not(:first-child), .header4__logout:not(:first-child) {
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .header4__notifications:not(:first-child), .header4__logout:not(:first-child) {
    margin-left: 15px;
  }
}

.header4__user {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 30px;
  transition: opacity .25s;
}

@media only screen and (max-width: 767px) {
  .header4__user {
    margin-left: 15px;
  }
}

.header4__user .icon {
  margin-left: 10px;
  font-size: 16px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .header4__user .icon {
    display: none;
  }
}

.header4__user:hover {
  opacity: .8;
}

.header4__ava {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  border: 3px solid #F1F1F5;
  border-radius: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
}

.header4__ava img {
  width: 32px;
  min-height: 32px;
  border-radius: 10px;
}

.header4__box {
  padding-left: 10px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .header4__box {
    display: none;
  }
}

.header4__man {
  margin-bottom: 3px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 600;
  color: #171725;
}

.header4__post {
  font-size: 12px;
  color: #92929D;
}

.header4__bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background: rgba(23, 23, 37, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: all .2s;
}

.header4__bg.visible {
  z-index: 13;
  visibility: visible;
  opacity: 1;
}

.header4_empty {
  padding-left: 0;
}

@media only screen and (max-width: 1199px) {
  .header4_empty {
    padding-left: 25px;
  }
}

@media only screen and (max-width: 1023px) {
  .header4_empty {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .header4_empty .header4__burger {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1023px) {
  .header4_empty .header4__burger {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header4_empty .header4__burger {
    margin-right: 15px;
  }
}

.header4_sm {
  height: 70px;
  margin-left: 250px;
}

@media only screen and (max-width: 1199px) {
  .header4_sm {
    margin: 0;
  }
}

.header4_sm .header4__logo {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .header4_sm .header4__logo {
    display: inline-block;
    margin-right: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .header4_sm .header4__logo {
    margin-right: auto;
  }
}

.header4_sm .header4__search {
  width: 280px;
  margin-right: auto;
}

@media only screen and (max-width: 1199px) {
  .header4_sm .header4__burger {
    display: inline-block;
    margin-right: 25px;
  }
}

.header4_sm .header4__input {
  height: 38px;
  padding: 0 10px 0 30px;
  border: none;
  background: none;
}

.header4_sm .header4__open {
  left: 0;
  right: auto;
  width: 20px;
}

.header4_sm .header4__open .icon {
  font-size: 17px;
}

body.dark .header4 {
  background: #1C1C24;
  box-shadow: inset 0px -1px 0px #292932;
}

body.dark .header4__logo_blue {
  background: #1C1C24;
}

body.dark .header4__pic_black {
  display: none;
}

body.dark .header4__pic_white {
  display: inline-block;
}

@media only screen and (max-width: 1023px) {
  body.dark .header4__pic_white {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  body.dark .header4__pic_black.header4__pic_big {
    display: none;
  }
}

body.dark .header4__pic_white.header4__pic_big {
  display: none;
}

@media only screen and (max-width: 1023px) {
  body.dark .header4__pic_white.header4__pic_big {
    display: inline-block;
  }
}

@media only screen and (max-width: 1023px) {
  body.dark .header4__logo_big .header4__pic_white {
    display: inline-block;
  }
}

body.dark .header4__bg {
  background: rgba(146, 146, 157, 0.3);
}

body.dark .header4__input {
  background: #292932;
  border-color: #292932;
}

body.dark .header4__man {
  color: #FAFAFB;
}

body.dark .header4__post {
  color: #B5B5BE;
}

body.dark .header4_sm .header4__input {
  background: none;
}

@media only screen and (max-width: 767px) {
  body.dark .header4_sm .header4__burger {
    margin-right: 20px;
  }
}

.header5 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 70px;
  padding: 5px 30px 5px 280px;
  background: #ffffff;
}

@media only screen and (max-width: 1199px) {
  .header5 {
    padding: 5px 25px;
  }
}

@media only screen and (max-width: 1023px) {
  .header5 {
    padding: 5px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header5 {
    height: 60px;
    padding: 0 20px;
    box-shadow: inset 0px -1px 0px #E2E2EA;
  }
}

.header5__burger {
  display: none;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .header5__burger {
    display: inline-block;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header5__burger {
    margin-right: 15px;
  }
}

.header5__burger .icon {
  font-size: 32px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .header5__burger .icon {
    font-size: 24px;
  }
}

.header5__logo {
  display: none;
  margin-right: auto;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .header5__logo {
    display: inline-block;
  }
}

.header5__logo .header5__pic {
  width: 112px;
}

.header5__logo .header5__pic_white {
  display: none;
}

.header5__notifications {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-left: auto;
  font-size: 0;
}

.header5__notifications .icon {
  font-size: 21px;
  fill: #92929D;
  transition: fill .25s;
}

.header5__notifications:before {
  content: "";
  position: absolute;
  top: -2px;
  right: 1px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #FC5A5A;
  opacity: 0;
  transition: opacity .25s;
}

.header5__notifications:hover .icon {
  fill: #B5B5BE;
}

.header5__notifications.active:before {
  opacity: 1;
}

.header5__notifications:not(:last-child) {
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .header5__notifications:not(:last-child) {
    margin-right: 15px;
  }
}

.header5__user {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  transition: opacity .25s;
}

.header5__user .icon {
  margin-left: 14px;
  font-size: 16px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .header5__user .icon {
    display: none;
  }
}

.header5__user:hover {
  opacity: .8;
}

.header5__ava {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  border: 3px solid #F1F1F5;
  border-radius: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
}

.header5__ava img {
  width: 32px;
  min-height: 32px;
  border-radius: 10px;
}

.header5__user .header5__text {
  margin-left: 7px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 600;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .header5__user .header5__text {
    display: none;
  }
}

.header5__bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background: rgba(23, 23, 37, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: all .2s;
}

.header5__bg.visible {
  z-index: 13;
  visibility: visible;
  opacity: 1;
}

body.dark .header5 {
  background: #1C1C24;
  box-shadow: inset 0px -1px 0px #292932;
}

body.dark .header5__pic_black {
  display: none;
}

body.dark .header5__pic_white {
  display: inline-block;
}

body.dark .header5__bg {
  background: rgba(146, 146, 157, 0.3);
}

body.dark .header5__text {
  color: #FAFAFB;
}

.header6 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 70px;
  padding: 5px 30px 5px 25px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #E2E2EA;
}

@media only screen and (max-width: 1023px) {
  .header6 {
    padding: 5px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header6 {
    height: 60px;
    padding: 0 20px;
  }
}

.header6__burger {
  display: none;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .header6__burger {
    display: inline-block;
    margin-right: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .header6__burger {
    margin-right: 15px;
  }
}

.header6__burger .icon {
  font-size: 32px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .header6__burger .icon {
    font-size: 24px;
  }
}

.header6__logo {
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .header6__logo {
    display: none;
  }
}

.header6__logo .header6__pic {
  width: 112px;
}

.header6__logo .header6__pic_white {
  display: none;
}

.header6__nav {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  margin-right: auto;
}

@media only screen and (max-width: 1365px) {
  .header6__nav {
    position: static;
    margin-left: 50px;
    transform: translate(0, 0);
  }
}

@media only screen and (max-width: 1199px) {
  .header6__nav {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .header6__nav {
    margin-left: 0;
  }
}

.header6__nav .header6__link {
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: #44444F;
  transition: color .25s;
}

.header6__nav .header6__link .icon {
  margin-right: 5px;
  font-size: 16px;
  fill: #92929D;
  transition: fill .25s;
}

.header6__nav .header6__link .icon-search, .header6__nav .header6__link .icon-clock {
  font-size: 14px;
}

.header6__nav .header6__link:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  border-radius: 3px 3px 0 0;
  background: #0062FF;
  transition: height .25s;
}

.header6__nav .header6__link:hover {
  color: #0062FF;
}

.header6__nav .header6__link:hover .icon {
  fill: #0062FF;
}

.header6__nav .header6__link.active {
  opacity: 1;
  font-weight: 600;
  color: #0062FF;
}

@media only screen and (max-width: 1023px) {
  .header6__nav .header6__link.active {
    font-size: 18px;
    color: #171725;
  }
}

.header6__nav .header6__link.active .icon {
  fill: #0062FF;
}

@media only screen and (max-width: 1023px) {
  .header6__nav .header6__link.active .icon {
    margin-right: 13px;
    font-size: 20px;
    fill: #92929D;
  }
}

.header6__nav .header6__link.active:before {
  height: 3px;
}

@media only screen and (max-width: 1023px) {
  .header6__nav .header6__link.active:before {
    display: none;
  }
}

.header6__nav .header6__link:not(:last-child) {
  margin-right: 48px;
}

@media only screen and (max-width: 1439px) {
  .header6__nav .header6__link:not(:last-child) {
    margin-right: 32px;
  }
}

@media only screen and (max-width: 1199px) {
  .header6__nav .header6__link:not(:last-child) {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .header6__nav .header6__link:not(:last-child) {
    margin: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .header6__nav .header6__link:not(.active) {
    display: none;
  }
}

.header6__control {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.header6__notifications {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 30px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .header6__notifications {
    margin-right: 20px;
  }
}

.header6__notifications .icon {
  font-size: 21px;
  fill: #92929D;
  transition: color .25s;
}

.header6__notifications:before {
  content: "";
  position: absolute;
  top: -2px;
  right: -3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #FC5A5A;
  opacity: 0;
  transition: opacity .25s;
}

.header6__notifications:hover .icon {
  fill: #B5B5BE;
}

.header6__notifications.active:before {
  opacity: 1;
}

.header6__notifications:not(:last-child) {
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .header6__notifications:not(:last-child) {
    margin-right: 15px;
  }
}

.header6__user {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  color: #171725;
  transition: opacity .25s;
}

@media only screen and (max-width: 767px) {
  .header6__user {
    padding-right: 0;
    font-size: 0;
  }
}

.header6__user .ava {
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .header6__user .ava {
    margin: 0;
  }
}

.header6__user .icon {
  margin-left: 19px;
  font-size: 16px;
  fill: #92929D;
}

@media only screen and (max-width: 1199px) {
  .header6__user .icon {
    margin-left: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .header6__user .icon {
    display: none;
  }
}

.header6__user:hover {
  opacity: .8;
}

.header6__bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background: rgba(23, 23, 37, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: all .2s;
}

.header6__bg.visible {
  visibility: visible;
  opacity: 1;
}

.header6_full {
  height: 80px;
  box-shadow: none;
}

@media only screen and (max-width: 1023px) {
  .header6_full {
    height: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .header6_full {
    height: 60px;
  }
}

.header6_full .header6__logo {
  display: inline-block;
}

body.dark .header6 {
  background: #1C1C24;
  box-shadow: inset 0px -1px 0px #292932;
}

body.dark .header6__pic_black {
  display: none;
}

body.dark .header6__pic_white {
  display: inline-block;
}

body.dark .header6__bg {
  background: rgba(146, 146, 157, 0.3);
}

body.dark .header6__link {
  color: #D5D5DC;
}

body.dark .header6__link:hover, body.dark .header6__link.active {
  color: #1E75FF;
}

@media only screen and (max-width: 1023px) {
  body.dark .header6__link:hover, body.dark .header6__link.active {
    color: #FAFAFB;
  }
}

body.dark .header6__link:hover .icon, body.dark .header6__link.active .icon {
  fill: #1E75FF;
}

@media only screen and (max-width: 1023px) {
  body.dark .header6__link:hover .icon, body.dark .header6__link.active .icon {
    fill: #92929D;
  }
}

body.dark .header6__link.active:before {
  background: #1E75FF;
}

body.dark .header6__user {
  color: #FAFAFB;
}

body.dark .header6_full {
  box-shadow: none;
}

.header7 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 70px;
  padding: 5px 30px 5px 25px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #E2E2EA;
}

@media only screen and (max-width: 1023px) {
  .header7 {
    padding: 5px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header7 {
    padding: 0 20px;
  }
}

.header7__burger {
  display: none;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .header7__burger {
    display: inline-block;
    margin-right: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .header7__burger {
    position: absolute;
    top: 52%;
    left: 20px;
    width: 50px;
    height: 42px;
    transform: translateY(-50%);
    z-index: 2;
    margin: 0;
  }
}

.header7__burger .icon {
  font-size: 32px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .header7__burger .icon {
    font-size: 24px;
  }
}

.header7__logo {
  margin-right: 133px;
  font-size: 0;
}

@media only screen and (max-width: 1365px) {
  .header7__logo {
    margin-right: 100px;
  }
}

@media only screen and (max-width: 1199px) {
  .header7__logo {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .header7__logo {
    display: none;
  }
}

.header7__logo .header7__pic {
  width: 112px;
}

.header7__logo .header7__pic_white {
  display: none;
}

.header7__search {
  position: relative;
  width: 280px;
  margin-right: auto;
}

@media only screen and (max-width: 1199px) {
  .header7__search {
    width: 230px;
  }
}

@media only screen and (max-width: 767px) {
  .header7__search {
    flex-grow: 1;
    width: auto;
    margin-right: 10px;
  }
}

.header7__input {
  width: 100%;
  height: 38px;
  padding-left: 30px;
  background: none;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .header7__input {
    height: 42px;
    padding: 0 15px 0 50px;
    border-radius: 10px;
    background: #FAFAFB;
  }
}

.header7__input::placeholder {
  color: #92929D;
}

.header7__open {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .header7__open {
    display: none;
  }
}

.header7__open .icon {
  font-size: 18px;
  fill: #92929D;
  transition: fill .25s;
}

.header7__open:hover .icon {
  fill: #B5B5BE;
}

.header7__control {
  display: flex;
  align-items: center;
}

.header7__notifications {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 30px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .header7__notifications {
    margin-right: 10px;
  }
}

.header7__notifications .icon {
  font-size: 22px;
  fill: #92929D;
  transition: fill .25s;
}

.header7__notifications:before {
  content: "";
  position: absolute;
  top: -2px;
  right: 1px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #FC5A5A;
  opacity: 0;
  transition: opacity .25s;
}

.header7__notifications:hover .icon {
  fill: #B5B5BE;
}

.header7__notifications.active:before {
  opacity: 1;
}

.header7__user {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  transition: opacity .25s;
}

.header7__user .icon {
  margin-left: 10px;
  font-size: 16px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .header7__user .icon {
    display: none;
  }
}

.header7__user:hover {
  opacity: .8;
}

.header7__box {
  padding-left: 10px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .header7__box {
    display: none;
  }
}

.header7__man {
  margin-bottom: 3px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 600;
  color: #171725;
}

.header7__post {
  font-size: 12px;
  color: #92929D;
}

.header7__bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background: rgba(23, 23, 37, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: all .2s;
}

.header7__bg.visible {
  visibility: visible;
  opacity: 1;
}

.header7_settings .header7__logo {
  margin-right: 40px;
}

@media only screen and (max-width: 1199px) {
  .header7_settings .header7__logo {
    margin-right: 25px;
  }
}

@media only screen and (max-width: 1199px) {
  .header7_settings .header7__search {
    width: 190px;
  }
}

body.dark .header7 {
  background: #1C1C24;
  box-shadow: inset 0px -1px 0px #44444F;
}

body.dark .header7__pic_black {
  display: none;
}

body.dark .header7__pic_white {
  display: inline-block;
}

body.dark .header7__bg {
  background: rgba(146, 146, 157, 0.3);
}

@media only screen and (max-width: 767px) {
  body.dark .header7__input {
    background: #292932;
  }
}

body.dark .header7__user .icon {
  fill: #B5B5BE;
}

body.dark .header7__man {
  color: #FAFAFB;
}

body.dark .header7__post {
  color: #696974;
}

.search {
  position: relative;
  z-index: 10;
  width: 350px;
  transition: box-shadow .25s;
}

@media only screen and (max-width: 1365px) {
  .search {
    z-index: 9;
  }
}

@media only screen and (max-width: 767px) {
  .search {
    position: static;
    width: 100%;
    margin-right: 15px;
  }
}

.search__head {
  position: relative;
  z-index: 2;
}

.search__input {
  width: 100%;
  height: 42px;
  padding: 0 44px 0 50px;
  border: none;
  border-radius: 10px;
  background: #FAFAFB;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #171725;
}

.search__input::placeholder {
  color: #92929D;
}

.search__open {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 45px;
  font-size: 0;
}

.search__open .icon {
  font-size: 20px;
  fill: #92929D;
  transition: fill .25s;
}

.search__open:hover .icon {
  fill: #B5B5BE;
}

.search__arrow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 44px;
  font-size: 0;
}

.search__arrow .icon {
  font-size: 7px;
  fill: #92929D;
}

.search__body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 42px;
  border-radius: 10px;
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .search__body {
    top: 100%;
    border-radius: 0 0 10px 10px;
    padding-top: 0;
  }
}

.search__form {
  display: none;
  padding: 30px 20px 20px;
  border-top: 1px solid #E2E2EA;
}

.search__form > .field2:not(:last-child) {
  margin-bottom: 25px;
}

.search__row {
  display: flex;
  margin: 0 -10px;
}

.search__row:not(:last-child) {
  margin-bottom: 20px;
}

.search__row .field2 {
  flex: 0 0 calc(50% - 20px);
  width: calc(50% - 20px);
  margin: 0 10px;
}

.search__variants {
  display: flex;
  flex-wrap: wrap;
  margin: -15px 0 0 -20px;
}

.search .checkbox {
  margin: 15px 0 0 20px;
}

.search .checkbox .checkbox__tick {
  margin-right: 5px;
}

.search .checkbox .checkbox__text {
  padding-top: 1px;
  font-size: 12px;
}

.search__btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.search__btn {
  min-width: 100px;
}

.search__btn:not(:last-child) {
  margin-right: 15px;
}

.search__btn.btn_border-gray {
  border-color: #E2E2EA;
}

.search__btn.btn_border-gray:hover {
  background: #E2E2EA;
}

.search__result {
  display: none;
  max-height: 674px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 12px 10px 10px;
}

@media only screen and (max-width: 767px) {
  .search__result {
    max-height: calc(100vh - 70px);
  }
}

.search__group:not(:last-child) {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #F1F1F5;
}

.search__item {
  display: flex;
  padding: 8px 10px;
  transition: opacity .25s;
}

@media only screen and (max-width: 767px) {
  .search__item {
    padding: 5px 10px;
  }
}

.search__item .ava {
  width: 24px;
  height: 24px;
  border: 1px solid #F1F1F5;
  font-size: 10px;
}

.search__item:hover {
  opacity: .8;
}

.search__item:not(:last-child) {
  margin-bottom: 10px;
}

.search__details {
  flex: 0 0 calc(100% - 24px);
  width: calc(100% - 24px);
  padding-left: 10px;
}

.search__icon {
  flex-shrink: 0;
  width: 24px;
  text-align: center;
  font-size: 0;
}

.search__icon .icon {
  font-size: 24px;
  fill: #92929D;
}

.search__info {
  margin-bottom: 5px;
  font-weight: 500;
  color: #44444F;
}

.search__text {
  font-size: 12px;
  color: #92929D;
}

.search__info, .search__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

body.dark .search__input {
  background: #292932;
  color: #FAFAFB;
}

body.dark .search__body {
  background: #1C1C24;
}

body.dark .search__form {
  border-color: #292932;
}

body.dark .search .checkbox__text {
  color: #92929D;
}

body.dark .search .checkbox__tick {
  border-color: #B5B5BE;
}

body.dark .search__group {
  border-color: #292932;
}

body.dark .search__info {
  color: #F1F1F5;
}

.checkbox {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.checkbox__in {
  display: flex;
}

.checkbox__tick {
  position: relative;
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  margin-right: 12px;
  border-radius: 3px;
  border: 1px solid #B5B5BE;
  transition: all .25s;
}

.checkbox__tick:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 11px;
  height: 9px;
  opacity: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' fill='none'%3E%3Cpath d='M2.36 3.28A1 1 0 1 0 .955 4.703l2.54 2.508a1 1 0 0 0 1.474-.075l4.126-5A1 1 0 1 0 7.553.864L4.123 5.02 2.36 3.28z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto;
  transition: opacity .25s;
}

.checkbox__text {
  font-size: 14px;
  line-height: 1.2;
  color: #696974;
  transition: color .25s;
}

.checkbox:hover .checkbox__tick {
  border-color: #50B5FF;
}

.checkbox__link {
  border-bottom: 1px solid transparent;
  color: #50B5FF;
  transition: border-color .25s;
}

.checkbox__link:hover {
  border-color: #50B5FF;
}

.checkbox__flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -1px;
}

.checkbox__info {
  color: #44444F;
}

.checkbox__info:not(:last-child) {
  margin-right: 10px;
}

.checkbox__date {
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: #92929D;
}

.checkbox__date .icon {
  margin-right: 5px;
  font-size: 15px;
  fill: #92929D;
}

.checkbox .user {
  margin: 5px 10px 5px 0;
}

.checkbox__box {
  flex-grow: 1;
}

.checkbox__line {
  display: flex;
  align-items: center;
}

.checkbox__line .users {
  flex-shrink: 0;
  margin: -4px 0 0 -4px;
}

.checkbox__line .users__item {
  width: 18px;
  height: 18px;
  margin: 4px 0 0 4px;
  font-family: 'Roboto', sans-serif;
  font-size: 8px;
}

.checkbox__line .checkbox__info {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkbox__desc {
  display: block;
  margin-top: 4px;
  font-size: 14px;
  line-height: 1.71429;
  color: #92929D;
}

.checkbox__status {
  position: relative;
  top: -4px;
  margin-left: auto;
  padding: 0 12px;
  border-radius: 5px;
  background: rgba(0, 98, 255, 0.1);
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 10px;
  font-weight: 500;
  line-height: 24px;
  color: #0062FF;
}

.checkbox__input:checked + .checkbox__in .checkbox__info {
  text-decoration: line-through;
}

.checkbox__input:checked + .checkbox__in .checkbox__tick {
  background: #50B5FF;
  border-color: #50B5FF;
}

.checkbox__input:checked + .checkbox__in .checkbox__tick:before {
  opacity: 1;
}

.checkbox_sm .checkbox__tick {
  flex: 0 0 14px;
  width: 14px;
  height: 14px;
  margin-right: 10px;
  border-radius: 2px;
}

.checkbox_sm .checkbox__tick:before {
  width: 8px;
  height: 7px;
}

.checkbox_sm .checkbox__text {
  margin-top: -1px;
}

.checkbox_big .checkbox__tick {
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  margin-right: 22px;
}

.checkbox_big .checkbox__tick:before {
  width: 11px;
  height: 9px;
}

.checkbox_big .checkbox__info {
  font-size: 16px;
}

.checkbox_green:hover .checkbox__tick {
  border-color: #3DD598;
}

.checkbox_green .checkbox__input:checked + .checkbox__in .checkbox__tick {
  background: #3DD598;
  border-color: #3DD598;
}

.checkbox_green .checkbox__input:checked + .checkbox__in .checkbox__tick:before {
  opacity: 1;
}

body.dark .checkbox__text {
  color: #FAFAFB;
}

body.dark .checkbox__info {
  color: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  body.dark .checkbox__info {
    color: #E2E2EA;
  }
}

body.dark .checkbox__status {
  background: #292932;
  color: #D5D5DC;
}

.entry {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 40px 20px 100px;
}

@media only screen and (max-width: 767px) {
  .entry {
    padding: 35px 20px 50px;
  }
}

.entry__wrap {
  position: relative;
  z-index: 2;
  max-width: 560px;
  width: 100%;
}

.entry__title {
  margin-bottom: 22px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 56px;
  line-height: 1.5;
  letter-spacing: 0.23px;
  font-weight: 700;
  color: #44444F;
}

@media only screen and (max-width: 767px) {
  .entry__title {
    margin-bottom: 10px;
    font-size: 30px;
    letter-spacing: .12px;
  }
}

.entry__info {
  margin-bottom: 40px;
  text-align: center;
  font-size: 16px;
  line-height: 1.625;
  letter-spacing: .1px;
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .entry__info {
    max-width: 260px;
    margin: 0 auto 20px;
    font-size: 14px;
  }
}

.entry__form {
  padding: 40px 30px 30px;
  background: #ffffff;
  box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
  border-radius: 20px;
}

@media only screen and (max-width: 767px) {
  .entry__form {
    padding: 25px 20px;
    box-shadow: 2px 1px 10px rgba(205, 205, 212, 0.1);
  }
}

.entry__fieldset {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .entry__fieldset {
    margin-bottom: 20px;
  }
}

.entry__row {
  display: flex;
  margin: 0 -10px;
}

@media only screen and (max-width: 767px) {
  .entry__row {
    margin: 0 -5px;
  }
}

@media only screen and (max-width: 767px) {
  .entry__row_mobile {
    display: block;
    margin: 0;
  }
}

.entry__row:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .entry__row:not(:last-child) {
    margin-bottom: 15px;
  }
}

.entry__col {
  flex: 0 0 calc(50% - 20px);
  width: calc(50% - 20px);
  margin: 0 10px;
}

@media only screen and (max-width: 767px) {
  .entry__col {
    flex: 0 0 calc(50% - 10px);
    width: calc(50% - 10px);
    margin: 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .entry__row_mobile .entry__col {
    width: 100%;
    margin: 0;
  }
  .entry__row_mobile .entry__col:not(:last-child) {
    margin-bottom: 15px;
  }
}

.entry__flex {
  display: flex;
}

.entry__flex .field1:first-child {
  flex-shrink: 0;
  width: 50px;
  margin-right: 10px;
}

.entry__flex .field1:first-child .field1__input {
  padding: 0 10px;
  text-align: center;
}

.entry__flex .field1:nth-child(2) {
  flex-grow: 1;
}

.entry__fieldset > .field1:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .entry__fieldset > .field1:not(:last-child) {
    margin-bottom: 15px;
  }
}

.entry__box {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .entry__box {
    margin-bottom: 15px;
  }
}

.entry__box .entry__text {
  margin-bottom: 15px;
  font-size: 14px;
  letter-spacing: .1px;
  color: #44444F;
}

@media only screen and (max-width: 767px) {
  .entry__box .entry__text {
    margin-bottom: 10px;
  }
}

.entry__form > .checkbox {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .entry__form > .checkbox {
    margin-bottom: 25px;
  }
}

.entry__btns {
  display: flex;
}

.entry__btns .btn_gray {
  flex-shrink: 0;
  min-width: 150px;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .entry__btns .btn_gray {
    min-width: 90px;
  }
}

.entry__btns .btn_blue {
  flex-grow: 1;
  min-width: auto;
}

.entry__group {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .entry__group {
    margin-bottom: 15px;
  }
}

.entry__group .field1:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .entry__group .field1:not(:last-child) {
    margin-bottom: 25px;
  }
}

.entry__line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .entry__line {
    margin-bottom: 25px;
  }
}

.entry__link {
  border-bottom: 1px solid transparent;
  font-weight: 500;
  letter-spacing: .1px;
  transition: border-color .25s;
}

.entry__form .entry__link {
  color: #50B5FF;
}

.entry__form .entry__link:hover {
  border-color: #50B5FF;
}

.entry__bottom {
  margin-top: 35px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .entry__bottom {
    margin-top: 25px;
  }
}

.entry__bottom .entry__link {
  color: #0062FF;
}

.entry__bottom .entry__link:hover {
  border-color: #0062FF;
}

.entry .switch {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .entry .switch {
    display: none;
  }
}

.entry_bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 430px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 100px));
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .entry_bg:before {
    height: 260px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 30px));
  }
}

body.dark .entry__title {
  color: #FAFAFB;
}

body.dark .entry__form {
  background: #1C1C24;
  box-shadow: none;
}

body.dark .entry__box .entry__text {
  color: #696974;
}

body.dark .entry__form .entry__link {
  color: #92929D;
}

body.dark .entry__form .entry__link:hover {
  border-color: #92929D;
}

body.dark .entry__bottom .entry__link {
  color: #FAFAFB;
}

body.dark .entry__bottom .entry__link:hover {
  border-color: #FAFAFB;
}

body.dark .entry_bg:before {
  display: none;
}

.users {
  position: relative;
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  margin: -10px 0 0 -10px;
  font-size: 0;
}

@media only screen and (max-width: 1365px) {
  .users {
    margin: -8px 0 0 -8px;
  }
}

.users__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 10px 0 0 10px;
  border-radius: 50%;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
}

@media only screen and (max-width: 1365px) {
  .users__item {
    width: 26px;
    height: 26px;
    margin: 8px 0 0 8px;
    font-size: 11px;
  }
}

.users__item_counter {
  border: 1px solid #E2E2EA;
  font-size: 12px;
  font-weight: 400;
  color: #696974;
}

@media only screen and (max-width: 1365px) {
  .users__item_counter {
    font-size: 11px;
  }
}

.users__item:first-child {
  z-index: 6;
}

.users__item:nth-child(2) {
  z-index: 5;
}

.users__item:nth-child(3) {
  z-index: 4;
}

.users__item:nth-child(4) {
  z-index: 3;
}

.users__item:nth-child(5) {
  z-index: 2;
}

.users__item:nth-child(6) {
  z-index: 1;
}

.users__pic {
  width: 100%;
  min-height: 100%;
}

.users_sm .users__item {
  width: 26px;
  height: 26px;
  font-size: 11px;
}

body.dark .users__item {
  border: 1px solid #44444F;
}

body.dark .users__item_counter {
  border-color: #FAFAFB;
  color: #FAFAFB;
}

.users1 {
  position: fixed;
  top: 80px;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 300px;
  padding-bottom: 60px;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
  background: #ffffff;
  border-left: 1px solid #E2E2EA;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  transition: transform .25s;
}

@media only screen and (max-width: 1599px) {
  .users1 {
    transform: translateX(100%);
  }
}

@media only screen and (max-width: 1023px) {
  .users1 {
    top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .users1 {
    width: 100%;
    border-radius: 0;
  }
}

.users1__close {
  display: none;
  position: absolute;
  top: 27px;
  right: 20px;
  z-index: 2;
}

@media only screen and (max-width: 1365px) {
  .users1__close {
    display: inline-block;
  }
}

.users1__close .icon {
  font-size: 14px;
  fill: #92929D;
}

.users1__wrapper {
  padding: 25px 20px 0;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.users1__group:not(:last-child) {
  margin-bottom: 38px;
}

@media only screen and (max-width: 767px) {
  .users1__group:not(:last-child) {
    margin-bottom: 25px;
  }
}

.users1__category {
  margin-bottom: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .users1__category {
    margin-bottom: 15px;
  }
}

.users1__item {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 50px;
  color: #171725;
}

.users1__item .ava__pic {
  border: 1px solid #F1F1F5;
}

.users1__item:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .users1__item:not(:last-child) {
    margin-bottom: 15px;
  }
}

.users1__item .users1__text {
  flex: calc(100% - 47px);
  width: calc(100% - 47px);
  padding-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: color .25s;
}

.users1__item:hover .users1__text {
  color: #92929D;
}

.users1__time, .users1__online {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.users1__time {
  max-width: 45px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #92929D;
}

.users1__online {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #82C43C;
}

.users1__field {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.users1__input {
  width: 100%;
  height: 40px;
  padding: 0 20px 0 45px;
  border-top: 1px solid #E2E2EA;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #171725;
}

.users1__input::placeholder {
  color: #92929D;
}

.users1__btn {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 45px;
  padding-left: 5px;
  font-size: 0;
}

.users1__btn .icon {
  font-size: 17px;
  fill: #92929D;
  transition: fill .25s;
}

.users1__btn:hover .icon {
  fill: #B5B5BE;
}

body.dark .users1 {
  background: #13131A;
  border-color: #292932;
}

body.dark .users1__item {
  color: #FAFAFB;
}

body.dark .users1__input {
  border-color: #44444F;
  background: #13131A;
}

body.dark .users1__input::placeholder {
  color: #696974;
}

.users2 {
  display: none;
  padding: 15px 0;
  background: #ffffff;
  overflow: auto;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 767px) {
  .users2 {
    display: flex;
  }
}

.users2::-webkit-scrollbar {
  display: none;
}

.users2__item {
  padding: 0 10px;
}

.users2__item .ava {
  width: 48px;
  height: 48px;
  margin-bottom: 5px;
  font-size: 18px;
}

.users2__item:first-child {
  padding-left: 20px;
}

.users2__item:last-child {
  padding-right: 20px;
}

.users2__add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-bottom: 5px;
  border-radius: 50%;
  border: 1px solid #F1F1F5;
  font-size: 0;
}

.users2__add .icon {
  font-size: 17px;
  fill: #0062FF;
}

.users2__text {
  margin: 0 -5px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 11px;
  font-weight: 500;
  color: #171725;
}

.users2:not(:last-child) {
  margin-bottom: 5px;
}

body.dark .users2 {
  background: #1C1C24;
}

body.dark .users2__add {
  background: #ffffff;
}

body.dark .users2__text {
  color: #FAFAFB;
}

body.dark .users2 .ava__pic {
  border: 1px solid #FAFAFB;
}

.sidebar {
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 250px;
  padding-bottom: 70px;
  background: #ffffff;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: .1px;
}

@media only screen and (max-width: 1023px) {
  .sidebar {
    top: 0;
    z-index: 10;
    width: 280px;
    padding: 70px 0;
    transform: translateX(-100%);
    transition: transform .25s;
  }
  .sidebar.visible {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar {
    padding: 60px 0 65px;
  }
}

.sidebar__wrapper {
  padding: 40px 20px 0;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 1199px) {
  .sidebar__wrapper {
    padding-top: 35px;
  }
}

@media only screen and (max-width: 1023px) {
  .sidebar__wrapper {
    padding: 20px 20px 0;
  }
}

.sidebar__top {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .sidebar__top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 70px;
    padding: 0 20px;
    border-bottom: 1px solid #E2E2EA;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar__top {
    height: 60px;
  }
}

.sidebar__close {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin: 0 15px 0 -4px;
  font-size: 0;
}

.sidebar__close .icon {
  font-size: 18px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .sidebar__close .icon {
    font-size: 16px;
  }
}

.sidebar__top .sidebar__logo {
  font-size: 0;
}

.sidebar__top .sidebar__pic {
  width: 112px;
}

.sidebar__top .sidebar__pic_white {
  display: none;
}

.sidebar__teams {
  margin-bottom: 10px;
  padding-bottom: 30px;
  border-bottom: 1px solid #F1F1F5;
}

@media only screen and (max-width: 767px) {
  .sidebar__teams {
    padding-bottom: 20px;
  }
}

.sidebar__category {
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  color: #92929D;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color .25s;
}

.sidebar__category:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 7px 5.5px 0 5.5px;
  border-color: #92929D transparent transparent transparent;
  transition: transform .25s;
}

.sidebar__category:hover {
  color: #B5B5BE;
}

.sidebar__teams.active .sidebar__category:before {
  transform: translateY(-50%) rotate(180deg);
}

.sidebar__inner {
  display: none;
  padding-top: 17px;
}

.sidebar__team:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .sidebar__team:not(:last-child) {
    margin-bottom: 20px;
  }
}

.sidebar__line {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.sidebar__team .sidebar__logo {
  font-size: 0;
}

.sidebar__team .sidebar__pic {
  max-width: 32px;
  max-height: 32px;
  border-radius: 7px;
}

.sidebar__company {
  padding-left: 10px;
  line-height: 1.5;
}

.sidebar__add {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: .8px;
  text-transform: uppercase;
  color: #0062FF;
  transition: color .25s;
}

.sidebar__add:hover {
  color: #50B5FF;
}

.sidebar__menu {
  display: flex;
  flex-direction: column;
  margin: 0 -20px auto;
}

.sidebar a.sidebar__item, .sidebar__head {
  position: relative;
  display: flex;
  align-items: center;
  height: 52px;
  color: #171725;
  transition: color .25s;
}

@media only screen and (max-width: 767px) {
  .sidebar a.sidebar__item, .sidebar__head {
    height: 44px;
  }
}

.sidebar a.sidebar__item .icon, .sidebar__head .icon {
  margin-right: 20px;
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.sidebar a.sidebar__item:before, .sidebar__head:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 3px;
  height: 32px;
  border-radius: 0 3px 3px 0;
  background: #0062FF;
  opacity: 0;
  transition: opacity .25s;
}

.sidebar a.sidebar__item:hover, .sidebar__head:hover {
  color: #0062FF;
}

.sidebar a.sidebar__item:hover .icon, .sidebar__head:hover .icon {
  fill: #0062FF;
}

.sidebar a.sidebar__item.active, .sidebar__head.active {
  opacity: 1;
}

.sidebar a.sidebar__item.active:before, .sidebar__head.active:before {
  opacity: 1;
}

.sidebar a.sidebar__item.active {
  color: #0062FF;
}

.sidebar a.sidebar__item.active .icon {
  fill: #0062FF;
}

.sidebar__item {
  padding: 0 20px;
}

.sidebar__item:not(:last-child) {
  margin-bottom: 10px;
}

.sidebar__head {
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.sidebar__head:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 12px;
  height: 7px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M6 4.586L10.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5A1 1 0 1 1 1.707.293L6 4.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat 0 0/100% auto;
  transition: transform .25s;
}

.sidebar__head:before {
  left: -20px;
}

.sidebar__item.active .sidebar__head {
  opacity: 1;
  color: #0062FF;
}

.sidebar__item.active .sidebar__head .icon {
  fill: #0062FF;
}

.sidebar__item.active .sidebar__head:before {
  opacity: 1;
}

.sidebar__item.active .sidebar__head:after {
  transform: translateY(-50%) rotate(180deg);
}

.sidebar__counter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  min-width: 32px;
  padding: 0 6px;
  border-radius: 12px;
  background: #FC5A5A;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;
}

.sidebar__body {
  display: none;
  padding-left: 44px;
}

.sidebar__item.active .sidebar__body {
  display: block;
}

.sidebar__link {
  position: relative;
  display: flex;
  align-items: center;
  height: 52px;
  padding-right: 30px;
  font-weight: 400;
  color: #171725;
  transition: color .25s;
}

@media only screen and (max-width: 767px) {
  .sidebar__link {
    height: 44px;
  }
}

.sidebar__link:hover {
  color: #0062FF;
}

.sidebar__number {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0;
  font-weight: 500;
  color: #B5B5BE;
}

.sidebar .switch {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .sidebar .switch {
    bottom: 15px;
  }
}

body.dark .sidebar {
  background: #1C1C24;
  box-shadow: inset -1px 0px 0px #292932;
}

body.dark .sidebar__top {
  border-color: #44444F;
}

body.dark .sidebar__pic_black {
  display: none;
}

body.dark .sidebar__pic_white {
  display: inline-block;
}

body.dark .sidebar__category {
  color: #696974;
}

body.dark .sidebar__category:hover {
  color: #B5B5BE;
}

body.dark .sidebar__company {
  color: #FAFAFB;
}

body.dark .sidebar__add {
  color: #1E75FF;
}

body.dark .sidebar__add:hover {
  color: #50B5FF;
}

body.dark .sidebar__teams {
  border-color: #44444F;
}

body.dark .sidebar a.sidebar__item, body.dark .sidebar__head {
  color: #B5B5BE;
}

body.dark .sidebar a.sidebar__item:hover, body.dark .sidebar__head:hover {
  color: #FAFAFB;
}

body.dark .sidebar a.sidebar__item:hover .icon, body.dark .sidebar__head:hover .icon {
  fill: #FAFAFB;
}

body.dark .sidebar a.sidebar__item:before, body.dark .sidebar__head:before {
  background: #FAFAFB;
}

body.dark .sidebar a.sidebar__item.active {
  color: #FAFAFB;
}

body.dark .sidebar a.sidebar__item.active .icon {
  fill: #FAFAFB;
}

body.dark .sidebar__item.active .sidebar__head {
  color: #FAFAFB;
}

body.dark .sidebar__item.active .sidebar__head .icon {
  fill: #FAFAFB;
}

body.dark .sidebar__link {
  color: #B5B5BE;
}

body.dark .sidebar__link:hover {
  color: #FAFAFB;
}

.sidebar1 {
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  width: 250px;
  padding-bottom: 70px;
  background: #FAFAFB;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  transition: width .25s, transform .25s;
}

@media only screen and (max-width: 1365px) {
  .sidebar1 {
    top: 0;
    z-index: 15;
    width: 280px;
    padding: 70px 0;
    transform: translateX(-100%);
    transition: transform .25s;
  }
}

.sidebar1__wrapper {
  max-height: 100%;
  padding: 20px 10px 0;
  overflow: auto;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.sidebar1__wrapper::-webkit-scrollbar {
  display: none;
}

.sidebar1__top {
  display: none;
}

@media only screen and (max-width: 1365px) {
  .sidebar1__top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 70px;
    padding: 0 20px;
    background: #ffffff;
    border-bottom: 1px solid #E2E2EA;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar1__top {
    height: 70px;
  }
}

.sidebar1__close {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin: 0 15px 0 -4px;
  font-size: 0;
}

.sidebar1__close .icon {
  font-size: 18px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .sidebar1__close .icon {
    font-size: 16px;
  }
}

.sidebar1__top .sidebar1__logo {
  font-size: 0;
}

.sidebar1__top .sidebar1__pic {
  width: 112px;
}

.sidebar1__top .sidebar1__pic_white {
  display: none;
}

.sidebar1__write {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  margin-bottom: 20px;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid transparent;
  white-space: nowrap;
  font-weight: 600;
  color: #44444F;
  transition: border-color .25s;
}

@media only screen and (max-width: 767px) {
  .sidebar1__write {
    display: none;
  }
}

.sidebar1__write .icon {
  margin-right: 10px;
  font-size: 24px;
  fill: #92929D;
}

.sidebar1__write:hover {
  border-color: #B5B5BE;
}

.sidebar1__menu {
  display: flex;
  flex-direction: column;
  margin: 0 -10px 10px;
}

.sidebar1__menu .sidebar1__link {
  position: relative;
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 20px;
  white-space: nowrap;
  font-weight: 500;
  color: #171725;
  transition: color .25s;
}

.sidebar1__menu .sidebar1__link .icon {
  flex-shrink: 0;
  margin-right: 22px;
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.sidebar1__menu .sidebar1__link:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 3px;
  height: 32px;
  border-radius: 0 3px 3px 0;
  background: #0062FF;
  opacity: 0;
  transition: opacity .25s;
}

.sidebar1__menu .sidebar1__link:hover {
  color: #0062FF;
}

.sidebar1__menu .sidebar1__link:hover .icon {
  fill: #0062FF;
}

.sidebar1__menu .sidebar1__link.active {
  color: #0062FF;
}

.sidebar1__menu .sidebar1__link.active .icon {
  fill: #0062FF;
}

.sidebar1__menu .sidebar1__link.active:before {
  opacity: 1;
}

.sidebar1__menu .sidebar1__link:not(:last-child) {
  margin-bottom: 5px;
}

.sidebar1__menu .sidebar1__counter,
.sidebar1__profile .sidebar1__counter {
  min-width: 28px;
  padding: 0 6px;
  margin-left: auto;
  border-radius: 12px;
  background: #FC5A5A;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;
}

.sidebar1__menu .sidebar1__counter {
  right: 20px;
}

.sidebar1__profile .sidebar1__counter {
  right: 0;
}

.sidebar1__group {
  margin: 0 -10px;
}

.sidebar1__item {
  position: relative;
  padding: 10px 0;
}

.sidebar1__item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  height: 1px;
  background: #F1F1F5;
}

.sidebar1__head {
  position: relative;
  display: flex;
  align-items: center;
  height: 42px;
  padding: 0 50px 0 20px;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .5px;
  color: #92929D;
}

.sidebar1__head:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 21px;
  transform: translateY(-50%);
  width: 12px;
  height: 7px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' viewBox='0 0 12 7'%3E%3Cpath fill='%2392929D' fill-rule='evenodd' d='M5.99999993,2.41421 L1.70711,6.70711 C1.31658,7.09763 0.683418,7.09763 0.292893,6.70711 C-0.0976311,6.31658 -0.0976311,5.68342 0.292893,5.29289 L5.29289,0.292893 C5.68342,-0.0976311 6.31658,-0.0976311 6.70711,0.292893 L11.7071,5.29289 C12.0976,5.68342 12.0976,6.31658 11.7071,6.70711 C11.3166,7.09763 10.6834,7.09763 10.2929,6.70711 L5.99999993,2.41421 Z' transform='rotate(180 6 3.5)'/%3E%3C/svg%3E%0A") no-repeat 50% 50%/100% auto;
  transition: transform .25s;
}

.sidebar1__item.active .sidebar1__head:before {
  transform: translateY(-50%) rotate(180deg);
}

.sidebar1__body .sidebar1__link {
  display: flex;
  align-items: center;
  height: 42px;
  padding: 0 20px 0 25px;
  white-space: nowrap;
  color: #171725;
  transition: opacity .25s;
}

.sidebar1__body .sidebar1__link:hover {
  opacity: .85;
}

.sidebar1__body .sidebar1__link .sidebar1__counter {
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #B5B5BE;
}

.sidebar1__status {
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  margin-right: 31px;
  border-radius: 50%;
  transition: margin .25s;
}

.sidebar1__chat {
  display: flex;
  align-items: center;
  height: 46px;
  padding: 0 20px 0 26px;
  white-space: nowrap;
  font-weight: 500;
  color: #0062FF;
  transition: color .25s;
}

.sidebar1__chat:hover {
  color: #50B5FF;
}

.sidebar1__chat:not(:last-child) {
  margin-bottom: 12px;
}

.sidebar1__plus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-right: 22px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #0062FF;
  font-size: 0;
  transition: background .25s;
}

.sidebar1__plus .icon {
  font-size: 12px;
  fill: #ffffff;
}

.sidebar1__chat:hover .sidebar1__plus {
  background: #50B5FF;
}

.sidebar1__list {
  padding: 0 20px;
}

.sidebar1__profile {
  position: relative;
  display: flex;
  align-items: center;
  color: #171725;
  transition: opacity .25s;
}

.sidebar1__profile.online:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #82C43C;
}

.sidebar1__profile:hover {
  opacity: .8;
}

.sidebar1__profile:not(:last-child) {
  margin-bottom: 24px;
}

.sidebar1 .ava {
  margin-right: 15px;
  border: 1px solid #F1F1F5;
}

.sidebar1__user {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: auto;
}

.sidebar1 .switch {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

body.dark .sidebar1 {
  background: #17171E;
}

body.dark .sidebar1__top {
  background: #1C1C24;
  border-color: #292932;
}

body.dark .sidebar1__pic_black {
  display: none;
}

body.dark .sidebar1__pic_white {
  display: inline-block;
}

body.dark .sidebar1__write {
  background: #292932;
  color: #FAFAFB;
}

body.dark .sidebar1__write .icon {
  fill: #B5B5BE;
}

body.dark .sidebar1__menu .sidebar1__link {
  color: #B5B5BE;
}

body.dark .sidebar1__menu .sidebar1__link .icon {
  fill: #B5B5BE;
}

body.dark .sidebar1__menu .sidebar1__link:before {
  background: #FAFAFB;
}

body.dark .sidebar1__menu .sidebar1__link:hover, body.dark .sidebar1__menu .sidebar1__link.active {
  color: #FAFAFB;
}

body.dark .sidebar1__menu .sidebar1__link:hover .icon, body.dark .sidebar1__menu .sidebar1__link.active .icon {
  fill: #FAFAFB;
}

body.dark .sidebar1__item:before {
  background: #292932;
}

body.dark .sidebar1__head {
  color: #FAFAFB;
}

body.dark .sidebar1__body .sidebar1__link {
  color: #B5B5BE;
  transition: color .25s;
}

body.dark .sidebar1__body .sidebar1__link:hover {
  opacity: 1;
  color: #ffffff;
}

body.dark .sidebar1__chat {
  color: #FAFAFB;
}

body.dark .sidebar1__plus {
  background: #1E75FF;
  box-shadow: 0 0 0 2px #ffffff;
}

body.dark .sidebar1__user {
  color: #B5B5BE;
}

.sidebar2 {
  position: fixed;
  top: 80px;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 250px;
  padding-bottom: 70px;
  background: #ffffff;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .sidebar2 {
    top: 0;
    z-index: 10;
    width: 280px;
    padding: 80px 0 70px;
    transform: translateX(-100%);
    transition: transform .25s;
  }
  .sidebar2.visible {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 1023px) {
  .sidebar2 {
    padding: 70px 0;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar2 {
    padding: 70px 0 65px;
  }
}

.sidebar2__wrapper {
  padding: 20px 20px 0;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.sidebar2__wrapper::-webkit-scrollbar {
  display: none;
}

.sidebar2__top {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .sidebar2__top {
    display: flex;
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    flex-shrink: 0;
    padding: 0 20px;
    border-bottom: 1px solid #E2E2EA;
  }
}

@media only screen and (max-width: 1023px) {
  .sidebar2__top {
    height: 70px;
  }
}

.sidebar2__close {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin: 0 15px 0 -4px;
  font-size: 0;
}

.sidebar2__close .icon {
  font-size: 18px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .sidebar2__close .icon {
    font-size: 16px;
  }
}

.sidebar2__top .sidebar2__logo {
  font-size: 0;
}

.sidebar2__top .sidebar2__pic {
  width: 112px;
}

.sidebar2__top .sidebar2__pic_white {
  display: none;
}

.sidebar2__profile {
  display: flex;
  align-items: center;
  margin: 0 -10px 20px;
  padding: 12px;
  background: #FAFAFB;
  border-radius: 15px;
  border: 1px solid #E2E2EA;
  color: #171725;
  transition: border-color .25s;
}

@media only screen and (max-width: 767px) {
  .sidebar2__profile {
    margin-bottom: 15px;
  }
}

.sidebar2__profile .ava {
  margin-right: 10px;
}

.sidebar2__profile:hover {
  border-color: #c4c4d5;
}

.sidebar2__user {
  margin-bottom: 5px;
  font-weight: 600;
}

.sidebar2__login {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #92929D;
}

.sidebar2__nav {
  display: flex;
  flex-direction: column;
  margin: 0 -20px 45px;
}

@media only screen and (max-width: 767px) {
  .sidebar2__nav {
    margin-bottom: 30px;
  }
}

.sidebar2__item {
  position: relative;
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 55px 0 20px;
  color: #171725;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .sidebar2__item {
    height: 44px;
  }
}

.sidebar2__item .icon {
  margin-right: 20px;
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.sidebar2__item:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 3px;
  height: 32px;
  border-radius: 0 3px 3px 0;
  background: #0062FF;
  opacity: 0;
  transition: opacity .25s;
}

.sidebar2__item:hover, .sidebar2__item.active {
  color: #0062FF;
}

.sidebar2__item:hover .icon, .sidebar2__item.active .icon {
  fill: #0062FF;
}

.sidebar2__item.active:before {
  opacity: 1;
}

.sidebar2__item .sidebar2__counter {
  right: 20px;
}

.sidebar2__category {
  margin-bottom: 24px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: .93px;
  text-transform: uppercase;
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .sidebar2__category {
    margin-bottom: 15px;
  }
}

.sidebar2__menu {
  margin-right: -5px;
}

.sidebar2__counter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  min-width: 28px;
  padding: 0 6px;
  border-radius: 12px;
  background: #FC5A5A;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;
}

.sidebar2__link {
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  padding-right: 40px;
  color: #171725;
  transition: color .25s;
}

.sidebar2__link:hover {
  color: #0062FF;
}

.sidebar2__link:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .sidebar2__link:not(:last-child) {
    margin-bottom: 15px;
  }
}

.sidebar2__letters {
  flex-shrink: 0;
  width: 32px;
  border-radius: 10px;
  text-align: center;
  line-height: 32px;
  color: #ffffff;
}

.sidebar2__link .sidebar2__text {
  flex: calc(100% - 47px);
  width: calc(100% - 47px);
  padding-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar2 .switch {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .sidebar2 .switch {
    bottom: 15px;
  }
}

body.dark .sidebar2 {
  background: #1C1C24;
  box-shadow: inset -1px 0px 0px #292932;
}

body.dark .sidebar2__top {
  border-color: #44444F;
}

body.dark .sidebar2__pic_black {
  display: none;
}

body.dark .sidebar2__pic_white {
  display: inline-block;
}

body.dark .sidebar2__profile {
  border-color: #44444F;
  background: #292932;
  color: #FAFAFB;
}

body.dark .sidebar2__user {
  color: #FAFAFB;
}

body.dark .sidebar2__item {
  color: #B5B5BE;
}

body.dark .sidebar2__item .icon {
  fill: #B5B5BE;
}

body.dark .sidebar2__item:before {
  background: #FAFAFB;
}

body.dark .sidebar2__item:hover, body.dark .sidebar2__item.active {
  color: #FAFAFB;
}

body.dark .sidebar2__item:hover .icon, body.dark .sidebar2__item.active .icon {
  fill: #FAFAFB;
}

body.dark .sidebar2__link {
  color: #FAFAFB;
}

body.dark .sidebar2__link:hover {
  color: #50B5FF;
}

.sidebar3 {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 15;
  width: 70px;
  overflow: hidden;
  padding-bottom: 70px;
  box-shadow: inset -1px 0px 0px #E2E2EA;
  background: #ffffff;
}

@media only screen and (max-width: 1023px) {
  .sidebar3 {
    top: 0;
    width: 330px;
    padding: 70px 0;
    transform: translateX(-100%);
    transition: transform .25s;
  }
  .sidebar3.visible {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar3 {
    width: 100%;
    padding: 60px 0 65px;
  }
}

.sidebar3__wrapper {
  max-height: 100%;
  padding: 30px 0 0;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 767px) {
  .sidebar3__wrapper {
    padding-top: 20px;
  }
}

.sidebar3__wrapper::-webkit-scrollbar {
  display: none;
}

.sidebar3__preview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .sidebar3__preview {
    display: none;
  }
}

.sidebar3__top {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .sidebar3__top {
    display: flex;
    height: 70px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    flex-shrink: 0;
    padding: 0 20px;
    border-bottom: 1px solid #E2E2EA;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar3__top {
    height: 60px;
  }
}

.sidebar3__close {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin: 0 15px 0 -4px;
  font-size: 0;
}

.sidebar3__close .icon {
  font-size: 18px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .sidebar3__close .icon {
    font-size: 16px;
  }
}

.sidebar3__top .sidebar3__logo {
  font-size: 0;
}

.sidebar3__top .sidebar3__pic {
  width: 112px;
}

.sidebar3__top .sidebar3__pic_white,
.sidebar3__preview .sidebar3__pic_white {
  display: none;
}

.sidebar3__item {
  position: relative;
  display: flex;
  align-items: center;
  height: 52px;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
  font-size: 16px;
  color: #171725;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .sidebar3__item {
    height: 44px;
  }
}

.sidebar3__item .icon {
  flex-shrink: 0;
  margin: 0 23px;
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

@media only screen and (max-width: 1023px) {
  .sidebar3__item .icon {
    margin-left: 20px;
  }
}

.sidebar3__item:hover .icon {
  fill: #50B5FF;
}

.sidebar3__item.active {
  background: rgba(80, 181, 255, 0.05);
  color: #50B5FF;
}

.sidebar3__item.active .icon {
  fill: #50B5FF;
}

.sidebar3 .switch {
  position: absolute;
  left: 23px;
  bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .sidebar3 .switch {
    left: 20px;
    bottom: 15px;
  }
}

.sidebar3_hide {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .sidebar3_hide {
    display: block;
  }
}

body.dark .sidebar3 {
  background: #1C1C24;
  box-shadow: inset -1px 0px 0px #292932;
}

body.dark .sidebar3__top {
  border-color: #44444F;
}

body.dark .sidebar3__pic_black {
  display: none;
}

body.dark .sidebar3__pic_white {
  display: inline-block;
}

body.dark .sidebar3__item {
  color: #92929D;
}

body.dark .sidebar3__item .icon {
  fill: #92929D;
}

body.dark .sidebar3__item:hover .icon {
  fill: #FAFAFB;
}

body.dark .sidebar3__item.active {
  background: rgba(80, 181, 255, 0.1);
  color: #50B5FF;
}

body.dark .sidebar3__item.active .icon {
  fill: #50B5FF;
}

.sidebar4 {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  padding: 78px 0 70px;
  background: #FCFCFE;
  box-shadow: inset 1px 0px 0px #E2E2EA;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .sidebar4 {
    z-index: 10;
    width: 280px;
    transform: translateX(-100%);
    transition: transform .25s;
  }
  .sidebar4.visible {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar4 {
    padding: 60px 0 65px;
  }
}

.sidebar4__wrapper {
  padding-top: 20px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.sidebar4__top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 78px;
  padding: 0 25px;
  box-shadow: inset 0px -1px 0px #E2E2EA;
}

@media only screen and (max-width: 1199px) {
  .sidebar4__top {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar4__top {
    height: 60px;
  }
}

.sidebar4__top_sm {
  height: 70px;
}

@media only screen and (max-width: 767px) {
  .sidebar4__top_sm {
    height: 60px;
  }
}

.sidebar4__close {
  display: none;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin: 0 15px 0 -4px;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .sidebar4__close {
    display: inline-block;
  }
}

.sidebar4__close .icon {
  font-size: 18px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .sidebar4__close .icon {
    font-size: 16px;
  }
}

.sidebar4__top .sidebar4__logo {
  font-size: 0;
}

.sidebar4__top .sidebar4__pic {
  width: 112px;
}

.sidebar4__top .sidebar4__pic_white {
  display: none;
}

.sidebar4 a.sidebar4__item, .sidebar4__head {
  position: relative;
  display: flex;
  align-items: center;
  height: 52px;
  color: #171725;
  transition: color .25s;
}

@media only screen and (max-width: 767px) {
  .sidebar4 a.sidebar4__item, .sidebar4__head {
    height: 44px;
  }
}

.sidebar4 a.sidebar4__item .icon, .sidebar4__head .icon {
  margin-right: 20px;
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.sidebar4 a.sidebar4__item .icon-search, .sidebar4__head .icon-search {
  margin: 0 22px 0 2px;
  font-size: 20px;
}

.sidebar4 a.sidebar4__item:hover, .sidebar4 a.sidebar4__item.active, .sidebar4__head:hover, .sidebar4__head.active {
  color: #0062FF;
}

.sidebar4 a.sidebar4__item:hover .icon, .sidebar4 a.sidebar4__item.active .icon, .sidebar4__head:hover .icon, .sidebar4__head.active .icon {
  fill: #0062FF;
}

.sidebar4__item {
  padding: 0 20px;
}

.sidebar4__item:not(:last-child) {
  margin-bottom: 10px;
}

.sidebar4__head {
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.sidebar4__body {
  padding: 10px 0 0 44px;
}

.sidebar4__link {
  position: relative;
  display: flex;
  align-items: center;
  height: 52px;
  padding-right: 25px;
  font-weight: 400;
  color: #171725;
  transition: color .25s;
}

.sidebar4__link:hover, .sidebar4__link.active {
  color: #0062FF;
}

.sidebar4__link:not(:last-child) {
  margin-bottom: 10px;
}

.sidebar4__number {
  position: absolute;
  top: 50%;
  right: 0;
  width: 15px;
  text-align: center;
  transform: translateY(-50%);
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #B5B5BE;
}

.sidebar4 .switch {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .sidebar4 .switch {
    bottom: 15px;
  }
}

.sidebar4_hide {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .sidebar4_hide {
    display: block;
  }
}

body.dark .sidebar4 {
  background: #1C1C24;
  box-shadow: none;
}

body.dark .sidebar4__top {
  box-shadow: inset 0px -1px 0px #292932;
}

body.dark .sidebar4__pic_black {
  display: none;
}

body.dark .sidebar4__pic_white {
  display: inline-block;
}

body.dark .sidebar4 a.sidebar4__item, body.dark .sidebar4__head {
  color: #B5B5BE;
}

body.dark .sidebar4 a.sidebar4__item .icon, body.dark .sidebar4__head .icon {
  fill: #B5B5BE;
}

body.dark .sidebar4 a.sidebar4__item:hover, body.dark .sidebar4__head:hover {
  color: #50B5FF;
}

body.dark .sidebar4 a.sidebar4__item:hover .icon, body.dark .sidebar4__head:hover .icon {
  fill: #50B5FF;
}

body.dark .sidebar4__link {
  color: #ffffff;
}

body.dark .sidebar4__link:hover, body.dark .sidebar4__link.active {
  color: #50B5FF;
}

body.dark .sidebar4__number {
  color: #B5B5BE;
}

.sidebar5 {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 15;
  width: 250px;
  overflow: hidden;
  padding: 70px 0 120px;
  background: #171725;
  box-shadow: inset -1px 0px 0px #E2E2EA;
}

@media only screen and (max-width: 1199px) {
  .sidebar5 {
    top: 0;
    transform: translateX(-100%);
    transition: transform .25s;
  }
  .sidebar5.visible {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar5 {
    width: 270px;
    padding: 60px 0 125px;
  }
}

.sidebar5__wrapper {
  max-height: 100%;
  padding: 20px 0 0;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 767px) {
  .sidebar5__wrapper {
    padding-top: 20px;
  }
}

.sidebar5__wrapper::-webkit-scrollbar {
  display: none;
}

.sidebar5__top {
  display: flex;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  flex-shrink: 0;
  padding: 0 25px;
}

@media only screen and (max-width: 1199px) {
  .sidebar5__top {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar5__top {
    height: 60px;
    padding: 0 20px;
  }
}

.sidebar5__close {
  display: none;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin: 0 15px 0 -4px;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .sidebar5__close {
    display: inline-block;
  }
}

.sidebar5__close .icon {
  font-size: 18px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .sidebar5__close .icon {
    font-size: 16px;
  }
}

.sidebar5__top .sidebar5__logo {
  font-size: 0;
}

.sidebar5__top .sidebar5__pic {
  width: 112px;
}

.sidebar5__item {
  position: relative;
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .sidebar5__item {
    height: 44px;
  }
}

.sidebar5__item .icon {
  flex-shrink: 0;
  margin-right: 20px;
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.sidebar5__item .icon-search {
  margin-left: 2px;
  font-size: 20px;
}

.sidebar5__item:hover, .sidebar5__item.active {
  background: rgba(213, 213, 220, 0.1);
}

.sidebar5__item:hover .icon, .sidebar5__item.active .icon {
  fill: #ffffff;
}

.sidebar5__item.active {
  font-weight: 600;
}

.sidebar5__item:not(:last-child) {
  margin-bottom: 10px;
}

.sidebar5__logout {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background: #FF974A;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  color: #FAFAFB;
  transition: background .25s;
}

@media only screen and (max-width: 767px) {
  .sidebar5__logout {
    height: 50px;
  }
}

.sidebar5__logout .icon {
  margin-right: 9px;
  font-size: 18px;
  fill: #FAFAFB;
}

.sidebar5__logout:hover {
  background: #ff7a17;
}

.sidebar5 .switch {
  position: absolute;
  left: 23px;
  bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .sidebar5 .switch {
    left: 20px;
    bottom: 75px;
  }
}

body.dark .sidebar5 {
  background: #1C1C24;
  box-shadow: inset -1px 0px 0px #292932;
}

.sidebar6 {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 280px;
  padding: 70px 0 70px;
  background: #ffffff;
  box-shadow: inset 1px 0px 0px #E2E2EA;
  transform: translateX(-100%);
  transition: transform .25s;
}

@media only screen and (max-width: 1023px) {
  .sidebar6 {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar6 {
    padding: 60px 0 65px;
  }
}

@media only screen and (max-width: 1023px) {
  .sidebar6.visible {
    transform: translateX(0);
  }
}

.sidebar6__wrapper {
  max-height: 100%;
  padding-top: 20px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.sidebar6__top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 70px;
  padding: 0 25px;
  box-shadow: inset 0px -1px 0px #E2E2EA;
}

@media only screen and (max-width: 1199px) {
  .sidebar6__top {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar6__top {
    height: 60px;
  }
}

.sidebar6__close {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin: 0 15px 0 -4px;
  font-size: 0;
}

.sidebar6__close .icon {
  font-size: 18px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .sidebar6__close .icon {
    font-size: 16px;
  }
}

.sidebar6__top .sidebar6__logo {
  font-size: 0;
}

.sidebar6__top .sidebar6__pic {
  width: 112px;
}

.sidebar6__top .sidebar6__pic_white {
  display: none;
}

.sidebar6__item {
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
  color: #171725;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .sidebar6__item {
    height: 44px;
  }
}

.sidebar6__item .icon {
  margin-right: 20px;
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.sidebar6__item .icon-search, .sidebar6__item .icon-clock {
  margin: 0 22px 0 2px;
  font-size: 20px;
}

.sidebar6__item.active {
  color: #0062FF;
}

.sidebar6__item.active .icon {
  fill: #0062FF;
}

.sidebar6__item:not(:last-child) {
  margin-bottom: 10px;
}

.sidebar6 .switch {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .sidebar6 .switch {
    bottom: 15px;
  }
}

body.dark .sidebar6 {
  background: #1C1C24;
  box-shadow: none;
}

body.dark .sidebar6__top {
  box-shadow: inset 0px -1px 0px #292932;
}

body.dark .sidebar6__pic_black {
  display: none;
}

body.dark .sidebar6__pic_white {
  display: inline-block;
}

body.dark .sidebar6__item {
  color: #B5B5BE;
}

body.dark .sidebar6__item .icon {
  fill: #B5B5BE;
}

body.dark .sidebar6__item.active {
  color: #ffffff;
}

body.dark .sidebar6__item.active .icon {
  fill: #ffffff;
}

.sidebar7 {
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 250px;
  padding-bottom: 70px;
  background: #ffffff;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
}

@media only screen and (max-width: 1023px) {
  .sidebar7 {
    top: 0;
    z-index: 10;
    width: 280px;
    padding: 70px 0;
    transform: translateX(-100%);
    transition: transform .25s;
  }
  .sidebar7.visible {
    transform: translateX(0);
  }
}

.sidebar7__wrapper {
  padding: 30px 20px 0;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 1023px) {
  .sidebar7__wrapper {
    padding-top: 20px;
  }
}

.sidebar7__top {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .sidebar7__top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 70px;
    padding: 0 20px;
    border-bottom: 1px solid #E2E2EA;
  }
}

.sidebar7__close {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin: 0 15px 0 -4px;
  font-size: 0;
}

.sidebar7__close .icon {
  font-size: 18px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .sidebar7__close .icon {
    font-size: 16px;
  }
}

.sidebar7__top .sidebar7__logo {
  font-size: 0;
}

.sidebar7__top .sidebar7__pic {
  width: 112px;
}

.sidebar7__top .sidebar7__pic_white {
  display: none;
}

.sidebar7__box:not(:last-child) {
  margin-bottom: 30px;
}

.sidebar7__category {
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #92929D;
}

.sidebar7__menu {
  display: flex;
  flex-direction: column;
  margin: 0 -20px auto;
}

.sidebar7__item {
  padding: 0 20px;
}

.sidebar7__item:not(:last-child) {
  margin-bottom: 10px;
}

@media only screen and (max-width: 1199px) {
  .sidebar7__item:not(:last-child) {
    margin: 0;
  }
}

.sidebar7__item[href], .sidebar7__head {
  position: relative;
  display: flex;
  align-items: center;
  height: 52px;
  color: #171725;
  transition: color .25s;
}

@media only screen and (max-width: 1599px) {
  .sidebar7__item[href], .sidebar7__head {
    height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar7__item[href], .sidebar7__head {
    height: 44px;
  }
}

.sidebar7__item[href] .icon, .sidebar7__head .icon {
  margin-right: 20px;
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.sidebar7__item[href]:before, .sidebar7__head:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 3px;
  height: 32px;
  border-radius: 0 3px 3px 0;
  background: #0062FF;
  opacity: 0;
  transition: opacity .25s;
}

.sidebar7__item[href].active:before, .sidebar7__head.active:before {
  opacity: 1;
}

.sidebar7__item[href]:hover, .sidebar7__item[href].active {
  color: #0062FF;
}

.sidebar7__item[href]:hover .icon, .sidebar7__item[href].active .icon {
  fill: #0062FF;
}

.sidebar7__head {
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: color .25s;
}

.sidebar7__head:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 12px;
  height: 7px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M6 4.586L10.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5A1 1 0 1 1 1.707.293L6 4.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat 0 0/100% auto;
  transition: transform .25s;
}

.sidebar7__head:before {
  left: -20px;
}

.sidebar7__head:hover {
  color: #0062FF;
}

.sidebar7__head:hover .icon {
  fill: #0062FF;
}

.sidebar7__item.active .sidebar7__head {
  opacity: 1;
  color: #0062FF;
}

.sidebar7__item.active .sidebar7__head .icon {
  fill: #0062FF;
}

.sidebar7__item.active .sidebar7__head:before {
  opacity: 1;
}

.sidebar7__item.active .sidebar7__head:after {
  transform: translateY(-50%) rotate(180deg);
}

.sidebar7__counter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  min-width: 28px;
  padding: 0 5px;
  border-radius: 12px;
  background: #FC5A5A;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;
}

.sidebar7__body {
  display: none;
  padding-left: 44px;
}

.sidebar7__item.active .sidebar7__body {
  display: block;
}

.sidebar7__link {
  display: flex;
  align-items: center;
  height: 52px;
  font-weight: 400;
  color: #171725;
  transition: opacity .25s;
}

@media only screen and (max-width: 1599px) {
  .sidebar7__link {
    height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar7__link {
    height: 44px;
  }
}

.sidebar7__link:hover, .sidebar7__link.active {
  color: #0062FF;
}

.sidebar7__number {
  margin-top: -1px;
  margin-left: auto;
  padding-left: 15px;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0;
  font-weight: 500;
  color: #B5B5BE;
}

.sidebar7 .switch {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .sidebar7 .switch {
    bottom: 15px;
  }
}

.sidebar7_hide {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .sidebar7_hide {
    display: flex;
  }
}

body.dark .sidebar7 {
  background: #1C1C24;
  box-shadow: inset -1px 0px 0px #44444F;
}

body.dark .sidebar7__top {
  border-color: #44444F;
}

body.dark .sidebar7__pic_black {
  display: none;
}

body.dark .sidebar7__pic_white {
  display: inline-block;
}

body.dark .sidebar7__category {
  color: #696974;
}

body.dark .sidebar7__item[href], body.dark .sidebar7__head {
  color: #B5B5BE;
}

body.dark .sidebar7__item[href]:before, body.dark .sidebar7__head:before {
  background: #FAFAFB;
}

body.dark .sidebar7__head:hover {
  color: #FAFAFB;
}

body.dark .sidebar7__head:hover .icon {
  fill: #FAFAFB;
}

body.dark .sidebar7__item[href]:hover, body.dark .sidebar7__item[href].active {
  color: #FAFAFB;
}

body.dark .sidebar7__item[href]:hover .icon, body.dark .sidebar7__item[href].active .icon {
  fill: #FAFAFB;
}

body.dark .sidebar7__item.active .sidebar7__head {
  color: #FAFAFB;
}

body.dark .sidebar7__item.active .sidebar7__head .icon {
  fill: #FAFAFB;
}

body.dark .sidebar7__link {
  color: #B5B5BE;
}

body.dark .sidebar7__link:hover, body.dark .sidebar7__link.active {
  color: #FAFAFB;
}

.options {
  position: relative;
}

.options__btn {
  width: 24px;
  height: 24px;
  font-size: 0;
}

.options__btn .icon {
  font-size: 4px;
  fill: #92929D;
  transition: fill .25s;
}

.options__btn:hover .icon {
  fill: #B5B5BE;
}

.options__dropdown {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  z-index: 3;
  width: 218px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid #F1F1F5;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
  border-radius: 8px;
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
}

.options__stage {
  position: relative;
  margin-bottom: 15px;
  padding-right: 35px;
  font-weight: 500;
  color: #44444F;
}

.options__close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  font-size: 0;
}

.options__close .icon {
  font-size: 10px;
  fill: #92929D;
  transition: fill .25s;
}

.options__close:hover .icon {
  fill: #B5B5BE;
}

.options__link {
  display: block;
  color: #696974;
  transition: color .25s;
}

.options__link:hover {
  color: #B5B5BE;
}

.options__link:not(:last-child) {
  margin-bottom: 14px;
}

.options__item {
  display: block;
  transition: opacity .25s;
}

.options__item:hover {
  opacity: .8;
}

.options__item:not(:last-child) {
  margin-bottom: 14px;
}

.options__info {
  margin-bottom: 6px;
  color: #44444F;
}

.options__text {
  font-size: 12px;
  line-height: 1.33333;
  color: #92929D;
}

.options__action {
  display: flex;
  align-items: center;
  line-height: 1.1;
  color: #696974;
  transition: opacity .25s;
}

.options__action .icon {
  margin-right: 14px;
  font-size: 16px;
  fill: #92929D;
}

.options__action:hover {
  opacity: .85;
}

.options__action:not(:last-child) {
  margin-bottom: 15px;
}

.options.active .options__dropdown {
  visibility: visible;
  opacity: 1;
}

body.dark .options__dropdown {
  background: #292932;
  border-color: #44444F;
}

body.dark .options__stage {
  color: #FAFAFB;
}

body.dark .options__close .icon {
  fill: #B5B5BE;
}

body.dark .options__close:hover .icon {
  fill: #ffffff;
}

body.dark .options__link {
  color: #D5D5DC;
}

body.dark .options__link:hover {
  color: #FAFAFB;
}

body.dark .options__info {
  color: #E2E2EA;
}

body.dark .options__text {
  color: #92929D;
}

body.dark .options__action {
  color: #E2E2EA;
}

.options1 {
  position: relative;
  font-size: 0;
}

.options1__btn {
  padding: 5px 8px;
  border-radius: 7px;
  font-size: 0;
}

.options1__btn .icon {
  font-size: 4px;
  fill: #92929D;
  transition: fill .25s;
}

.options1__btn:hover .icon {
  fill: #B5B5BE;
}

.options1__dropdown {
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0;
  z-index: 3;
  width: 218px;
  padding: 15px 15px 20px;
  font-size: 14px;
}

.options1__dropdown, .options1__body {
  background: #ffffff;
  border: 1px solid #F1F1F5;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
  border-radius: 8px;
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
}

.options1__group {
  display: flex;
  flex-direction: column;
}

.options1__group:not(:last-child) {
  margin-bottom: 11px;
  padding-bottom: 11px;
  border-bottom: 1px solid rgba(68, 68, 79, 0.1);
}

.options1__link {
  line-height: 1.1;
  color: #292932;
  transition: color .25s;
}

.options1__link:hover {
  color: #92929D;
}

.options1__link:not(:last-child) {
  margin-bottom: 15px;
}

.options1__item {
  position: relative;
}

.options1__item:not(:last-child) {
  margin-bottom: 15px;
}

.options1__head {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color .25s;
}

.options1__head .icon {
  margin-left: auto;
  font-size: 10px;
  fill: #0062FF;
}

.options1__head:hover {
  color: #92929D;
}

.options1__body {
  position: absolute;
  right: calc(100% + 15px);
  bottom: -3px;
  min-width: 120px;
  padding: 25px 15px 15px;
}

.options1__item.active .options1__head {
  color: #0062FF;
  opacity: 1;
}

.options1__item.active .options1__body {
  visibility: visible;
  opacity: 1;
}

.options1 .checkbox {
  display: block;
}

.options1 .checkbox:not(:last-child) {
  margin-bottom: 15px;
}

.options1.active .options1__btn {
  background: #E2E2EA;
  opacity: 1;
}

.options1.active .options1__dropdown {
  visibility: visible;
  opacity: 1;
}

body.dark .options1.active .options1__btn {
  background: #292932;
}

body.dark .options1__dropdown, body.dark .options1__body {
  background: #292932;
  border-color: #44444F;
}

body.dark .options1__link, body.dark .options1__head {
  color: #E2E2EA;
}

body.dark .options1__link:hover, body.dark .options1__head:hover {
  color: #ffffff;
}

body.dark .options1__head .icon {
  fill: #1E75FF;
}

body.dark .options1__item.active .options1__head {
  color: #1E75FF;
}

.options2 {
  position: relative;
  z-index: 2;
  font-size: 0;
}

.options2__btn {
  padding: 5px 8px;
  border-radius: 7px;
  font-size: 0;
}

.options2__btn .icon {
  font-size: 4px;
  fill: #92929D;
  transition: fill .25s;
}

.options2__btn:hover .icon {
  fill: #B5B5BE;
}

.options2__dropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  z-index: 3;
  width: 253px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid #F1F1F5;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
  border-radius: 8px;
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
}

.options2__item {
  position: relative;
  display: flex;
}

.options2__item:not(:last-child) {
  margin-bottom: 15px;
}

.options2__icon {
  flex-shrink: 0;
  width: 24px;
  margin-right: 12px;
  text-align: center;
  font-size: 0;
}

.options2__icon .icon {
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.options2__item:hover .icon {
  fill: #B5B5BE;
}

.options2__details {
  flex-grow: 1;
}

.options2__info {
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 1.15;
  color: #44444F;
}

.options2__text {
  font-size: 12px;
  color: #92929D;
}

.options2__link {
  display: block;
  font-size: 12px;
  line-height: 1.69231;
  color: #44444F;
  transition: color .25s;
}

.options2__link:hover {
  color: #92929D;
}

.options2__link:not(:last-child) {
  margin-bottom: 2px;
}

.options2.active {
  z-index: 4;
}

.options2.active .options2__btn {
  background: #E2E2EA;
  opacity: 1;
}

.options2.active .options2__dropdown {
  visibility: visible;
  opacity: 1;
}

body.dark .options2.active .options2__btn {
  background: #292932;
}

body.dark .options2__dropdown {
  background: #292932;
  border-color: #44444F;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
}

body.dark .options2__info {
  color: #F1F1F5;
}

body.dark .options2__text {
  color: #696974;
}

body.dark .options2__link {
  color: #D5D5DC;
}

body.dark .options2__link:hover {
  color: #FAFAFB;
}

.card {
  padding: 15px;
  background: #ffffff;
  border-radius: 20px;
}

.card__team {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 35px;
}

@media only screen and (max-width: 767px) {
  .card__team {
    padding: 0;
  }
}

.card__team .options {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 767px) {
  .card .options {
    display: none;
  }
}

.card .options__dropdown {
  width: 268px;
}

.card__team .card__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 52px;
  height: 52px;
  margin-right: 20px;
  border: 1px solid #E2E2EA;
  border-radius: 10px;
  overflow: hidden;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .card__team .card__logo {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
}

.card__team .card__pic {
  max-width: 100%;
  max-height: 100%;
}

.card__preview {
  margin-bottom: 10px;
  font-size: 0;
}

.card__pic {
  width: 100%;
  border-radius: 11px;
}

.card__title {
  display: block;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  line-height: 1.5;
  font-weight: 600;
  color: #171725;
  transition: opacity .25s;
}

.card__title:hover {
  opacity: .8;
}

.card__title_done {
  text-decoration: line-through;
}

.card__head .card__title {
  margin-bottom: 5px;
}

.card__info {
  color: #696974;
}

.card__add {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
  color: #171725;
}

.card__add .card__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  margin-right: 10px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 0;
}

.card__add .card__icon .icon {
  font-size: 15px;
  fill: #0062FF;
}

.card__parameters {
  display: flex;
  align-items: center;
  height: 26px;
}

.card__parameter {
  display: flex;
  align-items: center;
  color: #92929D;
}

.card__parameter .icon {
  margin-right: 5px;
  font-size: 14px;
  fill: #92929D;
}

.card__parameter:not(:last-child) {
  margin-right: 15px;
}

.card__parameter.card__parameter_time {
  padding: 4px 6px;
  border-radius: 5px;
  background: #F1F1F5;
}

.card__parameter.card__parameter_time.orange {
  background: rgba(255, 151, 74, 0.1);
  color: #FF974A;
}

.card__parameter.card__parameter_time.orange .icon {
  fill: #FF974A;
}

.card__parameter.card__parameter_time.red {
  background: rgba(252, 90, 90, 0.1);
  color: #FC5A5A;
}

.card__parameter.card__parameter_time.red .icon {
  fill: #FC5A5A;
}

.card__percent {
  margin-bottom: 5px;
  text-align: right;
  color: #696974;
}

.card__line {
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: #E2E2EA;
}

.card__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 2px;
  background: #3DD598;
}

.card_add {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #D5D5DC;
  background: none;
  transition: border-color .25s;
}

@media only screen and (max-width: 1199px) {
  .card_add {
    display: none;
  }
}

.card_add:hover {
  border-color: #0062FF;
}

.card_add, .card_big {
  padding: 20px;
}

@media only screen and (max-width: 1599px) {
  .card_add, .card_big {
    padding: 15px;
  }
}

.card_big .card__head {
  position: relative;
  margin-bottom: 20px;
  padding-right: 44px;
}

@media only screen and (max-width: 1599px) {
  .card_big .card__head {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .card_big .card__head {
    padding-right: 0;
  }
}

.card_big .card__title {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 1365px) {
  .card_big .card__title {
    font-size: 14px;
  }
}

.card_big .card__info {
  font-size: 16px;
}

@media only screen and (max-width: 1365px) {
  .card_big .card__info {
    font-size: 14px;
  }
}

.card_big .options {
  position: absolute;
  top: 0;
  right: 0;
}

.card_big .card__parameters {
  margin-bottom: 24px;
}

@media only screen and (max-width: 1599px) {
  .card_big .card__parameters {
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 1365px) {
  .card_big .card__parameters {
    margin-bottom: 15px;
  }
}

.card_big .card__scale {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1365px) {
  .card_big .card__scale {
    margin-bottom: 15px;
  }
}

body.dark .card {
  background: #1C1C24;
}

body.dark .card__logo {
  box-shadow: inset 0 0 0 1px #E2E2EA;
}

body.dark .card__title {
  color: #FAFAFB;
}

body.dark .card__add {
  color: #FAFAFB;
}

body.dark .card__info {
  color: #B5B5BE;
}

body.dark .card__parameter_time {
  background: #44444F;
  color: #B5B5BE;
}

body.dark .card__parameter_time .icon {
  fill: #B5B5BE;
}

body.dark .card__percent {
  color: #B5B5BE;
}

body.dark .card_add {
  border-color: #92929D;
  background: none;
}

body.dark .card_add:hover {
  border-color: #ffffff;
}

.dashboard__start {
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: .1px;
  color: #92929D;
}

@media only screen and (max-width: 1365px) {
  .dashboard__start {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard__start {
    font-size: 16px;
  }
}

.dashboard__start strong {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 24px;
  font-weight: 600;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .dashboard__start strong {
    font-size: 20px;
  }
}

.dashboard__section {
  border: 1px solid #E2E2EA;
  border-radius: 23px;
}

.dashboard__section:not(:last-child) {
  margin-bottom: 20px;
}

.dashboard__head {
  position: relative;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 60px 0 20px;
}

.dashboard__category {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
  color: #696974;
}

.dashboard__head .options {
  position: absolute;
  top: 50%;
  right: 25px;
  z-index: 2;
  transform: translateY(-50%);
}

@media only screen and (max-width: 1599px) {
  .dashboard__head .options {
    right: 20px;
  }
}

.dashboard__body {
  padding: 0 5px 5px;
}

.dashboard__list {
  display: flex;
  flex-wrap: wrap;
  margin: -30px -15px 0;
}

@media only screen and (max-width: 1599px) {
  .dashboard__list {
    margin: -20px -10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard__list {
    display: block;
    margin: -10px 0 0;
  }
}

.dashboard__list .card {
  flex: 0 0 calc(33.333% - 30px);
  width: calc(33.333% - 30px);
  margin: 30px 15px 0;
}

@media only screen and (max-width: 1599px) {
  .dashboard__list .card {
    flex: 0 0 calc(33.333% - 20px);
    width: calc(33.333% - 20px);
    margin: 20px 10px 0;
  }
}

@media only screen and (max-width: 1199px) {
  .dashboard__list .card {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .dashboard__list .card {
    width: 100%;
    margin: 10px 0 0;
  }
}

body.dark .dashboard__start strong {
  color: #FAFAFB;
}

body.dark .dashboard__section {
  border-color: #44444F;
}

body.dark .dashboard__category {
  color: #92929D;
}

.dropdown {
  position: relative;
  min-width: 200px;
}

.dropdown__head {
  position: relative;
  z-index: 6;
  display: flex;
  align-items: center;
  padding: 0 40px 0 12px;
  border-radius: 10px;
  height: 38px;
  box-shadow: 0px 0px 7px rgba(41, 41, 50, 0.1);
  background: #ffffff;
  transition: opacity .25s;
}

.dropdown__head:before, .dropdown__head:after {
  content: "";
  position: absolute;
}

.dropdown__head:before {
  top: 0;
  right: 28px;
  bottom: 0;
  width: 1px;
  background: #F1F1F5;
}

.dropdown__head:after {
  top: 50%;
  right: 9px;
  transform: translateY(-50%);
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 7px 5.5px 0 5.5px;
  border-color: #92929D transparent transparent transparent;
  transition: transform .25s;
}

.dropdown__head:hover {
  opacity: .8;
}

.dropdown__text {
  margin-right: 5px;
  color: #696974;
}

.dropdown__category {
  margin-right: 12px;
  font-weight: 500;
  color: #44444F;
}

.dropdown__body {
  position: absolute;
  top: calc(100% - 10px);
  left: 0;
  right: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 10px 15px;
  background: #ffffff;
  border: 1px solid #F1F1F5;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
  border-radius: 0px 0px 8px 8px;
  transform: translateY(10px);
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
}

.dropdown.active .dropdown__head:after {
  transform: translateY(-50%) rotate(180deg);
}

.dropdown.active .dropdown__body {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.dropdown .checkbox:not(:last-child) {
  margin-bottom: 15px;
}

body.dark .dropdown__head {
  background: #1C1C24;
}

body.dark .dropdown__head:before {
  background: #44444F;
}

body.dark .dropdown__text {
  color: #92929D;
}

body.dark .dropdown__category {
  color: #D5D5DC;
}

body.dark .dropdown__body {
  background: #292932;
  border-color: #44444F;
}

.sorting {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1439px) {
  .sorting {
    margin-bottom: 25px;
  }
}

.sorting__title {
  margin-right: 30px;
}

@media only screen and (max-width: 1199px) {
  .sorting__title {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .sorting__title {
    display: none;
  }
}

.sorting__variants {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.sorting__variants .sorting__text {
  margin-right: 5px;
  font-size: 18px;
  color: #92929D;
}

@media only screen and (max-width: 1199px) {
  .sorting__variants .sorting__text {
    font-size: 16px;
  }
}

.sorting__select {
  height: auto;
  padding: 0 22px 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat 100% 55%/11px 7px;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #44444F;
  cursor: pointer;
}

@media only screen and (max-width: 1199px) {
  .sorting__select {
    font-size: 16px;
  }
}

.sorting__select::-ms-expand {
  display: none;
}

.sorting__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.sorting__options {
  display: flex;
  align-items: center;
  margin-left: auto;
}

@media only screen and (max-width: 767px) {
  .sorting .dropdown {
    display: none;
  }
}

.sorting__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  margin-left: 15px;
  border-radius: 10px;
  background: #ffffff;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .sorting__filters {
    width: 88px;
    height: 32px;
    border-radius: 7px;
    font-size: 14px;
    color: #696974;
  }
}

.sorting__filters .icon {
  font-size: 18px;
  fill: #92929D;
  transition: fill .25s;
}

@media only screen and (max-width: 767px) {
  .sorting__filters .icon-filters {
    display: none;
  }
}

.sorting__filters .icon-filters-1 {
  display: none;
}

@media only screen and (max-width: 767px) {
  .sorting__filters .icon-filters-1 {
    display: inline-block;
    margin-right: 12px;
    font-size: 10px;
    color: #92929D;
  }
}

.sorting__filters:hover .icon {
  fill: #B5B5BE;
}

.sorting__view {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 15px;
}

@media only screen and (max-width: 1199px) {
  .sorting__view {
    display: none;
  }
}

.sorting__box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  background: #ffffff;
  font-size: 0;
  transition: background .25s;
}

.sorting__box .icon {
  font-size: 16px;
  fill: #92929D;
  transition: fill .25s;
}

.sorting__box:hover {
  background: #ebebee;
}

.sorting__box.active {
  background: #B5B5BE;
}

.sorting__box.active .icon {
  fill: #ffffff;
}

.sorting__box:not(:last-child) {
  border-right: 1px solid #F1F1F5;
}

body.dark .sorting__title {
  color: #FAFAFB;
}

body.dark .sorting__variants .sorting__text {
  color: #696974;
}

body.dark .sorting__select {
  color: #B5B5BE;
}

body.dark .sorting__filters {
  background: #292932;
  color: #FAFAFB;
}

body.dark .sorting__box {
  background: #292932;
  border-color: #44444F;
}

body.dark .sorting__box .icon {
  fill: #92929D;
}

body.dark .sorting__box:hover {
  background: #4d4d5a;
}

body.dark .sorting__box.active {
  background: #44444F;
}

body.dark .sorting__box.active .icon {
  fill: #92929D;
}

.sorting1 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1439px) {
  .sorting1 {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .sorting1 {
    margin-bottom: 5px;
  }
}

.sorting1__row {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .sorting1__row {
    height: 56px;
    padding: 0 20px;
    background: #ffffff;
  }
}

.sorting1__row:not(:last-child) {
  margin-bottom: 20px;
}

.sorting1__title {
  margin-right: 30px;
}

@media only screen and (max-width: 1199px) {
  .sorting1__title {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .sorting1__title {
    font-size: 18px;
  }
}

.sorting1__variants {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.sorting1__variants .sorting1__text {
  margin-right: 5px;
  font-size: 18px;
  color: #92929D;
}

@media only screen and (max-width: 1199px) {
  .sorting1__variants .sorting1__text {
    font-size: 16px;
  }
}

.sorting1__variants .sorting1__select {
  height: auto;
  padding: 0 22px 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat 100% 60%/11px 7px;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #44444F;
  cursor: pointer;
}

@media only screen and (max-width: 1199px) {
  .sorting1__variants .sorting1__select {
    font-size: 16px;
  }
}

.sorting1__variants .sorting1__select::-ms-expand {
  display: none;
}

.sorting1__variants .sorting1__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.sorting1__search {
  position: relative;
  border-radius: 10px;
  transition: width .25s;
}

.sorting1__open {
  position: relative;
  z-index: 2;
  width: 38px;
  height: 38px;
  border-radius: 10px;
  background: #ffffff;
  font-size: 0;
}

.sorting1__open .icon {
  font-size: 20px;
  fill: #92929D;
  transition: fill .25s;
}

.sorting1__open:hover .icon {
  fill: #B5B5BE;
}

.sorting1__search .sorting1__box {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 38px;
  overflow: hidden;
  border-radius: 10px;
  transition: width .25s;
}

.sorting1__search.show .sorting1__box {
  width: 200px;
}

@media only screen and (max-width: 767px) {
  .sorting1__search.show .sorting1__box {
    width: 285px;
  }
}

.sorting1__search .sorting1__input {
  width: 100%;
  height: 38px;
  padding: 0 38px 0 15px;
  background: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #171725;
}

.sorting1__search .sorting1__input::placeholder {
  color: #92929D;
}

.sorting1__options {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.sorting1 .dropdown {
  margin-left: 15px;
}

@media only screen and (max-width: 767px) {
  .sorting1 .dropdown {
    display: none;
  }
}

.sorting1 .dropdown__head {
  box-shadow: none;
}

.sorting1__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  margin-left: 15px;
  border-radius: 10px;
  background: #ffffff;
  font-size: 0;
}

.sorting1__filters .icon {
  font-size: 18px;
  fill: #92929D;
  transition: fill .25s;
}

.sorting1__filters:hover .icon {
  fill: #B5B5BE;
}

.sorting1__options .sorting1__btn {
  min-width: 170px;
  margin-left: 15px;
  padding: 0 15px;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .sorting1__options .sorting1__btn {
    min-width: 95px;
    height: 32px;
    padding: 0;
  }
}

.sorting1__options .sorting1__btn .icon-download-doc {
  font-size: 24px;
}

.sorting1__form {
  display: flex;
  padding: 15px;
  background: #ffffff;
  border-radius: 10px;
}

@media only screen and (max-width: 1023px) {
  .sorting1__form {
    display: none;
  }
}

.sorting1__wrap {
  flex-grow: 1;
  padding-right: 15px;
}

.sorting1__form .sorting1__btn {
  min-width: 105px;
}

.sorting1__fieldset {
  display: flex;
  margin: 0 -8px;
}

.sorting1__field {
  position: relative;
  margin: 0 8px;
}

.sorting1__field:first-child {
  flex: 0 0 calc(39% - 16px);
  width: calc(39% - 16px);
}

.sorting1__field:nth-child(2) {
  flex: 0 0 calc(33% - 16px);
  width: calc(33% - 16px);
}

.sorting1__field:nth-child(3) {
  flex: 0 0 calc(28% - 16px);
  width: calc(28% - 16px);
}

.sorting1__field .sorting1__input {
  width: 100%;
  height: 38px;
  padding: 0 13px;
  background: #FAFAFB;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #171725;
}

.sorting1__field .sorting1__input::placeholder {
  color: #92929D;
}

.sorting1__field_icon .sorting1__input {
  padding-left: 43px;
}

.sorting1__field .sorting1__icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  padding-left: 4px;
  pointer-events: none;
  font-size: 0;
}

.sorting1__field .sorting1__icon .icon {
  font-size: 20px;
  fill: #92929D;
}

.sorting1__field .sorting1__select {
  width: 100%;
  height: 38px;
  padding: 0 30px 0 13px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 10px;
  border: none;
  background: #FAFAFB url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M6 4.586L10.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5A1 1 0 1 1 1.707.293L6 4.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat calc(100% - 12px) 50%/12px auto;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #92929D;
  cursor: pointer;
}

.sorting1__field .sorting1__select::-ms-expand {
  display: none;
}

.sorting1__field .sorting1__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

@media only screen and (max-width: 767px) {
  body.dark .sorting1__row {
    background: #1C1C24;
  }
}

body.dark .sorting1__title {
  color: #FAFAFB;
}

body.dark .sorting1__search .sorting1__input {
  background: #1C1C24;
}

@media only screen and (max-width: 767px) {
  body.dark .sorting1__search .sorting1__input {
    background: #292932;
  }
}

body.dark .sorting1__open, body.dark .sorting1__filters {
  background: #1C1C24;
}

@media only screen and (max-width: 767px) {
  body.dark .sorting1__open, body.dark .sorting1__filters {
    background: #292932;
  }
}

body.dark .sorting1__form {
  background: #1C1C24;
}

body.dark .sorting1__field .sorting1__input,
body.dark .sorting1__field .sorting1__select {
  background: #292932;
  color: #92929D;
}

body.dark .sorting1__variants .sorting1__text {
  color: #696974;
}

body.dark .sorting1__variants .sorting1__select {
  color: #B5B5BE;
}

@media only screen and (max-width: 767px) {
  body.dark .sorting1__variants .sorting1__select {
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    color: #FAFAFB;
  }
}

.projects__container.cell {
  display: flex;
  align-items: flex-start;
  margin-right: -40px;
  overflow: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 1599px) {
  .projects__container.cell {
    margin-right: -30px;
  }
}

@media only screen and (max-width: 1365px) {
  .projects__container.cell {
    margin-right: -25px;
  }
}

@media only screen and (max-width: 1023px) {
  .projects__container.cell {
    margin: 0 -20px;
  }
}

.projects__container.cell .projects__section {
  flex-shrink: 0;
}

@media only screen and (max-width: 1023px) {
  .projects__container.cell .projects__section:first-child {
    padding-left: 20px;
  }
}

.projects__container.cell .projects__section:last-child {
  padding-right: 40px;
}

@media only screen and (max-width: 1599px) {
  .projects__container.cell .projects__section:last-child {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 1365px) {
  .projects__container.cell .projects__section:last-child {
    padding-right: 25px;
  }
}

@media only screen and (max-width: 1023px) {
  .projects__container.cell .projects__section:last-child {
    padding-right: 20px;
  }
}

.projects__container.cell .projects__section:not(:last-child) {
  margin-right: 20px;
}

.projects__container.cell .projects__inner {
  width: 302px;
}

.projects__container.cell .projects__group {
  max-height: 700px;
  padding: 0 5px 20px;
  overflow: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 1023px) {
  .projects__container.cell .projects__group {
    max-height: 740px;
  }
}

@media only screen and (max-width: 767px) {
  .projects__container.cell .projects__group {
    max-height: calc(100vh - 261px);
  }
}

.projects__container.cell .card__preview {
  margin: -5px -5px 10px;
}

.projects__container.cell .card__head {
  margin-bottom: 15px;
}

.projects__container.cell .card__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.projects__container.cell .card__parameters {
  margin-bottom: 10px;
}

.projects__container.cell .card__scale {
  margin-bottom: 10px;
}

.projects__container.cell .card .options {
  display: none;
}

.projects__container.line .projects__head .options {
  right: 30px;
}

@media only screen and (max-width: 1439px) {
  .projects__container.line .projects__head .options {
    right: 20px;
  }
}

.projects__container.line .projects__section:not(:last-child) {
  margin-bottom: 20px;
}

.projects__container.line .projects__section_empty {
  display: none;
}

.projects__container.line .projects__group {
  padding: 0 5px 5px;
}

.projects__container.line .projects__add {
  display: none;
}

.projects__container.line .card {
  display: flex;
  align-items: center;
  padding: 17px 25px;
}

@media only screen and (max-width: 1439px) {
  .projects__container.line .card {
    padding: 15px;
  }
}

.projects__container.line .card__preview {
  display: none;
}

.projects__container.line .card__head {
  flex-grow: 1;
  padding-right: 30px;
}

@media only screen and (max-width: 1365px) {
  .projects__container.line .card__head {
    padding-right: 20px;
  }
}

.projects__container.line .card__parameters {
  flex: 0 0 258px;
  padding-right: 20px;
}

@media only screen and (max-width: 1439px) {
  .projects__container.line .card__parameters {
    flex: 0 0 245px;
  }
}

@media only screen and (max-width: 1365px) {
  .projects__container.line .card__parameters {
    padding-right: 15px;
  }
}

.projects__container.line .card__scale {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex: 0 0 242px;
  margin-right: 50px;
}

@media only screen and (max-width: 1439px) {
  .projects__container.line .card__scale {
    flex: 0 0 200px;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 1365px) {
  .projects__container.line .card__scale {
    flex: 0 0 170px;
    margin-right: 20px;
  }
}

.projects__container.line .card__percent {
  flex-shrink: 0;
  width: 42px;
  margin: 0;
  text-align: right;
}

.projects__container.line .card__line {
  flex-grow: 1;
}

.projects__container.line .users {
  flex: 0 0 116px;
  margin: 0 45px 0 0;
}

@media only screen and (max-width: 1439px) {
  .projects__container.line .users {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 1365px) {
  .projects__container.line .users {
    flex: 0 0 100px;
    margin-right: 20px;
  }
}

.projects__container.line .users__item {
  margin: 0;
}

.projects__container.line .users__item:not(:last-child) {
  margin-right: 10px;
}

.projects__inner {
  border: 1px solid #E2E2EA;
  border-radius: 23px;
}

@media only screen and (max-width: 767px) {
  .projects__inner {
    border-radius: 20px;
  }
}

.projects__head {
  position: relative;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 60px 0 20px;
}

.projects__category {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
  color: #696974;
}

.projects__head .options {
  position: absolute;
  top: 50%;
  right: 25px;
  z-index: 2;
  transform: translateY(-50%);
}

.projects__add {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  border-radius: 0 0 23px 23px;
  border-top: 1px solid #E2E2EA;
  background: #ffffff;
  transition: background .25s;
}

@media only screen and (max-width: 767px) {
  .projects__add {
    border-radius: 0 0 19px 19px;
  }
}

.projects__add .icon {
  font-size: 18px;
  fill: #92929D;
  transition: fill .25s;
}

.projects__add:hover {
  background: #E2E2EA;
}

.projects__add:hover .icon {
  fill: #0062FF;
}

.projects .card:not(:last-child) {
  margin-bottom: 10px;
}

body.dark .projects__inner {
  border-color: #44444F;
}

body.dark .projects__category {
  color: #92929D;
}

body.dark .projects__add {
  background: #1C1C24;
  border-color: #44444F;
}

body.dark .projects__add .icon {
  fill: #FAFAFB;
}

body.dark .projects__add:hover {
  background: #44444F;
}

.ava {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.ava__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.ava.active {
  border: 2px solid #0062FF;
}

.ava.active .ava__pic {
  border: 1px solid #F1F1F5;
}

.popup {
  position: relative;
  max-width: 750px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 20px;
}

@media only screen and (max-width: 767px) {
  .popup {
    max-width: 100%;
    min-height: 100vh;
    border-radius: 0;
  }
}

.popup .mfp-close {
  position: absolute;
  top: 20px;
  right: 24px;
  width: 24px;
  height: 24px;
  line-height: normal;
  opacity: 1;
  font-size: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='none'%3E%3Cpath fill-rule='evenodd' d='M5.94 5L9.46 8.52c.26.26.26.683 0 .943s-.683.26-.943 0L4.997 5.942 1.47 9.464c-.26.26-.683.26-.943-.001S.268 8.78.53 8.52L4.055 5 .53 1.47C.27 1.21.27.79.53.528s.683-.26.943 0l3.525 3.528L8.516.545c.26-.26.683-.26.943.001s.26.683-.001.943L5.94 5z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat 50% 50%/14px auto;
  transition: opacity .25s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 767px) {
  .popup .mfp-close {
    top: 17px;
    left: 20px;
  }
}

.popup .mfp-close:hover {
  opacity: .75;
}

.mfp-container {
  padding: 30px 30px 20px;
}

@media only screen and (max-width: 1199px) {
  .mfp-container {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .mfp-container {
    padding: 0;
  }
}

@media (max-width: 900px) {
  .mfp-container {
    padding: 30px;
  }
}

.mfp-bg {
  background: rgba(23, 23, 37, 0.3);
}

.mfp-zoom-in .popup {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.2s ease-out;
}

.mfp-zoom-in.mfp-ready .popup {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 1;
}

.mfp-zoom-in.mfp-removing .popup {
  transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

body.dark .popup {
  background: #1C1C24;
}

body.dark .mfp-bg {
  background: rgba(146, 146, 157, 0.3);
}

.details__top {
  padding: 15px 120px 15px 20px;
  border-bottom: 1px solid #E2E2EA;
}

@media only screen and (max-width: 767px) {
  .details__top {
    padding: 13px 55px 13px 64px;
  }
}

.details__tag {
  display: inline-flex;
  align-items: center;
  padding: 6px 10px;
  border: 1px solid #E7E7E7;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
  color: #92929D;
}

.details__tag .icon {
  margin-right: 11px;
  font-size: 11px;
  fill: #696974;
}

.details__top .options {
  position: absolute;
  top: 20px;
  right: 68px;
}

@media only screen and (max-width: 767px) {
  .details__top .options {
    top: 17px;
    right: 20px;
  }
}

.details__container {
  padding: 32px 40px 40px;
}

@media only screen and (max-width: 767px) {
  .details__container {
    padding: 20px 15px 40px;
  }
}

.details__head {
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .details__head {
    margin-bottom: 20px;
  }
}

.details__title {
  margin-bottom: 3px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .details__title {
    font-size: 18px;
  }
}

.details__info {
  color: #696974;
}

.details__info .details__author {
  color: #0062FF;
}

.details__group {
  display: flex;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .details__group {
    margin-bottom: 20px;
    padding-right: 20px;
  }
}

.details__box:not(:last-child) {
  margin-right: 143px;
}

@media only screen and (max-width: 767px) {
  .details__box:not(:last-child) {
    margin-right: auto;
  }
}

.details__category {
  margin-bottom: 7px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .5px;
  color: #92929D;
}

.details__date {
  font-size: 16px;
  line-height: 2;
  font-weight: 500;
  color: #44444F;
}

.details__wrapper {
  padding-top: 30px;
  border-top: 1px solid #E2E2EA;
}

@media only screen and (max-width: 767px) {
  .details__wrapper {
    padding-top: 20px;
  }
}

.details__item:not(:last-child) {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .details__item:not(:last-child) {
    margin-bottom: 30px;
  }
}

.details__stage {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
}

.details__stage .icon {
  margin-right: 20px;
  font-size: 24px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .details__stage .icon {
    margin-right: 15px;
    font-size: 18px;
  }
}

.details__stage_mb-lg {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .details__stage_mb-lg {
    margin-bottom: 15px;
  }
}

.details .article {
  padding-left: 44px;
}

@media only screen and (max-width: 767px) {
  .details .article {
    padding-left: 33px;
  }
}

.details__gallery {
  display: flex;
  margin-left: 44px;
  overflow: auto;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 767px) {
  .details__gallery {
    margin-left: 33px;
    margin-right: -15px;
  }
}

.details__gallery::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 767px) {
  .details__photo:last-child {
    padding-right: 15px;
  }
}

.details__photo:not(:last-child) {
  margin-right: 13px;
}

@media only screen and (max-width: 767px) {
  .details__photo:not(:last-child) {
    margin-right: 10px;
  }
}

.details__preview {
  margin-bottom: 6px;
  font-size: 0;
}

.details__pic {
  max-width: 300px;
  border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .details__pic {
    max-width: 200px;
  }
}

.details__photo .details__text {
  font-size: 12px;
  line-height: 1.83333;
  color: #92929D;
}

.details__scale {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .details__scale {
    margin-bottom: 20px;
  }
}

.details__percent {
  flex-shrink: 0;
  width: 44px;
  color: #696974;
}

@media only screen and (max-width: 767px) {
  .details__percent {
    width: 33px;
  }
}

.details__line {
  position: relative;
  flex-grow: 1;
  height: 8px;
  border-radius: 4px;
  background: #E2E2EA;
}

.details__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 4px;
  background: #3DD598;
}

.details__list {
  padding-left: 2px;
}

.details__list .checkbox:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .details__list .checkbox:not(:last-child) {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .details__list .checkbox__tick {
    flex: 0 0 16px;
    width: 16px;
    height: 16px;
    margin-right: 15px;
    border-radius: 2px;
  }
  .details__list .checkbox__tick:before {
    width: 8px;
    height: 7px;
  }
}

.details__list .checkbox__flex {
  margin-top: -4px;
}

@media only screen and (max-width: 767px) {
  .details__list .checkbox__flex {
    margin-top: -2px;
  }
}

body.dark .details__top {
  border-color: #44444F;
}

body.dark .details__title {
  color: #FAFAFB;
}

body.dark .details__tag {
  border-color: #696974;
}

body.dark .details__info {
  color: #92929D;
}

body.dark .details__author {
  color: #1E75FF;
}

body.dark .details__category {
  color: #FAFAFB;
}

body.dark .details__date {
  color: #B5B5BE;
}

body.dark .details__wrapper {
  border-color: #44444F;
}

body.dark .details__stage {
  color: #B5B5BE;
}

@media only screen and (max-width: 767px) {
  body.dark .details__stage {
    color: #FAFAFB;
  }
}

.article__content {
  height: 144px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .article__content {
    height: 72px;
  }
}

.article__content p {
  font-size: 14px;
  line-height: 1.71429;
}

.article__content p:not(:last-child) {
  margin-bottom: 23px;
}

@media only screen and (max-width: 767px) {
  .article__content p:not(:last-child) {
    margin-bottom: 13px;
  }
}

.article__more {
  position: relative;
  display: inline-block;
  border-bottom: 1px solid transparent;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  line-height: 1;
  color: #0062FF;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 767px) {
  .article__more {
    margin-top: 10px;
  }
}

.article__more:hover {
  border-color: #0062FF;
}

body.dark .article__content {
  color: #92929D;
}

body.dark .article__more {
  color: #1E75FF;
}

.user {
  display: inline-flex;
  align-items: center;
}

.user__ava, .user__letters {
  flex-shrink: 0;
  width: 18px;
  margin-right: 5px;
}

.user__ava {
  height: 18px;
  font-size: 0;
}

.user__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.user__letters {
  padding-top: 2px;
  border-radius: 50%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
  font-size: 8px;
  line-height: 16px;
  text-transform: uppercase;
  color: #ffffff;
}

.user__name {
  color: #B5B5BE;
}

.comments__category {
  position: relative;
  margin-bottom: 17px;
  text-align: center;
}

.comments__category:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: 1px;
  background: #F1F1F5;
}

.comments__category span {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  background: #ffffff;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
  color: #171725;
}

.comments__list {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .comments__list {
    margin-bottom: 10px;
  }
}

.comments__list_mb30 {
  margin-bottom: 30px;
}

.comments__item {
  position: relative;
  margin-left: 24px;
  padding: 11px 20px 20px;
  border-radius: 20px;
  background: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  .comments__item {
    margin-left: 12px;
    padding: 20px 15px 15px 25px;
  }
}

.comments__item:not(:last-child) {
  margin-bottom: 20px;
}

.comments__item > .ava, .comments__write .ava {
  position: absolute;
  left: -24px;
  box-shadow: 0 0 0 3px #ffffff;
}

@media only screen and (max-width: 767px) {
  .comments__item > .ava, .comments__write .ava {
    left: -10px;
    width: 26px;
    height: 26px;
  }
}

.comments__item > .ava {
  top: 7px;
}

@media only screen and (max-width: 767px) {
  .comments__item > .ava {
    top: 15px;
  }
}

.comments__write .ava {
  top: 50%;
  transform: translateY(-50%);
}

.comments__head {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .comments__head {
    display: block;
  }
}

.comments__author {
  margin-right: auto;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .comments__author {
    margin: 0 0 2px;
  }
}

.comments__post {
  font-weight: 400;
}

.comments__date {
  margin-right: 10px;
  font-size: 12px;
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .comments .options {
    position: absolute;
    top: 5px;
    right: 15px;
  }
}

.comments__line {
  margin-top: 10px;
  color: #44444F;
}

.comments__line .ava {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  font-size: 10px;
}

.comments__line .comments__info {
  position: relative;
  top: -4px;
}

.comments__line .comments__info strong {
  font-weight: 500;
}

.comments__text {
  margin-top: 8px;
  line-height: 1.71429;
  color: #44444F;
}

.comments__photo {
  max-width: 386px;
  margin-top: 10px;
  border: 1px solid #F1F1F5;
  border-radius: 6px;
  font-size: 0;
}

.comments__photo .comments__pic {
  width: 100%;
  border-radius: 5px;
}

.comments__write {
  position: relative;
  margin-left: 24px;
  padding: 20px;
  border-radius: 10px;
  background: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  .comments__write {
    margin-left: 12px;
    padding: 10px 10px 10px 25px;
  }
}

.comments__input {
  width: 100%;
  height: 42px;
  padding: 0 12px;
  background: #ffffff;
  border: 1px solid #E2E2EA;
  border-radius: 3px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #171725;
}

.comments__input::placeholder {
  color: #92929D;
}

body.dark .comments__category:before, body.dark .comments__category:after {
  background: #44444F;
}

body.dark .comments__category span {
  background: #13131A;
  color: #FAFAFB;
}

body.dark .comments__item {
  background: #292932;
}

body.dark .comments__item > .ava, body.dark .comments__write .ava {
  box-shadow: 0 0 0 3px #1C1C24;
}

body.dark .comments__author, body.dark .comments__info {
  color: #B5B5BE;
}

body.dark .comments__author strong {
  color: #FAFAFB;
}

body.dark .comments__text {
  color: #92929D;
}

body.dark .comments__write {
  background: #292932;
}

body.dark .comments__input {
  background: #1C1C24;
  border-color: #1C1C24;
  color: #FAFAFB;
}

body.dark .comments__input::placeholder {
  color: #696974;
}

body.dark .comments__photo {
  border-color: #44444F;
}

.activity__group {
  margin-bottom: 20px;
}

.activity__head {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 767px) {
  .activity__head {
    font-size: 20px;
  }
}

.activity__head .icon {
  margin-left: 15px;
  font-size: 7px;
  color: #92929D;
  transition: transform .25s;
}

.activity__group.active .activity__head .icon {
  transform: rotate(180deg);
}

.activity__tags {
  display: flex;
  padding-top: 20px;
  margin-right: -40px;
  overflow: auto;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 1599px) {
  .activity__tags {
    margin-right: -30px;
  }
}

@media only screen and (max-width: 1365px) {
  .activity__tags {
    margin-right: -25px;
  }
}

@media only screen and (max-width: 1023px) {
  .activity__tags {
    margin: 0 -20px;
  }
}

.activity__tags::-webkit-scrollbar {
  display: none;
}

.activity__tags li:not(:last-child) {
  padding-right: 15px;
}

@media only screen and (max-width: 1023px) {
  .activity__tags li:not(:last-child) {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 1023px) {
  .activity__tags li:first-child {
    padding-left: 20px;
  }
}

.activity__tags li:last-child {
  padding-right: 40px;
}

@media only screen and (max-width: 1599px) {
  .activity__tags li:last-child {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 1365px) {
  .activity__tags li:last-child {
    padding-right: 25px;
  }
}

@media only screen and (max-width: 1023px) {
  .activity__tags li:last-child {
    padding-right: 20px;
  }
}

.activity__tag {
  display: flex;
  align-items: center;
  padding: 6px 11px;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  white-space: nowrap;
  font-weight: 500;
  color: #696974;
  transition: all .25s;
}

.activity__tag:hover {
  background: #ffffff;
}

.activity__tag.active {
  background: #ffffff;
  border: 1px solid rgba(226, 226, 234, 0.5);
}

.activity__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  font-size: 0;
}

.activity__icon .activity__pic {
  max-width: 100%;
  max-height: 100%;
}

.activity__container {
  position: relative;
  padding: 8px 30px 30px;
  border-radius: 20px;
  background: #ffffff;
}

@media only screen and (max-width: 1439px) {
  .activity__container {
    padding: 8px 20px 25px;
  }
}

@media only screen and (max-width: 1023px) {
  .activity__container {
    margin: 0 -20px;
    border-radius: 0;
  }
}

@media only screen and (max-width: 767px) {
  .activity__container {
    padding: 5px 15px 15px;
  }
}

.activity__wrap {
  position: relative;
}

.activity__wrap:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 1px;
  background: #E2E2EA;
}

.activity__section {
  position: relative;
  z-index: 2;
}

.activity__section:not(:last-child) {
  padding-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .activity__section:not(:last-child) {
    padding-bottom: 20px;
  }
}

.activity__date {
  margin-bottom: 10px;
  padding: 10px 0;
  text-align: center;
  background: #ffffff;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .activity__date {
    padding: 7px 0;
  }
}

.activity__section:first-child .activity__date {
  margin: 0;
  padding: 20px 0;
}

@media only screen and (max-width: 767px) {
  .activity__section:first-child .activity__date {
    margin-bottom: 10px;
    padding: 10px 0;
  }
}

.activity__list {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .activity__list {
    width: 100%;
  }
}

.activity__section:nth-child(2n+1) .activity__list {
  padding-right: 40px;
}

@media only screen and (max-width: 1439px) {
  .activity__section:nth-child(2n+1) .activity__list {
    padding-right: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .activity__section:nth-child(2n+1) .activity__list {
    padding: 0;
  }
}

.activity__section:nth-child(2n+1) .activity__item:before {
  left: 100%;
}

.activity__section:nth-child(2n) .activity__list {
  margin-left: auto;
  padding-left: 40px;
}

@media only screen and (max-width: 1439px) {
  .activity__section:nth-child(2n) .activity__list {
    padding-left: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .activity__section:nth-child(2n) .activity__list {
    padding-left: 0;
  }
}

.activity__section:nth-child(2n) .activity__item:before {
  right: 100%;
}

.activity__item {
  position: relative;
  padding: 20px 20px 30px;
  border-radius: 20px;
  border: 1px solid #E2E2EA;
}

@media only screen and (max-width: 767px) {
  .activity__item {
    padding: 20px;
    background: #ffffff;
  }
}

.activity__item:before {
  content: "";
  position: absolute;
  top: 31px;
  width: 41px;
  height: 1px;
  background: #E2E2EA;
}

@media only screen and (max-width: 1439px) {
  .activity__item:before {
    width: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .activity__item:before {
    display: none;
  }
}

.activity__item:not(:last-child) {
  margin-bottom: 40px;
}

@media only screen and (max-width: 1439px) {
  .activity__item:not(:last-child) {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .activity__item:not(:last-child) {
    margin-bottom: 15px;
  }
}

.activity__top {
  display: flex;
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .activity__top {
    margin-bottom: 20px;
  }
}

.activity__task {
  color: #92929D;
}

.activity__section:nth-child(2n+1) .activity__task {
  margin-right: auto;
}

.activity__section:nth-child(2n+1) .activity__time {
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .activity__section:nth-child(2n) .activity__top {
    flex-direction: row-reverse;
  }
}

.activity__section:nth-child(2n) .activity__time {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .activity__section:nth-child(2n) .activity__time {
    margin: 0 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .activity__section:nth-child(2n) .activity__task {
    margin-right: auto;
  }
}

.activity__section:nth-child(2n) .activity__line {
  padding-left: 60px;
}

@media only screen and (max-width: 1439px) {
  .activity__section:nth-child(2n) .activity__line {
    padding-left: 0;
  }
}

.activity__time {
  padding-top: 2px;
  flex-shrink: 0;
  font-size: 12px;
  color: #44444F;
}

.activity__line {
  display: flex;
}

.activity .ava {
  width: 42px;
  height: 42px;
  margin-right: 10px;
  font-size: 16px;
}

.activity__details {
  flex: 0 0 calc(100% - 52px);
  width: calc(100% - 52px);
}

.activity__info {
  margin-top: -5px;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 500;
  color: #44444F;
}

.activity__info strong {
  color: #0062FF;
}

.activity__link {
  border-bottom: 1px solid transparent;
  color: #0062FF;
  transition: border-color .25s;
}

.activity__link:hover {
  border-color: #0062FF;
}

.activity__variants {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 14px;
}

.activity .checkbox:not(:last-child) {
  margin-bottom: 12px;
}

.activity .checkbox__info {
  margin-top: -1px;
}

.activity__details .activity__text {
  margin-top: 5px;
  line-height: 1.5;
  font-style: italic;
  color: #44444F;
}

.activity__gallery {
  display: flex;
  align-items: center;
  margin-top: 10px;
  overflow: auto;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.activity__gallery::-webkit-scrollbar {
  display: none;
}

.activity__preview {
  font-size: 0;
}

.activity__preview:not(:last-child) {
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .activity__preview:not(:last-child) {
    margin-right: 10px;
  }
}

.activity__pic {
  border-radius: 5px;
}

body.dark .activity__head {
  color: #FAFAFB;
}

body.dark .activity__head .icon {
  fill: #B5B5BE;
}

body.dark .activity__tag {
  color: #92929D;
}

body.dark .activity__tag:hover {
  background: #1C1C24;
  border-color: #1C1C24;
}

body.dark .activity__tag.active {
  background: #1C1C24;
  border-color: #1C1C24;
  color: #FAFAFB;
}

body.dark .activity__container {
  background: #1C1C24;
}

body.dark .activity__wrap:before {
  background: #44444F;
}

body.dark .activity__date {
  background: #1C1C24;
  color: #B5B5BE;
}

body.dark .activity__item {
  border-color: #292932;
  background: #292932;
}

body.dark .activity__item:before {
  background: #44444F;
}

body.dark .activity__time {
  color: #B5B5BE;
}

body.dark .activity__info {
  color: #FAFAFB;
}

body.dark .activity__link,
body.dark .activity__info strong {
  color: #1E75FF;
}

body.dark .activity__link:hover {
  border-color: #1E75FF;
}

body.dark .activity__details .activity__text {
  color: #E2E2EA;
}

.schedule__container {
  display: flex;
  background: #ffffff;
  border-radius: 20px;
}

@media only screen and (max-width: 767px) {
  .schedule__container {
    display: block;
    margin: 0 -20px;
    padding: 20px 20px 30px;
    border-radius: 0;
  }
}

.schedule__calendar {
  flex-shrink: 0;
  width: 620px;
  padding: 40px 30px 30px;
}

@media only screen and (max-width: 1599px) {
  .schedule__calendar {
    width: 550px;
  }
}

@media only screen and (max-width: 1439px) {
  .schedule__calendar {
    width: 500px;
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 1365px) {
  .schedule__calendar {
    width: 400px;
  }
}

@media only screen and (max-width: 1199px) {
  .schedule__calendar {
    width: 350px;
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .schedule__calendar {
    width: 100%;
    margin-bottom: 30px;
    padding: 0;
  }
}

.schedule__list {
  flex: 0 0 calc(100% - 620px);
  width: calc(100% - 620px);
  padding: 50px 40px 50px 30px;
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: #E2E2EA;
}

@media only screen and (max-width: 1599px) {
  .schedule__list {
    flex: 0 0 calc(100% - 550px);
    width: calc(100% - 550px);
  }
}

@media only screen and (max-width: 1439px) {
  .schedule__list {
    flex: 0 0 calc(100% - 500px);
    width: calc(100% - 500px);
    padding: 30px 20px 30px 30px;
  }
}

@media only screen and (max-width: 1365px) {
  .schedule__list {
    flex: 0 0 calc(100% - 400px);
    width: calc(100% - 400px);
  }
}

@media only screen and (max-width: 1199px) {
  .schedule__list {
    flex: 0 0 calc(100% - 350px);
    width: calc(100% - 350px);
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .schedule__list {
    width: 100%;
    padding: 0;
    border-width: 1px 0 0;
  }
}

.schedule__item {
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid #E2E2EA;
}

@media only screen and (max-width: 1199px) {
  .schedule__item {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .schedule__item {
    position: relative;
    border: none;
  }
  .schedule__item:before {
    content: "";
    position: absolute;
    left: 32px;
    right: 0;
    bottom: 0;
    height: 1px;
    background: #E2E2EA;
  }
}

.schedule__item:first-child {
  padding-top: 0;
}

@media only screen and (max-width: 767px) {
  .schedule__item:first-child {
    padding-top: 20px;
  }
}

.schedule__date {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 160px;
  padding-right: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #44444F;
}

.schedule__date .icon {
  margin-right: 12px;
  font-size: 18px;
  color: #92929D;
}

.schedule__details {
  flex: 0 0 calc(100% - 160px);
  max-width: calc(100% - 160px);
}

@media only screen and (max-width: 1199px) {
  .schedule__details {
    max-width: 100%;
    margin-top: 10px;
    padding-left: 31px;
  }
}

.schedule .checkbox {
  display: block;
}

.schedule .checkbox:not(:last-child) {
  margin-bottom: 12px;
}

.schedule .checkbox__tick {
  margin-right: 9px;
}

.schedule .checkbox__details {
  flex: 0 0 calc(100% - 29px);
  max-width: calc(100% - 29px);
}

.schedule__foot {
  margin-top: 45px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .schedule__foot {
    margin-top: 25px;
  }
}

.schedule__more {
  display: inline-block;
  border-bottom: 1px solid transparent;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  letter-spacing: .2px;
  line-height: 1;
  color: #0062FF;
  transition: border-color .25s;
}

.schedule__more:hover {
  border-color: #0062FF;
}

body.dark .schedule__container {
  background: #1C1C24;
}

body.dark .schedule__list {
  border-color: #44444F;
}

body.dark .schedule__item {
  border-color: #44444F;
}

body.dark .schedule__item:before {
  background: #44444F;
}

body.dark .schedule__date {
  color: #696974;
}

body.dark .schedule__more {
  color: #1E75FF;
}

body.dark .schedule__more:hover {
  border-color: #1E75FF;
}

@media only screen and (max-width: 767px) {
  .datepicker__month:nth-child(n+2) {
    display: none;
  }
}

.datepicker__month:not(:first-child) {
  margin-top: 40px;
}

.datepicker__head {
  position: relative;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .datepicker__head {
    margin-bottom: 20px;
  }
}

.datepicker__head:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: 1px;
  background: #E2E2EA;
}

.datepicker__title {
  position: relative;
  z-index: 2;
  display: inline-block;
  padding-right: 20px;
  background: #ffffff;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .datepicker__title {
    padding-right: 15px;
  }
}

.datepicker__year {
  font-weight: 700;
}

.datepicker__control {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .datepicker__control {
    padding-left: 15px;
  }
}

.datepicker__arrow {
  width: 32px;
  height: 32px;
  border: 1px solid #E2E2EA;
  border-radius: 8px;
  font-size: 0;
}

.datepicker__arrow .icon {
  font-size: 7px;
  fill: #92929D;
  transition: fill .25s;
}

@media only screen and (max-width: 767px) {
  .datepicker__arrow .icon {
    transform: rotate(-90deg);
  }
}

.datepicker__arrow:hover .icon {
  fill: #B5B5BE;
}

.datepicker__arrow:not(:last-child) {
  margin-right: 10px;
}

.datepicker table {
  table-layout: fixed;
}

.datepicker th {
  height: 30px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  letter-spacing: .86px;
  font-weight: 600;
  text-transform: uppercase;
  color: #92929D;
}

.datepicker th,
.datepicker td {
  vertical-align: middle;
  text-align: center;
}

.datepicker td {
  padding: 2px 0;
}

@media only screen and (max-width: 767px) {
  .datepicker td {
    padding: 0;
  }
}

.datepicker__link {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 48px;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #171725;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .datepicker__link {
    height: 44px;
  }
}

.datepicker__link:hover {
  background: #50B5FF;
  color: #ffffff;
}

.datepicker__link.active {
  background: #0062FF;
  font-weight: 700;
  color: #ffffff;
}

.datepicker__link.one:before, .datepicker__link.one:after,
.datepicker__link.one .datepicker__dot, .datepicker__link.two:before, .datepicker__link.two:after,
.datepicker__link.two .datepicker__dot, .datepicker__link.three:before, .datepicker__link.three:after,
.datepicker__link.three .datepicker__dot {
  position: absolute;
  bottom: 9px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #0062FF;
  transition: background .25s;
}

@media only screen and (max-width: 767px) {
  .datepicker__link.one:before, .datepicker__link.one:after,
  .datepicker__link.one .datepicker__dot, .datepicker__link.two:before, .datepicker__link.two:after,
  .datepicker__link.two .datepicker__dot, .datepicker__link.three:before, .datepicker__link.three:after,
  .datepicker__link.three .datepicker__dot {
    bottom: 7px;
  }
}

.datepicker__link.one:before {
  content: "";
  left: 50%;
  transform: translateX(-50%);
}

.datepicker__link.two:before, .datepicker__link.two:after {
  content: "";
}

.datepicker__link.two:before {
  right: calc(50% + 1px);
}

.datepicker__link.two:after {
  left: calc(50% + 1px);
}

.datepicker__link.three:before, .datepicker__link.three:after {
  content: "";
}

.datepicker__link.three:before {
  right: calc(50% + 4px);
}

.datepicker__link.three:after {
  left: calc(50% + 4px);
}

.datepicker__link.three .datepicker__dot {
  left: 50%;
  transform: translateX(-50%);
}

.datepicker__link:hover:before, .datepicker__link:hover:after,
.datepicker__link:hover .datepicker__dot, .datepicker__link.active:before, .datepicker__link.active:after,
.datepicker__link.active .datepicker__dot {
  background: #ffffff;
}

.datepicker__link.blue {
  font-weight: 700;
  color: #0062FF;
}

.datepicker__link.blue:hover, .datepicker__link.blue.active {
  color: #ffffff;
}

body.dark .datepicker__head:before {
  background: #44444F;
}

body.dark .datepicker__title {
  color: #FAFAFB;
}

body.dark .datepicker__title, body.dark .datepicker__control {
  background: #1C1C24;
}

body.dark .datepicker__arrow {
  border-color: #44444F;
}

body.dark .datepicker__arrow .icon {
  fill: #B5B5BE;
}

body.dark .datepicker__link {
  color: #92929D;
}

body.dark .datepicker__link:hover {
  background: #44444F;
}

body.dark .datepicker__link.active {
  background: #1E75FF;
  color: #FAFAFB;
}

body.dark .datepicker__link.one:before, body.dark .datepicker__link.one:after,
body.dark .datepicker__link.one .datepicker__dot, body.dark .datepicker__link.two:before, body.dark .datepicker__link.two:after,
body.dark .datepicker__link.two .datepicker__dot, body.dark .datepicker__link.three:before, body.dark .datepicker__link.three:after,
body.dark .datepicker__link.three .datepicker__dot {
  background: #1E75FF;
}

body.dark .datepicker__link:hover:before, body.dark .datepicker__link:hover:after,
body.dark .datepicker__link:hover .datepicker__dot, body.dark .datepicker__link.active:before, body.dark .datepicker__link.active:after,
body.dark .datepicker__link.active .datepicker__dot {
  background: #ffffff;
}

.messages__write {
  display: none;
  justify-content: center;
  align-items: center;
  height: 48px;
  margin: 10px 15px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(196, 203, 216, 0.25);
  white-space: nowrap;
  font-weight: 600;
  color: #44444F;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .messages__write {
    display: flex;
  }
}

.messages__write .icon {
  margin-right: 10px;
  font-size: 24px;
  fill: #92929D;
}

.messages__head {
  display: flex;
  align-items: center;
  height: 78px;
  padding: 10px 20px;
  border-bottom: 1px solid #F1F1F5;
}

@media only screen and (max-width: 767px) {
  .messages__head {
    height: 68px;
    padding: 5px 15px;
  }
}

.messages__select {
  min-width: 104px;
  height: 38px;
  padding: 0 40px 0 11px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #E2E2EA;
  border-radius: 10px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat calc(100% - 16px) 50%/11px 7px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  letter-spacing: .1px;
  color: #44444F;
  transition: all .25s;
}

@media only screen and (max-width: 1023px) {
  .messages__select {
    min-width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .messages__select {
    flex-grow: 1;
    min-width: auto;
    margin-right: 20px;
  }
}

.messages__select::-ms-expand {
  display: none;
}

.messages__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.messages__variants {
  display: none;
  flex-grow: 1;
  margin-right: 20px;
  border: 1px solid #E2E2EA;
  border-radius: 10px;
  overflow: hidden;
}

.messages__variants .messages__link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  line-height: 36px;
  color: #171725;
  transition: opacity .25s;
}

.messages__variants .messages__link:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 50%;
}

.messages__variants .messages__link.purple:before {
  background: #A461D8;
}

.messages__variants .messages__link.purple.active {
  background: rgba(164, 97, 216, 0.1);
}

.messages__variants .messages__link.green:before {
  background: #82C43C;
}

.messages__variants .messages__link.green.active {
  background: rgba(130, 196, 60, 0.1);
}

.messages__variants .messages__link.blue:before {
  background: #0062FF;
}

.messages__variants .messages__link.blue.active {
  background: rgba(0, 98, 255, 0.1);
}

.messages__variants .messages__link.yellow:before {
  background: #FFC542;
}

.messages__variants .messages__link.yellow.active {
  background: rgba(255, 197, 66, 0.1);
}

.messages__variants .messages__link:hover {
  opacity: .8;
}

.messages__variants .messages__link.active {
  opacity: 1;
}

.messages__refresh {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-left: auto;
  font-size: 0;
}

.messages__refresh .icon {
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.messages__refresh:hover .icon {
  fill: #B5B5BE;
}

.messages__wrap {
  padding: 5px 10px 15px;
  height: calc(100vh - 148px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: padding .25s;
}

@media only screen and (max-width: 767px) {
  .messages__wrap {
    height: calc(100vh - 206px);
  }
}

.messages__box {
  position: relative;
  padding: 5px 0;
}

.messages__box:not(:first-child) {
  border-top: 1px solid #F1F1F5;
}

.messages__item {
  position: relative;
  display: flex;
  padding: 15px;
  border-radius: 15px;
  transition: background .25s;
}

.messages__item:hover {
  background: rgba(0, 98, 255, 0.05);
}

.messages__item.active {
  background: #1E75FF;
}

.messages__item.active .messages__user,
.messages__item.active .messages__time,
.messages__item.active .messages__place {
  color: #ffffff;
}

.messages__item.active .messages__add .icon {
  fill: #ffffff;
}

.messages__item.active .messages__text {
  color: rgba(255, 255, 255, 0.7);
}

.messages .ava {
  width: 42px;
  height: 42px;
  margin-right: 10px;
  font-size: 16px;
}

.messages .ava__pic {
  border: 1px solid #F1F1F5;
}

.messages__details {
  flex-grow: 1;
}

.messages__line {
  display: flex;
  margin-bottom: 5px;
}

.messages__user {
  margin-right: auto;
  color: #0062FF;
  transition: color .25s;
}

.messages__time {
  margin-left: 20px;
  padding-top: 1px;
  font-size: 12px;
  color: #696974;
  transition: color .25s;
}

.messages__add {
  position: absolute;
  top: 67px;
  left: 26px;
  font-size: 0;
}

.messages__add .icon {
  font-size: 20px;
  fill: #92929D;
}

.messages__place {
  margin-bottom: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  color: #171725;
  transition: color .25s;
}

.messages__text {
  max-height: 48px;
  overflow: hidden;
  line-height: 1.71429;
  color: #92929D;
}

body.dark .messages {
  background: #1C1C24;
}

body.dark .messages__write {
  background: #292932;
  box-shadow: 0px 4px 25px rgba(35, 36, 36, 0.25);
}

body.dark .messages__write .messages__text {
  color: #FAFAFB;
}

body.dark .messages__head {
  border-color: #292932;
}

body.dark .messages__select {
  border-color: #4F4F5A;
  border-radius: 10px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%23b5b5be'/%3E%3C/svg%3E") no-repeat calc(100% - 16px) 50%/11px 7px;
  color: #B5B5BE;
}

body.dark .messages__refresh .icon {
  fill: #B5B5BE;
}

body.dark .messages__refresh:hover .icon {
  fill: #ffffff;
}

body.dark .messages__box {
  border-color: #292932;
}

body.dark .messages__item:hover {
  background: #292932;
}

body.dark .messages__place {
  color: #FAFAFB;
}

body.dark .messages__variants {
  border: none;
  box-shadow: inset 0 0 0 1px #44444F;
}

body.dark .messages__variants .messages__link {
  color: #B5B5BE;
}

body.dark .messages__variants .messages__link.purple.active, body.dark .messages__variants .messages__link.green.active, body.dark .messages__variants .messages__link.blue.active, body.dark .messages__variants .messages__link.yellow.active {
  color: #FAFAFB;
}

body.dark .messages__variants .messages__link.purple.active:before, body.dark .messages__variants .messages__link.green.active:before, body.dark .messages__variants .messages__link.blue.active:before, body.dark .messages__variants .messages__link.yellow.active:before {
  background: #F9F9FA;
}

body.dark .messages__variants .messages__link.purple.active {
  background: #A461D8;
}

body.dark .messages__variants .messages__link.green.active {
  background: #82C43C;
}

body.dark .messages__variants .messages__link.blue.active {
  background: #0062FF;
}

body.dark .messages__variants .messages__link.yellow.active {
  background: #FFC542;
}

.message {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

@media only screen and (max-width: 1023px) {
  .message {
    position: relative;
    padding: 80px 0;
  }
}

@media only screen and (max-width: 767px) {
  .message {
    padding: 70px 0;
  }
}

.message__inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-right: 1px solid #F1F1F5;
}

@media only screen and (max-width: 1023px) {
  .message__inner {
    border: none;
  }
}

.message__head {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 78px;
  padding: 5px 20px;
  border-bottom: 1px solid #F1F1F5;
}

@media only screen and (max-width: 1023px) {
  .message__head {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .message__head {
    height: 70px;
  }
}

.message__stage {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
}

.message__head .message__time {
  font-size: 16px;
  color: #44444F;
}

@media only screen and (max-width: 767px) {
  .message__head .message__time {
    display: none;
  }
}

.message__head .message__close {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .message__head .message__close {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 30px;
    font-size: 0;
  }
  .message__head .message__close .icon {
    font-size: 16px;
    fill: #92929D;
  }
}

@media only screen and (max-width: 767px) {
  .message__head .message__close {
    margin-right: auto;
  }
}

.message__control {
  display: flex;
  margin-left: auto;
  padding-left: 30px;
}

@media only screen and (max-width: 767px) {
  .message__control {
    padding-left: 15px;
  }
}

.message__control .message__btn {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.message__control .message__btn .icon {
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.message__control .message__btn .icon-close {
  font-size: 14px;
}

.message__control .message__btn:hover .icon {
  fill: #B5B5BE;
}

.message__control .message__btn:not(:last-child) {
  margin-right: 15px;
}

.message__wrapper {
  flex-grow: 1;
}

@media only screen and (max-width: 1023px) {
  .message__wrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    height: calc(100vh - 160px);
  }
}

@media only screen and (max-width: 767px) {
  .message__wrapper {
    height: calc(100vh - 140px);
  }
}

.message__body {
  padding: 20px 20px 100px;
  flex-grow: 1;
}

@media only screen and (max-width: 1599px) {
  .message__body {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .message__body {
    padding-bottom: 50px;
  }
}

.message__user {
  display: flex;
  align-items: center;
}

.message__user .ava {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  font-size: 18px;
}

.message__user .message__icon {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .message__user .message__icon {
    margin-right: 10px;
  }
}

.message__user .message__icon .icon {
  font-size: 20px;
  fill: #92929D;
}

.message__body .message__user {
  margin-bottom: 35px;
}

@media only screen and (max-width: 1199px) {
  .message__body .message__user {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .message__body .message__user {
    margin-bottom: 20px;
  }
}

.message__man {
  margin-bottom: 2px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  font-size: 16px;
  color: #171725;
}

.message__man span {
  display: none;
}

@media only screen and (max-width: 767px) {
  .message__man span {
    display: inline;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 700;
    color: #92929D;
  }
}

.message__user .message__mail {
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #B5B5BE;
}

.message__group {
  margin-bottom: 25px;
}

.message__box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E2E2EA;
}

.message__box:first-child {
  padding-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .message__box:first-child {
    flex-wrap: wrap;
  }
  .message__box:first-child .message__text {
    margin-right: auto;
  }
}

.message__box:nth-child(2) {
  padding: 23px 0 15px;
}

@media only screen and (max-width: 767px) {
  .message__box:nth-child(2) {
    padding: 15px 0;
  }
}

.message__box .message__text {
  flex: 0 0 74px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 700;
  color: #92929D;
}

.message__mails {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
  margin-top: -5px;
}

@media only screen and (max-width: 767px) {
  .message__mails {
    order: 3;
    flex-wrap: nowrap;
    max-width: 100vw;
    margin: 10px -20px 0;
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  .message__mails::-webkit-scrollbar {
    display: none;
  }
}

.message__mails .message__mail {
  position: relative;
  margin: 5px 10px 5px 0;
  padding: 0 30px 0 12px;
  border-radius: 16px;
  border: 1px solid #E2E2EA;
  line-height: 30px;
  color: #44444F;
}

@media only screen and (max-width: 767px) {
  .message__mails .message__mail:first-child {
    margin-left: 20px;
  }
}

.message__remove {
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
  font-size: 0;
}

.message__remove .icon {
  font-size: 10px;
  fill: #92929D;
  transition: fill .25s;
}

.message__remove:hover .icon {
  fill: #B5B5BE;
}

.message__notes {
  display: flex;
  flex-shrink: 0;
  margin-left: 30px;
}

.message__note {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  line-height: 1.5;
  font-weight: 700;
  color: #696974;
}

.message__note:not(:last-child) {
  margin-right: 20px;
}

.message__start {
  font-weight: 500;
  color: #44444F;
}

.message__content {
  font-size: 16px;
  line-height: 1.625;
}

.message__content h3 {
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.41667;
  color: #171725;
}

@media only screen and (max-width: 1199px) {
  .message__content h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .message__content h3 {
    font-size: 18px;
  }
}

.message__content p {
  color: #44444F;
}

.message__content p:not(:last-child) {
  margin-bottom: 25px;
}

@media only screen and (max-width: 1199px) {
  .message__content p:not(:last-child) {
    margin-bottom: 15px;
  }
}

.message__content:not(:last-child) {
  margin-bottom: 50px;
}

@media only screen and (max-width: 1599px) {
  .message__content:not(:last-child) {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .message__content:not(:last-child) {
    margin-bottom: 25px;
  }
}

.message__top {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-width: 1px 0;
  border-style: solid;
  border-color: #F1F1F5;
}

.message__answer .message__user {
  margin-right: auto;
}

.message__answer .message__content {
  padding: 20px 20px 45px;
  font-size: 14px;
  line-height: 1.71429;
}

@media only screen and (max-width: 767px) {
  .message__answer .message__content {
    padding: 20px 20px 30px;
  }
}

.message__head .options, .message__answer .options {
  margin-top: -2px;
  margin-left: -3px;
}

.message__head .options.active .options__btn, .message__answer .options.active .options__btn {
  background: #F1F1F5;
  border-radius: 50%;
  border: 1px solid #E2E2EA;
}

.message__head .options__btn, .message__answer .options__btn {
  width: 28px;
  height: 28px;
}

.message__head .options__dropdown {
  right: -5px;
}

.message__answer .options__dropdown {
  top: auto;
  right: -5px;
  bottom: calc(100% + 5px);
}

.message__foot {
  display: flex;
  align-items: center;
  padding: 20px;
  border-top: 1px solid #F1F1F5;
}

@media only screen and (max-width: 1023px) {
  .message__foot {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
  }
}

@media only screen and (max-width: 767px) {
  .message__foot {
    padding: 15px 20px 16px;
  }
}

.message__actions {
  display: flex;
  align-items: center;
  margin-left: 28px;
}

@media only screen and (max-width: 1199px) {
  .message__actions {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .message__actions {
    margin-left: 15px;
  }
}

.message__action {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  flex-shrink: 0;
  font-size: 0;
  transition: background .25s;
}

@media only screen and (max-width: 1199px) {
  .message__action {
    width: 24px;
    height: 24px;
  }
}

@media only screen and (max-width: 1023px) {
  .message__action {
    width: 28px;
    height: 28px;
  }
}

.message__action .icon {
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

@media only screen and (max-width: 1199px) {
  .message__action .icon {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .message__action .icon {
    font-size: 24px;
  }
}

.message__action:hover {
  background: #F1F1F5;
}

.message__action:hover .icon {
  fill: #292932;
}

.message__action:not(:first-child) {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .message__action:not(:first-child) {
    margin-left: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .message__action:nth-child(n+4) {
    display: none;
  }
}

.message__list {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.message__saved {
  color: #44444F;
  transition: opacity .25s;
  display: none;
}

.message__saved:hover {
  opacity: .8;
}

.message__saved:not(:last-child) {
  margin-right: 20px;
}

.message__foot .message__option {
  margin-left: -5px;
}

body.dark .message__head {
  border-color: #292932;
}

body.dark .message__head .options.active .options__btn, body.dark .message__answer .options.active .options__btn {
  background: #292932;
  border-color: #696974;
}

body.dark .message__inner {
  border-color: #292932;
}

body.dark .message__stage {
  color: #FAFAFB;
}

body.dark .message__time {
  color: #92929D;
}

body.dark .message__box {
  border-color: #44444F;
}

body.dark .message__mails .message__mail {
  border-color: #44444F;
  color: #FAFAFB;
}

body.dark .message__note {
  color: #B5B5BE;
}

body.dark .message__note:hover {
  color: #ffffff;
}

body.dark .message__start {
  color: #FAFAFB;
}

body.dark .message__man {
  color: #FAFAFB;
}

body.dark .message__content p {
  color: #B5B5BE;
}

body.dark .message__content h3 {
  color: #FAFAFB;
}

body.dark .message__top {
  border-color: #292932;
}

body.dark .message__foot {
  border-color: #292932;
}

body.dark .message__action:hover {
  background: none;
}

body.dark .message__action:hover .icon {
  fill: #FAFAFB;
}

body.dark .message__saved {
  color: #B5B5BE;
}

@media only screen and (max-width: 1023px) {
  body.dark .message__foot {
    background: #1C1C24;
  }
}

.files__list {
  display: flex;
  flex-wrap: wrap;
  margin: -15px 0 0 -20px;
}

@media only screen and (max-width: 1199px) {
  .files__list {
    margin: -10px 0 0 -15px;
  }
}

@media only screen and (max-width: 767px) {
  .files__list {
    margin: -6px -3px 0;
  }
}

.files__item {
  display: flex;
  align-items: center;
  flex: 0 0 190px;
  max-width: 190px;
  border-radius: 15px;
  transition: opacity .25s;
}

.files__item_doc {
  background: rgba(196, 219, 255, 0.15);
}

.files__item_doc .files__icon {
  background: rgba(80, 181, 255, 0.1);
}

.files__item_doc .files__icon .icon {
  fill: #50B5FF;
}

.files__item_video {
  background: rgba(254, 242, 213, 0.15);
}

.files__item_video .files__icon {
  background: rgba(255, 197, 66, 0.1);
}

.files__item_video .files__icon .icon {
  fill: #FFC542;
}

.files__item_audio {
  background: rgba(226, 241, 210, 0.15);
}

.files__item_audio .files__icon {
  background: rgba(130, 196, 60, 0.1);
}

.files__item_audio .files__icon .icon {
  fill: #82C43C;
}

.files__item:hover {
  opacity: .75;
}

.files__list .files__item {
  margin: 15px 0 0 20px;
}

@media only screen and (max-width: 1199px) {
  .files__list .files__item {
    flex: 0 0 180px;
    max-width: 180px;
    margin: 10px 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .files__list .files__item {
    flex: 0 0 calc(50% - 6px);
    max-width: calc(50% - 6px);
    margin: 6px 3px 0;
  }
}

@media only screen and (max-width: 767px) {
  .files > .files__item {
    max-width: 100%;
  }
}

.files > .files__item:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1199px) {
  .files > .files__item:not(:last-child) {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .files > .files__item:not(:last-child) {
    margin-bottom: 10px;
  }
}

.files__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  border-radius: 15px;
  font-size: 0;
}

.files__icon .icon {
  font-size: 24px;
}

.files__details {
  flex: 0 0 calc(100% - 56px);
  width: calc(100% - 56px);
  padding: 0 10px;
}

.files__name {
  max-width: 102px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  line-height: 1.5;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .files__name {
    max-width: 100%;
  }
}

.files__size {
  font-size: 12px;
  line-height: 1.83333;
  color: #92929D;
}

body.dark .files__item_doc {
  background: rgba(196, 219, 255, 0.1);
}

body.dark .files__item_doc .files__icon {
  background: #50B5FF;
}

body.dark .files__item_doc .files__icon .icon {
  fill: #FAFAFB;
}

body.dark .files__item_video {
  background: rgba(254, 242, 213, 0.2);
}

body.dark .files__item_video .files__icon {
  background: #FFC542;
}

body.dark .files__item_video .files__icon .icon {
  fill: #FAFAFB;
}

body.dark .files__item_audio {
  background: rgba(226, 241, 210, 0.2);
}

body.dark .files__item_audio .files__icon {
  background: #82C43C;
}

body.dark .files__item_audio .files__icon .icon {
  fill: #FAFAFB;
}

body.dark .files__name {
  color: #FAFAFB;
}

.widget {
  flex-shrink: 0;
  width: 300px;
  margin-right: -301px;
  transition: margin .25s;
}

@media only screen and (max-width: 1199px) {
  .widget {
    width: 270px;
    margin-right: -271px;
  }
}

@media only screen and (max-width: 1023px) {
  .widget {
    margin-right: -270px;
    border-left: 1px solid #F1F1F5;
  }
}

@media only screen and (max-width: 767px) {
  .widget {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transform: translateX(100%);
    width: 100%;
    margin-right: 0;
    background: #ffffff;
    border: none;
    transition: transform .25s;
  }
}

.widget__head {
  display: flex;
  align-items: center;
  height: 78px;
  padding: 5px 20px;
  border-bottom: 1px solid #F1F1F5;
}

@media only screen and (max-width: 767px) {
  .widget__head {
    height: 70px;
  }
}

.widget__close {
  display: none;
  width: 24px;
  height: 24px;
  margin-right: 15px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .widget__close {
    display: inline-block;
  }
}

.widget__close .icon {
  font-size: 16px;
}

.widget__add {
  margin-right: auto;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: .8px;
  text-transform: uppercase;
  color: #0062FF;
  transition: color .25s;
}

.widget__add:hover {
  color: #50B5FF;
}

.widget__sorting {
  display: flex;
  align-items: center;
}

.widget__sorting .widget__link {
  font-size: 0;
  transition: opacity .25s;
}

.widget__sorting .widget__link .icon {
  font-size: 24px;
}

.widget__sorting .widget__link:hover {
  opacity: .75;
}

.widget__sorting .widget__link:not(:last-child) {
  margin-right: 15px;
}

.widget__body {
  padding: 25px 20px;
}

@media only screen and (max-width: 1199px) {
  .widget__body {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .widget__body {
    height: calc(100% - 70px);
    overflow: auto;
  }
}

.widget__item:not(:last-child) {
  margin-bottom: 30px;
}

.widget__category {
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33333;
}

@media only screen and (max-width: 1199px) {
  .widget__category {
    margin-bottom: 10px;
  }
}

.widget__gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -5px 0;
}

.widget__preview {
  flex: 0 0 calc(50% - 10px);
  width: calc(50% - 10px);
  margin: 10px 5px 0;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .widget__preview {
    flex: 0 0 calc(33.333% - 10px);
    width: calc(33.333% - 10px);
  }
}

.widget__pic {
  width: 100%;
  border-radius: 15px;
}

.widget.visible {
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  .widget.visible {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 1023px) {
  body.dark .widget {
    border-color: #292932;
  }
}

@media only screen and (max-width: 767px) {
  body.dark .widget {
    background: #1C1C24;
  }
}

body.dark .widget__head {
  border-color: #292932;
}

body.dark .widget__add {
  color: #1E75FF;
}

body.dark .widget__add:hover {
  color: #50B5FF;
}

body.dark .widget__category {
  color: #FAFAFB;
}

.chat {
  background: #ffffff;
  border-radius: 10px;
}

.chat__head {
  position: relative;
  padding: 12px 35px 12px 10px;
  box-shadow: inset 0px -1px 0px #F1F1F5;
}

.chat__head .ava {
  width: 26px;
  height: 26px;
  margin-right: 10px;
  font-size: 10px;
}

.chat__user {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
}

.chat__man {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat__close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 0;
}

.chat__close .icon {
  font-size: 16px;
  fill: #92929D;
  transition: fill .25s;
}

.chat__close:hover .icon {
  fill: #B5B5BE;
}

.chat__body {
  display: flex;
  flex-direction: column;
  max-height: 355px;
  padding: 15px 10px 15px 24px;
  overflow: auto;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.chat__body::-webkit-scrollbar {
  display: none;
}

.chat__item {
  position: relative;
  margin-left: -14px;
  padding-left: 14px;
}

.chat__item .ava {
  position: absolute;
  top: 7px;
  left: 0;
  width: 24px;
  height: 24px;
  box-shadow: 0 0 0 2px #ffffff;
  font-size: 9px;
}

.chat__text {
  display: inline-block;
  padding: 9px 10px 9px 20px;
  background: #F1F1F5;
  color: #44444F;
}

.chat__answer {
  margin-left: auto;
  padding: 9px 10px;
  background: #0062FF;
  color: #ffffff;
}

.chat__text, .chat__answer {
  border-radius: 10px;
  font-size: 12px;
  line-height: 1.5;
}

.chat__item:not(:last-child), .chat__answer:not(:last-child) {
  margin-bottom: 10px;
}

.chat__file {
  display: flex;
  max-width: 126px;
  padding: 8px 10px;
  border-radius: 10px;
  background: #F1F1F5;
}

.chat__file:not(:first-child) {
  margin-top: 5px;
}

.chat__file .chat__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  border-radius: 8px;
  background: rgba(0, 98, 255, 0.23);
}

.chat__details {
  flex: 0 0 calc(100% - 29px);
  width: calc(100% - 29px);
}

.chat__file .chat__info {
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  color: #44444F;
}

.chat__size {
  font-size: 10px;
  color: #92929D;
}

.chat__foot {
  position: relative;
  box-shadow: inset 0px 1px 0px #F1F1F5;
}

.chat__input {
  width: 100%;
  height: 36px;
  padding: 0 55px 0 10px;
  background: none;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #171725;
}

.chat__input::placeholder {
  color: #B5B5BE;
}

.chat__control {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
}

.chat__btn {
  font-size: 0;
  transition: opacity .25s;
}

.chat__btn .icon {
  font-size: 16px;
  fill: #92929D;
  transition: fill .25s;
}

.chat__btn:hover .icon {
  fill: #B5B5BE;
}

.chat__btn:not(:last-child) {
  margin-right: 10px;
}

body.dark .chat {
  background: #292932;
}

body.dark .chat__head {
  box-shadow: inset 0px -1px 0px #44444F;
}

body.dark .chat__man {
  color: #FAFAFB;
}

body.dark .chat__text {
  background: #44444F;
  color: #E2E2EA;
}

body.dark .chat__answer {
  background: #1E75FF;
}

body.dark .chat__file {
  background: #44444F;
}

body.dark .chat__file .chat__icon {
  background: rgba(181, 181, 190, 0.23);
}

body.dark .chat__file .chat__icon .icon {
  fill: #E2E2EA;
}

body.dark .chat__file .chat__info {
  color: #E2E2EA;
}

body.dark .chat__foot {
  box-shadow: inset 0px 1px 0px #44444F;
}

body.dark .chat__btn .icon {
  fill: #B5B5BE;
}

body.dark .chat__btn:hover .icon {
  fill: #ffffff;
}

.messenger {
  padding-left: 250px;
  transition: padding .25s;
}

@media only screen and (max-width: 1365px) {
  .messenger {
    padding-left: 0;
  }
}

.messenger__top {
  display: none;
}

@media only screen and (max-width: 767px) {
  .messenger__top {
    display: flex;
    align-items: center;
    padding: 10px 15px;
  }
}

.messenger__write {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 48px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(196, 203, 216, 0.25);
  white-space: nowrap;
  font-weight: 600;
  color: #44444F;
  transition: all .25s;
}

.messenger__write .icon {
  margin-right: 10px;
  font-size: 24px;
  fill: #92929D;
}

.messenger__top .messenger__btn {
  flex-shrink: 0;
  margin-left: 15px;
}

.messenger__head {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #F1F1F5;
}

@media only screen and (max-width: 767px) {
  .messenger__head {
    display: block;
    padding: 15px 20px;
    border: none;
  }
}

.messenger__variants {
  position: relative;
  margin-right: 30px;
  padding-right: 36px;
  border: 1px solid #E2E2EA;
  border-radius: 10px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .messenger__variants {
    margin: 0;
  }
}

.messenger__list {
  display: flex;
  overflow: auto;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.messenger__list::-webkit-scrollbar {
  display: none;
}

.messenger__variants .messenger__link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  line-height: 36px;
  color: #171725;
  transition: opacity .25s;
}

@media only screen and (max-width: 767px) {
  .messenger__variants .messenger__link {
    padding: 0 15px;
  }
}

.messenger__variants .messenger__link:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 50%;
}

.messenger__variants .messenger__link.purple:before {
  background: #A461D8;
}

.messenger__variants .messenger__link.purple.active {
  background: rgba(164, 97, 216, 0.1);
}

.messenger__variants .messenger__link.green:before {
  background: #82C43C;
}

.messenger__variants .messenger__link.green.active {
  background: rgba(130, 196, 60, 0.1);
}

.messenger__variants .messenger__link.blue:before {
  background: #0062FF;
}

.messenger__variants .messenger__link.blue.active {
  background: rgba(0, 98, 255, 0.1);
}

.messenger__variants .messenger__link.yellow:before {
  background: #FFC542;
}

.messenger__variants .messenger__link.yellow.active {
  background: rgba(255, 197, 66, 0.1);
}

.messenger__variants .messenger__link:hover {
  opacity: .8;
}

.messenger__variants .messenger__link.active {
  opacity: 1;
}

.messenger__add {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  border-radius: 10px;
  background: #F1F1F5;
  font-size: 0;
  transition: opacity .25s;
}

.messenger__add .icon {
  font-size: 12px;
  fill: #92929D;
}

.messenger__add:hover {
  opacity: .8;
}

.messenger__control {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
}

@media only screen and (max-width: 767px) {
  .messenger__control {
    display: none;
  }
}

.messenger__btn {
  font-size: 0;
  transition: opacity .25s;
}

.messenger__btn .icon {
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.messenger__btn:hover .icon {
  fill: #B5B5BE;
}

.messenger__btn:not(:last-child) {
  margin-right: 20px;
}

.messenger__result {
  font-size: 16px;
  color: #696974;
}

.messenger__body {
  padding: 15px 20px 30px;
}

@media only screen and (max-width: 767px) {
  .messenger__body {
    padding: 0 20px 20px;
    height: calc(100vh - 206px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.messenger__item {
  display: flex;
  align-items: center;
  height: 54px;
  padding: 5px 20px;
  border-radius: 15px;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .messenger__item {
    display: block;
    height: auto;
    position: relative;
    padding: 12px 15px;
  }
}

.messenger__item:hover {
  background: rgba(0, 98, 255, 0.05);
}

.messenger__item.unread {
  background: rgba(0, 98, 255, 0.05);
}

.messenger__item:not(:last-child) {
  margin-bottom: 5px;
}

.messenger .checkbox {
  flex-shrink: 0;
}

@media only screen and (max-width: 1023px) {
  .messenger .checkbox {
    display: none;
  }
}

.messenger .checkbox__tick {
  margin-right: 0;
}

.messenger__user {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 226px;
  width: 226px;
  padding: 0 20px 0 32px;
}

@media only screen and (max-width: 1023px) {
  .messenger__user {
    flex: 0 0 180px;
    width: 180px;
    padding: 0 15px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .messenger__user {
    width: 100%;
    align-items: flex-start;
    padding: 0;
  }
}

.messenger__user .ava {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  font-size: 11px;
}

@media only screen and (max-width: 767px) {
  .messenger__user .ava {
    width: 32px;
    height: 32px;
    font-size: 13px;
  }
}

.messenger__user .ava__pic {
  border: 1px solid #F1F1F5;
}

.messenger__user:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

@media only screen and (max-width: 1023px) {
  .messenger__user:before {
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .messenger__user:before {
    display: none;
  }
}

.messenger__user.gray:before {
  background: #92929D;
}

.messenger__user.green:before {
  background: #3DD598;
}

.messenger__user.orange:before {
  background: #FF974A;
}

.messenger__man {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .messenger__man {
    padding-right: 70px;
  }
}

.messenger__project {
  position: relative;
  flex: 0 0 210px;
  width: 210px;
  padding: 0 20px 0 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  color: #44444F;
}

@media only screen and (max-width: 1023px) {
  .messenger__project {
    flex: 0 0 190px;
    width: 190px;
    padding: 0 15px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .messenger__project {
    display: none;
  }
}

.messenger__project:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.0001 18.2241L6.90061 20.8865C6.16658 21.2697 5.31147 20.6458 5.4524 19.8298L6.42473 14.2001L2.30461 10.2119C1.70759 9.63404 2.03491 8.62234 2.8573 8.50367L8.55693 7.68123L11.1046 2.55495C11.4723 1.81502 12.5279 1.81502 12.8956 2.55495L15.4433 7.68123L21.1429 8.50367C21.9653 8.62234 22.2926 9.63404 21.6956 10.2119L17.5755 14.2001L18.5478 19.8298C18.6888 20.6458 17.8337 21.2697 17.0996 20.8865L12.0001 18.2241ZM11.5373 16.2096C11.8273 16.0582 12.173 16.0582 12.4629 16.2096L16.2329 18.1778L15.5147 14.0195C15.4584 13.6936 15.5669 13.3609 15.8046 13.1308L18.8405 10.1921L14.6385 9.5858C14.3133 9.53889 14.032 9.33528 13.8858 9.04111L12.0001 5.24693L10.1145 9.04111C9.96827 9.33528 9.68692 9.53889 9.36179 9.5858L5.15975 10.1921L8.19563 13.1308C8.4333 13.3609 8.54183 13.6936 8.48553 14.0195L7.76734 18.1778L11.5373 16.2096Z' fill='%2392929D'/%3E%3C/svg%3E%0A") no-repeat 0 0/100% auto;
}

.messenger__item .messenger__text {
  flex: 0 0 calc(100% - 552px);
  max-width: calc(100% - 552px);
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #92929D;
}

@media only screen and (max-width: 1023px) {
  .messenger__item .messenger__text {
    flex: 0 0 calc(100% - 466px);
    max-width: calc(100% - 466px);
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .messenger__item .messenger__text {
    max-width: 100%;
    height: 46px;
    margin-top: -10px;
    padding: 0 0 0 42px;
    white-space: normal;
    line-height: 1.66667;
  }
}

.messenger__file {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .messenger__file {
    position: absolute;
    top: 55px;
    left: 22px;
    width: 20px;
    height: 20px;
  }
}

.messenger__file .icon {
  font-size: 18px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .messenger__file .icon {
    font-size: 15px;
  }
}

.messenger__time {
  flex: 0 0 72px;
  width: 72px;
  margin-left: auto;
  text-align: right;
  font-size: 12px;
  color: #696974;
}

@media only screen and (max-width: 767px) {
  .messenger__time {
    display: inline-block;
    position: absolute;
    top: 14px;
    right: 15px;
    width: auto;
  }
}

body.dark .messenger__write {
  background: #292932;
  box-shadow: 0px 4px 25px rgba(35, 36, 36, 0.25);
}

body.dark .messenger__write .messenger__text {
  color: #FAFAFB;
}

body.dark .messenger__head {
  border-color: #292932;
}

body.dark .messenger__variants {
  border: none;
  box-shadow: inset 0 0 0 1px #44444F;
}

body.dark .messenger__variants .messenger__link {
  color: #B5B5BE;
}

body.dark .messenger__variants .messenger__link.purple.active, body.dark .messenger__variants .messenger__link.green.active, body.dark .messenger__variants .messenger__link.blue.active, body.dark .messenger__variants .messenger__link.yellow.active {
  color: #FAFAFB;
}

body.dark .messenger__variants .messenger__link.purple.active:before, body.dark .messenger__variants .messenger__link.green.active:before, body.dark .messenger__variants .messenger__link.blue.active:before, body.dark .messenger__variants .messenger__link.yellow.active:before {
  background: #F9F9FA;
}

body.dark .messenger__variants .messenger__link.purple.active {
  background: #A461D8;
}

body.dark .messenger__variants .messenger__link.green.active {
  background: #82C43C;
}

body.dark .messenger__variants .messenger__link.blue.active {
  background: #0062FF;
}

body.dark .messenger__variants .messenger__link.yellow.active {
  background: #FFC542;
}

body.dark .messenger__add {
  background: #44444F;
}

body.dark .messenger__add .icon {
  fill: #FAFAFB;
}

body.dark .messenger__btn .icon {
  fill: #B5B5BE;
}

body.dark .messenger__result {
  color: #B5B5BE;
}

body.dark .messenger__item:hover, body.dark .messenger__item.unread {
  background: #292932;
}

body.dark .messenger .checkbox__tick {
  border-color: #92929D;
}

body.dark .messenger__man {
  color: #FAFAFB;
}

body.dark .messenger__project {
  color: #ffffff;
}

body.dark .messenger__file .icon {
  fill: #B5B5BE;
}

body.dark .messenger__time {
  color: #92929D;
}

.post__item {
  border-radius: 15px;
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .post__item {
    border-radius: 0;
  }
}

.post__item_new {
  position: relative;
  z-index: 2;
  height: 107px;
}

.post__item_new.active {
  z-index: 20;
}

.post__item:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .post__item:not(:last-child) {
    margin-bottom: 5px;
  }
}

.post__top {
  position: relative;
  padding: 0 20px;
  border-bottom: 1px solid #F1F1F5;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  line-height: 40px;
  font-weight: 500;
}

.post__close {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  font-size: 0;
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
}

.post__close .icon {
  font-size: 14px;
  fill: #92929D;
  transition: fill .25s;
}

.post__close:hover .icon {
  fill: #B5B5BE;
}

.post__item.active .post__close {
  visibility: visible;
  opacity: 1;
}

.post__write {
  position: relative;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
  transition: border-radius .25s;
}

@media only screen and (max-width: 767px) {
  .post__write {
    border-radius: 0;
  }
}

.post__write .ava {
  position: absolute;
  top: 50%;
  left: 20px;
  z-index: 2;
  transform: translateY(-50%);
  width: 36px;
  height: 36px;
}

.post__item.active .post__write {
  border-radius: 0;
}

.post__field {
  position: relative;
}

.post__write .post__input {
  width: 100%;
  height: 66px;
  padding: 0 60px 0 70px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #171725;
}

.post__write .post__input::placeholder {
  color: #92929D;
}

.post__actions {
  display: flex;
  align-items: center;
}

.post__action {
  font-size: 0;
  transition: opacity .25s;
}

.post__action .icon {
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.post__action .icon-chart {
  font-size: 20px;
}

.post__action:hover .icon {
  fill: #B5B5BE;
}

.post__action:not(:first-child) {
  margin-left: 15px;
}

.post__field .post__actions, .post__progress {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.post__progress {
  display: none;
  right: 22px;
}

.post__item.active .post__progress {
  display: inline-block;
}

.post__item.active .post__field .post__action {
  display: none;
}

.post__options {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background: #ffffff;
  visibility: hidden;
  border-radius: 0 0 15px 15px;
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .post__options {
    border-radius: 0;
  }
}

.post__options:before {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  height: 1px;
  background: #F1F1F5;
}

.post__item.active .post__options {
  visibility: visible;
  opacity: 1;
}

.post__options .post__btn {
  margin-left: auto;
}

.post__body {
  padding: 20px 20px 0;
}

.post__user {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 40px;
}

@media only screen and (max-width: 767px) {
  .post__user {
    margin-bottom: 15px;
  }
}

.post__user .ava {
  width: 42px;
  height: 42px;
  font-size: 16px;
}

.post__user .ava__pic {
  border: 1px solid #F1F1F5;
}

.post__desc {
  flex: 0 0 calc(100% - 52px);
  width: calc(100% - 52px);
  padding-left: 10px;
}

.post__man {
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
}

.post__time {
  font-size: 12px;
  color: #92929D;
}

.post .options2 {
  position: absolute;
  top: 4px;
  right: -5px;
}

.post__body .post__text {
  margin-top: 20px;
  line-height: 1.71429;
  color: #44444F;
}

.post__gallery {
  display: flex;
  margin: 15px -7px 0;
}

@media only screen and (max-width: 767px) {
  .post__gallery {
    margin: 15px -5px 0;
  }
}

.post__col {
  flex: 0 0 calc(50% - 14px);
  width: calc(50% - 14px);
  margin: 0 7px;
}

@media only screen and (max-width: 767px) {
  .post__col {
    flex: 0 0 calc(50% - 10px);
    width: calc(50% - 10px);
    margin: 0 5px;
  }
}

.post__preview {
  position: relative;
  font-size: 0;
  cursor: pointer;
}

.post__preview:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(23, 23, 37, 0.2);
  border-radius: 15px;
  opacity: 0;
  transition: opacity .25s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.post__preview_sm {
  padding-bottom: calc((115%/2) - 7.5px);
}

@media only screen and (max-width: 767px) {
  .post__preview_sm {
    padding-bottom: calc((115%/2) - 5px);
  }
}

.post__preview_big {
  padding-bottom: 115%;
}

.post__preview:hover:before {
  opacity: 1;
}

.post__preview:not(:last-child) {
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .post__preview:not(:last-child) {
    margin-bottom: 10px;
  }
}

.post__preview .post__pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}

.post__control {
  display: flex;
  margin-top: 20px;
  padding: 20px 10px;
  border-top: 1px solid #F1F1F5;
}

.post__link {
  display: flex;
  align-items: center;
  color: #44444F;
  transition: color .25s;
}

@media only screen and (max-width: 767px) {
  .post__link {
    font-size: 0;
  }
}

.post__link .icon {
  margin-right: 10px;
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

@media only screen and (max-width: 767px) {
  .post__link .icon {
    margin-right: 5px;
  }
}

.post__link:hover {
  color: #92929D;
}

.post__link:hover .icon {
  fill: #B5B5BE;
}

.post__link:not(:last-child) {
  margin-right: auto;
}

.post__counter {
  padding-right: 4px;
}

@media only screen and (max-width: 767px) {
  .post__counter {
    font-size: 14px;
  }
}

.post__foot {
  display: flex;
  align-items: center;
  padding: 15px;
  border-top: 1px solid #F1F1F5;
}

.post__foot .ava {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.post__foot .post__field {
  flex-grow: 1;
}

.post__foot .post__input {
  width: 100%;
  height: 46px;
  padding: 0 130px 0 15px;
  border: 1px solid #F1F1F5;
  background: #FAFAFB;
  border-radius: 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .post__foot .post__input {
    padding-right: 90px;
  }
}

.post__foot .post__input::placeholder {
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .post__foot .post__action:last-child {
    display: none;
  }
}

body.dark .post__item {
  background: #1C1C24;
}

body.dark .post__top {
  border-color: #292932;
  color: #FAFAFB;
}

body.dark .post__write .post__input {
  background: #1C1C24;
}

body.dark .post__write .post__input::placeholder {
  color: #696974;
}

body.dark .post__options {
  background: #1C1C24;
}

body.dark .post__options:before {
  background: #292932;
}

body.dark .post__man {
  color: #FAFAFB;
}

body.dark .post__time {
  color: #696974;
}

body.dark .post__body .post__text {
  color: #B5B5BE;
}

body.dark .post__control {
  border-color: #292932;
}

body.dark .post__link {
  color: #D5D5DC;
}

body.dark .post__link:hover {
  color: #FAFAFB;
}

body.dark .post__foot {
  border-color: #292932;
}

body.dark .post__foot .post__input {
  background: #292932;
  border-color: #292932;
  color: #FAFAFB;
}

.card1 {
  background: #ffffff;
  border-radius: 15px;
}

@media only screen and (max-width: 767px) {
  .card1 {
    border-radius: 0;
  }
}

.card1__head {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  border-bottom: 1px solid #F1F1F5;
}

.card1__head .options2 {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.card1__category {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
}

.card1__body {
  padding: 20px;
}

.card1__add .card1__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  margin-right: 14px;
  border-radius: 50%;
  border: 1px solid #F1F1F5;
  font-size: 0;
  transition: border-color .25s;
}

.card1__add .card1__icon .icon {
  font-size: 17px;
  fill: #0062FF;
}

.card1__add:hover .card1__icon {
  border-color: #0062FF;
}

.card1__desc {
  flex-grow: 1;
}

.card1__add .card1__info {
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: #171725;
}

.card1__add .card1__text, .card1__time {
  font-size: 12px;
  color: #92929D;
}

.card1__add, .card1__user {
  display: flex;
  align-items: center;
}

.card1__add:not(:last-child), .card1__user:not(:last-child) {
  margin-bottom: 20px;
}

.card1__user {
  transition: opacity .25s;
}

.card1__user .ava {
  width: 42px;
  height: 42px;
  margin-right: 14px;
  font-size: 16px;
}

.card1__user .ava.active {
  border: 2px solid #0062FF;
  box-shadow: inset 0 0 0 3px #ffffff;
}

.card1__user .ava__pic {
  border: 1px solid #F1F1F5;
}

.card1__user_team .ava {
  width: 46px;
  height: 46px;
}

.card1__user:hover {
  opacity: .8;
}

.card1__man {
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  color: #171725;
}

.card1__company {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: #171725;
}

.card1__all {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  border-radius: 0 0 15px 15px;
  background: rgba(0, 115, 255, 0.05);
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .8px;
  text-transform: uppercase;
  color: #0062FF;
  transition: background .25s;
}

@media only screen and (max-width: 767px) {
  .card1__all {
    border-radius: 0;
  }
}

.card1__all:hover {
  background: rgba(0, 115, 255, 0.1);
}

.card1__item {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  letter-spacing: .2px;
  color: #171725;
  transition: color .25s;
}

.card1__item .icon {
  margin-right: 15px;
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.card1__item:hover {
  color: #92929D;
}

.card1__item:hover .icon {
  fill: #B5B5BE;
}

.card1__item:not(:last-child) {
  margin-bottom: 15px;
}

.card1__more {
  flex-shrink: 0;
  padding-left: 15px;
  margin-left: auto;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .8px;
  text-transform: uppercase;
  color: #0062FF;
  transition: color .25s;
}

.card1__more:hover {
  color: #50B5FF;
}

.card1__note {
  line-height: 1.71429;
  color: #44444F;
}

.card1__note:not(:last-child) {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #F1F1F5;
}

.card1__like {
  display: block;
  margin: -5px -5px 0;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid #F1F1F5;
}

.card1__photo {
  font-size: 0;
}

.card1__photo .card1__pic {
  width: 100%;
}

.card1__like .card1__text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  border-top: 1px solid #F1F1F5;
  color: #44444F;
}

.card1__like .card1__text .icon {
  margin-right: 10px;
  font-size: 24px;
  fill: #92929D;
}

.card1__gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -5px 0;
}

.card1__gallery .card1__photo {
  flex: 0 0 calc(33.333% - 10px);
  width: calc(33.333% - 10px);
  margin: 10px 5px 0;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .card1__gallery .card1__photo {
    flex: 0 0 calc(20% - 10px);
    width: calc(20% - 10px);
  }
}

@media only screen and (max-width: 767px) {
  .card1__gallery .card1__photo {
    flex: 0 0 calc(33.333% - 10px);
    width: calc(33.333% - 10px);
  }
}

.card1__gallery .card1__pic {
  width: 100%;
  border-radius: 15px;
}

.card1__wrap {
  padding: 15px 20px;
}

.card1__title {
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
  line-height: 1.5;
}

.card1__status {
  display: flex;
  align-items: center;
}

.card1__line {
  position: relative;
  flex-grow: 1;
  height: 6px;
  overflow: hidden;
  border-radius: 3px;
  background: #E2E2EA;
}

.card1__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #0062FF;
}

.card1__percent {
  flex-shrink: 0;
  margin-left: 15px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 13px;
  line-height: 1.46154;
  font-weight: 500;
  color: #44444F;
}

.card1__group {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 100px;
}

.card1__group .ava {
  width: 42px;
  height: 42px;
  margin-right: 10px;
  font-size: 16px;
}

.card1__box {
  width: calc(100% - 52px);
}

.card1__group .card1__info {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
}

.card1__group .card1__btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  min-width: 89px;
  height: 34px;
}

.card1__group .card1__btn .icon {
  font-size: 18px;
  fill: #92929D;
}

.card1__date {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #92929D;
}

.card1__content {
  line-height: 1.71429;
  color: #44444F;
}

.card1__content p:not(:last-child) {
  margin-bottom: 10px;
}

.card1__content:not(:last-child) {
  margin-bottom: 25px;
}

.card1__stage {
  margin-bottom: 7px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  line-height: 1.5;
  color: #B5B5BE;
}

.card1__type .card1__value {
  line-height: 1.71429;
  color: #44444F;
}

.card1__top {
  display: flex;
}

.card1__top .options2 {
  bottom: -2px;
}

.card1__title .card1__counter {
  position: relative;
  margin-left: 5px;
  font-weight: 400;
  color: #92929D;
}

.card1__title .card1__counter:before {
  content: "";
  display: inline-block;
  position: relative;
  top: -2px;
  width: 4px;
  height: 4px;
  margin-right: 4px;
  border-radius: 50%;
  background: #B5B5BE;
}

.card1__members {
  display: flex;
  flex-wrap: wrap;
  margin: -4px -10px 0;
}

@media only screen and (max-width: 767px) {
  .card1__members {
    margin-top: -10px;
  }
}

.card1__members .ava {
  width: 42px;
  height: 42px;
  font-size: 16px;
  margin: 15px 7px 0;
}

@media only screen and (max-width: 767px) {
  .card1__members .ava {
    width: 32px;
    height: 32px;
  }
}

@media only screen and (max-width: 1365px) {
  .card1__members .ava:nth-child(n+9) {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .card1__members .ava:nth-child(n+9) {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .card1__members .ava:nth-child(n+8) {
    display: none;
  }
}

.card1__top .card1__more {
  position: relative;
  bottom: -2px;
}

.card1__top .card1__title {
  margin-right: auto;
}

.card1__event {
  display: flex;
}

.card1__event .icon {
  margin-right: 15px;
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.card1__event:hover .icon {
  fill: #B5B5BE;
}

.card1__event:not(:last-child) {
  margin-bottom: 15px;
}

.card1__event .card1__info {
  margin-bottom: 8px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .card1__event .card1__info {
    margin-bottom: 4px;
  }
}

.card1__event .card1__time {
  font-size: 14px;
}

.card1__place {
  color: #92929D;
}

.card1__place:before {
  content: "";
  display: inline-block;
  position: relative;
  top: -2px;
  width: 4px;
  height: 4px;
  margin-right: 4px;
  border-radius: 50%;
  background: #B5B5BE;
}

.card1__place:not(:first-child) {
  margin-left: 5px;
}

.card1__media {
  display: flex;
  flex-wrap: wrap;
  margin: -8px -4px 0;
}

.card1__cell {
  margin: 8px 4px 0;
}

.card1__cell_w17 {
  flex: 0 0 calc((100%/6) - 8px);
  width: calc((100%/6) - 8px);
}

@media only screen and (max-width: 767px) {
  .card1__cell_w17 {
    flex: 0 0 calc((100%/3) - 8px);
    width: calc((100%/3) - 8px);
  }
}

.card1__cell_w33 {
  flex: 0 0 calc((100%/3) - 8px);
  width: calc((100%/3) - 8px);
}

@media only screen and (max-width: 767px) {
  .card1__cell_w33 {
    flex: 0 0 calc((2*100%/3) - 8px);
    width: calc((2*100%/3) - 8px);
  }
}

.card1__preview {
  font-size: 0;
}

.card1__cell > .card1__preview:not(:last-child) {
  margin-bottom: 8px;
}

.card1__media .card1__pic {
  width: 100%;
  border-radius: 15px;
}

.card1__media .card1__flex {
  display: flex;
  margin: 0 -4px;
}

.card1__media .card1__flex:not(:last-child) {
  margin-bottom: 8px;
}

.card1__flex .card1__preview {
  flex: 0 0 calc(50% - 8px);
  width: calc(50% - 8px);
  margin: 0 4px;
}

.card1:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .card1:not(:last-child) {
    margin-bottom: 10px;
  }
}

body.dark .card1 {
  background: #1C1C24;
}

body.dark .card1__head {
  border-color: #292932;
}

body.dark .card1__category {
  color: #FAFAFB;
}

body.dark .card1__add .card1__icon {
  background: #ffffff;
}

body.dark .card1__add .card1__info {
  color: #FAFAFB;
}

body.dark .card1__add .card1__text {
  color: #696974;
}

body.dark .card1__man {
  color: #FAFAFB;
}

body.dark .card1__time {
  color: #696974;
}

body.dark .card1__all {
  background: rgba(146, 146, 157, 0.1);
  color: #ffffff;
}

body.dark .card1__all:hover {
  background: rgba(146, 146, 157, 0.2);
}

body.dark .card1__item {
  color: #E2E2EA;
}

body.dark .card1__item:hover {
  color: #FAFAFB;
}

body.dark .card1__more {
  color: #ffffff;
}

body.dark .card1__more:hover {
  color: #50B5FF;
}

body.dark .card1__user_team .card1__time {
  color: #92929D;
}

body.dark .card1__like {
  border-color: #292932;
}

body.dark .card1__like .card1__text {
  border-color: #292932;
  color: #D5D5DC;
}

body.dark .card1__company {
  color: #FAFAFB;
}

body.dark .card1__title {
  color: #FAFAFB;
}

body.dark .card1__line {
  background: #FAFAFB;
}

body.dark .card1__progress {
  background: #1E75FF;
}

body.dark .card1__percent {
  color: #D5D5DC;
}

body.dark .card1__note {
  color: #E2E2EA;
}

body.dark .card1__note:not(:last-child) {
  border-color: #292932;
}

body.dark .card1__group .card1__info {
  color: #FAFAFB;
}

body.dark .card1__content {
  color: #D5D5DC;
}

body.dark .card1__stage {
  color: #696974;
}

body.dark .card1__type .card1__value {
  color: #D5D5DC;
}

body.dark .card1__event .card1__info {
  color: #FAFAFB;
}

body.dark .card1__event .card1__time {
  color: #92929D;
}

.friends__list {
  display: flex;
  flex-wrap: wrap;
  margin: -30px -15px 0;
}

@media only screen and (max-width: 1199px) {
  .friends__list {
    margin: -20px -10px 0;
  }
}

@media only screen and (max-width: 1023px) {
  .friends__list {
    display: block;
    margin: 0;
  }
}

.friends__item {
  flex: 0 0 calc(50% - 30px);
  width: calc(50% - 30px);
  margin: 30px 15px 0;
  border-radius: 15px;
  overflow: hidden;
  background: #ffffff;
}

@media only screen and (max-width: 1199px) {
  .friends__item {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
    margin: 20px 10px 0;
  }
}

@media only screen and (max-width: 1023px) {
  .friends__item {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .friends__item:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .friends__item:not(:last-child) {
    margin-bottom: 5px;
  }
}

.friends__bg {
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

@media only screen and (max-width: 767px) {
  .friends__bg {
    display: none;
  }
}

.friends__body {
  display: flex;
  min-height: 146px;
  padding: 15px 20px;
}

@media only screen and (max-width: 1023px) {
  .friends__body {
    min-height: auto;
  }
}

.friends__ava {
  flex-shrink: 0;
  width: 92px;
  height: 92px;
  margin: -36px 17px 0 -3px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .friends__ava {
    width: 56px;
    height: 56px;
    margin: 0 10px 0 0;
    border: none;
  }
}

.friends__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.friends__details {
  position: relative;
  flex-grow: 1;
}

.friends__user {
  margin-bottom: 3px;
  padding-right: 90px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .friends__user {
    font-size: 16px;
  }
}

.friends__login {
  margin-bottom: 8px;
  line-height: 1.15;
  color: #92929D;
}

.friends__text {
  font-size: 16px;
  line-height: 1.625;
  color: #44444F;
}

@media only screen and (max-width: 767px) {
  .friends__text {
    font-size: 14px;
  }
}

.friends__status {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  padding-top: 1px;
  border: 1px solid rgba(0, 98, 255, 0.5);
  border-radius: 10px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 600;
  line-height: 25px;
  color: #0062FF;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .friends__status {
    line-height: 23px;
  }
}

.friends__status:hover {
  opacity: .75;
}

.friends__status.active {
  background: #0062FF;
  border-color: #0062FF;
  opacity: 1;
  color: #FAFAFB;
}

body.dark .friends__item {
  background: #1C1C24;
}

body.dark .friends__user {
  color: #FAFAFB;
}

body.dark .friends__login {
  color: #696974;
}

body.dark .friends__text {
  color: #92929D;
}

body.dark .friends__status:not(.active) {
  border-color: rgba(250, 250, 251, 0.5);
  color: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  body.dark .friends__status:not(.active) {
    background: #292932;
    border-color: #292932;
  }
}

.event__list {
  display: flex;
  flex-wrap: wrap;
  margin: -20px -10px 0;
}

@media only screen and (max-width: 767px) {
  .event__list {
    display: block;
    margin: 0;
    padding: 5px 20px 25px;
  }
}

.event__item {
  flex: 0 0 calc(33.333% - 20px);
  width: calc(33.333% - 20px);
  margin: 20px 10px 0;
  border-radius: 15px;
  background: #ffffff;
  overflow: hidden;
}

@media only screen and (max-width: 1023px) {
  .event__item {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .event__item {
    display: block;
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .event__item:not(:last-child) {
    margin-bottom: 10px;
  }
}

.event__preview {
  font-size: 0;
}

.event__pic {
  width: 100%;
}

.event__body {
  display: flex;
  padding: 20px 15px;
}

.event__date {
  flex-shrink: 0;
  width: 36px;
  margin-right: 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
}

.event__month {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: .8px;
  color: #979797;
}

.event__day {
  font-size: 24px;
  line-height: 1.5;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .event__day {
    color: #0062FF;
  }
}

.event__title {
  margin-bottom: 7px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: #171725;
  transition: color .25s;
}

@media only screen and (max-width: 767px) {
  .event__title {
    margin-bottom: 5px;
  }
}

.event__item:hover .event__title {
  color: #0062FF;
}

.event__info {
  margin-bottom: 20px;
  line-height: 1.15;
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .event__info {
    margin-bottom: 15px;
  }
}

.event__place:not(:first-child) {
  margin-left: 5px;
}

.event__place:not(:first-child):before {
  content: "";
  display: inline-block;
  position: relative;
  top: -3px;
  width: 4px;
  height: 4px;
  margin-right: 5px;
  border-radius: 50%;
  background: #B5B5BE;
}

body.dark .event__item {
  background: #1C1C24;
}

body.dark .event__item:hover .event__title {
  color: #1E75FF;
}

body.dark .event__month, body.dark .event__day {
  color: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  body.dark .event__month {
    color: #92929D;
  }
}

body.dark .event__title {
  color: #E2E2EA;
}

.place__list {
  display: flex;
  flex-wrap: wrap;
  margin: -20px -10px 0;
}

@media only screen and (max-width: 767px) {
  .place__list {
    margin: -15px 5px 25px;
  }
}

.place__item {
  flex: 0 0 calc(25% - 20px);
  width: calc(25% - 20px);
  margin: 20px 10px 0;
  border-radius: 15px;
  background: #ffffff;
  overflow: hidden;
}

@media only screen and (max-width: 1365px) {
  .place__item {
    flex: 0 0 calc(33.333% - 20px);
    width: calc(33.333% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .place__item {
    flex: 0 0 calc(50% - 10px);
    width: calc(50% - 10px);
    margin: 10px 5px 0;
  }
}

.place__preview {
  font-size: 0;
}

.place__pic {
  width: 100%;
}

.place__item:hover .place__title {
  color: #1E75FF;
}

.place__body {
  padding: 15px;
}

@media only screen and (max-width: 767px) {
  .place__body {
    padding: 10px;
  }
}

.place__title {
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  line-height: 1.57143;
  font-weight: 600;
  color: #44444F;
  transition: color .25s;
}

@media only screen and (max-width: 767px) {
  .place__title {
    line-height: 1.3;
  }
}

.place__line {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.place__price {
  margin-right: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #0062FF;
}

@media only screen and (max-width: 767px) {
  .place__price {
    margin-right: auto;
  }
}

.place__rating {
  display: flex;
  margin-top: -2px;
}

.place__rating .icon {
  font-size: 16px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .place__rating .icon {
    fill: #FF974A;
  }
}

.place__rating .icon:not(:last-child) {
  margin-right: 1px;
}

@media only screen and (max-width: 767px) {
  .place__rating .icon:not(:first-child) {
    display: none;
  }
}

.place__counter {
  display: none;
}

@media only screen and (max-width: 767px) {
  .place__counter {
    display: block;
    margin-left: 5px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;
    color: #FF974A;
  }
}

.place__info {
  line-height: 1.15;
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .place__info {
    font-size: 12px;
  }
}

.place__time:not(:first-child) {
  margin-left: 5px;
}

.place__time:not(:first-child):before {
  content: "";
  display: inline-block;
  position: relative;
  top: -3px;
  width: 4px;
  height: 4px;
  margin-right: 5px;
  border-radius: 50%;
  background: #B5B5BE;
}

@media only screen and (max-width: 767px) {
  .place__time:not(:first-child):before {
    top: -2px;
  }
}

body.dark .place__item {
  background: #1C1C24;
}

body.dark .place__title {
  color: #E2E2EA;
}

@media only screen and (max-width: 767px) {
  body.dark .place__title {
    color: #FAFAFB;
  }
}

body.dark .place__price {
  color: #1E75FF;
}

@media only screen and (max-width: 767px) {
  body.dark .place__rating .icon {
    fill: #FFC542;
  }
}

@media only screen and (max-width: 767px) {
  body.dark .place__counter {
    color: #FFC542;
  }
}

.videos__top {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .videos__top {
    height: 56px;
    margin-bottom: 5px;
    padding: 0 20px;
    background: #ffffff;
  }
}

.videos__title {
  margin-right: auto;
}

.videos__search {
  position: relative;
  width: 250px;
}

@media only screen and (max-width: 767px) {
  .videos__search {
    width: 38px;
  }
}

.videos__open {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 38px;
  background: #ffffff;
  border-radius: 10px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .videos__open {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    z-index: 2;
    height: 38px;
  }
}

.videos__open .icon {
  font-size: 20px;
  fill: #92929D;
  transition: fill .25s;
}

.videos__open:hover .icon {
  fill: #B5B5BE;
}

@media only screen and (max-width: 767px) {
  .videos__search .videos__field {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 38px;
    overflow: hidden;
    border-radius: 10px;
    transition: width .25s;
  }
}

@media only screen and (max-width: 767px) {
  .videos__search.show .videos__field {
    width: 340px;
  }
}

.videos__search .videos__input {
  width: 100%;
  height: 38px;
  padding: 0 38px 0 13px;
  border-radius: 10px;
  background: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #171725;
}

.videos__search .videos__input::placeholder {
  color: #92929D;
}

.videos__list {
  display: flex;
  flex-wrap: wrap;
  margin: -20px -10px 0;
}

@media only screen and (max-width: 767px) {
  .videos__list {
    display: block;
    margin: 0;
  }
}

.videos__item {
  margin: 20px 10px 0;
  border-radius: 20px;
  overflow: hidden;
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .videos__item {
    display: flex;
    flex-direction: column;
    margin: 0;
    border-radius: 0;
  }
}

.videos__item_sm {
  flex: 0 0 calc(50% - 20px);
  width: calc(50% - 20px);
}

@media only screen and (max-width: 767px) {
  .videos__item_sm {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .videos__item:not(:last-child) {
    margin-bottom: 10px;
  }
}

.videos__preview {
  display: block;
  position: relative;
  font-size: 0;
}

.videos__preview .videos__pic {
  width: 100%;
}

.videos__time {
  position: absolute;
  left: 15px;
  bottom: 15px;
  display: inline-block;
  padding: 0 14px;
  background: #171725;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  line-height: 28px;
  font-weight: 600;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .videos__head {
    display: flex;
  }
}

.videos__body {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .videos__body {
    order: -1;
  }
}

.videos__item_big .videos__body {
  padding: 15px 25px 20px;
}

@media only screen and (max-width: 1023px) {
  .videos__item_big .videos__body {
    padding: 15px 20px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .videos__item_big .videos__body {
    padding: 20px 20px 15px;
  }
}

.videos__item_sm .videos__body {
  padding: 10px 15px 20px;
}

@media only screen and (max-width: 767px) {
  .videos__item_sm .videos__body {
    padding: 20px 20px 15px;
  }
}

.videos__item_big .videos__company {
  top: -30px;
  right: 20px;
  width: 60px;
  height: 60px;
  border: 3px solid #ffffff;
}

@media only screen and (max-width: 767px) {
  .videos__item_big .videos__company {
    top: auto;
    right: auto;
    width: 48px;
    height: 48px;
    border: none;
  }
}

.videos__item_sm .videos__company {
  top: -24px;
  right: 20px;
  width: 48px;
  height: 48px;
}

@media only screen and (max-width: 767px) {
  .videos__item_sm .videos__company {
    top: auto;
    right: auto;
  }
}

.videos__add {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background: #0062FF;
  font-size: 0;
  transition: transform .25s;
}

.videos__add .icon {
  font-size: 12px;
  fill: #ffffff;
}

.videos__add:hover {
  transform: rotate(90deg);
}

.videos__item_big .videos__add {
  right: -3px;
  bottom: -3px;
}

.videos__item_sm .videos__add {
  right: -2px;
  bottom: -2px;
}

@media only screen and (max-width: 767px) {
  .videos__item_sm .videos__add {
    right: -3px;
    bottom: -3px;
  }
}

.videos__company {
  position: absolute;
  border-radius: 50%;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .videos__company {
    position: relative;
    flex-shrink: 0;
    margin-right: 15px;
  }
}

.videos__company .videos__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.videos__category {
  padding-right: 80px;
  line-height: 1.71429;
  color: #0062FF;
}

@media only screen and (max-width: 767px) {
  .videos__category {
    padding: 0;
  }
}

.videos__item_big .videos__category {
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .videos__item_big .videos__category {
    margin-bottom: 5px;
  }
}

.videos__item_sm .videos__category {
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .videos__details {
    flex: 0 0 calc(100% - 61px);
    width: calc(100% - 61px);
  }
}

.videos__info {
  display: block;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  color: #171725;
  transition: color .25s;
}

.videos__info:hover {
  color: #1E75FF;
}

.videos__item_big .videos__info {
  margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
  .videos__item_big .videos__info {
    margin-bottom: 10px;
  }
}

.videos__item_sm .videos__info {
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .videos__item_sm .videos__info {
    margin-bottom: 5px;
  }
}

.videos__text {
  line-height: 1.71429;
  color: #44444F;
}

.videos__control {
  display: flex;
  margin: 0 25px;
  padding: 20px 10px;
  border-top: 1px solid #F1F1F5;
}

@media only screen and (max-width: 1023px) {
  .videos__control {
    margin: 0 20px;
  }
}

.videos__link {
  display: flex;
  align-items: center;
  color: #44444F;
  transition: color .25s;
}

@media only screen and (max-width: 767px) {
  .videos__link {
    font-size: 0;
  }
}

.videos__link .icon {
  margin-right: 10px;
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

@media only screen and (max-width: 767px) {
  .videos__link .icon {
    margin-right: 5px;
  }
}

.videos__link:hover {
  color: #92929D;
}

.videos__link:hover .icon {
  fill: #B5B5BE;
}

.videos__link:not(:last-child) {
  margin-right: auto;
}

.videos__counter {
  padding-right: 4px;
}

@media only screen and (max-width: 767px) {
  .videos__counter {
    font-size: 14px;
  }
}

.videos__desc {
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  body.dark .videos__top {
    background: #1C1C24;
  }
}

body.dark .videos__item {
  background: #1C1C24;
}

body.dark .videos__title {
  color: #FAFAFB;
}

body.dark .videos__search .videos__input {
  background: #1C1C24;
}

@media only screen and (max-width: 767px) {
  body.dark .videos__search .videos__input {
    background: #292932;
  }
}

body.dark .videos__open {
  background: #1C1C24;
}

@media only screen and (max-width: 767px) {
  body.dark .videos__open {
    background: #292932;
  }
}

body.dark .videos__category {
  color: #1E75FF;
}

body.dark .videos__info {
  color: #FAFAFB;
}

body.dark .videos__info:hover {
  color: #B5B5BE;
}

body.dark .videos__text {
  color: #B5B5BE;
}

body.dark .videos__control {
  border-color: #292932;
}

body.dark .videos__link {
  color: #D5D5DC;
}

.gallery__list {
  display: flex;
  flex-wrap: wrap;
  margin: -30px -10px 0;
}

@media only screen and (max-width: 1023px) {
  .gallery__list {
    margin-top: -20px;
  }
}

@media only screen and (max-width: 767px) {
  .gallery__list {
    display: block;
    margin: -10px -5px 0;
    padding: 10px 10px 20px;
    *zoom: 1;
  }
  .gallery__list:after {
    content: " ";
    display: table;
    clear: both;
  }
}

.gallery__preview {
  position: relative;
  flex: 0 0 calc(33.333% - 20px);
  width: calc(33.333% - 20px);
  margin: 30px 10px 0;
  border-radius: 15px;
  overflow: hidden;
  font-size: 0;
  cursor: pointer;
}

@media only screen and (max-width: 1023px) {
  .gallery__preview {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .gallery__preview {
    float: left;
    width: calc(33.33333% - 10px);
    margin: 10px 5px 0;
  }
}

@media only screen and (max-width: 767px) {
  .gallery__preview:first-child {
    width: calc(100% - 10px);
  }
  .gallery__preview:first-child .gallery__options {
    visibility: visible;
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .gallery__preview:nth-child(8) {
    width: calc(66.66666% - 10px);
  }
}

@media only screen and (max-width: 767px) {
  .gallery__preview.show {
    width: calc(100% - 10px);
  }
}

.gallery__options {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px 50px 20px 20px;
  background: linear-gradient(180deg, rgba(122, 122, 135, 0.0001) -6.26%, #171725 89.85%);
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
}

.gallery__preview:hover .gallery__options {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .gallery__preview:hover .gallery__options {
    visibility: hidden;
    opacity: 0;
  }
}

.gallery__title {
  margin-bottom: 3px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  color: #ffffff;
}

.gallery__links {
  display: flex;
  flex-wrap: wrap;
}

.gallery__links li:not(:first-child) {
  position: relative;
  margin-left: 5px;
  padding-left: 9px;
}

.gallery__links li:not(:first-child):before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #ffffff;
}

.gallery__link {
  font-size: 14px;
  color: #ffffff;
  transition: opacity .25s;
}

.gallery__link:hover {
  opacity: .85;
}

.gallery__pic {
  width: 100%;
}

.gallery__download {
  position: absolute;
  top: 20px;
  right: 20px;
}

.gallery__download .icon {
  font-size: 28px;
  fill: #92929D;
  transition: fill .25s;
}

.gallery__download:hover .icon {
  fill: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  .gallery .sorting1__title {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .gallery .sorting1__variants .sorting1__text {
    display: none;
  }
}

body.dark .gallery__link {
  color: #FAFAFB;
}

.profile {
  border-radius: 15px;
  overflow: hidden;
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .profile {
    border-radius: 0;
  }
}

.profile__bg {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.profile__edit {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 38px;
  padding-top: 1px;
  border-radius: 10px;
  border: 1px solid #ffffff;
  background: rgba(23, 23, 37, 0.5);
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  transition: all .25s;
}

.profile__edit .icon {
  display: none;
}

@media only screen and (max-width: 767px) {
  .profile__edit {
    top: 15px;
    right: 15px;
    width: 110px;
  }
}

.profile__edit:hover {
  background: #FAFAFB;
  color: #171725;
}

.profile__edit:hover .icon {
  fill: #171725;
}

.profile__user {
  position: relative;
  z-index: 2;
  display: flex;
  margin: -110px 25px 0;
}

@media only screen and (max-width: 767px) {
  .profile__user {
    display: block;
    text-align: center;
    margin: -65px 0 0;
  }
}

.profile__ava {
  flex-shrink: 0;
  width: 170px;
  height: 170px;
  margin-right: 25px;
  border: 6px solid #ffffff;
  border-radius: 50%;
  background: #ffffff;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .profile__ava {
    width: 130px;
    height: 130px;
    margin: 0 auto 10px;
    border-width: 5px;
  }
}

.profile__ava .profile__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.profile__details {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

@media only screen and (max-width: 767px) {
  .profile__details {
    display: block;
    padding: 0;
  }
}

.profile__title {
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .profile__title {
    font-size: 16px;
    color: #171725;
  }
}

.profile__login {
  font-size: 16px;
  color: #D5D5DC;
}

@media only screen and (max-width: 767px) {
  .profile__login {
    margin-left: 5px;
    font-size: 14px;
    color: #92929D;
  }
}

.profile__options {
  position: relative;
  z-index: 3;
  display: flex;
  margin-top: -60px;
  padding-left: calc(33.5% + 30px);
}

@media only screen and (max-width: 1023px) {
  .profile__options {
    padding-left: 215px;
  }
}

@media only screen and (max-width: 767px) {
  .profile__options {
    margin-top: 15px;
    padding: 0;
    border-top: 1px solid #F1F1F5;
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  .profile__options::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .profile__option:first-child {
    padding-left: 20px;
  }
  .profile__option:last-child {
    padding-right: 20px;
  }
}

.profile__link {
  position: relative;
  display: block;
  padding: 10px 16px 12px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
}

@media only screen and (max-width: 1365px) {
  .profile__link {
    padding: 10px 12px;
  }
}

@media only screen and (max-width: 1023px) {
  .profile__link {
    padding: 10px;
  }
}

.profile__link:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  background: #0062FF;
  border-radius: 3px 3px 0 0;
  transition: height .25s;
}

.profile__link.active:before {
  height: 3px;
}

@media only screen and (max-width: 767px) {
  .profile__link.active .profile__category,
  .profile__link.active .profile__number {
    color: #0062FF;
  }
}

.profile__text {
  display: none;
}

@media only screen and (max-width: 767px) {
  .profile__text {
    display: block;
    margin-top: 5px;
    line-height: 1.57143;
    color: #44444F;
  }
}

.profile__category {
  margin-bottom: 4px;
  letter-spacing: .2px;
  line-height: 1.5;
  font-weight: 700;
  color: #92929D;
  transition: color .25s;
}

.profile__link:hover .profile__category {
  color: #B5B5BE;
}

.profile__number {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: #44444F;
  transition: color .25s;
}

.profile__link:hover .profile__number {
  color: #92929D;
}

.profile_user .profile__bg {
  height: 260px;
}

@media only screen and (max-width: 1023px) {
  .profile_user .profile__bg {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .profile_user .profile__bg {
    height: 130px;
    border-radius: 15px 15px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .profile_user .profile__edit {
    width: 32px;
    height: 32px;
    font-size: 0;
  }
  .profile_user .profile__edit .icon {
    display: inline-block;
    font-size: 16px;
    fill: #ffffff;
  }
  .profile_user .profile__edit:hover .icon {
    fill: #171725;
  }
}

.profile_group .profile__bg {
  height: 200px;
}

@media only screen and (max-width: 767px) {
  .profile_group .profile__bg {
    height: 150px;
  }
}

@media only screen and (max-width: 1023px) {
  .profile_hide {
    display: none;
  }
}

.profile_show {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .profile_show {
    display: block;
    border-radius: 15px 15px 0 0;
  }
  .profile_show:not(:last-child) {
    margin: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .profile_show .profile__bg:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 66px;
    background: linear-gradient(180deg, rgba(122, 122, 135, 0.0001) 0%, #171725 131.54%);
  }
}

.profile:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .profile:not(:last-child) {
    margin-bottom: 5px;
  }
}

body.dark .profile {
  background: #1C1C24;
}

body.dark .profile__title {
  color: #FAFAFB;
}

body.dark .profile__number {
  color: #E2E2EA;
}

body.dark .profile__link:before {
  background: #1E75FF;
}

body.dark .profile__link:hover .profile__number {
  color: #B5B5BE;
}

body.dark .profile__link.active .profile__category,
body.dark .profile__link.active .profile__number {
  color: #1E75FF;
}

@media only screen and (max-width: 767px) {
  body.dark .profile__login {
    color: #FAFAFB;
  }
}

body.dark .profile__text {
  color: #E2E2EA;
}

body.dark .profile__options {
  border-color: #292932;
}

@media only screen and (max-width: 767px) {
  .notifications {
    padding-bottom: 30px;
  }
}

.notifications__item {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 15px;
  background: #ffffff;
  transition: background .25s;
}

@media only screen and (max-width: 767px) {
  .notifications__item {
    position: relative;
    align-items: flex-start;
    min-height: 100px;
    padding-right: 50px;
    border-radius: 0;
  }
}

.notifications__item:hover, .notifications__item.active {
  background: rgba(0, 98, 255, 0.05);
}

.notifications__item:not(:last-child) {
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .notifications__item:not(:last-child) {
    margin: 0;
  }
}

.notifications .ava {
  width: 42px;
  height: 42px;
  font-size: 16px;
}

.notifications__details {
  flex: 0 0 calc(100% - 128px);
  width: calc(100% - 128px);
  padding: 0 20px;
}

@media only screen and (max-width: 767px) {
  .notifications__details {
    flex: 0 0 calc(100% - 42px);
    width: calc(100% - 42px);
    padding: 0 0 0 15px;
  }
}

.notifications__text {
  max-width: 663px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 15px;
  line-height: 1.46667;
}

@media only screen and (max-width: 767px) {
  .notifications__text {
    overflow: visible;
    white-space: normal;
  }
}

.notifications__text strong {
  font-weight: 600;
}

.notifications__time {
  line-height: 1.15;
  color: #92929D;
}

.notifications__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 20px;
  border-radius: 50%;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .notifications__icon {
    position: absolute;
    top: 65px;
    left: 30px;
    width: 24px;
    height: 24px;
  }
}

.notifications__icon .icon {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  .notifications__icon .icon {
    font-size: 16px;
  }
}

.notifications__icon .icon-friends {
  fill: #50B5FF;
}

.notifications__icon .icon-like {
  fill: #FFC542;
}

.notifications__icon .icon-comment {
  fill: #82C43C;
}

.notifications__icon_friends {
  background: rgba(0, 115, 255, 0.1);
}

.notifications__icon_like {
  background: rgba(255, 197, 66, 0.1);
}

.notifications__icon_comment {
  background: rgba(130, 196, 60, 0.1);
}

@media only screen and (max-width: 767px) {
  .notifications .options2 {
    position: absolute;
    top: 19px;
    right: 15px;
  }
}

.notifications .options2__dropdown {
  top: calc(100% + 5px);
  width: 210px;
  padding: 5px 10px;
}

@media only screen and (max-width: 767px) {
  .notifications .sorting1 {
    display: none;
  }
}

body.dark .notifications__item {
  background: #1C1C24;
}

body.dark .notifications__item:hover, body.dark .notifications__item.active {
  background: #292932;
}

body.dark .notifications__text {
  color: #FAFAFB;
}

body.dark .notifications .options2__dropdown {
  background: #44444F;
  border-color: #696974;
}

@media only screen and (max-width: 1023px) {
  .group {
    margin-top: -78px;
  }
}

.group__logo {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border: 8px solid #ffffff;
  border-radius: 30px;
  background: #50B5FF;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 48px;
  font-weight: 600;
  text-transform: uppercase;
  color: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  .group__logo {
    width: 120px;
    height: 120px;
    border-width: 6px;
    font-size: 40px;
  }
}

.group__details {
  margin-top: -78px;
  padding: 90px 20px 30px;
  background: #ffffff;
  border-radius: 15px;
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .group__details {
    border-radius: 0 0 15px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .group__details {
    padding-bottom: 20px;
    border-radius: 0;
  }
}

.group__title {
  margin-bottom: 2px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
}

.group__login {
  margin-bottom: 25px;
  line-height: 1.71429;
  color: #B5B5BE;
}

.group__quality {
  display: flex;
  justify-content: space-around;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
}

.group__counter {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: #44444F;
}

.group__info {
  line-height: 1.71429;
  letter-spacing: .2px;
  color: #92929D;
}

.group__btns {
  margin-top: 15px;
  padding-top: 25px;
  border-top: 1px solid #F1F1F5;
}

@media only screen and (max-width: 767px) {
  .group__btns {
    padding-top: 20px;
  }
}

.group__btn {
  min-width: auto;
  padding: 1px 15px 0;
}

.group__btn:not(:last-child) {
  margin-right: 15px;
}

.group:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .group:not(:last-child) {
    margin-bottom: 5px;
  }
}

body.dark .group__logo {
  border-color: #292932;
}

body.dark .group__details {
  background: #1C1C24;
}

body.dark .group__title {
  color: #FAFAFB;
}

body.dark .group__counter {
  color: #E2E2EA;
}

body.dark .group__btns {
  border-color: #292932;
}

.popular {
  border-radius: 15px;
  overflow: hidden;
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .popular {
    border-radius: 0;
  }
}

.popular__body {
  padding: 15px 20px 30px;
}

@media only screen and (max-width: 767px) {
  .popular__body {
    padding: 15px 20px 20px;
  }
}

.popular__title {
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
  line-height: 1.5;
}

.popular__item {
  display: flex;
}

.popular__item:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .popular__item:not(:last-child) {
    margin-bottom: 15px;
  }
}

.popular__preview {
  flex-shrink: 0;
  width: 130px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .popular__preview {
    width: 70px;
  }
}

.popular__pic {
  width: 100%;
  border-radius: 15px;
}

@media only screen and (max-width: 767px) {
  .popular__pic {
    border-radius: 10px;
  }
}

.popular__details {
  flex-grow: 1;
  padding-left: 15px;
}

@media only screen and (max-width: 1199px) {
  .popular__details {
    padding-left: 10px;
  }
}

.popular__head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 4px;
}

.popular__head .ava {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  font-size: 11px;
}

@media only screen and (max-width: 767px) {
  .popular__head .ava {
    display: none;
  }
}

.popular__user {
  margin-right: 8px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
  line-height: 1.5;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .popular__user {
    font-size: 12px;
  }
}

.popular__time {
  font-size: 12px;
  color: #92929D;
}

.popular__time:before {
  content: "";
  display: inline-block;
  position: relative;
  top: -2px;
  width: 4px;
  height: 4px;
  margin-right: 5px;
  border-radius: 50%;
  background: #B5B5BE;
}

.popular__text {
  line-height: 1.71429;
  color: #696974;
}

@media only screen and (max-width: 767px) {
  .popular__text {
    font-size: 12px;
  }
}

.popular__foot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
  border-top: 1px solid #F1F1F5;
}

@media only screen and (max-width: 767px) {
  .popular__foot {
    height: 40px;
  }
}

.popular__all {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .8px;
  color: #0062FF;
  transition: color .25s;
}

.popular__all:hover {
  color: #50B5FF;
}

.popular:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .popular:not(:last-child) {
    margin-bottom: 10px;
  }
}

body.dark .popular {
  background: #1C1C24;
}

body.dark .popular__title {
  color: #FAFAFB;
}

body.dark .popular__user {
  color: #FAFAFB;
}

body.dark .popular__text {
  color: #D5D5DC;
}

body.dark .popular__foot {
  border-color: #292932;
}

body.dark .popular__all {
  color: #FAFAFB;
}

body.dark .popular__all:hover {
  color: #50B5FF;
}

.courses {
  width: 100%;
}

.courses__head {
  padding-top: 70px;
  background: #ffffff;
}

@media only screen and (max-width: 1199px) {
  .courses__head {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .courses__head {
    padding-top: 40px;
  }
}

.courses__title {
  margin-bottom: 47px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 48px;
  line-height: 1.5;
  letter-spacing: .2px;
  font-weight: 600;
}

@media only screen and (max-width: 1365px) {
  .courses__title {
    font-size: 42px;
  }
}

@media only screen and (max-width: 1199px) {
  .courses__title {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .courses__title {
    font-size: 28px;
  }
}

.courses__row {
  position: relative;
  display: flex;
  align-items: center;
  height: 78px;
  padding: 0 50px;
}

@media only screen and (max-width: 1599px) {
  .courses__row {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .courses__row {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .courses__row {
    height: auto;
    padding: 0;
  }
}

.courses__options {
  display: flex;
  align-items: center;
  margin-right: auto;
}

@media only screen and (max-width: 1023px) {
  .courses__options {
    display: none;
  }
}

.courses__search {
  position: relative;
  width: 145px;
  margin-right: 20px;
}

.courses__open {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 42px;
  font-size: 0;
}

.courses__open .icon {
  font-size: 20px;
  fill: #92929D;
  transition: fill .25s;
}

.courses__open:hover .icon {
  fill: #B5B5BE;
}

.courses__input {
  width: 100%;
  height: 38px;
  padding: 0 10px 0 42px;
  border-radius: 10px;
  background: #F1F1F5;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 600;
  color: #171725;
}

.courses__input::placeholder {
  color: #696974;
}

.courses__options .courses__btn {
  min-width: 88px;
}

.courses__menu {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .courses__menu {
    position: static;
    transform: translate(0, 0);
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .courses__menu {
    justify-content: flex-start;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  .courses__menu::-webkit-scrollbar {
    display: none;
  }
  .courses__menu:before, .courses__menu:after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 48px;
    flex-shrink: 0;
  }
}

.courses__link {
  position: relative;
  display: flex;
  align-items: center;
  height: 78px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: #44444F;
  transition: color .25s;
}

@media only screen and (max-width: 767px) {
  .courses__link {
    height: 48px;
    white-space: nowrap;
  }
}

.courses__link:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  margin: 0 auto;
  border-radius: 3px 3px 0 0;
  background: #FFC542;
  transition: width .25s;
}

.courses__link:hover {
  color: #FFC542;
}

.courses__link.active {
  font-weight: 600;
  color: #FFC542;
}

.courses__link.active:before {
  width: 100%;
}

.courses__link:not(:last-child) {
  margin-right: 40px;
}

@media only screen and (max-width: 767px) {
  .courses__link:not(:last-child) {
    margin-right: 30px;
  }
}

.courses .sorting {
  margin-left: auto;
  margin-bottom: 0;
}

@media only screen and (max-width: 1439px) {
  .courses .sorting {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .courses .sorting {
    display: none;
  }
}

.courses .sorting__filters {
  margin: 0 20px 0 0;
  border: 1px solid #F1F1F5;
}

.courses .sorting__view {
  margin-left: 20px;
  border: 1px solid #F1F1F5;
}

@media only screen and (max-width: 1365px) {
  .courses .sorting__view {
    display: none;
  }
}

.courses .sorting__box {
  height: 36px;
}

.courses .dropdown__head {
  box-shadow: none;
  border: 1px solid #F1F1F5;
}

.courses__body {
  max-width: 1360px;
  margin: 0 auto;
  padding: 60px 30px;
}

@media only screen and (max-width: 1023px) {
  .courses__body {
    padding: 40px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .courses__body {
    padding: 30px 10px;
  }
}

.courses__list {
  display: flex;
  flex-wrap: wrap;
  margin: -40px -20px 0;
}

@media only screen and (max-width: 767px) {
  .courses__list {
    display: block;
    margin: 0;
  }
}

.courses__item {
  flex: 0 0 calc(25% - 20px);
  width: calc(25% - 20px);
  margin: 40px 10px 0;
}

@media only screen and (max-width: 1365px) {
  .courses__item {
    flex: 0 0 calc(33.333% - 20px);
    width: calc(33.333% - 20px);
  }
}

@media only screen and (max-width: 1023px) {
  .courses__item {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .courses__item {
    display: block;
    width: 100%;
    margin: 0;
  }
  .courses__item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.courses__preview {
  font-size: 0;
}

.courses__pic {
  width: 100%;
  border-radius: 20px 20px 0 0;
}

.courses__wrap {
  position: relative;
  z-index: 2;
  margin: -80px 10px 0;
  padding: 20px;
  background: #ffffff;
  border-radius: 20px;
}

.courses__info {
  margin-bottom: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 18px;
  line-height: 1.55556;
  font-weight: 600;
  color: #171725;
  transition: color .25s;
}

.courses__item:hover .courses__info {
  color: #1E75FF;
}

.courses__details {
  margin-bottom: 30px;
  line-height: 1.15;
}

@media only screen and (max-width: 767px) {
  .courses__details {
    margin-bottom: 20px;
  }
}

.courses__desc {
  font-weight: 500;
  color: #44444F;
}

.courses__category {
  margin-left: 5px;
  color: #92929D;
}

.courses__category:before {
  content: "";
  display: inline-block;
  position: relative;
  top: -2px;
  width: 4px;
  height: 4px;
  margin-right: 4px;
  border-radius: 50%;
  background: #D8D8D8;
}

.courses__line {
  display: flex;
}

.courses__box {
  display: flex;
  align-items: center;
  color: #92929D;
}

.courses__box .icon {
  margin-right: 5px;
  font-size: 16px;
}

.courses__box .icon-file {
  fill: #3DD598;
}

.courses__box .icon-clock {
  fill: #FFC542;
}

.courses__box:not(:last-child) {
  margin-right: 20px;
}

.courses .switch {
  margin: 0 20px 20px;
}

@media only screen and (max-width: 1023px) {
  .courses .switch {
    display: none;
  }
}

body.dark .courses__head {
  background: #1C1C24;
}

body.dark .courses__title {
  color: #FAFAFB;
}

body.dark .courses__search .courses__input {
  background: #44444F;
  color: #FAFAFB;
}

body.dark .courses__search .courses__input::placeholder {
  color: #FAFAFB;
}

body.dark .courses__link {
  color: #D5D5DC;
}

body.dark .courses__link:hover, body.dark .courses__link.active {
  color: #FFC542;
}

body.dark .courses .sorting__filters {
  border-color: #44444F;
  background: #44444F;
}

body.dark .courses .sorting__view {
  border-color: #44444F;
}

body.dark .courses .sorting__box {
  background: #44444F;
}

body.dark .courses .sorting__box:hover {
  background: #50505d;
}

body.dark .courses .sorting__box.active {
  background: #696974;
}

body.dark .courses .sorting__box.active .icon {
  fill: #ffffff;
}

body.dark .courses .dropdown__head {
  border-color: #44444F;
  background: #44444F;
}

body.dark .courses .dropdown__head:before {
  background: #696974;
}

body.dark .courses__wrap {
  background: #1C1C24;
}

body.dark .courses__info {
  color: #FAFAFB;
}

body.dark .courses__desc {
  color: #E2E2EA;
}

.courses1 {
  position: fixed;
  top: 70px;
  left: 70px;
  bottom: 0;
  z-index: 5;
  width: 330px;
  padding-top: 160px;
  background: #ffffff;
  transition: width .25s, transform .25s;
}

@media only screen and (max-width: 1365px) {
  .courses1 {
    transform: translateX(-100%);
    padding-top: 140px;
  }
}

@media only screen and (max-width: 1023px) {
  .courses1 {
    left: 0;
    z-index: 15;
  }
}

@media only screen and (max-width: 767px) {
  .courses1 {
    width: 100%;
    padding-top: 135px;
  }
}

.courses1__wrapper {
  max-height: 100%;
  padding: 30px 20px 20px;
  overflow: auto;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 1365px) {
  .courses1__wrapper {
    padding: 20px;
  }
}

.courses1__wrapper::-webkit-scrollbar {
  display: none;
}

.courses1__head {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 40px 20px 0;
}

@media only screen and (max-width: 1439px) {
  .courses1__head {
    padding-top: 20px;
  }
}

.courses1__top {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .courses1__top {
    margin-bottom: 25px;
  }
}

.courses1__title {
  margin-right: auto;
}

.courses1__back {
  font-size: 0;
}

.courses1__back .icon {
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.courses1__back:hover .icon {
  fill: #B5B5BE;
}

.courses1__search {
  position: relative;
}

.courses1__input {
  width: 100%;
  height: 38px;
  padding: 0 15px 0 43px;
  background: #FAFAFB;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #171725;
}

.courses1__input::placeholder {
  color: #92929D;
}

.courses1__open {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 43px;
  padding-left: 3px;
  font-size: 0;
}

.courses1__open .icon {
  font-size: 20px;
  fill: #92929D;
  transition: fill .25s;
}

.courses1__open:hover .icon {
  fill: #B5B5BE;
}

.courses1__group:not(:last-child) {
  margin-bottom: 30px;
}

.courses1__category {
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .5px;
  text-transform: uppercase;
  color: #92929D;
}

.courses1__item {
  display: flex;
}

.courses1__item:not(:last-child) {
  margin-bottom: 30px;
}

.courses1__preview {
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  font-size: 0;
}

.courses1__preview .courses1__pic {
  width: 100%;
  border-radius: 20px;
}

.courses1__details {
  flex-grow: 1;
  padding-left: 15px;
}

.courses1__info {
  margin-bottom: 9px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  color: #696974;
  transition: color .25s;
}

.courses1__item:hover .courses1__info {
  color: #171725;
}

.courses1__indicators {
  display: flex;
  align-items: center;
}

.courses1__box {
  display: flex;
  align-items: center;
  line-height: 1.15;
  color: #92929D;
}

.courses1__box:not(:last-child) {
  margin-right: 20px;
}

.courses1__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  margin-right: 5px;
  border-radius: 5px;
  border: 1px solid #FFC542;
}

.courses1__icon .icon {
  font-size: 7px;
  fill: #92929D;
}

.courses1__icon_blue {
  border-color: #50B5FF;
}

.courses1__lines {
  position: relative;
  top: -2px;
  display: flex;
  align-items: flex-end;
  margin-right: 5px;
}

.courses1__lines span {
  display: block;
  width: 3px;
  border-radius: 1px;
}

.courses1__lines span:first-child, .courses1__lines span:nth-child(3) {
  height: 8px;
}

.courses1__lines span:nth-child(2) {
  height: 10px;
}

.courses1__lines span:nth-child(4) {
  height: 13px;
}

.courses1__lines span:nth-child(5) {
  height: 15px;
}

.courses1__lines span:not(:last-child) {
  margin-right: 3px;
}

body.dark .courses1 {
  background: #1C1C24;
}

body.dark .courses1__title {
  color: #FAFAFB;
}

body.dark .courses1__item:hover .courses1__info {
  color: #50B5FF;
}

body.dark .courses1__back .icon {
  fill: #B5B5BE;
}

body.dark .courses1__back:hover .icon {
  fill: #FAFAFB;
}

body.dark .courses1__input {
  background: #292932;
  color: #FAFAFB;
}

body.dark .courses1__category {
  color: #FAFAFB;
}

body.dark .courses1__info {
  color: #D5D5DC;
}

body.dark .courses1__icon .icon {
  fill: #FFC542;
}

body.dark .courses1__icon_blue .icon {
  fill: #50B5FF;
}

.courses2 {
  padding: 20px;
  border-radius: 20px;
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .courses2 {
    border-radius: 0;
  }
}

.courses2__top {
  margin-bottom: 20px;
}

.courses2__top .switch1 {
  display: none;
}

@media only screen and (max-width: 767px) {
  .courses2__top {
    display: flex;
    align-items: center;
  }
  .courses2__top .switch1 {
    position: relative;
    bottom: -2px;
    display: inline-block;
  }
}

.courses2__title {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .courses2__title {
    margin-right: auto;
    text-align: left;
  }
}

.courses2__item {
  display: block;
}

@media only screen and (max-width: 767px) {
  .courses2__item {
    display: flex;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
  }
}

.courses2__item:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .courses2__item:not(:last-child) {
    margin-bottom: 10px;
  }
}

.courses2__preview {
  position: relative;
  z-index: 2;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .courses2__preview {
    flex-shrink: 0;
    width: 80px;
  }
}

.courses2__pic {
  width: 100%;
  border-radius: 20px;
}

@media only screen and (max-width: 767px) {
  .courses2__pic {
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

.courses2__body {
  margin-top: -40px;
  padding: 55px 20px 20px;
  border: 1px solid #F1F1F5;
  border-radius: 20px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .courses2__body {
    flex-grow: 1;
    margin: 0;
    padding: 5px 10px;
    text-align: left;
    border-radius: 0;
    border: none;
  }
}

.courses2__info {
  margin-bottom: 7px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  color: #696974;
  transition: color .25s;
}

.courses2__item:hover .courses2__info {
  color: #171725;
}

.courses2__details {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .courses2__details {
    justify-content: flex-start;
  }
}

.courses2__view {
  display: flex;
  align-items: center;
}

.courses2__view .icon {
  margin-right: 5px;
  font-size: 16px;
  fill: #92929D;
}

.courses2__view:not(:first-child) {
  position: relative;
  margin-left: 5px;
  padding-left: 8px;
}

.courses2__view:not(:first-child):before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #D8D8D8;
}

.courses2:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .courses2:not(:last-child) {
    margin-bottom: 10px;
  }
}

body.dark .courses2 {
  background: #1C1C24;
}

body.dark .courses2__title {
  color: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  body.dark .courses2__item {
    border-color: #292932;
    background: #292932;
    border-radius: 10px;
  }
}

body.dark .courses2__item:hover .courses2__info {
  color: #50B5FF;
}

body.dark .courses2__body {
  border-color: #292932;
  background: #292932;
}

@media only screen and (max-width: 767px) {
  body.dark .courses2__body {
    background: none;
    border: none;
  }
}

body.dark .courses2__info {
  color: #D5D5DC;
}

.student {
  max-width: 1390px;
  margin: 0 auto;
  padding: 60px 30px;
}

@media only screen and (max-width: 1365px) {
  .student {
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 1199px) {
  .student {
    padding: 30px 20px;
  }
}

.student__head {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .student__head {
    margin-bottom: 25px;
    text-align: center;
  }
}

.student__head .student__title {
  margin-bottom: 10px;
}

.student__head .student__text {
  font-size: 16px;
  line-height: 1.625;
  color: #92929D;
}

.student__head .student__link {
  border-bottom: 1px solid transparent;
  font-weight: 500;
  color: #50B5FF;
  transition: border-color .25s;
}

.student__head .student__link:hover {
  border-color: #50B5FF;
}

.student__control {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1365px) {
  .student__control {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .student__control {
    margin-bottom: 30px;
  }
}

.student__search {
  position: relative;
  width: 250px;
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .student__search {
    padding-right: 15px;
    flex-grow: 1;
    width: auto;
  }
}

.student__input {
  width: 100%;
  height: 26px;
  background: none;
  border: none;
  padding: 0 15px 0 40px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #171725;
}

.student__input::placeholder {
  color: #B5B5BE;
}

.student__open {
  position: absolute;
  top: 55%;
  left: 3px;
  transform: translateY(-50%);
  font-size: 0;
}

.student__open .icon {
  font-size: 20px;
  fill: #92929D;
  transition: fill .25s;
}

.student__open:hover .icon {
  fill: #B5B5BE;
}

.student .sorting {
  margin-left: auto;
  margin-bottom: 0;
}

@media only screen and (max-width: 1439px) {
  .student .sorting {
    margin-bottom: 0;
  }
}

.student .sorting__filters {
  margin: 0 20px 0 0;
  border: 1px solid #F1F1F5;
}

@media only screen and (max-width: 767px) {
  .student .sorting__filters {
    width: 38px;
    height: 38px;
    margin: 0;
    font-size: 0;
  }
  .student .sorting__filters .icon-filters {
    display: inline-block;
  }
}

@media only screen and (max-width: 767px) {
  .student .dropdown {
    display: none;
  }
}

.student .dropdown__head {
  box-shadow: none;
  border: 1px solid #F1F1F5;
}

.student .sorting__btn {
  min-width: 167px;
  margin-left: 20px;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .student .sorting__btn {
    min-width: 38px;
    margin-left: 15px;
    padding: 0;
    font-size: 0;
  }
  .student .sorting__btn .icon {
    margin: 0;
  }
}

.student__list {
  display: flex;
  flex-wrap: wrap;
  margin: -30px -15px 0;
}

@media only screen and (max-width: 1365px) {
  .student__list {
    margin: -20px -10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .student__list {
    display: block;
    margin: 0;
  }
}

.student__item {
  flex: 0 0 calc(25% - 30px);
  width: calc(25% - 30px);
  margin: 30px 15px 0;
  padding: 30px;
  border-radius: 20px;
  background: #ffffff;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 1365px) {
  .student__item {
    flex: 0 0 calc(25% - 20px);
    width: calc(25% - 20px);
    margin: 20px 10px 0;
    padding: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .student__item {
    flex: 0 0 calc(33.333% - 20px);
    width: calc(33.333% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .student__item {
    display: block;
    width: 100%;
    margin: 0;
  }
  .student__item:not(:first-child) {
    margin-top: 15px;
  }
}

.student__ava {
  width: 84px;
  height: 84px;
  margin: 0 auto 15px;
  font-size: 0;
}

.student__ava .student__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.student__item .student__title {
  margin-bottom: 7px;
  transition: color .25s;
}

.student__item:hover .student__title {
  color: #1E75FF;
}

.student__details {
  margin-bottom: 20px;
}

.student__details .student__text {
  line-height: 1.15;
  color: #92929D;
}

.student__details .student__text:not(:first-child) {
  position: relative;
  margin-left: 10px;
  padding-left: 10px;
}

.student__details .student__text:not(:first-child):before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 16px;
  background: #E2E2EA;
}

.student__indicators {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

@media only screen and (max-width: 1365px) {
  .student__indicators {
    margin-bottom: 30px;
  }
}

.student__indicator {
  min-width: 47px;
  padding: 0 5px;
  text-align: center;
  line-height: 26px;
  border-radius: 5px;
}

.student__indicator:first-child {
  background: rgba(61, 213, 152, 0.1);
  color: #3DD598;
}

.student__indicator:nth-child(2) {
  background: rgba(255, 197, 66, 0.1);
  color: #FFC542;
}

.student__indicator:nth-child(3) {
  background: rgba(252, 90, 90, 0.1);
  color: #FC5A5A;
}

.student__indicator:not(:last-child) {
  margin-right: 20px;
}

.student__quality {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: -20px -10px 0;
}

@media only screen and (max-width: 1023px) {
  .student__quality {
    margin-top: -15px;
  }
}

.student__quality .student__icon {
  margin: 20px 10px 0;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .student__quality .student__icon {
    margin: 15px 5px 0;
  }
}

@media only screen and (max-width: 767px) {
  .student__quality .student__icon {
    margin: 15px 10px 0;
  }
}

.student__options {
  display: flex;
  justify-content: center;
  margin: 0 -20px 25px -5px;
}

.student__option {
  margin: 0 18px;
}

@media only screen and (max-width: 1365px) {
  .student__option {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .student__option {
    margin: 0 18px;
  }
}

.student__category {
  margin-bottom: 5px;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1.15;
  color: #D5D5DC;
}

.student__counter {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
}

.student__btn {
  min-width: 100%;
  height: 40px;
  margin-top: 30px;
}

.student__list_2 .student__details {
  margin-bottom: 25px;
}

.student__list_2 .student__indicators,
.student__list_3 .student__indicators {
  display: none;
}

@media only screen and (max-width: 767px) {
  .student__list_2 .student__indicators,
  .student__list_3 .student__indicators {
    display: flex;
    margin: 20px 0 25px;
  }
}

.student__list_3 .student__item {
  margin-top: 80px;
  padding-top: 0;
}

@media only screen and (max-width: 1365px) {
  .student__list_3 .student__item {
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .student__list_3 .student__item {
    display: inline-block;
    margin-top: 65px;
  }
  .student__list_3 .student__item:first-child {
    margin-top: 40px;
  }
}

.student__list_3 .student__ava {
  margin-top: -50px;
}

@media only screen and (max-width: 767px) {
  .student__list_3 .student__ava {
    margin-top: -42px;
  }
}

body.dark .student__title {
  color: #FAFAFB;
}

body.dark .student__head .student__text {
  color: #B5B5BE;
}

body.dark .student__search .student__input {
  color: #FAFAFB;
}

body.dark .student__search .student__input::placeholder {
  color: #696974;
}

body.dark .student .sorting__filters {
  border-color: #44444F;
  background: #44444F;
}

body.dark .student .dropdown__head {
  border-color: #44444F;
  background: #44444F;
}

body.dark .student .dropdown__head:before {
  background: #696974;
}

body.dark .student__item {
  background: #1C1C24;
}

.player__video {
  position: relative;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .player__video {
    border-radius: 0;
  }
}

.player__video > .player__pic {
  width: 100%;
}

.player__top, .player__bottom {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  height: 100px;
}

.player__top {
  top: 0;
  padding: 20px;
  background: linear-gradient(0deg, rgba(122, 122, 135, 0.0001) 0%, rgba(68, 68, 79, 0.3) 118.87%, rgba(23, 23, 37, 0.8) 257.4%);
}

@media only screen and (max-width: 767px) {
  .player__top {
    height: 70px;
    padding: 15px;
    background: linear-gradient(0deg, rgba(122, 122, 135, 0.0001) 0%, rgba(68, 68, 79, 0.3) 138.12%, rgba(23, 23, 37, 0.8) 282.72%);
  }
}

.player__bottom {
  bottom: 0;
  align-items: flex-end;
  padding: 20px 30px;
  background: linear-gradient(180deg, rgba(122, 122, 135, 0.0001) 0%, rgba(68, 68, 79, 0.3) 45.88%, rgba(23, 23, 37, 0.8) 100%);
}

@media only screen and (max-width: 1439px) {
  .player__bottom {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .player__bottom {
    height: 50px;
    background: linear-gradient(180deg, rgba(122, 122, 135, 0.0001) 0%, rgba(68, 68, 79, 0.3) 110.85%, rgba(23, 23, 37, 0.8) 260.65%);
  }
}

.player__view {
  display: flex;
  align-items: center;
  height: 36px;
  margin-right: auto;
  padding: 0 10px;
  background: #82C43C;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .player__view {
    height: 26px;
    font-size: 12px;
  }
}

.player__view .icon {
  margin-right: 5px;
  font-size: 16px;
  fill: #ffffff;
}

@media only screen and (max-width: 767px) {
  .player__view .icon {
    font-size: 12px;
  }
}

.player__zoom {
  width: 36px;
  height: 36px;
  background: url("../img/zoom.svg") no-repeat 0 0/100% auto;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .player__zoom {
    width: 26px;
    height: 26px;
    background-image: url("../img/zoom-black.svg");
  }
}

.player__left {
  display: flex;
  align-items: center;
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .player__left {
    display: none;
  }
}

.player__actions {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .player__actions {
    display: none;
  }
}

.player__action {
  font-size: 0;
}

.player__action .icon {
  font-size: 24px;
  fill: #ffffff;
}

.player__action:not(:last-child) {
  margin-right: 20px;
}

.player__action, .player__zoom {
  transition: opacity .25s;
}

.player__action:hover, .player__zoom:hover {
  opacity: .7;
}

.player__time {
  margin-left: 20px;
  font-size: 14px;
  line-height: 1.71429;
  font-weight: 500;
  color: #ffffff;
}

.player__scale {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  height: 5px;
  background: rgba(255, 255, 255, 0.56);
}

@media only screen and (max-width: 767px) {
  .player__scale {
    display: block;
  }
}

.player__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #FFC542;
}

.player__container {
  position: relative;
  padding: 30px;
  border-radius: 0 0 20px 20px;
  background: #ffffff;
}

@media only screen and (max-width: 1599px) {
  .player__container {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .player__container {
    border-radius: 0;
  }
}

.player__details {
  margin-bottom: 40px;
  padding-right: 100px;
}

@media only screen and (max-width: 1599px) {
  .player__details {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .player__details {
    margin-bottom: 20px;
    padding: 0 0 15px;
    border-bottom: 1px solid #F1F1F5;
  }
}

@media only screen and (max-width: 767px) {
  .player__details_show {
    display: none;
  }
}

.player__details_hide {
  display: none;
}

@media only screen and (max-width: 767px) {
  .player__details_hide {
    display: block;
    margin: 0;
    padding: 20px;
    border: none;
  }
}

.player__title {
  margin-bottom: 12px;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 1599px) {
  .player__title {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .player__title {
    margin-bottom: 9px;
    font-size: 18px;
  }
}

.player__desc {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .player__desc {
    font-size: 14px;
  }
}

.player__desc li:not(:last-child) {
  position: relative;
  margin-right: 10px;
  padding-right: 10px;
}

.player__desc li:not(:last-child):before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 16px;
  background: #E2E2EA;
}

.player__user {
  font-weight: 500;
  color: #696974;
}

.player__category {
  color: #92929D;
}

.player__follow {
  color: #50B5FF;
  transition: color .25s;
}

.player__follow:hover {
  color: #1E75FF;
}

@media only screen and (max-width: 767px) {
  .player__follow span {
    display: none;
  }
}

.player__settings {
  position: absolute;
  top: 45px;
  right: 30px;
  display: flex;
}

@media only screen and (max-width: 1599px) {
  .player__settings {
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .player__settings {
    top: 0;
    right: 0;
  }
}

.player__settings .player__link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border: 1px solid #E2E2EA;
  border-radius: 10px;
  background: #F1F1F5;
  font-size: 0;
  transition: background .25s;
}

@media only screen and (max-width: 767px) {
  .player__settings .player__link {
    width: 40px;
    height: 40px;
    border-radius: 0;
  }
}

.player__settings .player__link .icon {
  font-size: 20px;
  fill: #92929D;
}

.player__settings .player__link:hover {
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .player__settings .player__link:first-child {
    border-radius: 10px 0 0 10px;
  }
}

.player__settings .player__link:not(:last-child) {
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .player__settings .player__link:not(:last-child) {
    margin: 0;
  }
}

.player__control {
  margin: 0 -30px 30px;
  padding: 0 30px;
  border-bottom: 1px solid #F1F1F5;
}

@media only screen and (max-width: 1599px) {
  .player__control {
    margin: 0 -20px 25px;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .player__control {
    position: relative;
    padding-right: 100px;
  }
}

.player__nav {
  display: flex;
  align-items: center;
  margin-bottom: -1px;
}

@media only screen and (max-width: 767px) {
  .player__nav {
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    margin-left: -20px;
  }
  .player__nav::-webkit-scrollbar {
    display: none;
  }
}

.player__nav .player__link {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 10px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .05px;
  line-height: 1.1;
  color: #92929D;
  transition: color .25s;
}

@media only screen and (max-width: 767px) {
  .player__nav .player__link {
    height: 40px;
    white-space: nowrap;
  }
}

.player__nav .player__link:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  margin: 0 auto;
  background: #44444F;
  transition: width .25s;
}

.player__nav .player__link:hover, .player__nav .player__link.active {
  color: #44444F;
}

.player__nav .player__link.active:before {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .player__nav .player__link:first-child {
    margin-left: 10px;
  }
}

.player__nav .player__link:not(:last-child) {
  margin-right: 15px;
}

.player__counter {
  min-width: 20px;
  margin-left: 17px;
  padding: 0 4px;
  border-radius: 12px;
  background: #50B5FF;
  text-align: center;
  line-height: 18px;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .player__list {
    margin: 0 -10px;
  }
}

.player__item {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #F1F1F5;
  background: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  .player__item {
    border: none;
    padding: 15px;
  }
}

.player__item:not(:last-child) {
  margin-bottom: 20px;
}

.player__head {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  color: #44444F;
}

@media only screen and (max-width: 767px) {
  .player__head {
    display: flex;
    font-size: 16px;
  }
}

.player__arrow {
  display: none;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-left: 30px;
  border-radius: 7px;
  border: 1px solid #F1F1F5;
  background: #ffffff;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .player__arrow {
    display: flex;
  }
}

.player__arrow .icon {
  font-size: 5px;
  fill: #44444F;
  transition: transform .25s;
}

@media only screen and (max-width: 767px) {
  .player__item.active .player__arrow .icon {
    transform: rotate(180deg);
  }
}

.player__body {
  padding-top: 12px;
}

@media only screen and (min-width: 768px) {
  .player__body {
    display: block !important;
    padding-top: 12px !important;
  }
}

@media only screen and (max-width: 767px) {
  .player__body {
    display: none;
    padding-top: 10px;
  }
}

.player__text {
  line-height: 1.71429;
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .player__text {
    font-size: 12px;
  }
}

.player__text:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .player__text:not(:last-child) {
    margin-bottom: 15px;
  }
}

.player__flex {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .player__flex {
    flex-direction: column-reverse;
    align-items: stretch;
  }
}

.player__btns {
  display: flex;
  align-items: center;
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .player__btns {
    margin: 20px 0 0;
  }
}

.player__btns .player__btn {
  min-width: auto;
  padding: 0 15px;
  font-size: 14px;
}

.player__btns .player__btn.btn_gray {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.player__btns .player__btn:not(:last-child) {
  margin-right: 15px;
}

.player__info {
  display: flex;
  align-items: center;
}

.player__students {
  font-weight: 500;
  color: #44444F;
}

.player__date {
  color: #FC5A5A;
}

.player__date:not(:first-child) {
  position: relative;
  margin-left: 5px;
  padding-left: 8px;
}

.player__date:not(:first-child):before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #D8D8D8;
}

body.dark .player__container {
  background: #1C1C24;
}

body.dark .player__title {
  color: #FAFAFB;
}

body.dark .player__user {
  color: #D5D5DC;
}

body.dark .player__category {
  color: #B5B5BE;
}

body.dark .player__settings .player__link:hover {
  border-color: #696974;
  background: transparent;
}

@media only screen and (max-width: 767px) {
  body.dark .player__settings .player__link:hover {
    border-color: #292932;
    background: #292932;
  }
}

body.dark .player__control {
  border-color: #292932;
}

body.dark .player__nav .player__link {
  color: #696974;
}

body.dark .player__nav .player__link:before {
  background: #FAFAFB;
}

body.dark .player__nav .player__link:hover, body.dark .player__nav .player__link.active {
  color: #FAFAFB;
}

body.dark .player__item {
  background: #292932;
  border-color: #292932;
}

body.dark .player__head {
  color: #FAFAFB;
}

body.dark .player__arrow {
  background: #44444F;
  border-color: #44444F;
}

body.dark .player__arrow .icon {
  fill: #B5B5BE;
}

body.dark .player__text {
  color: #B5B5BE;
}

body.dark .player__students {
  color: #FAFAFB;
}

.modul {
  padding: 20px;
  background: #ffffff;
  border-radius: 20px;
}

@media only screen and (max-width: 767px) {
  .modul {
    border-radius: 0;
  }
}

.modul__nav {
  display: flex;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1439px) {
  .modul__nav {
    margin-bottom: 20px;
  }
}

.modul__link {
  position: relative;
  padding: 0 10px 12px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  letter-spacing: .5px;
  font-weight: 500;
  color: #92929D;
  transition: color .25s;
}

.modul__link:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  margin: 0 auto;
  background: #44444F;
  transition: width .25s;
}

.modul__link:hover, .modul__link.active {
  color: #44444F;
}

.modul__link.active:before {
  width: 100%;
}

.modul__link:not(:last-child) {
  margin-right: 10px;
}

.modul__item {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 45px;
}

.modul__item:not(:last-child) {
  margin-bottom: 20px;
}

.modul__number {
  flex-shrink: 0;
  width: 30px;
  border-radius: 8px;
  background: #50B5FF;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  line-height: 30px;
  font-weight: 600;
  color: #ffffff;
}

.modul__text {
  flex: 0 0 calc(100% - 30px);
  width: calc(100% - 30px);
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
  color: #696974;
  transition: color .25s;
}

.modul__item:hover .modul__text {
  color: #171725;
}

.modul__time {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  color: #92929D;
}

.modul:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .modul:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .modul_show {
    display: none;
  }
}

.modul_hide {
  display: none;
}

@media only screen and (max-width: 767px) {
  .modul_hide {
    display: block;
  }
  .modul_hide:not(:last-child) {
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .modul_hide .modul__nav {
    display: none;
  }
}

body.dark .modul {
  background: #1C1C24;
}

body.dark .modul__link {
  color: #696974;
}

body.dark .modul__link:before {
  background: #FAFAFB;
}

body.dark .modul__link:hover, body.dark .modul__link.active {
  color: #FAFAFB;
}

body.dark .modul__text {
  color: #D5D5DC;
}

body.dark .modul__item:hover .modul__text {
  color: #50B5FF;
}

.knowledge {
  width: 100%;
  padding: 50px 0;
}

@media only screen and (max-width: 1365px) {
  .knowledge {
    padding: 40px 0;
  }
}

@media only screen and (max-width: 767px) {
  .knowledge {
    padding: 30px 0;
  }
}

.knowledge__center {
  position: relative;
  z-index: 2;
  max-width: 1198px;
  margin: 0 auto;
  padding: 0 30px;
}

@media only screen and (max-width: 767px) {
  .knowledge__center {
    padding: 0 20px;
  }
}

.knowledge__question {
  margin-bottom: 32px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 36px;
  line-height: 1.5;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .knowledge__question {
    margin-bottom: 15px;
    font-size: 22px;
  }
}

.knowledge__filters {
  position: relative;
  max-width: 604px;
  margin: 0 auto 20px;
}

.knowledge__input {
  width: 100%;
  height: 50px;
  padding: 0 60px 0 138px;
  border-radius: 15px;
  background: #FAFAFB;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .knowledge__input {
    height: 42px;
    padding: 0 55px 0 125px;
  }
}

.knowledge__input::placeholder {
  color: #92929D;
}

.knowledge__select {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 113px;
  height: 30px;
  padding: 0 22px 0 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 7px;
  background: #F1F1F5 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' fill='none'%3E%3Cpath fill-rule='evenodd' d='M4 3.057L6.862.195c.26-.26.682-.26.943 0s.26.682 0 .943L4.47 4.47c-.26.26-.682.26-.943 0L.195 1.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0L4 3.057z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat calc(100% - 12px) 55%/8px 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #92929D;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .knowledge__select {
    left: 5px;
  }
}

.knowledge__select::-ms-expand {
  display: none;
}

.knowledge__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.knowledge__open {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  width: 44px;
  height: 40px;
  border-radius: 10px;
  background: #0062FF;
  font-size: 0;
  transition: background .25s;
}

@media only screen and (max-width: 767px) {
  .knowledge__open {
    width: 32px;
    height: 32px;
  }
}

.knowledge__open .icon {
  font-size: 20px;
  fill: #ffffff;
  transition: fill .25s;
}

@media only screen and (max-width: 767px) {
  .knowledge__open .icon {
    font-size: 16px;
  }
}

.knowledge__open:hover {
  background: #50B5FF;
}

.knowledge__indicators {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .knowledge__indicators {
    margin-bottom: 30px;
  }
}

.knowledge__indicators .knowledge__text {
  margin-right: 10px;
  line-height: 26px;
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .knowledge__indicators .knowledge__text {
    display: none;
  }
}

.knowledge__tags {
  display: flex;
  flex-wrap: wrap;
  margin: -10px 0 0 -10px;
}

@media only screen and (max-width: 767px) {
  .knowledge__tags {
    justify-content: center;
    margin: -10px -5px 0;
  }
}

.knowledge__tag {
  margin: 10px 0 0 10px;
  padding: 1px 14px 0;
  background: rgba(0, 98, 255, 0.1);
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 10px;
  line-height: 25px;
  font-weight: 500;
  color: #0062FF;
}

@media only screen and (max-width: 767px) {
  .knowledge__tag {
    margin: 10px 5px 0;
    line-height: 27px;
  }
}

.knowledge__list {
  display: flex;
  flex-wrap: wrap;
  margin: -30px -15px 0;
}

@media only screen and (max-width: 1365px) {
  .knowledge__list {
    margin: -20px -10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .knowledge__list {
    display: block;
    margin: 0;
  }
}

.knowledge__item {
  position: relative;
  flex: 0 0 calc(25% - 30px);
  width: calc(25% - 30px);
  min-height: 302px;
  margin: 30px 15px 0;
  padding: 25px 16px 20px;
  border-radius: 20px;
  border: 1px solid #E2E2EA;
  background: #ffffff;
  transition: border-color .25s;
}

@media only screen and (max-width: 1365px) {
  .knowledge__item {
    flex: 0 0 calc(25% - 20px);
    width: calc(25% - 20px);
    margin: 20px 10px 0;
  }
}

@media only screen and (max-width: 1199px) {
  .knowledge__item {
    flex: 0 0 calc(33.33% - 20px);
    width: calc(33.33% - 20px);
  }
}

@media only screen and (max-width: 1023px) {
  .knowledge__item {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .knowledge__item {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .knowledge__item:not(:last-child) {
    margin-bottom: 15px;
  }
}

.knowledge__item_add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-color: transparent;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .knowledge__item_add {
    min-height: 100%;
    padding: 30px 30px 40px;
  }
}

.knowledge__item:hover {
  border-color: #B5B5BE;
}

.knowledge__preview {
  height: 156px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .knowledge__preview {
    margin-bottom: 15px;
  }
}

.knowledge__preview .knowledge__pic {
  max-width: 100%;
  max-height: 100%;
}

.knowledge__category {
  display: block;
  margin-bottom: 8px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
  color: #171725;
}

.knowledge__category[href="#"]:after {
  content: "";
  display: inline-block;
  position: relative;
  top: -1px;
  width: 5px;
  height: 8px;
  margin-left: 20px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='8' fill='none'%3E%3Cpath fill-rule='evenodd' d='M3.057 4L.195 1.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0L4.47 3.53c.26.26.26.682 0 .943L1.138 7.805c-.26.26-.682.26-.943 0s-.26-.682 0-.943L3.057 4z' fill='%230062ff'/%3E%3C/svg%3E") no-repeat 50% 50%/auto 100%;
  transition: transform .25s;
}

@media only screen and (max-width: 767px) {
  .knowledge__category[href="#"]:after {
    margin-left: 13px;
  }
}

.knowledge__category[href="#"]:hover:after {
  transform: translateX(2px);
}

.knowledge__item .knowledge__text {
  line-height: 1.57143;
  color: #B5B5BE;
}

.knowledge__plus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
  border-radius: 50%;
  background: rgba(255, 197, 66, 0.1);
  font-size: 0;
}

.knowledge__plus .icon {
  font-size: 36px;
  fill: #FFC542;
}

.knowledge__details {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 22px 20px;
  border-radius: 20px;
  background: #44444F;
}

@media only screen and (max-width: 767px) {
  .knowledge__details {
    text-align: left;
  }
}

.knowledge__list_white .knowledge__details {
  background: #ffffff;
}

.knowledge__details .knowledge__category {
  height: 54px;
  margin-bottom: 10px;
  overflow: hidden;
  color: #ffffff;
}

.knowledge__list_white .knowledge__category {
  color: #171725;
}

.knowledge__details .knowledge__text {
  height: 66px;
  overflow: hidden;
  margin-bottom: 30px;
  color: #92929D;
}

.knowledge__status {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.knowledge__progress {
  display: flex;
  flex-grow: 1;
  margin: 0 15px 0 -3px;
}

.knowledge__line {
  flex: 1;
  margin-left: 3px;
  height: 6px;
  background: #ffffff;
  border-radius: 3px;
  transition: background .25s;
}

.knowledge__line:first-child {
  border-radius: 3px 0 0 3px;
}

.knowledge__line:last-child {
  border-radius: 0 3px 3px 0;
}

.knowledge__line.active {
  background: #50B5FF;
}

.knowledge__percent {
  font-size: 12px;
  color: #ffffff;
}

.knowledge .users {
  margin: -5px 0 0 -5px;
}

.knowledge .users__item {
  margin: 5px 0 0 5px;
}

.knowledge__list_white .knowledge__line {
  background: #E2E2EA;
}

.knowledge__list_white .knowledge__line.active {
  background: #50B5FF;
}

.knowledge__bottom {
  display: flex;
  align-items: center;
}

.knowledge__bottom .users {
  margin: 0 auto 0 0;
}

.knowledge__bottom .users__item {
  margin: 0;
}

.knowledge__bottom .users__item_counter {
  color: #FAFAFB;
}

.knowledge__bottom .users__item:not(:first-child) {
  margin-left: -7px;
}

.knowledge__list_white .knowledge__bottom .users__item_counter {
  color: #696974;
}

.knowledge__btn {
  min-width: 100px;
  height: 28px;
  background: none;
  border-radius: 5px;
  font-size: 10px;
  color: #FAFAFB;
}

.knowledge__btn:hover {
  color: #171725;
}

.knowledge__list_white .knowledge__btn {
  background: #F1F1F5;
  border: none;
  color: #696974;
}

.knowledge__list_white .knowledge__btn:hover {
  opacity: .85;
}

.knowledge__top {
  display: flex;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .knowledge__top {
    display: block;
    margin-bottom: 30px;
  }
}

.knowledge__wrap {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 60px;
  margin-right: 20px;
  padding: 5px 74px 5px 30px;
  background: #ffffff;
  border-radius: 20px;
}

@media only screen and (max-width: 767px) {
  .knowledge__wrap {
    display: block;
    height: auto;
    margin: 0;
    padding: 15px 35px 15px 20px;
  }
}

.knowledge__wrap:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 36px;
  transform: translateY(-50%);
  width: 12px;
  height: 7px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' fill='none'%3E%3Cpath fill-rule='evenodd' d='M4 3.057L6.862.195c.26-.26.682-.26.943 0s.26.682 0 .943L4.47 4.47c-.26.26-.682.26-.943 0L.195 1.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0L4 3.057z' fill='%230062FF'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto;
}

@media only screen and (max-width: 767px) {
  .knowledge__wrap:before {
    top: 25px;
    transform: translateY(0);
    right: 20px;
  }
}

.knowledge__box {
  display: flex;
  align-items: center;
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .knowledge__box {
    margin: 0 0 15px;
  }
}

.knowledge__stage {
  margin-right: 23px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}

.knowledge__counter {
  color: #92929D;
}

.knowledge__settings {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border: 1px solid #D5D5DC;
  border-radius: 20px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .knowledge__settings {
    display: none;
  }
}

.knowledge__settings .icon {
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.knowledge__settings:hover .icon {
  fill: #B5B5BE;
}

.knowledge__accord:not(:last-child) {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .knowledge__accord:not(:last-child) {
    margin-bottom: 30px;
  }
}

.knowledge__head {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color .25s;
}

.knowledge__head:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50px;
  right: 0;
  transform: translateY(-50%);
  height: 1px;
  background: #F1F1F5;
}

.knowledge__head:hover {
  color: #92929D;
}

.knowledge__arrow {
  margin-right: 10px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .knowledge__arrow {
    margin-right: 5px;
  }
}

.knowledge__arrow .icon {
  font-size: 16px;
  fill: #D5D5DC;
  transition: transform .25s;
}

.knowledge__accord.active .knowledge__arrow .icon {
  transform: rotate(90deg);
}

.knowledge__doc {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  margin-right: auto;
  padding-right: 20px;
  background: #FAFAFB;
}

.knowledge__doc .icon {
  margin-right: 10px;
  font-size: 24px;
  fill: #92929D;
}

.knowledge__doc .knowledge__text {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
}

.knowledge__doc .knowledge__counter {
  margin-left: 5px;
}

.knowledge__actions {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background: #FAFAFB;
}

.knowledge__action {
  font-size: 0;
}

.knowledge__action .icon {
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.knowledge__action:hover .icon {
  fill: #B5B5BE;
}

.knowledge__action:not(:last-child) {
  margin-right: 10px;
}

.knowledge__body {
  display: none;
  padding-top: 40px;
}

@media only screen and (max-width: 767px) {
  .knowledge__body {
    padding-top: 30px;
  }
}

body.dark .knowledge__question {
  color: #FAFAFB;
}

body.dark .knowledge__input {
  background: #292932;
  color: #FAFAFB;
}

body.dark .knowledge__input::placeholder {
  color: #696974;
}

body.dark .knowledge__select {
  background-color: #44444F;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' fill='none'%3E%3Cpath fill-rule='evenodd' d='M4 3.057L6.862.195c.26-.26.682-.26.943 0s.26.682 0 .943L4.47 4.47c-.26.26-.682.26-.943 0L.195 1.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0L4 3.057z' fill='%23d5d5d5'/%3E%3C/svg%3E");
  color: #D5D5DC;
}

body.dark .knowledge__tag {
  background: #292932;
  color: #D5D5DC;
}

body.dark .knowledge__item {
  background: #1C1C24;
  border-color: #292932;
}

body.dark .knowledge__item:hover {
  border-color: #44444F;
}

body.dark .knowledge__item_add {
  border: 1px solid #292932;
}

body.dark .knowledge__category {
  color: #FAFAFB;
}

body.dark .knowledge__category:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='8' fill='none'%3E%3Cpath fill-rule='evenodd' d='M3.057 4L.195 1.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0L4.47 3.53c.26.26.26.682 0 .943L1.138 7.805c-.26.26-.682.26-.943 0s-.26-.682 0-.943L3.057 4z' fill='%23d5d5d5'/%3E%3C/svg%3E");
}

body.dark .knowledge__wrap {
  background: #1C1C24;
}

body.dark .knowledge__wrap:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' fill='none'%3E%3Cpath fill-rule='evenodd' d='M4 3.057L6.862.195c.26-.26.682-.26.943 0s.26.682 0 .943L4.47 4.47c-.26.26-.682.26-.943 0L.195 1.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0L4 3.057z' fill='%231e75ff'/%3E%3C/svg%3E");
}

body.dark .knowledge__stage {
  color: #FAFAFB;
}

body.dark .knowledge__settings {
  background: #1C1C24;
  border-color: #1C1C24;
}

body.dark .knowledge__head:before {
  background: #292932;
}

body.dark .knowledge__head:hover {
  color: #50B5FF;
}

body.dark .knowledge__doc, body.dark .knowledge__actions {
  background: #13131A;
}

body.dark .knowledge__arrow .icon {
  fill: #92929D;
}

body.dark .knowledge__doc .knowledge__text {
  color: #FAFAFB;
}

body.dark .knowledge__plus {
  background: #FFC542;
}

body.dark .knowledge__plus .icon {
  fill: #FAFAFB;
}

body.dark .knowledge__details {
  background: #292932;
}

body.dark .knowledge .btn_border-gray {
  border-color: #B5B5BE;
  background: transparent;
  color: #FAFAFB;
}

body.dark .knowledge .btn_border-gray:hover {
  background: #FAFAFB;
  color: #292932;
}

body.dark .knowledge__list_white .knowledge__line {
  background: #ffffff;
}

body.dark .knowledge__list_white .knowledge__line.active {
  background: #50B5FF;
}

body.dark .knowledge__list_white .knowledge__bottom .users__item_counter {
  color: #FAFAFB;
}

body.dark .knowledge__list_white .knowledge__btn {
  border: 1px solid #B5B5BE;
  background: transparent;
  color: #FAFAFB;
}

body.dark .knowledge__list_white .knowledge__btn:hover {
  background: #FAFAFB;
  color: #292932;
}

body.dark .knowledge__list_white .knowledge__details {
  background: #3D3D49;
}

.forums__row {
  position: relative;
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .forums__row {
    flex-direction: column-reverse;
  }
}

.forums__col:first-child {
  flex-grow: 1;
  padding-right: 40px;
}

@media only screen and (max-width: 1365px) {
  .forums__col:first-child {
    padding-right: 25px;
  }
}

@media only screen and (max-width: 1023px) {
  .forums__col:first-child {
    padding: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .forums__col:first-child .forums__title.title_big {
    display: none;
  }
}

.forums__col:nth-child(2) {
  flex-shrink: 0;
  width: 263px;
}

@media only screen and (max-width: 1365px) {
  .forums__col:nth-child(2) {
    width: 240px;
  }
}

@media only screen and (max-width: 1023px) {
  .forums__col:nth-child(2) {
    width: 100%;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .forums__col:nth-child(2) {
    margin-bottom: 30px;
  }
}

.forums__col:nth-child(2) .forums__title.title_big {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .forums__col:nth-child(2) .forums__title.title_big {
    display: block;
  }
}

.forums__col > .forums__title {
  margin-bottom: 34px;
  padding-right: 70px;
}

@media only screen and (max-width: 1023px) {
  .forums__col > .forums__title {
    margin-bottom: 25px;
    padding: 0;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .forums__col > .forums__title {
    margin-bottom: 20px;
  }
}

.forums__item {
  display: flex;
  align-items: center;
  padding: 15px 30px 15px 15px;
  background: #ffffff;
  border-radius: 20px;
}

@media only screen and (max-width: 1365px) {
  .forums__item {
    padding: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .forums__item {
    display: block;
    padding: 0 20px 25px;
  }
}

.forums__item:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1023px) {
  .forums__item:not(:last-child) {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .forums__item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.forums__preview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 230px;
  height: 170px;
  margin-right: 25px;
  padding: 20px 15px;
  border-radius: 20px;
  border: 1px solid #F1F1F5;
  font-size: 0;
}

@media only screen and (max-width: 1365px) {
  .forums__preview {
    width: 200px;
    margin-right: 15px;
    padding: 15px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .forums__preview {
    width: 100%;
    height: 175px;
    margin: 0 0 15px;
    padding: 20px;
    border: none;
    border-radius: 0;
  }
}

.forums__pic {
  max-width: 100%;
  max-height: 100%;
}

.forums__details {
  flex-grow: 1;
}

.forums__info {
  margin-bottom: 7px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
  color: #171725;
  transition: color .25s;
}

.forums__item:hover .forums__info {
  color: #1E75FF;
}

.forums__item .forums__text {
  margin-bottom: 24px;
  line-height: 1.71429;
  color: #92929D;
}

@media only screen and (max-width: 1365px) {
  .forums__item .forums__text {
    margin-bottom: 15px;
  }
}

.forums__line {
  display: flex;
  align-items: center;
}

.forums__user {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.forums__user .ava {
  width: 36px;
  height: 36px;
  margin-right: 15px;
  font-size: 16px;
}

.forums__man {
  margin-bottom: 4px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 600;
  color: #0062FF;
}

.forums__counter {
  display: flex;
  font-size: 12px;
  line-height: 1.15;
  color: #92929D;
}

.forums__counter li:not(:first-child) {
  margin-left: 5px;
}

@media only screen and (max-width: 767px) {
  .forums__counter li:not(:first-child) {
    display: none;
  }
}

.forums__counter li:not(:first-child):before {
  content: "";
  display: inline-block;
  position: relative;
  top: -2px;
  width: 3px;
  height: 3px;
  margin-right: 5px;
  background: #D8D8D8;
}

.forums__actions {
  display: flex;
  align-items: center;
}

.forums__action {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 10px;
  border: 1px solid #E2E2EA;
  font-size: 0;
}

.forums__action .icon {
  font-size: 20px;
  fill: #92929D;
  transition: fill .25s;
}

.forums__action:hover .icon {
  fill: #B5B5BE;
}

.forums__action:not(:last-child) {
  margin-right: 15px;
}

.forums__filters {
  position: absolute;
  top: 0;
  right: 303px;
  width: 46px;
  height: 46px;
  border-radius: 10px;
  background: #ffffff;
  font-size: 0;
}

@media only screen and (max-width: 1365px) {
  .forums__filters {
    right: 265px;
  }
}

@media only screen and (max-width: 1023px) {
  .forums__filters {
    position: static;
    flex-shrink: 0;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .forums__filters {
    height: 42px;
  }
}

.forums__filters .icon {
  font-size: 18px;
  fill: #92929D;
  transition: fill .25s;
}

.forums__filters:hover .icon {
  fill: #B5B5BE;
}

.forums__top {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1023px) {
  .forums__top {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .forums__top {
    margin-bottom: 25px;
  }
}

.forums__search {
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .forums__search {
    flex-grow: 1;
  }
}

.forums__input {
  width: 100%;
  height: 46px;
  padding: 0 45px 0 13px;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .forums__input {
    height: 42px;
  }
}

.forums__input::placeholder {
  color: #92929D;
}

.forums__open {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 38px;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .forums__open {
    width: 45px;
  }
}

.forums__open .icon {
  font-size: 20px;
  fill: #92929D;
  transition: fill .25s;
}

.forums__open:hover .icon {
  fill: #B5B5BE;
}

.forums__accord {
  margin-bottom: 40px;
  padding: 30px 20px;
  border-radius: 20px;
  background: #0062FF;
}

@media only screen and (max-width: 1023px) {
  .forums__accord {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .forums__accord {
    margin-bottom: 30px;
    padding: 15px 20px;
  }
}

.forums__head {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
  color: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  .forums__head {
    position: relative;
    padding-right: 30px;
  }
  .forums__head:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 12px;
    height: 7px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M6 4.586L10.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5A1 1 0 1 1 1.707.293L6 4.586z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50%/auto 100%;
    transition: transform .25s;
  }
}

@media only screen and (max-width: 767px) {
  .forums__accord.active .forums__head:before {
    transform: translateY(-50%) rotate(180deg);
  }
}

.forums__body {
  padding-top: 12px;
}

@media only screen and (min-width: 768px) {
  .forums__body {
    display: block !important;
    height: 100% !important;
    padding-top: 12px !important;
  }
}

@media only screen and (max-width: 767px) {
  .forums__body {
    display: none;
  }
}

.forums__accord .forums__text {
  margin-bottom: 25px;
  line-height: 1.71429;
  color: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  .forums__accord .forums__text {
    margin-bottom: 20px;
  }
}

.forums__btn {
  min-width: 100%;
}

.forums__flex {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
}

.forums__popular .forums__title {
  padding-left: 10px;
}

@media only screen and (max-width: 767px) {
  .forums__popular .forums__title {
    padding: 0;
  }
}

.forums__view {
  display: none;
}

@media only screen and (max-width: 767px) {
  .forums__view {
    display: inline-block;
    margin-left: auto;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .8px;
    color: #0062FF;
  }
}

@media only screen and (max-width: 1023px) {
  .forums__links {
    display: flex;
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    margin: 0 -25px;
  }
  .forums__links::-webkit-scrollbar {
    display: none;
  }
  .forums__links:before, .forums__links:after {
    content: "";
    flex-shrink: 0;
    width: 25px;
    height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .forums__links {
    margin: 0 -20px;
  }
  .forums__links:before, .forums__links:after {
    width: 20px;
    height: 50px;
  }
}

.forums__link {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #D5D5DC;
  white-space: nowrap;
  font-size: 16px;
  color: #171725;
  transition: border-color .25s;
}

@media only screen and (max-width: 767px) {
  .forums__link {
    padding: 5px 15px 5px 10px;
    border-radius: 12px;
  }
}

.forums__link:hover {
  border-color: #50B5FF;
}

.forums__link:not(:last-child) {
  margin-bottom: 15px;
}

@media only screen and (max-width: 1023px) {
  .forums__link:not(:last-child) {
    margin: 0 10px 0 0;
  }
}

.forums__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  margin-right: 15px;
  border-radius: 12px;
  background: rgba(0, 98, 255, 0.1);
}

.forums__icon .icon {
  font-size: 24px;
  fill: #50B5FF;
}

body.dark .forums__title {
  color: #FAFAFB;
}

body.dark .forums__filters {
  background: #1C1C24;
}

body.dark .forums__filters:hover {
  background: #292932;
}

body.dark .forums__filters:hover .icon {
  fill: #FAFAFB;
}

body.dark .forums__item {
  background: #1C1C24;
  border: 1px solid #292932;
}

body.dark .forums__preview {
  border-color: #292932;
}

body.dark .forums__info {
  color: #FAFAFB;
}

body.dark .forums__man {
  color: #FAFAFB;
}

body.dark .forums__action {
  border-color: #696974;
}

body.dark .forums__action:hover {
  background: #696974;
}

body.dark .forums__action:hover .icon {
  fill: #FAFAFB;
}

body.dark .forums__input {
  background: #1C1C24;
  color: #FAFAFB;
}

body.dark .forums__link {
  border-color: #44444F;
  color: #FAFAFB;
}

body.dark .forums__link:hover {
  border-color: #50B5FF;
}

body.dark .forums__icon {
  background: rgba(80, 181, 255, 0.1);
}

@media only screen and (max-width: 767px) {
  body.dark .forums__icon .icon {
    fill: #92929D;
  }
}

body.dark .forums__view {
  color: #1E75FF;
}

.code {
  padding: 50px 540px 50px 60px;
}

@media only screen and (max-width: 1599px) {
  .code {
    padding: 40px 490px 40px 40px;
  }
}

@media only screen and (max-width: 1365px) {
  .code {
    padding: 40px 430px 40px 25px;
  }
}

@media only screen and (max-width: 1023px) {
  .code {
    padding: 30px 360px 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .code {
    padding: 30px 20px;
  }
}

.code__container {
  max-width: 100%;
}

.code__content h1, .code__content h2 {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
}

.code__content h1 {
  margin-bottom: 24px;
  font-size: 28px;
}

@media only screen and (max-width: 1023px) {
  .code__content h1 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .code__content h1 {
    margin-bottom: 14px;
    font-size: 21px;
  }
}

.code__content h2 {
  margin-bottom: 12px;
  font-size: 18px;
}

.code__content p {
  font-size: 16px;
  line-height: 1.625;
  color: #696974;
}

@media only screen and (max-width: 767px) {
  .code__content p {
    font-size: 14px;
  }
}

.code__content p:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .code__content p:not(:last-child) {
    margin-bottom: 20px;
  }
}

.code__content a {
  border-bottom: 1px solid transparent;
  font-weight: 500;
  color: #0062FF;
  transition: border-color .25s;
}

.code__content a:hover {
  border-color: #0062FF;
}

.code__content hr {
  border: none;
  height: 1px;
  background: #F1F1F5;
  color: #F1F1F5;
}

.code__content hr:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .code__content hr:not(:last-child) {
    margin-bottom: 20px;
  }
}

.code__content table {
  width: 100%;
}

.code__content table:not(:first-child) {
  margin-top: 30px;
}

.code__content table:not(:last-child) {
  margin-bottom: 30px;
}

.code__content table th,
.code__content table td {
  white-space: nowrap;
}

.code__content table th {
  height: 40px;
  padding: 0 15px;
  vertical-align: middle;
  background: #F1F1F5;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .code__content table th {
    height: 30px;
    padding: 0 10px;
    font-size: 11px;
  }
}

.code__content table th:first-child {
  border-radius: 12px 0 0 12px;
}

@media only screen and (max-width: 767px) {
  .code__content table th:first-child {
    border-radius: 5px 0 0 0;
  }
}

.code__content table th:last-child {
  border-radius: 0 12px 12px 0;
}

@media only screen and (max-width: 767px) {
  .code__content table th:last-child {
    border-radius: 0 5px 0 0;
  }
}

.code__content table td {
  height: 70px;
  padding-left: 15px;
  border-bottom: 1px solid #F1F1F5;
  vertical-align: middle;
  font-size: 16px;
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .code__content table td {
    height: 46px;
    padding-left: 10px;
    font-size: 12px;
  }
}

.code__content table td:first-child {
  padding-left: 5px;
}

.code__content table td:last-child {
  padding-right: 15px;
}

@media only screen and (max-width: 767px) {
  .code__content table td:last-child {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .code__content table td:last-child {
    border: none;
  }
}

.code__content .table-wrap {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 767px) {
  .code__content .table-wrap {
    border: 1px solid #F1F1F5;
    border-radius: 5px 5px 0 0;
  }
}

.code__status {
  display: inline-block;
  padding: 0 15px;
  background: #FAFAFB;
  border-radius: 12px;
  font-size: 14px;
  line-height: 34px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .code__status {
    padding: 0 10px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 26px;
  }
}

.code__status_blue {
  color: #50B5FF;
}

.code__status_yellow {
  color: #FFC542;
}

.code__status_green {
  color: #82C43C;
}

.code__status_red {
  color: #FC5A5A;
}

.code .hljs {
  padding: 15px 20px;
  border-radius: 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

.code__content .code__box {
  position: relative;
}

.code__content .code__box:not(:first-child) {
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .code__content .code__box:not(:first-child) {
    margin-top: 25px;
  }
}

.code__content .code__box:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .code__content .code__box:not(:last-child) {
    margin-bottom: 25px;
  }
}

.code__full {
  display: none;
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .code__full {
    display: inline-block;
  }
}

.code__full .icon {
  font-size: 24px;
  fill: #92929D;
}

.code__wrap {
  position: fixed;
  top: 70px;
  right: 0;
  bottom: 0;
  width: 465px;
  padding-top: 50px;
  background: #44444F;
  border-radius: 20px;
}

@media only screen and (max-width: 1365px) {
  .code__wrap {
    width: 400px;
  }
}

@media only screen and (max-width: 1023px) {
  .code__wrap {
    width: 340px;
  }
}

@media only screen and (max-width: 767px) {
  .code__wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0;
    z-index: 30;
    transform: scale(0.75);
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
  }
  .code__wrap.show {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }
}

.code__close {
  display: none;
}

@media only screen and (max-width: 767px) {
  .code__close {
    display: inline-block;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 40px;
    height: 40px;
    font-size: 0;
  }
  .code__close .icon {
    font-size: 18px;
    fill: #ffffff;
  }
}

.code__nav {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 40px;
  border-radius: 20px;
  background: #696974;
}

@media only screen and (max-width: 1023px) {
  .code__nav {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .code__nav {
    padding: 0 50px 0 20px;
    border-radius: 0;
  }
}

.code__link {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
  color: #92929D;
  transition: color .25s;
}

@media only screen and (max-width: 767px) {
  .code__link {
    font-size: 14px;
  }
}

.code__link:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0;
  height: 4px;
  margin: 0 auto;
  border-radius: 4px 4px 0 0;
  background: #50B5FF;
  transition: width .25s;
}

.code__link:hover {
  color: #ffffff;
}

.code__link.active {
  color: #ffffff;
}

.code__link.active:before {
  width: 100%;
}

.code__link:not(:last-child) {
  margin-right: 30px;
}

@media only screen and (max-width: 1023px) {
  .code__link:not(:last-child) {
    margin-right: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .code__link:not(:last-child) {
    margin-right: 20px;
  }
}

.code__body {
  max-height: 100%;
  padding: 30px;
  overflow: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 1365px) {
  .code__body {
    padding: 25px 20px;
  }
}

.code__body .code__text {
  font-size: 12px;
  line-height: 1.83333;
  color: #92929D;
}

.code__body .code__text:not(:last-child) {
  margin-bottom: 20px;
}

.code__body .code__box:not(:last-child) {
  margin-bottom: 20px;
}

body.dark .code__content h1, body.dark .code__content h2 {
  color: #FAFAFB;
}

body.dark .code__content p {
  color: #FAFAFB;
}

body.dark .code__content a {
  color: #1E75FF;
}

body.dark .code__content a:hover {
  border-color: #1E75FF;
}

body.dark .code__content hr {
  background: #292932;
  border-color: #292932;
}

body.dark .code__content table th {
  background: #292932;
}

body.dark .code__content table td {
  border-color: #292932;
  color: #696974;
}

@media only screen and (max-width: 767px) {
  body.dark .code__content .table-wrap {
    border-color: #292932;
  }
}

body.dark .code__status {
  background: #1C1C24;
}

body.dark .code__nav {
  background: #292932;
}

body.dark .code__link {
  color: #696974;
}

body.dark .code__link:hover, body.dark .code__link.active {
  color: #FAFAFB;
}

body.dark .code__wrap {
  background: #1C1C24;
}

.editor {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 30px;
}

@media only screen and (max-width: 1365px) {
  .editor {
    padding: 40px 25px;
  }
}

@media only screen and (max-width: 767px) {
  .editor {
    padding: 30px 0;
  }
}

.editor__row {
  display: flex;
}

.editor__container {
  flex-grow: 1;
  padding-left: 50px;
}

@media only screen and (max-width: 1365px) {
  .editor__container {
    padding-left: 25px;
  }
}

@media only screen and (max-width: 1023px) {
  .editor__container {
    padding: 0;
  }
}

.editor__item {
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #F1F1F5;
}

@media only screen and (max-width: 767px) {
  .editor__item {
    border-radius: 0;
  }
}

.editor__item:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .editor__item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.editor__top {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 30px;
  border-bottom: 1px solid #F1F1F5;
}

@media only screen and (max-width: 1199px) {
  .editor__top {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .editor__top {
    height: 42px;
  }
}

.editor__country {
  display: flex;
  align-items: center;
  margin-right: auto;
  font-size: 12px;
  color: #92929D;
}

.editor__flag {
  margin-right: 10px;
  font-size: 0;
}

.editor__flag .editor__pic {
  border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .editor__flag .editor__pic {
    width: 30px;
  }
}

.editor__dropdown {
  position: relative;
  z-index: 2;
}

.editor__head {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 12px;
  line-height: 1.15;
  color: #92929D;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color .25s;
}

.editor__head:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background: #FFC542;
  border-radius: 50%;
}

.editor__head .icon {
  margin-left: 10px;
  font-size: 16px;
  fill: #B5B5BE;
}

.editor__head:hover {
  color: #B5B5BE;
}

.editor__body {
  position: absolute;
  top: calc(100% + 8px);
  left: -116px;
  width: 237px;
  padding: 13px 15px 12px;
  background: #ffffff;
  border: 1px solid #F1F1F5;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
  border-radius: 6px;
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
}

@media only screen and (max-width: 1439px) {
  .editor__body {
    left: auto;
    right: -30px;
  }
}

@media only screen and (max-width: 1199px) {
  .editor__body {
    right: -20px;
  }
}

@media only screen and (max-width: 767px) {
  .editor__body {
    right: -15px;
  }
}

.editor__body:before, .editor__body:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 1439px) {
  .editor__body:before, .editor__body:after {
    left: 146px;
  }
}

@media only screen and (max-width: 1199px) {
  .editor__body:before, .editor__body:after {
    left: 156px;
  }
}

@media only screen and (max-width: 767px) {
  .editor__body:before, .editor__body:after {
    left: 161px;
  }
}

.editor__body:before {
  z-index: 2;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 7px 8px 7px;
  border-color: transparent transparent #ffffff transparent;
}

.editor__body:after {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 8px 9px 8px;
  border-color: transparent transparent #F1F1F5 transparent;
}

.editor__dropdown.active .editor__body {
  visibility: visible;
  opacity: 1;
}

.editor__info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 1.15;
  font-weight: 500;
  color: #44444F;
}

.editor__info .icon {
  margin-right: 6px;
  font-size: 14px;
  fill: #92929D;
}

.editor__body .editor__text {
  margin-bottom: 14px;
  font-size: 12px;
  line-height: 1.66667;
  color: #92929D;
}

.editor__control {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editor__link {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .4px;
  color: #0062FF;
  transition: color .25s;
}

.editor__link:hover {
  color: #50B5FF;
}

.editor__body .editor__btn {
  min-width: 64px;
  height: 24px;
  border-radius: 10px;
  font-size: 11px;
}

.editor__wrap {
  padding: 30px;
}

@media only screen and (max-width: 1199px) {
  .editor__wrap {
    padding: 20px;
  }
}

.editor__line {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .editor__line {
    align-items: flex-start;
  }
}

.editor__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 20px;
  border-radius: 50%;
  background: #F1F1F5;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .editor__icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
}

.editor__icon .icon {
  font-size: 24px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .editor__icon .icon {
    font-size: 20px;
  }
}

.editor__details {
  flex-grow: 1;
}

.editor__title {
  margin-bottom: 7px;
}

@media only screen and (max-width: 767px) {
  .editor__title {
    margin-top: -2px;
  }
}

.editor__details .editor__text {
  font-size: 12px;
  line-height: 1.15;
  color: #92929D;
}

.editor__author {
  border-bottom: 1px solid transparent;
  color: #0062FF;
  transition: border-color .25s;
}

.editor__author:hover {
  border-color: #0062FF;
}

.editor__field {
  padding-left: 68px;
}

@media only screen and (max-width: 1199px) {
  .editor__field {
    padding: 0;
  }
}

.editor__filters-btn {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .editor__filters-btn {
    display: inline-block;
    position: fixed;
    right: 30px;
    bottom: 30px;
    box-shadow: 5px 5px 20px rgba(0, 98, 255, 0.25);
    font-size: 14px;
  }
  .editor__filters-btn .icon {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .editor__filters-btn {
    min-width: 100px;
  }
}

.editor .richText .richText-toolbar {
  border: none;
}

.editor .richText .richText-toolbar ul li a {
  border-bottom: #EFEFEF solid 1px;
}

body.dark .editor__item {
  background: #1C1C24;
  border-color: #292932;
}

body.dark .editor__top {
  border-color: #292932;
}

body.dark .editor__country {
  color: #B5B5BE;
}

body.dark .editor__head {
  color: #B5B5BE;
}

body.dark .editor__head:hover {
  color: #FAFAFB;
}

body.dark .editor__body {
  background: #44444F;
  border-color: #292932;
}

body.dark .editor__body:before {
  border-color: transparent transparent #44444F transparent;
}

body.dark .editor__body:after {
  border-color: transparent transparent #292932 transparent;
}

body.dark .editor__info {
  color: #FAFAFB;
}

body.dark .editor__body .editor__text {
  color: #B5B5BE;
}

body.dark .editor__link {
  color: #FAFAFB;
}

body.dark .editor__icon {
  background: #292932;
}

body.dark .editor__title {
  color: #FAFAFB;
}

body.dark .editor .richText {
  background: #292932;
  border-color: transparent;
}

body.dark .editor .richText .richText-toolbar {
  background: #44444F;
  border-color: #44444F;
}

body.dark .editor .richText .richText-toolbar ul li a {
  border-color: #44444F;
  border-bottom: 1px solid #44444F;
}

body.dark .editor .richText .richText-toolbar ul li a:hover .fa,
body.dark .editor .richText .richText-toolbar ul li a:hover .fas,
body.dark .editor .richText .richText-toolbar ul li a:hover .far,
body.dark .editor .richText .richText-toolbar ul li a:hover svg {
  color: #171725;
}

body.dark .editor .richText .richText-toolbar ul li a .fa, body.dark .editor .richText .richText-toolbar ul li a .fas, body.dark .editor .richText .richText-toolbar ul li a .far, body.dark .editor .richText .richText-toolbar ul li a svg {
  color: #ffffff;
  fill: #ffffff;
}

body.dark .editor .richText .richText-toolbar ul li a {
  border-color: #44444F;
  border-bottom: 1px solid #44444F;
}

body.dark .editor .richText .richText-toolbar ul li a:hover {
  color: #171725;
}

body.dark .editor .richText .richText-editor {
  border-color: transparent;
  background: #292932;
  color: #ffffff;
}

body.dark .editor .richText .richText-toolbar {
  border-color: #44444F;
  border-top: 1px solid #44444F;
}

body.dark .editor .richText .richText-toolbar .fa {
  color: #ffffff;
}

body.dark .editor .richText .richText-undo, body.dark .editor .richText .richText-redo {
  border-color: #44444F;
}

.filters {
  flex-shrink: 0;
  width: 280px;
}

@media only screen and (max-width: 1023px) {
  .filters {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 20;
    width: 330px;
    padding: 30px 20px;
    background: #FAFAFB;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateX(-100%);
    transition: all .25s;
  }
  .filters.show {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 767px) {
  .filters {
    width: 100%;
  }
}

.filters__top {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.filters__stage {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}

.filters__item > .filters__stage {
  margin-bottom: 17px;
}

.filters__back {
  display: none;
  margin-left: auto;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  text-decoration: underline;
  color: #0062FF;
}

@media only screen and (max-width: 1023px) {
  .filters__back {
    display: inline-block;
  }
}

.filters__info {
  margin-bottom: 15px;
  line-height: 1.15;
  color: #92929D;
}

.filters__item {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #E2E2EA;
}

.filters__dropdown {
  position: relative;
  z-index: 2;
}

.filters__dropdown:not(:last-child) {
  margin-bottom: 20px;
}

.filters__head {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 40px 0 15px;
  background: #ffffff;
  border: 1px solid #F1F1F5;
  border-radius: 10px;
  color: #44444F;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.filters__head .icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 16px;
  fill: #D5D5DC;
}

.filters__body {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% - 10px);
  padding: 20px 15px 15px;
  border: 1px solid #F1F1F5;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
  border-radius: 0 0 10px 10px;
  transform: translateY(-10px);
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
}

.filters__body_users {
  padding: 20px 5px 10px;
}

.filters__dropdown.active .filters__body {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.filters__body .filters__link {
  display: block;
  padding-right: 40px;
  line-height: 1.15;
  color: #696974;
  transition: color .25s;
}

.filters__body .filters__link:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  width: 11px;
  height: 8px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none'%3E%3Cpath fill-rule='evenodd' d='M1.803 2.648c-.262-.26-.684-.257-.943.005s-.257.684.005.943L4.748 7.44c.274.27.72.254.973-.038l5.45-6.3c.24-.278.2-.7-.068-.94s-.7-.2-.94.068L5.18 5.99 1.803 2.648z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat 0 0/100% auto;
}

.filters__body .filters__link:hover, .filters__body .filters__link.active {
  color: #171725;
}

.filters__body .filters__link.active:before {
  opacity: 1;
}

.filters__body .filters__link:not(:last-child) {
  margin-bottom: 15px;
}

.filters__variants {
  display: flex;
  flex-direction: column;
}

.filters__variants .checkbox:not(:last-child) {
  margin-bottom: 15px;
}

.filters__line {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
}

.filters__add {
  margin-left: auto;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .8px;
  color: #0062FF;
  transition: color .25s;
}

.filters__add:hover {
  color: #50B5FF;
}

.filters .ava {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  font-size: 10px;
}

.filters__user {
  position: relative;
  display: flex;
  align-items: center;
  height: 34px;
  padding: 0 30px 0 10px;
  border-radius: 4px;
  color: #696974;
  transition: all .25s;
}

.filters__user:hover, .filters__user.active {
  background: rgba(0, 98, 255, 0.05);
}

.filters__user.active .checkbox {
  opacity: 1;
}

.filters__user:not(:last-child) {
  margin-bottom: 4px;
}

.filters__user .checkbox {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity .25s;
}

.filters__tags {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px 15px;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #F1F1F5;
}

.filters__tag {
  position: relative;
  margin: 10px 5px 0;
  padding: 0 24px 0 12px;
  border-radius: 12px;
  border: 1px solid #E2E2EA;
  line-height: 24px;
  color: #44444F;
}

.filters__close {
  position: absolute;
  top: 50%;
  right: 9px;
  transform: translateY(-50%);
  font-size: 0;
}

.filters__close .icon {
  font-size: 10px;
  fill: #92929D;
  transition: fill .25s;
}

.filters__close:hover .icon {
  fill: #B5B5BE;
}

.filters__doc {
  display: flex;
  align-items: center;
}

.filters__load {
  position: relative;
  overflow: hidden;
}

.filters__load:not(:last-child) {
  margin-right: 15px;
}

.filters__load .filters__input {
  position: absolute;
  font-size: 140px;
  opacity: 0;
  cursor: pointer;
}

.filters__file {
  padding: 1px 14px 0;
  border-radius: 10px;
  border: 1px solid rgba(0, 98, 255, 0.5);
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  color: #0062FF;
}

.filters__status {
  color: #92929D;
}

.filters__btns {
  display: flex;
}

.filters__btns .btn_gray {
  flex-shrink: 0;
  min-width: 115px;
}

.filters__btns .btn_blue-light {
  flex-grow: 1;
  min-width: auto;
  margin-left: 15px;
}

@media only screen and (max-width: 1023px) {
  body.dark .filters {
    background: #13131A;
  }
}

body.dark .filters__stage {
  color: #FAFAFB;
}

body.dark .filters__back {
  color: #92929D;
}

body.dark .filters__add {
  color: #1E75FF;
}

body.dark .filters__add:hover {
  color: #50B5FF;
}

body.dark .filters__head {
  background: #1C1C24;
  border-color: #292932;
  color: #FAFAFB;
}

body.dark .filters__body {
  background: #1C1C24;
  border-color: #292932;
}

body.dark .filters__body .filters__link:hover,
body.dark .filters__body .filters__link.active {
  color: #FAFAFB;
}

body.dark .filters__item {
  border-color: #292932;
}

body.dark .filters__tags {
  border-color: #292932;
  background: #1C1C24;
}

body.dark .filters__tag {
  border-color: #44444F;
  color: #FAFAFB;
}

body.dark .filters__file {
  border-color: rgba(250, 250, 251, 0.5);
  color: #FAFAFB;
}

body.dark .filters__user:hover, body.dark .filters__user.active {
  background: #44444F;
  color: #FAFAFB;
}

@media only screen and (max-width: 1023px) {
  .filters1 {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 20;
    width: 330px;
    padding: 70px 0 0;
    background: #FAFAFB;
    transform: translateX(-100%);
    transition: all .25s;
  }
  .filters1.show {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 767px) {
  .filters1 {
    width: 100%;
    padding-top: 60px;
  }
}

.filters1__top {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .filters1__top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 70px;
    padding: 0 20px;
    background: #ffffff;
    border-bottom: 1px solid #F1F1F5;
  }
}

@media only screen and (max-width: 767px) {
  .filters1__top {
    height: 60px;
  }
}

.filters1__close {
  margin-right: 25px;
  font-size: 0;
}

.filters1__close .icon {
  font-size: 18px;
}

.filters1__title {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
}

@media only screen and (max-width: 1023px) {
  .filters1__wrap {
    max-height: 100%;
    padding: 30px 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media only screen and (max-width: 767px) {
  .filters1__wrap {
    padding: 20px;
  }
}

.filters1__item:not(:last-child) {
  margin-bottom: 30px;
}

.filters1__head {
  position: relative;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  color: #92929D;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color .25s;
}

.filters1__head:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  width: 10px;
  height: 7px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' fill='none'%3E%3Cpath fill-rule='evenodd' d='M4 3.057L6.862.195c.26-.26.682-.26.943 0s.26.682 0 .943L4.47 4.47c-.26.26-.682.26-.943 0L.195 1.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0L4 3.057z' fill='%2344444f'/%3E%3C/svg%3E") no-repeat 50% 50%/auto 100%;
  transition: transform .25s;
}

.filters1__item.active .filters1__head {
  color: #44444F;
}

.filters1__item.active .filters1__head:before {
  transform: translateY(-50%) rotate(180deg);
}

.filters1__body {
  display: none;
  padding-top: 15px;
}

.filters1 .checkbox {
  display: block;
}

.filters1 .checkbox:not(:last-child) {
  margin-bottom: 13px;
}

.filters1 .checkbox__tick {
  flex: 0 0 19px;
  width: 19px;
  height: 19px;
}

.filters1 .checkbox__text {
  margin-right: auto;
  padding-top: 2px;
  color: #44444F;
}

.filters1 .checkbox__counter {
  min-width: 24px;
  flex-shrink: 0;
  margin-top: -3px;
  margin-left: 20px;
  padding: 0 5px;
  border-radius: 5px;
  background: #F1F1F5;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 10px;
  font-weight: 500;
  line-height: 24px;
  color: #696974;
}

@media only screen and (max-width: 1023px) {
  body.dark .filters1 {
    background: #13131A;
  }
}

body.dark .filters1__top {
  background: #1C1C24;
  border-color: #292932;
}

body.dark .filters1__title {
  color: #FAFAFB;
}

body.dark .filters1__head {
  color: #B5B5BE;
}

body.dark .filters1__head:before {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' fill='none'%3E%3Cpath fill-rule='evenodd' d='M4 3.057L6.862.195c.26-.26.682-.26.943 0s.26.682 0 .943L4.47 4.47c-.26.26-.682.26-.943 0L.195 1.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0L4 3.057z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat 50% 50%/auto 100%;
}

body.dark .filters1__item.active .filters1__head {
  color: #B5B5BE;
}

body.dark .filters1 .checkbox__counter {
  background: #292932;
  color: #D5D5DC;
}

.history__row {
  display: flex;
  align-items: flex-start;
  margin: 0 -15px;
}

@media only screen and (max-width: 1599px) {
  .history__row {
    margin: 0 -10px;
  }
}

@media only screen and (max-width: 1365px) {
  .history__row {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0 -30px;
  }
  .history__row:before, .history__row:after {
    content: "";
    display: block;
    flex-shrink: 0;
    width: 30px;
    height: 1px;
  }
}

@media only screen and (max-width: 1023px) {
  .history__row {
    margin: 0 -20px;
  }
  .history__row:before, .history__row:after {
    width: 20px;
  }
}

.history__col {
  flex: 0 0 calc(25% - 30px);
  width: calc(25% - 30px);
  margin: 0 15px;
  padding: 20px;
  border-radius: 10px;
  background: #ffffff;
}

@media only screen and (max-width: 1599px) {
  .history__col {
    flex: 0 0 calc(25% - 20px);
    width: calc(25% - 20px);
    margin: 0 10px;
  }
}

@media only screen and (max-width: 1439px) {
  .history__col {
    padding: 20px 15px;
  }
}

@media only screen and (max-width: 1365px) {
  .history__col {
    flex: 0 0 330px;
    width: 330px;
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .history__col {
    border-radius: 20px;
  }
}

@media only screen and (max-width: 1365px) {
  .history__col:not(:last-child) {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .history__col:not(:last-child) {
    margin-right: 15px;
  }
}

.history__top {
  display: flex;
  margin-bottom: 20px;
  padding-left: 5px;
}

@media only screen and (max-width: 767px) {
  .history__top {
    margin-bottom: 15px;
    padding: 0;
  }
}

.history__top .options2 {
  margin-top: 5px;
  margin-left: 20px;
  flex-shrink: 0;
}

.history__title {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
  color: #171725;
}

.history__top .history__title {
  margin-right: auto;
  color: #44444F;
}

.history__group:not(:last-child) {
  margin-bottom: 20px;
}

.history__card {
  position: relative;
  padding: 20px;
  border: 1px solid #F1F1F5;
  border-radius: 10px;
  transition: border-color .25s;
}

@media only screen and (max-width: 767px) {
  .history__card {
    padding: 15px;
  }
}

.history__card .options2 {
  position: absolute;
  top: 25px;
  right: 15px;
}

.history__card .options2__btn .icon {
  font-size: 3px;
}

.history__card:hover {
  border-color: #d3d3e0;
}

.history__card:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .history__card:not(:last-child) {
    margin-bottom: 10px;
  }
}

.history__logo {
  width: 48px;
  height: 48px;
  border-radius: 10px;
  box-shadow: 0 0 0 3px #FAFAFB;
  font-size: 0;
}

.history__card .history__logo {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .history__card .history__logo {
    margin-bottom: 15px;
  }
}

.history__logo .history__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 10px;
}

.history__card .history__title {
  margin-bottom: 7px;
}

.history__details:not(:last-child) {
  margin-bottom: 16px;
}

.history__company, .history__date {
  font-weight: 500;
  color: #44444F;
}

.history__work {
  color: #92929D;
}

.history__work:not(:first-child),
.history__user .history__time:not(:first-child) {
  margin-left: 5px;
}

.history__work:not(:first-child):before,
.history__user .history__time:not(:first-child):before {
  content: "";
  position: relative;
  top: -4px;
  display: inline-block;
  width: 3px;
  height: 3px;
  margin-right: 3px;
  border-radius: 50%;
  background: #D8D8D8;
}

.history__line {
  display: flex;
}

.history__line:not(:last-child) {
  margin-bottom: 16px;
}

.history__place {
  margin-right: auto;
  color: #44444F;
}

.history__added {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #92929D;
}

.history__added .icon {
  margin-right: 3px;
  font-size: 16px;
  fill: #92929D;
}

.history__card .history__time {
  margin-right: auto;
  line-height: 23px;
  color: #B5B5BE;
}

.history__status {
  display: inline-block;
  padding: 0 8px;
  border-radius: 5px;
  background: #F1F1F5;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  line-height: 23px;
  font-size: 10px;
  font-weight: 500;
  color: #696974;
}

.history__item {
  position: relative;
  display: flex;
  padding: 20px;
  border: 1px solid #F1F1F5;
  border-radius: 10px;
  transition: border-color .25s;
}

@media only screen and (max-width: 767px) {
  .history__item {
    padding: 15px;
  }
}

.history__item:hover {
  border-color: #d3d3e0;
}

.history__item:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .history__item:not(:last-child) {
    margin-bottom: 10px;
  }
}

.history__item .history__logo {
  flex-shrink: 0;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .history__item .history__logo {
    margin-right: 15px;
  }
}

.history__item .history__title {
  margin-bottom: 6px;
  padding-right: 20px;
}

.history__close {
  position: absolute;
  top: 22px;
  right: 20px;
  font-size: 0;
  transition: opacity .25s;
}

@media only screen and (max-width: 767px) {
  .history__close {
    right: 15px;
  }
}

.history__close .icon {
  font-size: 10px;
  fill: #FC5A5A;
}

.history__close:hover {
  opacity: .75;
}

.history__list {
  margin-bottom: 35px;
  padding-top: 5px;
}

@media only screen and (max-width: 767px) {
  .history__list {
    margin-bottom: 25px;
  }
}

.history__variant {
  display: flex;
}

.history__variant:not(:last-child) {
  margin-bottom: 15px;
}

.history__variant .history__wrap {
  margin-right: auto;
}

.history__actions {
  display: flex;
  flex-shrink: 0;
  margin-left: 20px;
  padding-top: 7px;
}

.history__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  border: 2px solid #92929D;
  font-size: 0;
  transition: all .25s;
}

.history__action .icon {
  font-size: 8px;
  fill: #92929D;
  transition: fill .25s;
}

.history__action:hover {
  border-color: #B5B5BE;
}

.history__action:hover .icon {
  fill: #B5B5BE;
}

.history__action_accept.active {
  border-color: #3DD598;
  background: #3DD598;
}

.history__action_accept.active .icon {
  fill: #ffffff;
}

.history__action_reject.active {
  border-color: #FC5A5A;
  background: #FC5A5A;
}

.history__action_reject.active .icon {
  fill: #ffffff;
}

.history__action:not(:last-child) {
  margin-right: 16px;
}

.history__category {
  margin-bottom: 5px;
  font-size: 12px;
  color: #92929D;
}

.history__variant .history__info {
  font-weight: 500;
  color: #44444F;
}

.history__btns {
  display: flex;
  margin: 0 -8px;
}

.history__btns .history__btn {
  flex: 0 0 calc(50% - 16px);
  min-width: calc(50% - 16px);
  margin: 0 8px;
}

.history__user {
  display: flex;
  padding: 20px;
  border: 1px solid #F1F1F5;
  border-radius: 10px;
  transition: border-color .25s;
}

@media only screen and (max-width: 767px) {
  .history__user {
    padding: 15px;
  }
}

.history__user:hover {
  border-color: #d3d3e0;
}

.history__user:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .history__user:not(:last-child) {
    margin-bottom: 10px;
  }
}

.history__user .history__logo {
  position: relative;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .history__user .history__logo {
    margin-right: 15px;
  }
}

.history__brand {
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
}

.history__brand .history__pic {
  border-radius: 50%;
}

.history__user .history__title {
  margin-bottom: 12px;
}

.history__option {
  display: flex;
}

.history__option .icon {
  margin-right: 5px;
  font-size: 16px;
  fill: #92929D;
}

.history__option:not(:last-child) {
  margin-bottom: 15px;
}

.history__user .history__time,
.history__user .history__text {
  color: #696974;
}

body.dark .history__col {
  background: #1C1C24;
}

body.dark .history__title {
  color: #E2E2EA;
}

body.dark .history__company {
  color: #E2E2EA;
}

body.dark .history__work:not(:first-child):before,
body.dark .history__user .history__time:not(:first-child):before {
  background: #92929D;
}

body.dark .history__place {
  color: #B5B5BE;
}

body.dark .history__card .history__time {
  color: #92929D;
}

body.dark .history__logo {
  box-shadow: 0 0 0 3px #44444F;
}

body.dark .history__status {
  background: #292932;
  color: #D5D5DC;
}

body.dark .history__item .history__work {
  color: #696974;
}

body.dark .history__variant .history__info {
  color: #FAFAFB;
}

body.dark .history__card, body.dark .history__item, body.dark .history__user {
  border-color: #292932;
}

body.dark .history__card:hover, body.dark .history__item:hover, body.dark .history__user:hover {
  border-color: #44444F;
}

body.dark .history__date {
  color: #E2E2EA;
}

body.dark .history__user .history__work,
body.dark .history__user .history__text {
  color: #B5B5BE;
}

.jobs__row {
  display: flex;
}

.jobs__filters {
  flex-shrink: 0;
  width: 280px;
}

@media only screen and (max-width: 1365px) {
  .jobs__filters {
    width: 240px;
  }
}

@media only screen and (max-width: 1023px) {
  .jobs__filters {
    width: 0;
  }
}

.jobs__container {
  flex-grow: 1;
  padding-left: 50px;
}

@media only screen and (max-width: 1365px) {
  .jobs__container {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .jobs__container {
    padding: 0;
  }
}

.jobs__form {
  padding: 20px;
  background: #44444F;
  border-radius: 10px;
}

.jobs__form:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .jobs__form:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .jobs__filters .jobs__form {
    display: none;
  }
}

.jobs__form .jobs__category {
  margin-bottom: 3px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  color: #FAFAFB;
}

.jobs__form .jobs__info {
  margin-bottom: 24px;
  line-height: 1.71429;
  color: #D5D5DC;
}

@media only screen and (max-width: 1023px) {
  .jobs__form .jobs__info {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .jobs__fielset {
    display: flex;
  }
}

.jobs__field {
  margin-bottom: 15px;
}

@media only screen and (max-width: 1023px) {
  .jobs__field {
    flex-grow: 1;
    margin: 0 10px 0 0;
  }
}

.jobs__input {
  width: 100%;
  height: 38px;
  padding: 0 13px;
  border-radius: 10px;
  background: #FAFAFB;
  font-family: 'Roboto', sans-serif;
  color: #171725;
}

.jobs__input::placeholder {
  color: #92929D;
}

.jobs__form .jobs__btn {
  min-width: 80px;
  height: 32px;
}

@media only screen and (max-width: 1023px) {
  .jobs__form .jobs__btn {
    min-width: 90px;
    height: 38px;
  }
}

.jobs__head {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .jobs__head {
    margin-bottom: 20px;
  }
}

.jobs__head .jobs__title {
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .jobs__head .jobs__title {
    font-size: 20px;
  }
}

.jobs__open-filters {
  display: none;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: 38px;
  background: #ffffff;
  border-radius: 7px;
  color: #696974;
}

@media only screen and (max-width: 1023px) {
  .jobs__open-filters {
    display: inline-flex;
  }
}

@media only screen and (max-width: 767px) {
  .jobs__open-filters {
    height: 34px;
  }
}

.jobs__open-filters .icon {
  margin-right: 10px;
  font-size: 12px;
  fill: #92929D;
}

.jobs__sorting {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .jobs__sorting {
    display: none;
  }
}

.jobs__sorting .jobs__text {
  margin-right: 5px;
  font-size: 18px;
  color: #92929D;
}

@media only screen and (max-width: 1199px) {
  .jobs__sorting .jobs__text {
    font-size: 16px;
  }
}

.jobs__select {
  height: auto;
  padding: 0 22px 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat 100% 55%/11px 7px;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #44444F;
  cursor: pointer;
}

@media only screen and (max-width: 1199px) {
  .jobs__select {
    font-size: 16px;
  }
}

.jobs__select::-ms-expand {
  display: none;
}

.jobs__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.jobs__list {
  display: flex;
  flex-wrap: wrap;
  margin: -30px -15px 0;
}

@media only screen and (max-width: 1365px) {
  .jobs__list {
    margin: -20px -10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .jobs__list {
    display: block;
    margin: 0;
  }
}

.jobs__list .jobs__form {
  flex: 0 0 calc(100% - 20px);
  width: calc(100% - 20px);
  margin: 20px 10px 0;
}

@media only screen and (max-width: 767px) {
  .jobs__list .jobs__form {
    width: 100%;
    margin: 0;
  }
  .jobs__list .jobs__form:not(:last-child) {
    margin-bottom: 15px;
  }
}

.jobs__item {
  position: relative;
  flex: 0 0 calc(33.333% - 30px);
  width: calc(33.333% - 30px);
  margin: 30px 15px 0;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
}

@media only screen and (max-width: 1365px) {
  .jobs__item {
    flex: 0 0 calc(33.333% - 20px);
    width: calc(33.333% - 20px);
    margin: 20px 10px 0;
  }
}

@media only screen and (max-width: 1199px) {
  .jobs__item {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .jobs__item {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .jobs__item:not(:last-child) {
    margin-bottom: 15px;
  }
}

.jobs__logo {
  width: 48px;
  height: 48px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 0 0 3px #FAFAFB;
  font-size: 0;
}

.jobs__logo .jobs__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 10px;
}

.jobs__item .jobs__title {
  display: block;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
  color: #171725;
  transition: color .25s;
}

.jobs__item .jobs__title .icon {
  position: relative;
  bottom: -3px;
  margin-left: 5px;
  font-size: 16px;
  fill: #92929D;
}

.jobs__item .jobs__title:hover {
  color: #0062FF;
}

.jobs .options2 {
  position: absolute;
  top: 22px;
  right: 20px;
}

.jobs .options2__btn .icon {
  font-size: 3px;
}

.jobs__info {
  margin-bottom: 20px;
  line-height: 1.71429;
  color: #696974;
}

.jobs__tags {
  display: flex;
  flex-wrap: wrap;
  margin: -10px 0 20px -10px;
}

.jobs__tag {
  margin: 10px 0 0 10px;
  padding: 0 11px;
  border-radius: 5px;
  background: #F1F1F5;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 10px;
  line-height: 28px;
  font-weight: 500;
  color: #696974;
}

.jobs__line {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.jobs .users {
  margin: 0;
}

.jobs .users__item {
  position: relative;
  margin: 0;
}

.jobs .users__item:first-child {
  z-index: 4;
}

.jobs .users__item:nth-child(2) {
  z-index: 3;
}

.jobs .users__item:nth-child(3) {
  z-index: 2;
}

.jobs .users__item:nth-child(4) {
  z-index: 1;
}

.jobs .users__item:not(:first-child) {
  margin-left: -6px;
}

.jobs__friends {
  margin-left: 10px;
  font-size: 12px;
  color: #92929D;
}

.jobs__btns {
  display: flex;
  margin: 0 -8px;
}

.jobs__btns .jobs__btn {
  flex: 0 0 calc(50% - 16px);
  min-width: calc(50% - 16px);
  margin: 0 8px;
}

.jobs__wrapper {
  flex-grow: 1;
  padding-left: 60px;
}

@media only screen and (max-width: 1599px) {
  .jobs__wrapper {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 1365px) {
  .jobs__wrapper {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .jobs__wrapper {
    padding: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .jobs__wrapper .jobs__row {
    display: block;
  }
}

.jobs__col:first-child {
  flex-grow: 1;
  padding-right: 60px;
}

@media only screen and (max-width: 1599px) {
  .jobs__col:first-child {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 1365px) {
  .jobs__col:first-child {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .jobs__col:first-child {
    margin-bottom: 30px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .jobs__col:first-child {
    margin-bottom: 20px;
  }
}

.jobs__col:nth-child(2) {
  flex-shrink: 0;
  width: 340px;
}

@media only screen and (max-width: 1365px) {
  .jobs__col:nth-child(2) {
    width: 280px;
  }
}

@media only screen and (max-width: 1199px) {
  .jobs__col:nth-child(2) {
    width: 100%;
  }
}

@media only screen and (max-width: 1199px) {
  .jobs__group_flex {
    display: flex;
    flex-wrap: wrap;
    margin: -20px -10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .jobs__group_flex {
    display: block;
    margin: 0;
  }
}

.jobs__group:not(:last-child) {
  margin-bottom: 20px;
}

.jobs__box {
  display: flex;
  padding: 20px;
  border-radius: 10px;
  background: #ffffff;
  transition: background .25s;
}

@media only screen and (max-width: 1365px) {
  .jobs__box {
    padding: 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .jobs__box {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
    margin: 20px 10px 0;
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .jobs__box {
    width: 100%;
    margin: 0;
    padding: 15px;
  }
}

.jobs__box:hover {
  background: #50B5FF;
  color: #ffffff;
}

.jobs__box:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1365px) {
  .jobs__box:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .jobs__box:not(:last-child) {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .jobs__box:not(:last-child) {
    margin-bottom: 10px;
  }
}

.jobs__box .jobs__logo {
  flex-shrink: 0;
  margin-right: 20px;
}

@media only screen and (max-width: 1365px) {
  .jobs__box .jobs__logo {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .jobs__box .jobs__logo {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .jobs__box .jobs__logo {
    margin-right: 15px;
  }
}

.jobs__desc {
  flex-grow: 1;
}

.jobs__flex {
  display: flex;
  margin-bottom: 7px;
}

.jobs__title {
  margin-right: auto;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  transition: color .25s;
}

.jobs__price {
  flex-shrink: 0;
  margin-left: 15px;
  font-size: 12px;
  color: #92929D;
  transition: color .25s;
}

.jobs__money {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 600;
  color: #171725;
  transition: color .25s;
}

.jobs__place {
  margin-bottom: 15px;
  font-size: 12px;
  color: #92929D;
  transition: color .25s;
}

.jobs__control {
  display: flex;
  align-items: center;
}

.jobs__box .jobs__btn {
  flex-shrink: 0;
  min-width: 62px;
  height: 28px;
  margin-right: 10px;
}

.jobs__action {
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  margin-right: auto;
  border: 1px solid #E2E2EA;
  border-radius: 8px;
  font-size: 0;
  transition: all .25s;
}

.jobs__action .icon {
  font-size: 16px;
  fill: #92929D;
  transition: fill .25s;
}

.jobs__action:hover {
  opacity: .85;
}

.jobs__time {
  font-size: 12px;
  color: #92929D;
  transition: color .25s;
}

.jobs__box:hover .jobs__price,
.jobs__box:hover .jobs__money,
.jobs__box:hover .jobs__place,
.jobs__box:hover .jobs__time {
  color: #ffffff;
}

.jobs__box:hover .jobs__btn {
  background: #ffffff;
  color: #50B5FF;
}

.jobs__box:hover .jobs__action {
  border-color: #ffffff;
}

.jobs__box:hover .jobs__action .icon {
  fill: #ffffff;
}

body.dark .jobs__form {
  background: #292932;
}

body.dark .jobs__form .jobs__info {
  color: #92929D;
}

body.dark .jobs__head .jobs__title {
  color: #FAFAFB;
}

body.dark .jobs__select {
  color: #D5D5DC;
}

body.dark .jobs__item {
  background: #1C1C24;
}

body.dark .jobs__logo {
  box-shadow: 0 0 0 3px #44444F;
  transition: box-shadow .25s;
}

body.dark .jobs__item .jobs__title {
  color: #FAFAFB;
}

body.dark .jobs__item .jobs__title:hover {
  color: #1E75FF;
}

body.dark .jobs__info {
  color: #B5B5BE;
}

body.dark .jobs__tag {
  background: #292932;
  color: #D5D5DC;
}

body.dark .jobs__open-filters {
  background: #292932;
  color: #FAFAFB;
}

body.dark .jobs__box {
  background: #1C1C24;
}

body.dark .jobs__box:hover {
  background: #50B5FF;
}

body.dark .jobs__title {
  color: #FAFAFB;
}

body.dark .jobs__money {
  color: #FAFAFB;
}

body.dark .jobs__action {
  border-color: #44444F;
}

body.dark .jobs__box:hover .jobs__logo {
  box-shadow: 0 0 0 3px #ffffff;
}

body.dark .jobs__box:hover .btn_gray {
  background: #FAFAFB;
  color: #50B5FF;
}

.description {
  border-radius: 10px;
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .description {
    position: relative;
  }
}

.description__bg {
  position: relative;
  border-radius: 10px 10px 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.description__logo {
  position: absolute;
  overflow: hidden;
  font-size: 0;
}

.description__logo .description__pic {
  width: 100%;
  min-height: 100%;
}

.description__head {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

@media only screen and (max-width: 767px) {
  .description__head {
    display: block;
    margin-bottom: 10px;
  }
}

.description__head .description__title {
  margin-right: auto;
}

.description__actions {
  display: flex;
}

.description__head .description__actions {
  margin-left: 30px;
  flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
  .description__head .description__actions {
    position: absolute;
    top: 132px;
    right: 20px;
    margin: 0;
  }
}

.description__action {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 10px;
  border: 1px solid #E2E2EA;
  font-size: 0;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .description__action {
    background: #ffffff;
  }
}

.description__action .icon {
  font-size: 20px;
  fill: #92929D;
  transition: fill .25s;
}

.description__action:hover {
  background: #E2E2EA;
}

.description__action:not(:last-child) {
  margin-right: 15px;
}

.description__line {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .description__line {
    display: block;
  }
}

.description__line:not(:last-child) {
  margin-bottom: 20px;
}

.description__line .description__details:first-child {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .description__line .description__details:first-child {
    margin: 0;
  }
}

.description__line .description__details:nth-child(2) {
  margin-left: auto;
}

@media only screen and (max-width: 767px) {
  .description__line .description__details:nth-child(2) {
    margin: 20px 0 0;
  }
}

.description__details {
  color: #92929D;
}

.description__details span:not(:first-child),
.description__details a:not(:first-child) {
  margin-left: 5px;
}

.description__details span:not(:first-child):before,
.description__details a:not(:first-child):before {
  content: "";
  position: relative;
  top: -4px;
  display: inline-block;
  width: 3px;
  height: 3px;
  margin-right: 3px;
  border-radius: 50%;
  background: #D8D8D8;
}

.description__company {
  color: #0062FF;
}

.description__company, .description__counter,
.description__details .description__info {
  font-weight: 500;
  color: #44444F;
}

.description__place .icon {
  position: relative;
  bottom: -3px;
  margin-left: 4px;
  font-size: 16px;
  fill: #92929D;
}

.description__work {
  text-decoration: underline;
  color: #92929D;
}

.description__work:hover {
  text-decoration: none;
}

.description__friends {
  display: flex;
  align-items: center;
}

.description__line .description__friends {
  margin-right: 20px;
}

.description .users {
  margin: 0;
}

.description .users__item {
  position: relative;
  margin: 0;
}

.description .users__item:not(:first-child) {
  margin-left: -6px;
}

.description__friends .description__text {
  margin-left: 10px;
  font-size: 12px;
  color: #92929D;
}

.description__wrap {
  display: flex;
  margin: 40px 0 30px;
  border-radius: 10px;
  border: 1px solid #FAFAFB;
  overflow: hidden;
}

@media only screen and (max-width: 1439px) {
  .description__wrap {
    border: none;
  }
}

@media only screen and (max-width: 767px) {
  .description__wrap {
    flex-wrap: wrap;
    margin: 25px 0 20px;
    border-radius: 0;
  }
}

.description__item {
  flex: 1;
  padding: 16px 15px 16px 20px;
}

@media only screen and (max-width: 1439px) {
  .description__item {
    flex: 0 0 auto;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .description__item {
    flex: 0 0 50%;
    width: 50%;
    padding: 15px 5px;
    border: 1px solid #FAFAFB;
    text-align: center;
  }
}

.description__item:not(:last-child) {
  border-right: 1px solid #FAFAFB;
}

@media only screen and (max-width: 1439px) {
  .description__item:not(:last-child) {
    margin-right: auto;
    border: none;
  }
}

@media only screen and (max-width: 767px) {
  .description__item:not(:last-child) {
    border: 1px solid #FAFAFB;
  }
}

.description__value {
  font-weight: 500;
  color: #44444F;
}

@media only screen and (max-width: 767px) {
  .description__wrap .description__value {
    color: #1E75FF;
  }
}

.description__content h2 {
  margin-bottom: 16px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .description__content h2 {
    margin-bottom: 10px;
  }
}

.description__content h2:not(:first-child) {
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .description__content h2:not(:first-child) {
    margin-top: 20px;
  }
}

.description__content p {
  line-height: 2;
  color: #696974;
}

@media only screen and (max-width: 767px) {
  .description__content p {
    font-size: 12px;
  }
}

.description__content p:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .description__content p:not(:last-child) {
    margin-bottom: 10px;
  }
}

.description__content ul li {
  position: relative;
  padding-left: 20px;
  color: #696974;
}

@media only screen and (max-width: 767px) {
  .description__content ul li {
    font-size: 12px;
    line-height: 1.66667;
  }
}

.description__content ul li:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #50B5FF;
}

@media only screen and (max-width: 767px) {
  .description__content ul li:before {
    top: 5px;
  }
}

.description__content ul li:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .description__content ul li:not(:last-child) {
    margin-bottom: 15px;
  }
}

.description__content ul:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .description__content ul:not(:last-child) {
    margin-bottom: 20px;
  }
}

.description__foot {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #F1F1F5;
}

@media only screen and (max-width: 767px) {
  .description__foot {
    display: block;
    margin-top: 20px;
    padding-top: 20px;
  }
}

.description__question {
  margin-right: auto;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .description__question {
    margin: 0 0 20px;
    text-align: center;
  }
}

.description__btns {
  display: flex;
  flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
  .description__btns {
    margin: 0 -5px;
  }
}

@media only screen and (max-width: 767px) {
  .description__btns .description__btn {
    flex: 0 0 calc(50% - 10px);
    margin: 0 5px;
  }
}

.description__btns .description__btn.btn_blue {
  min-width: 142px;
}

@media only screen and (max-width: 767px) {
  .description__btns .description__btn.btn_blue {
    width: calc(50% - 10px);
  }
}

.description__btns .description__btn.btn_gray {
  min-width: 102px;
}

@media only screen and (max-width: 767px) {
  .description__btns .description__btn.btn_gray {
    width: calc(50% - 10px);
  }
}

.description__btns .description__btn:not(:last-child) {
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .description__btns .description__btn:not(:last-child) {
    margin-right: 5px;
  }
}

.description__box {
  margin-bottom: 30px;
  text-align: center;
}

.description__parameters {
  border-top: 1px solid #F1F1F5;
}

.description__parameters:not(:last-child) {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .description__parameters:not(:last-child) {
    margin-bottom: 20px;
  }
}

.description__parameter {
  display: flex;
  align-items: center;
  height: 54px;
  border-bottom: 1px solid #F1F1F5;
}

@media only screen and (max-width: 767px) {
  .description__parameter {
    height: 46px;
  }
}

.description__parameter .description__category {
  display: flex;
  align-items: center;
  margin-right: auto;
  color: #696974;
}

.description__parameter .description__category .icon {
  margin-right: 10px;
  font-size: 16px;
  fill: #92929D;
}

.description__document {
  display: flex;
  align-items: center;
  line-height: 1.1;
  color: #171725;
  transition: color .25s;
}

.description__document:hover {
  color: #92929D;
}

.description__document:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .description__document:not(:last-child) {
    margin-bottom: 15px;
  }
}

.description__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  margin-right: 15px;
  border-radius: 10px;
  background: rgba(80, 181, 255, 0.1);
}

.description__icon .icon {
  font-size: 20px;
  fill: #50B5FF;
}

.description__size {
  margin-left: auto;
  padding-left: 15px;
  color: #696974;
}

.description__post {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #F1F1F5;
}

@media only screen and (max-width: 767px) {
  .description__post {
    margin-top: 20px;
  }
}

.description__post .description__stage {
  margin-bottom: 15px;
  color: #696974;
}

.description__user {
  display: flex;
  align-items: center;
}

.description__ava {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 15px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 0 3px #FAFAFB;
}

.description__ava .description__pic {
  width: 100%;
  min-height: 100%;
}

.description__man {
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
}

.description__user .description__details {
  font-size: 12px;
}

.description__specialization {
  color: #0062FF;
}

.description_big .description__bg {
  height: 200px;
}

@media only screen and (max-width: 767px) {
  .description_big .description__bg {
    height: 150px;
  }
}

.description_sm .description__bg {
  height: 150px;
}

@media only screen and (max-width: 1023px) {
  .description_sm .description__bg {
    height: 230px;
  }
}

@media only screen and (max-width: 767px) {
  .description_sm .description__bg {
    height: 150px;
  }
}

.description_big .description__logo {
  left: 40px;
  bottom: -42px;
  width: 84px;
  height: 84px;
  border-radius: 10px;
  box-shadow: 0 0 0 5px #FAFAFB;
}

@media only screen and (max-width: 1599px) {
  .description_big .description__logo {
    left: 30px;
  }
}

@media only screen and (max-width: 1365px) {
  .description_big .description__logo {
    left: 20px;
    bottom: -32px;
    width: 64px;
    height: 64px;
    box-shadow: 0 0 0 3px #FAFAFB;
  }
}

.description_sm .description__logo {
  left: 50%;
  bottom: -32px;
  transform: translateX(-50%);
  width: 64px;
  height: 64px;
  border-radius: 8px;
  box-shadow: 0 0 0 3px #FAFAFB;
}

.description_big .description__body {
  padding: 74px 40px 40px;
}

@media only screen and (max-width: 1599px) {
  .description_big .description__body {
    padding: 70px 30px 30px;
  }
}

@media only screen and (max-width: 1365px) {
  .description_big .description__body {
    padding: 45px 20px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .description_big .description__body {
    padding-bottom: 20px;
  }
}

.description_sm .description__body {
  padding: 62px 30px 30px;
}

@media only screen and (max-width: 1199px) {
  .description_sm .description__body {
    padding: 50px 20px 30px;
  }
}

.description_sm .description__title {
  margin-bottom: 12px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
}

.description_big .description__category {
  margin-bottom: 7px;
  font-size: 12px;
  color: #B5B5BE;
}

.description_sm .description__box .description__details {
  margin-bottom: 20px;
}

.description_sm .description__friends {
  display: inline-flex;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1023px) {
  .description_sm .description__friends {
    margin-bottom: 20px;
  }
}

.description_sm .description__actions {
  justify-content: center;
}

body.dark .description {
  background: #1C1C24;
}

body.dark .description__title {
  color: #FAFAFB;
}

body.dark .description__action {
  border-color: #696974;
}

body.dark .description__action:hover {
  background: #696974;
}

body.dark .description__action:hover .icon {
  fill: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  body.dark .description__action:hover {
    background: #292932;
  }
}

@media only screen and (max-width: 767px) {
  body.dark .description__action {
    background: #292932;
  }
}

body.dark .description__company, body.dark .description__counter,
body.dark .description__details .description__info {
  color: #FAFAFB;
}

body.dark .description__wrap {
  border-color: #292932;
}

body.dark .description__item {
  border-color: #292932;
}

body.dark .description__value {
  color: #FAFAFB;
}

body.dark .description__content {
  color: #FAFAFB;
}

body.dark .description__content p {
  color: #B5B5BE;
}

body.dark .description__content ul li {
  color: #B5B5BE;
}

body.dark .description__foot {
  border-color: #292932;
}

body.dark .description__question {
  color: #FAFAFB;
}

body.dark .description__parameters, body.dark .description__parameter {
  border-color: #292932;
}

body.dark .description__parameter .description__category {
  color: #92929D;
}

body.dark .description__document {
  color: #E2E2EA;
}

body.dark .description__document:hover {
  color: #FAFAFB;
}

body.dark .description__size {
  color: #B5B5BE;
}

body.dark .description__post {
  border-color: #292932;
}

body.dark .description__post .description__stage {
  color: #92929D;
}

body.dark .description__man {
  color: #FAFAFB;
}

body.dark .description__details span:not(:first-child):before,
body.dark .description__details a:not(:first-child):before {
  background: #92929D;
}

body.dark .description_big .description__logo {
  box-shadow: 0 0 0 5px #44444F;
}

@media only screen and (max-width: 1365px) {
  body.dark .description_big .description__logo {
    box-shadow: 0 0 0 3px #44444F;
  }
}

body.dark .description_sm body.dark .description__logo {
  box-shadow: 0 0 0 3px #44444F;
}

.applicant__nav {
  display: none;
  justify-content: center;
  background: #ffffff;
  border-bottom: 1px solid #F1F1F5;
}

@media only screen and (max-width: 1023px) {
  .applicant__nav {
    display: flex;
    margin: 0 -20px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .applicant__nav {
    margin-bottom: 20px;
  }
}

.applicant__nav .applicant__link {
  position: relative;
  display: flex;
  align-items: center;
  height: 56px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
  color: #171725;
  transition: color .25s;
}

@media only screen and (max-width: 767px) {
  .applicant__nav .applicant__link {
    font-size: 16px;
  }
}

.applicant__nav .applicant__link:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  margin: 0 auto;
  border-radius: 2px 2px 0 0;
  background: #0062FF;
  transition: width .25s;
}

.applicant__nav .applicant__link.active {
  color: #0062FF;
}

.applicant__nav .applicant__link.active:before {
  width: 100%;
}

.applicant__nav .applicant__link:not(:last-child) {
  margin-right: 50px;
}

.applicant__container {
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .applicant__container {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .applicant__container {
    margin: 0 -20px;
  }
}

@media only screen and (min-width: 769px) {
  .applicant__box {
    display: block !important;
  }
}

@media only screen and (max-width: 1023px) {
  .applicant__box {
    display: none;
  }
}

.applicant__box:first-child {
  flex-grow: 1;
  padding-right: 40px;
}

@media only screen and (max-width: 1439px) {
  .applicant__box:first-child {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 1365px) {
  .applicant__box:first-child {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .applicant__box:first-child {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .applicant__box:first-child {
    display: block;
    padding: 0;
  }
}

.applicant__box:nth-child(2) {
  flex-shrink: 0;
  width: 340px;
}

@media only screen and (max-width: 1439px) {
  .applicant__box:nth-child(2) {
    width: 320px;
  }
}

@media only screen and (max-width: 1365px) {
  .applicant__box:nth-child(2) {
    width: 380px;
  }
}

@media only screen and (max-width: 1199px) {
  .applicant__box:nth-child(2) {
    width: 300px;
  }
}

@media only screen and (max-width: 1023px) {
  .applicant__box:nth-child(2) {
    width: 100%;
  }
}

.applicant__row {
  display: flex;
}

@media only screen and (max-width: 1365px) {
  .applicant__row {
    display: block;
  }
}

.applicant__users {
  flex-shrink: 0;
  width: 330px;
}

@media only screen and (max-width: 1365px) {
  .applicant__users {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .applicant__users {
    margin-bottom: 20px;
  }
}

.applicant__wrap {
  flex-grow: 1;
  padding-left: 40px;
}

@media only screen and (max-width: 1439px) {
  .applicant__wrap {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1365px) {
  .applicant__wrap {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .applicant__wrap {
    padding: 0;
  }
}

.applicant__users .applicant__title {
  margin-bottom: 22px;
}

@media only screen and (max-width: 767px) {
  .applicant__users .applicant__title {
    display: none;
  }
}

.applicant__users .applicant__group {
  padding: 20px;
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .applicant__users .applicant__group {
    padding: 15px 20px;
  }
}

.applicant__user {
  display: flex;
  align-items: center;
}

.applicant__user:not(:last-child) {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #F1F1F5;
}

@media only screen and (max-width: 767px) {
  .applicant__user:not(:last-child) {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}

.applicant__ava {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 0 3px #FAFAFB;
}

@media only screen and (max-width: 767px) {
  .applicant__ava {
    width: 36px;
    height: 36px;
    border-radius: 7.5px;
    box-shadow: 0 0 0 2px #FAFAFB;
  }
}

.applicant__user .applicant__ava {
  margin-right: 15px;
}

.applicant__ava .applicant__pic {
  width: 100%;
  min-height: 100%;
}

.applicant__user .applicant__desc {
  flex-grow: 1;
}

.applicant__user .applicant__actions {
  flex-shrink: 0;
  margin-left: 15px;
}

.applicant__actions {
  display: flex;
}

.applicant__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  border: 2px solid #92929D;
  font-size: 0;
  transition: all .25s;
}

.applicant__action .icon {
  font-size: 8px;
  fill: #92929D;
  transition: fill .25s;
}

.applicant__action:hover {
  border-color: #B5B5BE;
}

.applicant__action:hover .icon {
  fill: #B5B5BE;
}

.applicant__action_accept.active {
  border-color: #3DD598;
  background: #3DD598;
}

.applicant__action_accept.active .icon {
  fill: #ffffff;
}

.applicant__action_reject.active {
  border-color: #FC5A5A;
  background: #FC5A5A;
}

.applicant__action_reject.active .icon {
  fill: #ffffff;
}

.applicant__action:not(:last-child) {
  margin-right: 12px;
}

.applicant__user .applicant__man {
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
}

.applicant__user .applicant__details {
  font-size: 12px;
}

.applicant__post {
  color: #0062FF;
}

.applicant__experience {
  font-size: 12px;
  color: #92929D;
}

.applicant__experience:not(:first-child), .applicant__post:not(:first-child) {
  margin-left: 5px;
}

.applicant__experience:not(:first-child):before, .applicant__post:not(:first-child):before {
  content: "";
  position: relative;
  top: -4px;
  display: inline-block;
  width: 3px;
  height: 3px;
  margin-right: 3px;
  border-radius: 50%;
  background: #D8D8D8;
}

.applicant__card {
  padding: 25px;
  background: #ffffff;
  border-radius: 10px;
}

@media only screen and (max-width: 1599px) {
  .applicant__card {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .applicant__card {
    padding: 15px 20px;
  }
}

.applicant__card:not(:last-child) {
  margin-bottom: 20px;
}

.applicant__card .applicant__title {
  margin-bottom: 16px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}

.applicant__table {
  display: table;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .applicant__table {
    display: block;
  }
}

.applicant__line {
  display: table-row;
}

@media only screen and (max-width: 767px) {
  .applicant__line {
    position: relative;
    display: block;
    padding: 10px 15px 55px 65px;
    border: 1px solid #F1F1F5;
    border-radius: 10px;
  }
  .applicant__line:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40px;
    border-top: 1px solid #F1F1F5;
    background: #FAFAFB;
    border-radius: 0 0 10px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .applicant__line_head {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .applicant__line:not(:last-child) {
    margin-bottom: 10px;
  }
}

.applicant__line:nth-child(2) .applicant__cell {
  height: 78px;
  padding-top: 20px;
}

@media only screen and (max-width: 767px) {
  .applicant__line:nth-child(2) .applicant__cell {
    height: auto;
    padding: 0;
  }
}

.applicant__cell {
  display: table-cell;
  vertical-align: middle;
  height: 68px;
  padding: 0 10px;
  color: #44444F;
}

@media only screen and (max-width: 1199px) {
  .applicant__cell {
    padding: 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .applicant__cell {
    position: relative;
    z-index: 2;
    display: block;
    height: auto;
    padding: 0;
  }
}

.applicant__cell:first-child {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .applicant__cell:first-child {
    position: absolute;
    top: 15px;
    left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .applicant__cell:nth-child(2) {
    font-weight: 500;
    line-height: 1.71429;
  }
}

@media only screen and (max-width: 767px) {
  .applicant__cell:nth-child(3) {
    position: absolute;
    top: 25px;
    right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .applicant__cell:nth-child(4) {
    position: absolute;
    left: 65px;
    bottom: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .applicant__cell:nth-child(5) {
    position: absolute;
    right: 15px;
    bottom: 12px;
  }
}

.applicant__line_head .applicant__cell {
  height: 38px;
  background: #FAFAFB;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 11px;
  letter-spacing: .8px;
  font-weight: 600;
  text-transform: uppercase;
  color: #92929D;
}

.applicant__line_head .applicant__cell:first-child {
  border-radius: 10px 0 0 10px;
}

.applicant__line_head .applicant__cell:last-child {
  border-radius: 0 10px 10px 0;
}

.applicant__table .applicant__ava {
  display: inline-block;
}

.applicant__table .applicant__link {
  display: inline-flex;
  align-items: center;
  color: #0062FF;
  transition: color .25s;
}

.applicant__table .applicant__link .icon {
  margin-right: 5px;
  font-size: 14px;
  fill: #0062FF;
  transition: fill .25s;
}

.applicant__table .applicant__link:hover {
  color: #50B5FF;
}

.applicant__table .applicant__link:hover .icon {
  fill: #50B5FF;
}

.applicant__note {
  display: none;
}

@media only screen and (max-width: 767px) {
  .applicant__note {
    display: inline;
    margin-right: 5px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    font-size: 11px;
    font-weight: 600;
    color: #696974;
  }
}

.applicant__item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
}

@media only screen and (max-width: 767px) {
  .applicant__item {
    border-radius: 10px;
    border: 1px solid #F1F1F5;
  }
  .applicant__item:not(:last-child) {
    margin-bottom: 10px;
  }
}

.applicant__brand {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.applicant__brand .applicant__text {
  margin-left: 20px;
  font-weight: 500;
  color: #44444F;
}

@media only screen and (max-width: 767px) {
  .applicant__brand .applicant__text {
    margin-left: 15px;
  }
}

.applicant__item .applicant__ava,
.applicant__leader .applicant__ava {
  width: 36px;
  height: 36px;
  border-radius: 7.5px;
}

.applicant__team {
  display: flex;
  align-items: center;
}

.applicant__list {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .applicant__list .applicant__ava {
    width: 28px;
    height: 28px;
  }
}

.applicant__list .applicant__ava:not(:last-child) {
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .applicant__list .applicant__ava:not(:last-child) {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .applicant__list .applicant__ava:nth-child(n+4) {
    display: none;
  }
}

.applicant__counter {
  display: none;
}

@media only screen and (max-width: 767px) {
  .applicant__counter {
    display: block;
    width: 28px;
    flex-shrink: 0;
    text-align: center;
    box-shadow: 0 0 0 2px #E2E2EA;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    font-size: 12px;
    line-height: 28px;
    color: #696974;
  }
}

.applicant__leader {
  display: flex;
  padding: 15px 10px;
}

@media only screen and (max-width: 767px) {
  .applicant__leader {
    position: relative;
    padding: 10px 0;
  }
}

.applicant__leader .applicant__ava {
  margin-right: 15px;
}

.applicant__leader .applicant__desc {
  flex-grow: 1;
}

.applicant__top {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 639px) {
  .applicant__top {
    flex-wrap: wrap;
  }
}

.applicant__leader .applicant__man {
  font-weight: 500;
  color: #44444F;
}

.applicant__result {
  display: flex;
  align-items: center;
  margin-left: 13px;
  color: #92929D;
}

@media only screen and (max-width: 639px) {
  .applicant__result {
    margin: 13px 0 0;
    flex: 0 0 100%;
  }
}

.applicant__result .icon {
  margin-right: 3px;
  font-size: 16px;
  fill: #92929D;
}

.applicant__bottom {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .applicant__bottom {
    display: block;
    margin-top: 10px;
  }
}

.applicant__scale {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.applicant__progress {
  position: relative;
  flex-grow: 1;
  height: 4px;
  border-radius: 2px;
  background: #E2E2EA;
}

.applicant__progress .applicant__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #50B5FF;
  border-radius: 2px;
}

.applicant__percent {
  flex-shrink: 0;
  width: 45px;
  text-align: right;
  color: #696974;
}

.applicant__status {
  flex-shrink: 0;
  min-width: 100px;
  margin-left: 20px;
  padding: 0 7px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  line-height: 26px;
}

@media only screen and (max-width: 767px) {
  .applicant__status {
    position: absolute;
    left: 90px;
    bottom: 33px;
  }
}

.applicant__status_green {
  background: rgba(61, 213, 152, 0.1);
  color: #3DD598;
}

.applicant__status_blue {
  background: rgba(80, 181, 255, 0.1);
  color: #50B5FF;
}

.applicant__status_red {
  background: rgba(252, 90, 90, 0.1);
  color: #FC5A5A;
}

body.dark .applicant__title {
  color: #FAFAFB;
}

body.dark .applicant__users .applicant__group {
  background: #1C1C24;
}

body.dark .applicant__user {
  border-color: #1C1C24;
}

body.dark .applicant__ava {
  box-shadow: 0 0 0 3px #44444F;
}

body.dark .applicant__man {
  color: #FAFAFB;
}

body.dark .applicant__post {
  color: #1E75FF;
}

body.dark .applicant__experience:not(:first-child):before, body.dark .applicant__post:not(:first-child):before {
  background: #92929D;
}

body.dark .applicant__card {
  background: #1C1C24;
}

@media only screen and (max-width: 767px) {
  body.dark .applicant__line {
    border-color: #292932;
  }
  body.dark .applicant__line:before {
    background: #292932;
    border-color: #292932;
  }
}

body.dark .applicant__note {
  color: #92929D;
}

body.dark .applicant__link {
  color: #1E75FF;
}

body.dark .applicant__link .icon {
  fill: #1E75FF;
}

body.dark .applicant__line_head .applicant__cell {
  background: #292932;
}

body.dark .applicant__cell {
  color: #E2E2EA;
}

@media only screen and (max-width: 767px) {
  body.dark .applicant__cell:nth-child(6) {
    color: #92929D;
  }
}

body.dark .applicant__brand .applicant__text {
  color: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  body.dark .applicant__brand .applicant__text {
    color: #E2E2EA;
  }
}

body.dark .applicant__counter {
  color: #E2E2EA;
}

body.dark .applicant__percent {
  color: #B5B5BE;
}

body.dark .applicant__nav {
  background: #1C1C24;
  border-color: #292932;
}

body.dark .applicant__nav .applicant__link {
  color: #92929D;
}

body.dark .applicant__nav .applicant__link:before {
  background: #FAFAFB;
}

body.dark .applicant__nav .applicant__link.active {
  color: #FAFAFB;
}

body.dark .applicant__item {
  border-color: #292932;
}

.admin__head {
  display: flex;
  align-items: center;
  margin-bottom: 54px;
}

@media only screen and (max-width: 1365px) {
  .admin__head {
    margin-bottom: 46px;
  }
}

@media only screen and (max-width: 1023px) {
  .admin__head {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .admin__head {
    display: block;
  }
}

.admin__head .admin__title {
  margin-bottom: 9px;
}

@media only screen and (max-width: 767px) {
  .admin__head .admin__title {
    margin-bottom: 5px;
  }
}

.admin__date {
  font-size: 16px;
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .admin__date {
    font-size: 14px;
  }
}

.admin__btns {
  display: flex;
  margin-left: auto;
}

@media only screen and (max-width: 767px) {
  .admin__btns {
    margin: 20px -5px 0;
  }
}

.admin__btns .admin__btn {
  min-width: 256px;
}

@media only screen and (max-width: 1199px) {
  .admin__btns .admin__btn {
    min-width: 200px;
  }
}

@media only screen and (max-width: 1023px) {
  .admin__btns .admin__btn {
    min-width: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .admin__btns .admin__btn {
    flex: 0 0 calc(50% - 10px);
    min-width: calc(50% - 10px);
    margin: 0 5px;
  }
}

.admin__btns .admin__btn:not(:last-child) {
  margin-right: 30px;
}

@media only screen and (max-width: 1023px) {
  .admin__btns .admin__btn:not(:last-child) {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .admin__btns .admin__btn:not(:last-child) {
    margin-right: 5px;
  }
}

.admin__list {
  display: flex;
  margin: 0 -18px 36px;
}

@media only screen and (max-width: 1365px) {
  .admin__list {
    margin: 0 -10px 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .admin__list {
    flex-wrap: wrap;
    margin-top: -20px;
  }
}

@media only screen and (max-width: 767px) {
  .admin__list {
    margin: -10px -5px 15px;
  }
}

.admin__item {
  flex: 0 0 calc(25% - 36px);
  width: calc(25% - 36px);
  margin: 0 18px;
  padding: 0 20px 30px;
  border-radius: 10px;
  background: #ffffff;
  text-align: center;
}

@media only screen and (max-width: 1365px) {
  .admin__item {
    flex: 0 0 calc(25% - 20px);
    width: calc(25% - 20px);
    margin: 0 10px;
  }
}

@media only screen and (max-width: 1199px) {
  .admin__item {
    padding: 0 10px 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .admin__item {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .admin__item {
    flex: 0 0 calc(50% - 10px);
    width: calc(50% - 10px);
    margin: 30px 5px 0;
  }
}

.admin__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  margin: -18px auto 20px;
  border-radius: 15px;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .admin__icon {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .admin__icon {
    width: 48px;
    height: 48px;
    border-radius: 13px;
  }
}

.admin__icon .icon {
  font-size: 25px;
  fill: #ffffff;
}

@media only screen and (max-width: 767px) {
  .admin__icon .icon {
    font-size: 22px;
  }
}

.admin__icon_up {
  background: #50B5FF;
}

.admin__icon_down {
  background: #FC5A5A;
}

.admin__icon_minus {
  background: #FFC542;
}

.admin__number {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 28px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .admin__number {
    font-size: 24px;
  }
}

.admin__item .admin__info {
  margin-top: 4px;
  color: #696974;
}

@media only screen and (max-width: 767px) {
  .admin__item .admin__info {
    font-size: 12px;
  }
}

.admin__row {
  display: flex;
  margin: 0 -18px;
}

@media only screen and (max-width: 1365px) {
  .admin__row {
    margin: 0 -10px;
  }
}

@media only screen and (max-width: 1023px) {
  .admin__row {
    display: block;
    margin: 0;
  }
}

.admin__row:not(:last-child) {
  margin-bottom: 36px;
}

@media only screen and (max-width: 1365px) {
  .admin__row:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .admin__row_tablet {
    display: block;
  }
  .admin__row_tablet .admin__col {
    width: 100%;
    margin: 0;
  }
  .admin__row_tablet .admin__col:not(:last-child) {
    margin-bottom: 20px;
  }
}

.admin__col {
  margin: 0 18px;
}

@media only screen and (max-width: 1365px) {
  .admin__col {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 1023px) {
  .admin__col {
    margin: 0;
  }
}

.admin__col_w25 {
  flex: 0 0 calc(25% - 36px);
  width: calc(25% - 36px);
}

@media only screen and (max-width: 1365px) {
  .admin__col_w25 {
    flex: 0 0 calc(25% - 20px);
    width: calc(25% - 20px);
  }
}

@media only screen and (max-width: 1023px) {
  .admin__col_w25 {
    width: 100%;
  }
}

.admin__col_w35 {
  flex: 0 0 calc(35% - 36px);
  width: calc(35% - 36px);
}

@media only screen and (max-width: 1365px) {
  .admin__col_w35 {
    flex: 0 0 calc(35% - 20px);
    width: calc(35% - 20px);
  }
}

@media only screen and (max-width: 1023px) {
  .admin__col_w35 {
    width: 100%;
  }
}

.admin__col_w65 {
  flex: 0 0 calc(65% - 36px);
  width: calc(65% - 36px);
}

@media only screen and (max-width: 1365px) {
  .admin__col_w65 {
    flex: 0 0 calc(65% - 20px);
    width: calc(65% - 20px);
  }
}

@media only screen and (max-width: 1023px) {
  .admin__col_w65 {
    width: 100%;
  }
}

.admin__col_w75 {
  flex: 0 0 calc(75% - 36px);
  width: calc(75% - 36px);
}

@media only screen and (max-width: 1365px) {
  .admin__col_w75 {
    flex: 0 0 calc(75% - 20px);
    width: calc(75% - 20px);
  }
}

@media only screen and (max-width: 1023px) {
  .admin__col_w75 {
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .admin__col:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .admin__col:not(:last-child) {
    margin-bottom: 15px;
  }
}

.admin__card {
  padding: 30px 20px;
  background: #ffffff;
  border-radius: 10px;
}

@media only screen and (max-width: 1365px) {
  .admin__card {
    padding: 20px 15px;
  }
}

.admin__card_p30 {
  padding: 30px;
}

@media only screen and (max-width: 1365px) {
  .admin__card_p30 {
    padding: 20px;
  }
}

.admin__card_pb20 {
  padding-bottom: 20px;
}

.admin__card:not(:last-child) {
  margin-bottom: 40px;
}

@media only screen and (max-width: 1365px) {
  .admin__card:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .admin__card:not(:last-child) {
    margin-bottom: 15px;
  }
}

.admin__top {
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .admin__top {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .admin__top {
    flex-wrap: wrap;
    margin-bottom: 25px;
  }
}

.admin__total {
  margin-bottom: 10px;
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .admin__total {
    margin-bottom: 7px;
  }
}

.admin__top .admin__details {
  margin-right: auto;
}

.admin__top .admin__number {
  line-height: 38px;
}

.admin__legends {
  display: flex;
  height: 38px;
  margin-right: 30px;
}

@media only screen and (max-width: 1199px) {
  .admin__legends {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .admin__legends {
    flex-wrap: wrap;
    margin: 0 0 0 -15px;
    order: 3;
  }
}

.admin__parameter {
  display: flex;
  align-items: center;
  color: #44444F;
}

@media only screen and (max-width: 1199px) {
  .admin__parameter {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .admin__parameter {
    margin: 15px 0 0 15px;
    font-size: 14px;
  }
}

.admin__parameter:not(:last-child) {
  margin-right: 20px;
}

@media only screen and (max-width: 1199px) {
  .admin__parameter:not(:last-child) {
    margin-right: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .admin__parameter:not(:last-child) {
    margin-right: 0;
  }
}

.admin__bg {
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  margin-right: 10px;
  border-radius: 2px;
  box-shadow: 0 0 0 2px #FAFAFB;
}

@media only screen and (max-width: 1199px) {
  .admin__bg {
    margin-right: 5px;
  }
}

.admin__top .admin__select {
  min-width: 120px;
  height: 38px;
  padding: 0 40px 0 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #E2E2EA;
  border-radius: 10px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat calc(100% - 13px) 55%/11px 7px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #44444F;
  cursor: pointer;
}

.admin__top .admin__select::-ms-expand {
  display: none;
}

.admin__top .admin__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.admin__bottom {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

@media only screen and (max-width: 1199px) {
  .admin__bottom {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .admin__bottom {
    display: block;
    margin-top: 10px;
  }
}

.admin__bottom .admin__details {
  margin-right: auto;
}

.admin__bottom .admin__title {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  color: #171725;
  transition: color .25s;
}

.admin__bottom .admin__title .icon {
  margin-left: 20px;
  font-size: 11px;
  fill: #0062FF;
}

.admin__bottom .admin__title:hover {
  color: #0062FF;
}

.admin__bottom .admin__info {
  line-height: 1.71429;
  color: #888891;
}

.admin__variants {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 20px;
}

@media only screen and (max-width: 767px) {
  .admin__variants {
    margin: 10px 0 0;
  }
}

.admin__variants .admin__text {
  margin-right: 3px;
  font-size: 16px;
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  .admin__variants .admin__text {
    font-size: 14px;
  }
}

.admin__select {
  height: auto;
  padding: 0 22px 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%230062ff'/%3E%3C/svg%3E") no-repeat 100% 55%/11px 7px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #0062FF;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .admin__select {
    font-size: 14px;
  }
}

.admin__select::-ms-expand {
  display: none;
}

.admin__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.admin__flex {
  display: flex;
  align-items: center;
}

.admin__flex .admin__title {
  margin-right: auto;
}

.admin__popular {
  margin-top: 15px;
}

.admin__line {
  display: flex;
  align-items: center;
  padding: 15px 0;
}

.admin__line:first-child {
  padding-top: 5px;
}

.admin__line:last-child {
  padding-bottom: 5px;
}

.admin__line:not(:last-child) {
  border-bottom: 1px solid #F1F1F5;
}

.admin__popular .admin__details {
  flex-grow: 1;
}

.admin__popular .admin__category {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #44444F;
}

.admin__popular .admin__info {
  font-weight: 500;
  color: #B5B5BE;
}

@media only screen and (max-width: 1365px) {
  .admin__popular .admin__info {
    font-size: 12px;
  }
}

.admin__line .admin__chart {
  max-width: 60px;
  margin: 0 20px;
}

@media only screen and (max-width: 1439px) {
  .admin__line .admin__chart {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 1199px) {
  .admin__line .admin__chart {
    max-width: 120px;
    margin: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .admin__line .admin__chart {
    max-width: 60px;
    margin: 0 20px;
  }
}

.admin__chart svg {
  overflow: visible;
}

.admin__chart_total {
  height: 310px;
  margin: 0 0 -15px -15px;
}

@media only screen and (max-width: 767px) {
  .admin__chart_total {
    height: 230px;
    margin-left: -20px;
  }
}

.admin__chart_total .apexcharts-tooltip.apexcharts-theme-light {
  padding: 12px;
  border: none;
  border-radius: 8px;
  box-shadow: none;
  background: #292932;
  font-size: 12px;
}

.admin__chart_total .apexcharts-tooltip-series-group {
  padding: 0;
  align-items: center;
}

.admin__chart_total .apexcharts-tooltip-series-group:not(:last-child) {
  margin-bottom: 8px;
}

.admin__chart_total .apexcharts-tooltip-series-group.apexcharts-active,
.admin__chart_total .apexcharts-tooltip-series-group:last-child {
  margin: 0;
  padding: 0;
}

.admin__chart_total .apexcharts-tooltip-marker {
  width: 6px;
  height: 6px;
  margin-right: 5px;
  border-radius: 2px;
}

.admin__chart_total .apexcharts-tooltip-text-value,
.admin__chart_total .apexcharts-tooltip-text-z-value {
  margin-left: 3px;
  font-weight: 500;
  color: #FAFAFB;
}

.admin__chart_total .apexcharts-tooltip-text-label {
  color: #B5B5BE;
}

.admin__chart_financial {
  height: 230px;
}

@media only screen and (max-width: 767px) {
  .admin__chart_financial {
    margin-top: -15px;
  }
}

.admin__chart_financial .apexcharts-tooltip.apexcharts-theme-light {
  padding: 10px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
  background: #ffffff;
}

.admin__chart_financial .tooltip__box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin__chart_financial .tooltip__price {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}

.admin__chart_financial .tooltip__price .icon {
  margin-left: 5px;
  font-size: 12px;
  fill: #92929D;
}

.admin__chart_financial .tooltip__date {
  color: #696974;
}

.admin__chart_percent {
  height: 130px;
}

@media only screen and (max-width: 1439px) {
  .admin__chart_percent {
    margin: 0 -10px;
  }
}

@media only screen and (max-width: 1365px) {
  .admin__chart_percent {
    margin: 0 -20px;
  }
}

@media only screen and (max-width: 1199px) {
  .admin__chart_percent {
    margin: 0 -30px;
  }
}

@media only screen and (max-width: 1023px) {
  .admin__chart_percent {
    max-width: 280px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 767px) {
  .admin__chart_percent {
    margin-top: -10px;
    max-width: 100%;
  }
}

.admin__result {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 500;
  color: #44444F;
}

.admin__result .icon {
  font-size: 16px;
}

.admin__result .icon-profile {
  margin-right: 5px;
  fill: #92929D;
}

.admin__result .icon-arrow-top {
  fill: #92929D;
}

.admin__result .icon-arrow-bottom {
  fill: #FC5A5A;
}

.admin__result .admin__text {
  min-width: 42px;
  margin-right: 5px;
}

.admin__stat {
  text-align: center;
}

.admin__stat .admin__title {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .admin__stat .admin__title {
    margin-bottom: 20px;
  }
}

.admin__stat .admin__number {
  margin: -70px 0 13px;
}

@media only screen and (max-width: 767px) {
  .admin__stat .admin__number {
    margin-top: -20px;
  }
}

.admin__stat .admin__info {
  margin-bottom: 30px;
  line-height: 1.57143;
  color: #B5B5BE;
}

@media only screen and (max-width: 767px) {
  .admin__stat .admin__info {
    margin-bottom: 20px;
  }
}

.admin__stat .admin__btn {
  min-width: 143px;
}

.admin__jobs .admin__info {
  margin-bottom: 8px;
  color: #92929D;
}

@media only screen and (max-width: 1199px) {
  .admin__jobs .admin__info {
    margin-bottom: 5px;
  }
}

.admin__counter {
  font-size: 16px;
  color: #92929D;
}

.admin__counter span {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 24px;
  font-weight: 600;
  color: #171725;
}

.admin__box {
  display: flex;
}

.admin__status {
  margin-left: auto;
  padding: 0 10px;
  border-radius: 5px;
  background: rgba(0, 98, 255, 0.1);
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 10px;
  font-weight: 500;
  line-height: 30px;
  color: #0062FF;
}

@media only screen and (max-width: 1199px) {
  .admin__status {
    position: relative;
    top: -5px;
  }
}

@media only screen and (max-width: 1023px) {
  .admin__status {
    position: static;
  }
}

.admin__jobs .admin__flex {
  margin-bottom: 15px;
}

@media only screen and (max-width: 1199px) {
  .admin__jobs .admin__flex {
    align-items: flex-end;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1023px) {
  .admin__jobs .admin__flex {
    align-items: center;
  }
}

.admin__jobs .admin__group {
  max-height: 345px;
  overflow: auto;
}

@media only screen and (max-width: 767px) {
  .admin__jobs .admin__group {
    max-height: 365px;
  }
}

.admin__box {
  display: flex;
  padding: 13px 15px;
  border-radius: 10px;
  background: #FAFAFB;
  transition: background .25s;
}

@media only screen and (max-width: 1199px) {
  .admin__box {
    padding: 10px;
  }
}

@media only screen and (max-width: 1023px) {
  .admin__box {
    padding: 15px;
  }
}

.admin__box:hover {
  background: #ececf0;
}

.admin__box:not(:last-child) {
  margin-bottom: 10px;
}

.admin__logo {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  margin-right: 10px;
  border: 3px solid #FAFAFB;
  border-radius: 8px;
  overflow: hidden;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .admin__logo {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 1023px) {
  .admin__logo {
    margin-right: 10px;
  }
}

.admin__logo .admin__pic {
  width: 100%;
  min-height: 100%;
}

.admin__jobs .admin__title {
  margin-bottom: 7px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
  color: #171725;
}

.admin__jobs .admin__info {
  margin-bottom: 10px;
  font-size: 12px;
  color: #92929D;
}

.admin__jobs .admin__info span:not(:first-child) {
  margin-left: 5px;
}

.admin__jobs .admin__info span:not(:first-child):before {
  content: "";
  position: relative;
  top: -3px;
  display: inline-block;
  width: 3px;
  height: 3px;
  margin-right: 3px;
  border-radius: 50%;
  background: #D8D8D8;
}

.admin__price {
  font-size: 12px;
  color: #92929D;
}

.admin__money {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 600;
  color: #171725;
}

body.dark .admin__title {
  color: #FAFAFB;
}

body.dark .admin__item {
  background: #1C1C24;
}

body.dark .admin__number {
  color: #FAFAFB;
}

body.dark .admin__item .admin__info {
  color: #B5B5BE;
}

body.dark .admin__card {
  background: #1C1C24;
}

body.dark .admin__top .admin__select {
  border-color: #4F4F5A;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%23B5B5BE'/%3E%3C/svg%3E");
  color: #B5B5BE;
}

body.dark .admin__parameter {
  color: #E2E2EA;
}

body.dark .admin__bg {
  box-shadow: 0 0 0 2px #292932;
}

body.dark .admin__bottom .admin__info {
  color: #92929D;
}

body.dark .admin__select {
  color: #1E75FF;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%231e75ff'/%3E%3C/svg%3E");
}

body.dark .admin__popular .admin__category {
  color: #E2E2EA;
}

body.dark .admin__popular .admin__info {
  color: #44444F;
}

body.dark .admin__line {
  border-color: #292932;
}

body.dark .admin__result {
  color: #E2E2EA;
}

body.dark .admin__jobs .admin__info {
  color: #D5D5DC;
}

body.dark .admin__counter {
  color: #D5D5DC;
}

body.dark .admin__counter span {
  color: #FAFAFB;
}

body.dark .admin__status {
  background: #292932;
  color: #D5D5DC;
}

body.dark .admin__box {
  background: #292932;
}

body.dark .admin__box:hover {
  background: #44444F;
}

body.dark .admin__logo {
  border-color: #44444F;
}

body.dark .admin__money {
  color: #FAFAFB;
}

body.dark .admin .apexcharts-gridline {
  stroke: #292932;
}

body.dark .admin .apexcharts-xcrosshairs.apexcharts-active,
body.dark .admin .apexcharts-ycrosshairs.apexcharts-active {
  stroke: #44444F;
}

body.dark .admin .apexcharts-xaxis line {
  stroke: #292932;
}

body.dark .admin__chart svg {
  overflow: visible;
}

body.dark .admin__chart_financial .apexcharts-tooltip.apexcharts-theme-light {
  background: #292932;
}

body.dark .admin__chart_financial .tooltip__price {
  color: #FAFAFB;
}

body.dark .admin__chart_financial .tooltip__date {
  color: #92929D;
}

body.dark .admin__line .admin__chart .apexcharts-tooltip.apexcharts-theme-light {
  border: none;
  background: #292932;
  box-shadow: none;
}

body.dark .admin__line .admin__chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  border-color: #44444F;
  background: #292932;
  color: #FAFAFB;
}

body.dark .admin__line .admin__chart .apexcharts-tooltip-text-value,
body.dark .admin__line .admin__chart .apexcharts-tooltip-text-z-value {
  color: #FAFAFB;
}

body.dark .admin__line .admin__chart .apexcharts-tooltip-text-label {
  color: #B5B5BE;
}

.products__container {
  background: #ffffff;
  border-radius: 20px;
}

.products__body {
  padding: 20px 10px 0;
}

.products__head {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 10px;
}

@media only screen and (max-width: 1365px) {
  .products__head {
    margin-bottom: 20px;
    padding: 0;
  }
}

.products__search {
  position: relative;
  flex-grow: 1;
}

.products__input {
  width: 100%;
  height: 38px;
  padding: 0 20px 0 42px;
  border-radius: 10px;
  border: 1px solid #E2E2EA;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  color: #171725;
}

.products__input::placeholder {
  color: #92929D;
}

.products__open {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 42px;
  font-size: 0;
}

.products__open .icon {
  font-size: 20px;
  fill: #92929D;
  transition: fill .25s;
}

.products__open:hover .icon {
  fill: #B5B5BE;
}

.products__head .products__select {
  min-width: 94px;
  height: 38px;
  margin-left: 20px;
  padding: 0 30px 0 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #E2E2EA;
  border-radius: 10px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat calc(100% - 15px) 55%/11px 7px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #44444F;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .products__head .products__select {
    margin-left: 10px;
  }
}

.products__head .products__select::-ms-expand {
  display: none;
}

.products__head .products__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.products__row {
  display: flex;
  align-items: center;
  height: 72px;
  padding: 5px 15px;
  border-radius: 15px;
}

@media only screen and (max-width: 767px) {
  .products__row {
    position: relative;
    height: auto;
    padding: 15px 0 55px;
  }
  .products__row:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    height: 1px;
    background: #F1F1F5;
  }
}

.products__row_head {
  height: 38px;
  background: #FAFAFB;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: .8px;
  text-transform: uppercase;
  color: #44444F;
}

@media only screen and (max-width: 767px) {
  .products__row_head {
    display: none;
  }
}

.products__row:not(.products__row_head) {
  margin-top: -1px;
  border: 1px solid transparent;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .products__row:not(.products__row_head) {
    border: none;
  }
}

.products__row:not(.products__row_head):hover {
  border-color: #3DD598;
}

.products__cell {
  text-align: right;
  color: #44444F;
}

@media only screen and (max-width: 767px) {
  .products__cell {
    text-align: left;
  }
}

.products__cell:first-child {
  width: 20px;
  text-align: center;
  font-size: 0;
}

.products__cell:nth-child(2) {
  flex-grow: 1;
  padding: 0 20px;
  text-align: left;
}

@media only screen and (max-width: 1365px) {
  .products__cell:nth-child(2) {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .products__cell:nth-child(2) {
    flex: 0 0 calc(100% - 20px);
    max-width: calc(100% - 20px);
    padding-right: 100px;
  }
}

.products__cell:nth-child(3) {
  width: 160px;
  padding-right: 15px;
}

@media only screen and (max-width: 1439px) {
  .products__cell:nth-child(3) {
    width: 130px;
  }
}

@media only screen and (max-width: 1365px) {
  .products__cell:nth-child(3) {
    width: 115px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .products__cell:nth-child(3) {
    position: absolute;
    top: 40px;
    left: 82px;
    width: auto;
    padding: 0;
    font-size: 12px;
    color: #696974;
  }
}

.products__cell:nth-child(4), .products__cell:nth-child(5), .products__cell:nth-child(7) {
  width: 140px;
  padding-right: 15px;
}

@media only screen and (max-width: 1439px) {
  .products__cell:nth-child(4), .products__cell:nth-child(5), .products__cell:nth-child(7) {
    width: 110px;
  }
}

@media only screen and (max-width: 1365px) {
  .products__cell:nth-child(4), .products__cell:nth-child(5), .products__cell:nth-child(7) {
    width: 80px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .products__cell:nth-child(4), .products__cell:nth-child(5), .products__cell:nth-child(7) {
    width: auto;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
  }
}

@media only screen and (max-width: 767px) {
  .products__cell:nth-child(4) {
    position: absolute;
    top: 80px;
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .products__cell:nth-child(5) {
    position: absolute;
    top: 80px;
    left: 53%;
    transform: translateX(-50%);
  }
}

.products__cell:nth-child(6) {
  width: 100px;
  padding-right: 15px;
}

@media only screen and (max-width: 1365px) {
  .products__cell:nth-child(6) {
    width: 80px;
  }
}

@media only screen and (max-width: 1199px) {
  .products__cell:nth-child(6) {
    width: 55px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .products__cell:nth-child(6) {
    position: absolute;
    top: 80px;
    right: 30px;
    width: auto;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
  }
}

.products__cell:nth-child(7) {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .products__cell:nth-child(7) {
    position: absolute;
    top: 27px;
    right: 30px;
  }
}

.products__cell:nth-child(8) {
  width: 74px;
}

@media only screen and (max-width: 1365px) {
  .products__cell:nth-child(8) {
    width: 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .products__cell:nth-child(8) {
    width: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .products__cell:nth-child(8) {
    position: absolute;
    top: 30px;
    right: 0;
  }
}

.products__cell:not(:nth-child(2)) {
  flex-shrink: 0;
}

.products__details {
  display: flex;
  align-items: center;
}

.products__preview {
  flex-shrink: 0;
  width: 42px;
  margin-right: 15px;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .products__preview {
    margin-right: 10px;
  }
}

.products__preview .products__pic {
  max-width: 100%;
  max-height: 42px;
  border-radius: 10px;
}

.products__title {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .products__title {
    flex: 0 0 calc(100% - 52px);
    padding-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.products__note {
  display: none;
  margin-right: 9px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  color: #696974;
}

@media only screen and (max-width: 767px) {
  .products__note {
    display: inline;
    position: relative;
    top: -1px;
  }
}

.products .options2__btn {
  padding: 5px 6px;
  border-radius: 4px;
}

.products .options2__dropdown {
  top: calc(100% + 5px);
  width: 174px;
  padding: 5px 10px;
  text-align: left;
  box-shadow: none;
  background: #44444F;
}

.products .options2__link {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #FAFAFB;
}

.products .options2__link .icon {
  margin-right: 8px;
  font-size: 12px;
  fill: #FAFAFB;
}

.products .options2__link .icon-plus {
  margin: 0 9px 0 1px;
  font-size: 10px;
}

.products__foot {
  display: flex;
  align-items: center;
  padding: 30px;
  border-top: 1px solid #F1F1F5;
}

@media only screen and (max-width: 767px) {
  .products__foot {
    padding: 20px 15px;
  }
}

.products__counter {
  margin-right: auto;
  color: #44444F;
}

body.dark .products__container {
  background: #1C1C24;
}

body.dark .products__input {
  border-color: #44444F;
  background: transparent;
}

body.dark .products__input::placeholder {
  color: #92929D;
}

body.dark .products__head .products__select {
  border-color: #4F4F5A;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%23b5b5be'/%3E%3C/svg%3E");
  color: #B5B5BE;
}

body.dark .products__row_head {
  background: #292932;
}

body.dark .products__row:not(.products__row_head):hover {
  background: #292932;
  border-color: #292932;
}

body.dark .products__row:before {
  background: #44444F;
}

body.dark .products__row_head .products__cell {
  color: #92929D;
}

body.dark .products__cell {
  color: #FAFAFB;
}

@media only screen and (max-width: 767px) {
  body.dark .products__cell:nth-child(3) {
    color: #92929D;
  }
}

@media only screen and (max-width: 767px) {
  body.dark .products__cell:nth-child(4), body.dark .products__cell:nth-child(5), body.dark .products__cell:nth-child(6) {
    color: #D5D5DC;
  }
}

body.dark .products__title {
  color: #FAFAFB;
}

body.dark .products__foot {
  border-color: #44444F;
}

body.dark .products__counter {
  color: #92929D;
}

body.dark .products__note {
  color: #FAFAFB;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination__wrap {
  display: flex;
  align-items: center;
}

.pagination__arrow, .pagination__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.pagination__arrow {
  border-radius: 8px;
  border: 1px solid #E2E2EA;
  font-size: 0;
}

.pagination__arrow .icon {
  font-size: 24px;
  fill: #92929D;
}

@media only screen and (max-width: 767px) {
  .pagination__arrow:not(:last-child) {
    margin-right: 15px;
  }
}

.pagination__arrow {
  transition: all .25s;
}

.pagination__arrow:hover {
  background: #E2E2EA;
}

.pagination__link {
  font-size: 14px;
  color: #92929D;
  transition: color .25s;
}

.pagination__link:hover, .pagination__link.active {
  color: #0062FF;
}

.pagination__link.active {
  font-weight: 700;
}

.pagination__list {
  display: flex;
  margin: 0 15px;
}

@media only screen and (max-width: 767px) {
  .pagination__list {
    display: none;
  }
}

.pagination__view {
  position: relative;
  display: inline-block;
  margin-left: 15px;
}

@media only screen and (max-width: 767px) {
  .pagination__view {
    display: none;
  }
}

.pagination__select {
  min-width: 61px;
  height: 32px;
  padding: 0 30px 0 9px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #E2E2EA;
  border-radius: 8px;
  background: none;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #92929D;
  cursor: pointer;
}

.pagination__select::-ms-expand {
  display: none;
}

.pagination__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.pagination__icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  border-left: 1px solid #E2E2EA;
  pointer-events: none;
  font-size: 0;
}

.pagination__icon .icon {
  font-size: 24px;
  fill: #92929D;
}

body.dark .pagination__arrow {
  border-color: #44444F;
}

body.dark .pagination__arrow .icon {
  fill: #B5B5BE;
}

body.dark .pagination__arrow:hover {
  background: #44444F;
}

body.dark .pagination__link:hover, body.dark .pagination__link.active {
  color: #1E75FF;
}

body.dark .pagination__select {
  border-color: #44444F;
  color: #92929D;
}

body.dark .pagination__icon {
  border-color: #44444F;
}

body.dark .pagination__icon .icon {
  fill: #B5B5BE;
}

.settings {
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .settings {
    display: block;
  }
}

.settings__col:first-child {
  flex-grow: 1;
  padding-right: 20px;
}

@media only screen and (max-width: 1023px) {
  .settings__col:first-child {
    margin-bottom: 20px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .settings__col:first-child {
    margin-bottom: 10px;
  }
}

.settings__col:nth-child(2) {
  flex-shrink: 0;
  width: 273px;
}

@media only screen and (max-width: 1023px) {
  .settings__col:nth-child(2) {
    width: 100%;
  }
}

.settings__card {
  padding: 25px;
  border-radius: 20px;
  background: #ffffff;
}

@media only screen and (max-width: 1199px) {
  .settings__card {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .settings__card {
    padding: 20px 15px;
  }
}

.settings__card_table {
  padding-bottom: 10px;
}

.settings__card_profile {
  padding: 30px 20px;
}

@media only screen and (max-width: 1199px) {
  .settings__card_profile {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .settings__card_profile {
    padding: 20px 10px;
  }
}

.settings__card:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .settings__card:not(:last-child) {
    margin-bottom: 10px;
  }
}

.settings__head {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .settings__head_block {
    display: block;
    margin-bottom: 5px;
  }
}

.settings__title {
  margin-right: auto;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .settings__head_block .settings__title {
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .settings .options2 {
    margin-right: -7px;
  }
}

.settings__sorting {
  display: flex;
  padding: 1px 5px;
  border-radius: 8px;
  border: 1px solid #F1F1F5;
}

.settings__sorting .settings__link {
  padding: 0 10px;
  border-radius: 8px;
  line-height: 26px;
  color: #696974;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .settings__sorting .settings__link {
    flex: 1;
    text-align: center;
  }
}

.settings__sorting .settings__link:hover {
  opacity: .85;
}

.settings__sorting .settings__link.active {
  opacity: 1;
  background: #0062FF;
  color: #ffffff;
}

.settings__sorting .settings__link:not(:last-child) {
  margin-right: 4px;
}

@media only screen and (max-width: 767px) {
  .settings__sorting .settings__link:not(:last-child) {
    margin: 0;
  }
}

.settings__chart {
  height: 300px;
  margin: 0 -10px 0 -15px;
}

@media only screen and (max-width: 767px) {
  .settings__chart {
    height: 250px;
    margin-bottom: -10px;
  }
}

.settings__list {
  display: flex;
  margin: 0 -10px;
}

@media only screen and (max-width: 767px) {
  .settings__list {
    flex-wrap: wrap;
    margin: -10px -5px 0;
  }
}

.settings__list:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .settings__list:not(:last-child) {
    margin-bottom: 10px;
  }
}

.settings__list .settings__card {
  flex: 0 0 calc(25% - 20px);
  width: calc(25% - 20px);
  margin: 0 10px;
  padding: 25px 20px;
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .settings__list .settings__card {
    padding: 20px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .settings__list .settings__card {
    flex: 0 0 calc(50% - 10px);
    width: calc(50% - 10px);
    margin: 10px 5px 0;
    padding: 15px 20px;
    text-align: left;
  }
}

.settings__counter {
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 24px;
  font-weight: 600;
}

.settings__list .settings__text {
  margin-bottom: 20px;
  color: #696974;
}

@media only screen and (max-width: 1199px) {
  .settings__list .settings__text {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .settings__list .settings__text {
    margin-bottom: 12px;
  }
}

.settings__status {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}

.settings__status .icon {
  position: relative;
  top: -1px;
  margin-left: 3px;
  font-size: 16px;
}

.settings__status_up {
  color: #3DD598;
}

.settings__status_up .icon {
  fill: #3DD598;
}

.settings__status_down {
  color: #FC5A5A;
}

.settings__status_down .icon {
  fill: #FC5A5A;
}

.settings__body {
  margin: 0 -15px;
}

.settings__table {
  display: table;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .settings__table {
    display: block;
    margin-top: 20px;
  }
}

.settings__row {
  display: table-row;
}

@media only screen and (max-width: 767px) {
  .settings__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .settings__row_head {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .settings__row:not(:last-child) {
    margin-bottom: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #F1F1F5;
  }
}

.settings__cell {
  display: table-cell;
  height: 52px;
  vertical-align: middle;
  padding: 0 10px;
  color: #44444F;
}

@media only screen and (max-width: 767px) {
  .settings__cell {
    display: block;
    height: auto;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
  }
}

.settings__cell:first-child {
  padding-left: 15px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: #171725;
}

@media only screen and (max-width: 767px) {
  .settings__cell:first-child {
    order: 1;
    padding-left: 0;
    flex: 0 0 calc(100% - 70px);
    font-weight: 600;
  }
}

@media only screen and (max-width: 767px) {
  .settings__cell:nth-child(2), .settings__cell:nth-child(3) {
    order: 3;
    flex-shrink: 0;
    width: 30%;
    padding-top: 15px;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .settings__cell:nth-child(4) {
    order: 4;
    flex: 40%;
    padding-top: 15px;
  }
}

.settings__cell:last-child {
  padding-right: 15px;
}

@media only screen and (max-width: 767px) {
  .settings__cell:last-child {
    flex: 0 0 70px;
    order: 2;
    padding-right: 0;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    font-weight: 500;
  }
}

.settings__cell:not(:first-child) {
  text-align: right;
}

.settings__row_head .settings__cell {
  height: 38px;
  background: #FAFAFB;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: .8px;
  text-transform: uppercase;
  color: #44444F;
}

.settings__row_head .settings__cell:first-child {
  border-radius: 10px 0 0 10px;
}

.settings__row_head .settings__cell:last-child {
  border-radius: 0 10px 10px 0;
}

.settings__note {
  display: none;
}

@media only screen and (max-width: 767px) {
  .settings__note {
    position: relative;
    top: -1px;
    display: inline;
    margin-right: 6px;
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: .8px;
    text-transform: uppercase;
  }
}

.settings__top {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #F1F1F5;
}

.settings__edit {
  position: absolute;
  top: -10px;
  right: -18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .settings__edit {
    right: 0;
  }
}

.settings__edit .icon {
  margin-bottom: 8px;
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.settings__edit:hover .icon {
  fill: #B5B5BE;
}

.settings__edit .settings__text {
  padding: 0 7px;
  border-radius: 7px;
  background: #F1F1F5;
  font-size: 12px;
  line-height: 22px;
  color: #171725;
}

.settings__ava {
  width: 92px;
  height: 92px;
  margin: 0 auto 15px;
  font-size: 0;
}

.settings__ava .settings__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.settings__man {
  margin-bottom: 4px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}

.settings__post {
  font-size: 12px;
  color: #92929D;
}

.settings__parameter:not(:last-child) {
  margin-bottom: 22px;
}

.settings__category {
  margin-bottom: 9px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .8px;
  text-transform: uppercase;
  color: #92929D;
}

.settings__value {
  display: inline-block;
  margin-top: -4px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 500;
  color: #171725;
}

.settings__details {
  display: flex;
  align-items: center;
}

.settings__line {
  position: relative;
  flex-grow: 1;
  height: 4px;
  border-radius: 2px;
  background: #E2E2EA;
}

.settings__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #0062FF;
  border-radius: 2px;
}

.settings__price {
  margin-left: 15px;
  color: #696974;
}

.settings__price span {
  color: #0062FF;
}

.settings__content {
  font-size: 14px;
  line-height: 1.71429;
  color: #44444F;
}

.settings__skills {
  display: flex;
  flex-wrap: wrap;
  margin: -5px 0 0 -5px;
}

.settings__skill {
  margin: 5px 0 0 5px;
  padding: 0 7px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .5px;
  color: #ffffff;
}

.settings .apexcharts-tooltip.apexcharts-theme-light {
  padding: 8px 10px;
  border-radius: 6px;
  background: #162C50;
  border: none;
  box-shadow: none;
  font-size: 12px;
}

.settings .tooltip__box {
  display: flex;
  flex-direction: column;
}

.settings .tooltip__title {
  margin-bottom: 7px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 600;
  color: #FAFAFB;
}

.settings .tooltip__line {
  display: flex;
  align-items: center;
  color: #92929D;
}

.settings .tooltip__line .icon {
  margin-right: 4px;
  font-size: 12px;
  fill: #92929D;
}

.settings .tooltip__line .icon-arrow-bottom {
  fill: #82C43C;
}

.settings .tooltip__line:not(:last-child) {
  margin-bottom: 8px;
}

.settings .tooltip__price {
  margin-left: auto;
  padding-left: 7px;
  color: #FAFAFB;
}

body.dark .settings__card {
  background: #1C1C24;
}

body.dark .settings__title {
  color: #FAFAFB;
}

body.dark .settings__sorting {
  border-color: #44444F;
}

@media only screen and (max-width: 767px) {
  body.dark .settings__sorting {
    border-color: #292932;
    background: #292932;
  }
}

body.dark .settings__sorting .settings__link {
  color: #B5B5BE;
}

body.dark .settings__sorting .settings__link.active {
  background: #1E75FF;
  color: #FAFAFB;
}

body.dark .settings__counter {
  color: #FAFAFB;
}

body.dark .settings__row {
  border-color: #44444F;
}

body.dark .settings__cell {
  color: #92929D;
}

@media only screen and (max-width: 767px) {
  body.dark .settings__cell {
    color: #FAFAFB;
  }
}

body.dark .settings__cell:first-child {
  color: #FAFAFB;
}

body.dark .settings__row_head .settings__cell {
  background: #292932;
  color: #92929D;
}

body.dark .settings__edit .settings__text {
  background: #292932;
  color: #FAFAFB;
}

body.dark .settings__note {
  color: #696974;
}

body.dark .settings__man {
  color: #FAFAFB;
}

body.dark .settings__top {
  border-color: #44444F;
}

body.dark .settings__category {
  color: #B5B5BE;
}

body.dark .settings__value {
  color: #FAFAFB;
}

body.dark .settings__progress {
  background: #1E75FF;
}

body.dark .settings__price {
  color: #92929D;
}

body.dark .settings__price span {
  color: #1E75FF;
}

body.dark .settings__content {
  color: #FAFAFB;
}

body.dark .settings .apexcharts-tooltip.apexcharts-theme-light {
  background: #292932;
}

body.dark .settings .tooltip__line {
  color: #B5B5BE;
}

body.dark .settings .apexcharts-gridline {
  stroke: #3D3D49;
}

body.dark .settings .apexcharts-xcrosshairs.apexcharts-active,
body.dark .settings .apexcharts-ycrosshairs.apexcharts-active {
  stroke: #44444F;
}

body.dark .settings .apexcharts-xaxis line {
  stroke: #292932;
}

.overview__card {
  padding: 25px 25px 30px;
  border-radius: 20px;
  background: #ffffff;
}

@media only screen and (max-width: 1439px) {
  .overview__card {
    padding: 20px 20px 25px;
  }
}

@media only screen and (max-width: 1365px) {
  .overview__card {
    padding: 20px 15px;
  }
}

.overview__card_p20 {
  padding: 20px;
}

@media only screen and (max-width: 1439px) {
  .overview__card_p20 {
    padding: 20px;
  }
}

@media only screen and (max-width: 1365px) {
  .overview__card_p20 {
    padding: 20px 15px;
  }
}

.overview__card.overview__card_p0 {
  padding: 0;
}

.overview__card.overview__card_location {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .overview__card.overview__card_location {
    padding: 15px;
    flex-direction: column-reverse;
  }
}

.overview__card:not(:last-child) {
  margin-bottom: 20px;
}

.overview__list {
  display: flex;
  margin: 0 -10px;
}

@media only screen and (max-width: 1199px) {
  .overview__list {
    flex-wrap: wrap;
    margin-top: -20px;
  }
}

@media only screen and (max-width: 767px) {
  .overview__list {
    margin-top: -10px;
  }
}

.overview__list:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .overview__list:not(:last-child) {
    margin-bottom: 10px;
  }
}

.overview__list .overview__card {
  flex: 0 0 calc(25% - 20px);
  width: calc(25% - 20px);
  margin: 0 10px;
  padding: 20px 25px 30px;
}

@media only screen and (max-width: 1439px) {
  .overview__list .overview__card {
    padding: 20px 20px 25px;
  }
}

@media only screen and (max-width: 1199px) {
  .overview__list .overview__card {
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
    margin: 20px 10px 0;
    padding: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .overview__list .overview__card {
    flex: 0 0 calc(50% - 10px);
    width: calc(50% - 10px);
    margin: 10px 5px 0;
  }
}

.overview__title {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}

.overview__main {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1199px) {
  .overview__main {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .overview__main {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .overview__main .overview__title {
    margin-right: 8px;
    font-size: 13px;
  }
}

.overview__main .overview__status {
  display: none;
}

@media only screen and (max-width: 767px) {
  .overview__main .overview__status {
    display: block;
    font-size: 13px;
  }
  .overview__main .overview__status .icon {
    font-size: 14px;
  }
}

.overview__number {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 28px;
  font-weight: 600;
}

@media only screen and (max-width: 1365px) {
  .overview__number {
    font-size: 24px;
  }
}

.overview__flex {
  display: flex;
}

.overview__list .overview__flex {
  align-items: center;
  margin-bottom: 5px;
}

.overview__list .overview__number {
  margin-right: 14px;
}

@media only screen and (max-width: 767px) {
  .overview__list .overview__number {
    margin: 0;
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .overview__flex .overview__status {
    display: none;
  }
}

.overview__info {
  color: #696974;
}

@media only screen and (max-width: 1365px) {
  .overview__info {
    font-size: 12px;
  }
}

.overview__status {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}

.overview__status .icon {
  position: relative;
  bottom: -2px;
  margin-left: 3px;
  font-size: 16px;
  fill: #92929D;
}

.overview__status_up {
  color: #3DD598;
}

.overview__status_down {
  color: #FC5A5A;
}

.overview__status_down .icon {
  fill: #FC5A5A;
}

.overview__row {
  display: flex;
  margin: 0 -10px;
}

@media only screen and (max-width: 767px) {
  .overview__row {
    display: block;
    margin: 0;
  }
}

.overview__row:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .overview__row:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1199px) {
  .overview__row_tablet-album {
    display: block;
    margin: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .overview__row_tablet-album .overview__col {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .overview__row_tablet-album .overview__col:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .overview__row_tablet-album .overview__col:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1199px) {
  .overview__row_tablet-album:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .overview__row_tablet-album:not(:last-child) {
    margin-bottom: 10px;
  }
}

.overview__col {
  margin: 0 10px;
}

@media only screen and (max-width: 767px) {
  .overview__col {
    margin: 0;
  }
}

.overview__col_w25 {
  flex: 0 0 calc(25% - 20px);
  width: calc(25% - 20px);
}

@media only screen and (max-width: 1365px) {
  .overview__col_w25 {
    flex: 0 0 calc(33% - 20px);
    width: calc(33% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .overview__col_w25 {
    width: 100%;
  }
}

.overview__col_w33 {
  flex: 0 0 calc(33% - 20px);
  width: calc(33% - 20px);
}

@media only screen and (max-width: 767px) {
  .overview__col_w33 {
    width: 100%;
  }
}

.overview__col_w50 {
  flex: 0 0 calc(50% - 20px);
  width: calc(50% - 20px);
}

@media only screen and (max-width: 767px) {
  .overview__col_w50 {
    width: 100%;
  }
}

.overview__col_w67 {
  flex: 0 0 calc(67% - 20px);
  width: calc(67% - 20px);
}

@media only screen and (max-width: 767px) {
  .overview__col_w67 {
    width: 100%;
  }
}

.overview__col_w75 {
  flex: 0 0 calc(75% - 20px);
  width: calc(75% - 20px);
}

@media only screen and (max-width: 1365px) {
  .overview__col_w75 {
    flex: 0 0 calc(67% - 20px);
    width: calc(67% - 20px);
  }
}

@media only screen and (max-width: 767px) {
  .overview__col_w75 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .overview__col:not(:last-child) {
    margin-bottom: 10px;
  }
}

.overview__head {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .overview__head {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 639px) {
  .overview__head {
    display: block;
  }
}

.overview__head_mb30 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1439px) {
  .overview__head_mb30 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .overview__head_mb30 {
    margin-bottom: 15px;
  }
}

.overview__legend {
  display: flex;
}

.overview__legend_column {
  flex-wrap: wrap;
  margin: -10px -5px 0;
}

.overview__legend_group {
  flex-direction: column;
}

.overview__head .overview__legend {
  margin-left: auto;
}

@media only screen and (max-width: 767px) {
  .overview__head .overview__legend {
    margin: 10px 0 0;
  }
}

.overview__parameter {
  display: flex;
  align-items: center;
  color: #44444F;
}

@media only screen and (max-width: 1023px) {
  .overview__parameter {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .overview__parameter {
    font-size: 14px;
  }
}

.overview__legend_column .overview__parameter {
  flex: 0 0 calc(50% - 10px);
  width: calc(50% - 10px);
  margin: 10px 5px 0;
}

.overview__legend_group .overview__parameter:not(:last-child) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .overview__legend_group .overview__parameter:not(:last-child) {
    margin-bottom: 10px;
  }
}

.overview__head .overview__legend {
  padding-top: 3px;
}

.overview__head .overview__parameter:not(:last-child) {
  margin-right: 30px;
}

@media only screen and (max-width: 1199px) {
  .overview__head .overview__parameter:not(:last-child) {
    margin-right: 15px;
  }
}

.overview__bg {
  flex-shrink: 0;
  width: 10px;
  height: 12px;
  margin-right: 10px;
  border-radius: 6px;
}

@media only screen and (max-width: 1199px) {
  .overview__bg {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .overview__bg {
    margin-right: 10px;
  }
}

.overview__value {
  padding-left: 5px;
  font-weight: 700;
  color: #44444F;
}

.overview__chart_sales-figures {
  height: 382px;
  margin: 0 -5px 0 -15px;
}

@media only screen and (max-width: 767px) {
  .overview__chart_sales-figures {
    height: 250px;
    margin: -10px -5px -10px -15px;
  }
}

.overview__chart_sales-figures .apexcharts-tooltip.apexcharts-theme-light {
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
}

.overview__chart_sales-figures .apexcharts-tooltip-series-group {
  padding: 0;
  align-items: center;
}

.overview__chart_sales-figures .apexcharts-tooltip-series-group:not(:last-child) {
  margin-bottom: 8px;
}

.overview__chart_sales-figures .apexcharts-tooltip-series-group.apexcharts-active,
.overview__chart_sales-figures .apexcharts-tooltip-series-group:last-child {
  margin: 0;
  padding: 0;
}

.overview__chart_sales-figures .apexcharts-tooltip-text-value,
.overview__chart_sales-figures .apexcharts-tooltip-text-z-value {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
  color: #171725;
}

.overview__chart_sales-figures .apexcharts-tooltip-text-value:before,
.overview__chart_sales-figures .apexcharts-tooltip-text-z-value:before {
  content: "$";
}

.overview__chart_sales-figures .apexcharts-tooltip-text-label {
  font-size: 14px;
  color: #696974;
}

.overview__chart_visitors {
  height: 60px;
}

@media only screen and (max-width: 767px) {
  .overview__chart_visitors {
    height: 80px;
  }
}

.overview__chart_sales-report {
  height: 242px;
  margin: -10px -5px -20px -18px;
}

@media only screen and (max-width: 1439px) {
  .overview__chart_sales-report {
    height: 255px;
  }
}

@media only screen and (max-width: 1365px) {
  .overview__chart_sales-report {
    height: 252px;
  }
}

.overview__chart_sales-report .apexcharts-tooltip.apexcharts-theme-light {
  padding: 10px;
  border: none;
  background: #FAFAFB;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
}

.overview__chart_sales-report .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: none;
  border: none;
  font-family: Poppins, sans-serif !important;
  font-size: 12px;
  font-weight: 600;
  color: #171725;
}

.overview__chart_sales-report .apexcharts-tooltip-title {
  margin-bottom: 7px;
  padding: 0;
}

.overview__chart_sales-report .apexcharts-tooltip-y-group {
  padding: 0;
}

.overview__chart_sales-report .apexcharts-tooltip-series-group {
  padding: 0;
}

.overview__chart_sales-report .apexcharts-tooltip-series-group:not(:last-child) {
  margin-bottom: 8px;
}

.overview__chart_sales-report .apexcharts-tooltip-series-group.apexcharts-active, .overview__chart_sales-report .apexcharts-tooltip-series-group:last-child {
  padding-bottom: 0;
}

.overview__chart_sales-report .apexcharts-tooltip-marker {
  width: 6px;
  height: 8px;
  margin-right: 6px;
  border-radius: 6px;
}

.overview__chart_sales-report .apexcharts-tooltip-text-label {
  color: #92929D;
}

.overview__chart_sales-report .apexcharts-tooltip-text-value {
  color: #171725;
}

.overview__chart_sales-report .apexcharts-tooltip-text-value:before {
  content: "$";
}

.overview__chart_visitors-all {
  position: relative;
  height: 260px;
  margin: 15px 0 5px;
}

@media only screen and (max-width: 1439px) {
  .overview__chart_visitors-all {
    height: 240px;
  }
}

@media only screen and (max-width: 1365px) {
  .overview__chart_visitors-all {
    height: 210px;
  }
}

@media only screen and (max-width: 767px) {
  .overview__chart_visitors-all {
    height: 180px;
    margin: 15px 0;
  }
}

.overview__chart_visitors-all .apexcharts-pie-area {
  stroke: inherit;
}

.overview__chart_visitors-all .overview__details {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .overview__chart_visitors-all .overview__details {
    top: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .overview__group {
    display: flex;
    align-items: center;
  }
}

.overview__el {
  display: flex;
  align-items: center;
}

.overview__el:not(:last-child) {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #F1F1F5;
}

@media only screen and (max-width: 767px) {
  .overview__el:not(:last-child) {
    margin: 0 auto 0 0;
    padding-bottom: 0;
    border: none;
  }
}

.overview__el .overview__chart {
  position: relative;
  width: 115px;
  height: 100px;
  margin-left: -15px;
  margin-right: 10px;
  font-size: 0;
}

@media only screen and (max-width: 1439px) {
  .overview__el .overview__chart {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .overview__el .overview__chart {
    width: 70px;
    height: 60px;
  }
}

.overview__el .overview__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0;
}

.overview__el .overview__icon .icon {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  .overview__el .overview__icon .icon {
    font-size: 16px;
  }
}

.overview__el .overview__icon .icon-goal {
  fill: #0062FF;
}

.overview__el .overview__icon .icon-briefcase {
  fill: #3DD598;
}

.overview__el .overview__number {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  .overview__el .overview__number {
    font-size: 18px;
  }
}

.overview__bottom {
  display: flex;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .overview__bottom {
    margin-top: 5px;
  }
}

.overview__bottom .overview__details {
  margin-right: auto;
}

.overview__bottom .overview__status {
  padding-top: 5px;
}

.overview__bottom .overview__number {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  .overview__details_flex {
    display: flex;
    align-items: center;
  }
}

.overview__years {
  margin-top: 10px;
  color: #696974;
}

@media only screen and (max-width: 767px) {
  .overview__years {
    margin: 0 0 0 10px;
  }
}

.overview__charts {
  display: flex;
  margin-left: -25px;
  padding-right: 30px;
}

@media only screen and (max-width: 1439px) {
  .overview__charts {
    justify-content: space-around;
    padding: 0;
  }
}

@media only screen and (max-width: 1365px) {
  .overview__charts {
    margin-left: -10px;
  }
}

@media only screen and (max-width: 1023px) {
  .overview__charts {
    margin-left: 0;
  }
}

.overview__box {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1439px) {
  .overview__box {
    flex-direction: column;
    text-align: center;
  }
}

.overview__box:not(:last-child) {
  margin-right: auto;
}

@media only screen and (max-width: 1439px) {
  .overview__box:not(:last-child) {
    margin: 0;
  }
}

.overview__charts .overview__chart {
  position: relative;
  flex-shrink: 0;
  width: 120px;
  height: 100px;
}

.overview__percent, .overview__counter {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
}

.overview__charts .overview__percent {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
}

.overview__charts .overview__details {
  margin-left: -5px;
}

.overview__charts .overview__counter {
  margin-bottom: 4px;
  font-size: 16px;
}

.overview__note {
  display: flex;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #F1F1F5;
  font-size: 12px;
  line-height: 1.83333;
  color: #92929D;
}

@media only screen and (max-width: 1439px) {
  .overview__note {
    margin-top: 15px;
    padding-top: 15px;
    line-height: 1.4;
  }
}

.overview__note .icon {
  position: relative;
  bottom: -2px;
  flex-shrink: 0;
  margin-right: 10px;
  font-size: 16px;
  fill: #92929D;
}

.overview__control {
  display: flex;
  align-items: center;
}

.overview__control .overview__title {
  margin: 0 auto;
}

.overview__arrow {
  font-size: 0;
}

.overview__arrow .icon {
  font-size: 24px;
  fill: #92929D;
  transition: fill .25s;
}

.overview__arrow:hover .icon {
  fill: #B5B5BE;
}

.overview__cell {
  padding: 25px;
}

@media only screen and (max-width: 1439px) {
  .overview__cell {
    padding: 20px;
  }
}

@media only screen and (max-width: 1365px) {
  .overview__cell {
    padding: 20px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .overview__cell {
    padding: 0;
  }
}

.overview__cell:first-child {
  flex-shrink: 0;
  width: 265px;
  border-right: 1px solid #F1F1F5;
}

@media only screen and (max-width: 1365px) {
  .overview__cell:first-child {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .overview__cell:first-child {
    width: 100%;
    border: none;
  }
}

.overview__cell:nth-child(2) {
  flex-grow: 1;
}

.overview__cell .overview__title {
  margin-bottom: 49px;
}

@media only screen and (max-width: 1439px) {
  .overview__cell .overview__title {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 1365px) {
  .overview__cell .overview__title {
    margin-bottom: 15px;
  }
}

.overview__cell .overview__flex {
  display: flex;
  align-items: center;
}

.overview__flag {
  flex-shrink: 0;
  width: 28px;
  margin-left: 10px;
  font-size: 0;
}

.overview__flag .overview__pic {
  width: 100%;
  border-radius: 2px;
}

.overview__cell .overview__info {
  margin-bottom: 40px;
}

@media only screen and (max-width: 1365px) {
  .overview__cell .overview__info {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .overview__cell .overview__info {
    margin-bottom: 15px;
  }
}

.overview__cell .overview__legend {
  max-width: 185px;
}

@media only screen and (max-width: 767px) {
  .overview__cell .overview__legend {
    max-width: 100%;
  }
}

.overview__cell .overview__value {
  margin-left: auto;
}

@media only screen and (max-width: 1439px) {
  .overview__map > div {
    height: 335px !important;
  }
}

@media only screen and (max-width: 1365px) {
  .overview__map > div {
    height: 300px !important;
  }
}

@media only screen and (max-width: 767px) {
  .overview__map > div {
    height: 220px !important;
  }
}

.overview .jqvmap-zoomin,
.overview .jqvmap-zoomout {
  left: auto;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  border: 1px solid #E2E2EA;
  background: #ffffff;
  font-size: 0;
  transition: opacity .25s;
}

.overview .jqvmap-zoomin:before,
.overview .jqvmap-zoomout:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 2px;
  background: #92929D;
  border-radius: 1px;
}

.overview .jqvmap-zoomin:hover,
.overview .jqvmap-zoomout:hover {
  opacity: .85;
}

.overview .jqvmap-zoomin {
  top: 0;
  right: 0;
}

.overview .jqvmap-zoomout {
  top: 34px;
  right: 0;
}

@media only screen and (max-width: 767px) {
  .overview .jqvmap-zoomout {
    top: 29px;
  }
}

.overview .jqvmap-zoomout:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2px;
  height: 12px;
  background: #92929D;
  border-radius: 1px;
}

body.dark .overview__card {
  background: #1C1C24;
}

body.dark .overview__title {
  color: #FAFAFB;
}

body.dark .overview__number {
  color: #FAFAFB;
}

body.dark .overview__info {
  color: #92929D;
}

body.dark .overview__parameter {
  color: #92929D;
}

body.dark .overview__el {
  border-color: #44444F;
}

body.dark .overview__counter {
  color: #FAFAFB;
}

body.dark .overview__box .overview__info {
  color: #696974;
}

body.dark .overview__note {
  border-color: #44444F;
}

body.dark .overview .apexcharts-gridline {
  stroke: #3D3D49;
}

body.dark .overview .apexcharts-xcrosshairs.apexcharts-active,
body.dark .overview .apexcharts-ycrosshairs.apexcharts-active {
  stroke: #44444F;
}

body.dark .overview .apexcharts-xaxis line {
  stroke: #3D3D49;
}

body.dark .overview__chart_sales-figures .apexcharts-tooltip.apexcharts-theme-light {
  background: #292932;
  box-shadow: none;
}

body.dark .overview__chart_sales-figures .apexcharts-tooltip-text-value,
body.dark .overview__chart_sales-figures .apexcharts-tooltip-text-z-value {
  color: #FAFAFB;
}

body.dark .overview__chart_sales-figures .apexcharts-tooltip-text-label {
  color: #B5B5BE;
}

body.dark .overview__chart_visitors .apexcharts-tooltip.apexcharts-theme-light {
  border: none;
  background: #292932;
  box-shadow: none;
}

body.dark .overview__chart_visitors .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  border-color: #44444F;
  background: #292932;
  color: #FAFAFB;
}

body.dark .overview__chart_visitors .apexcharts-tooltip-text-value,
body.dark .overview__chart_visitors .apexcharts-tooltip-text-z-value {
  color: #FAFAFB;
}

body.dark .overview__chart_visitors .apexcharts-tooltip-text-label {
  color: #B5B5BE;
}

body.dark .overview__chart_sales-report .apexcharts-tooltip.apexcharts-theme-light {
  background: #292932;
  box-shadow: none;
}

body.dark .overview__chart_sales-report .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  color: #FAFAFB;
}

body.dark .overview__chart_sales-report .apexcharts-tooltip-text-label {
  color: #B5B5BE;
}

body.dark .overview__chart_sales-report .apexcharts-tooltip-text-value {
  color: #FAFAFB;
}

body.dark .overview__chart_sales-report .apexcharts-tooltip-text-value:before {
  content: "$";
}

body.dark .overview__chart_visitors-all .apexcharts-pie-area {
  stroke: inherit;
}

body.dark .overview__value {
  color: #B5B5BE;
}

body.dark .overview__legend_column .overview__parameter {
  color: #B5B5BE;
}

body.dark .overview__cell {
  border-color: #44444F;
}

body.dark .overview__cell .overview__parameter {
  color: #B5B5BE;
}

body.dark .overview__cell .overview__info {
  color: #B5B5BE;
}

body.dark .overview .jqvmap-zoomin,
body.dark .overview .jqvmap-zoomout {
  border-color: #696974;
  background: #1C1C24;
}

body.dark .overview .jqvmap-zoomin:before, body.dark .overview .jqvmap-zoomin:after,
body.dark .overview .jqvmap-zoomout:before,
body.dark .overview .jqvmap-zoomout:after {
  background: #B5B5BE;
}

.statistics__body {
  padding: 25px;
}

@media only screen and (max-width: 1439px) {
  .statistics__body {
    padding: 20px;
  }
}

@media only screen and (max-width: 1365px) {
  .statistics__body {
    padding: 20px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .statistics__body {
    padding: 15px;
  }
}

.statistics__top {
  display: flex;
  align-items: center;
  margin-bottom: 33px;
}

@media only screen and (max-width: 767px) {
  .statistics__top {
    margin-bottom: 20px;
  }
}

.statistics__top_mb22 {
  margin-bottom: 22px;
}

.statistics__title, .statistics__man {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-weight: 600;
}

.statistics__title {
  margin-right: auto;
  font-size: 16px;
}

.statistics .options2 {
  margin-right: -5px;
}

.statistics__man {
  margin-bottom: 2px;
  font-size: 14px;
}

.statistics__item {
  display: flex;
  align-items: center;
}

.statistics__item:not(:last-child) {
  margin-bottom: 28px;
}

@media only screen and (max-width: 767px) {
  .statistics__item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.statistics .ava {
  width: 42px;
  height: 42px;
  font-size: 16px;
}

.statistics__details {
  flex-grow: 1;
  padding: 0 15px;
}

@media only screen and (max-width: 1365px) {
  .statistics__details {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 1199px) {
  .statistics__details {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .statistics__details {
    padding: 0 15px;
  }
}

.statistics__actions {
  display: flex;
  flex-shrink: 0;
}

.statistics__action {
  font-size: 0;
}

.statistics__action .icon {
  font-size: 20px;
  fill: #92929D;
  transition: fill .25s;
}

.statistics__action:hover .icon {
  fill: #B5B5BE;
}

.statistics__action:not(:last-child) {
  margin-right: 20px;
}

@media only screen and (max-width: 1365px) {
  .statistics__action:not(:last-child) {
    margin-right: 10px;
  }
}

.statistics__id {
  font-size: 12px;
  color: #92929D;
}

.statistics__foot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  border-top: 1px solid #F1F1F5;
}

@media only screen and (max-width: 1199px) {
  .statistics__foot {
    height: 50px;
  }
}

.statistics__view {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .8px;
  text-transform: uppercase;
  color: #0062FF;
  transition: color .25s;
}

.statistics__view:hover {
  color: #50B5FF;
}

.statistics__inner {
  margin: 0 -15px;
}

@media only screen and (max-width: 1365px) {
  .statistics__inner {
    margin: 0 -10px;
  }
}

.statistics__table {
  display: table;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .statistics__table {
    display: block;
  }
}

.statistics__row {
  display: table-row;
}

@media only screen and (max-width: 767px) {
  .statistics__row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .statistics__row_head {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .statistics__row:not(:last-child) {
    border-bottom: 1px solid #F1F1F5;
  }
}

.statistics__cell {
  display: table-cell;
  vertical-align: middle;
  height: 59px;
  padding: 0 10px;
}

@media only screen and (max-width: 1365px) {
  .statistics__cell {
    padding: 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .statistics__cell {
    height: auto;
    padding: 0;
  }
}

.statistics__cell:first-child {
  padding-left: 15px;
}

@media only screen and (max-width: 1365px) {
  .statistics__cell:first-child {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .statistics__cell:first-child {
    order: 1;
    flex: 0 0 calc(100% - 82px);
    padding: 0 10px 0 0;
  }
}

.statistics__cell:nth-child(2) {
  color: #44444F;
}

@media only screen and (max-width: 767px) {
  .statistics__cell:nth-child(2) {
    order: 3;
    padding-top: 15px;
    color: #92929D;
  }
}

.statistics__cell:nth-child(3) {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .statistics__cell:nth-child(3) {
    order: 4;
    margin-left: auto;
    padding-top: 15px;
  }
}

.statistics__cell:last-child {
  padding-right: 15px;
}

@media only screen and (max-width: 1365px) {
  .statistics__cell:last-child {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .statistics__cell:last-child {
    order: 2;
    flex: 0 0 82px;
    padding: 0;
  }
}

.statistics__cell:not(:first-child) {
  text-align: right;
}

.statistics__row_head .statistics__cell {
  height: 38px;
  background: #FAFAFB;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: .8px;
  text-transform: uppercase;
  color: #44444F;
}

.statistics__row_head .statistics__cell:first-child {
  border-radius: 10px 0 0 10px;
}

.statistics__row_head .statistics__cell:last-child {
  border-radius: 0 10px 10px 0;
}

.statistics__line {
  display: inline-flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: #171725;
}

.statistics__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 15px;
  border-radius: 50%;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .statistics__icon {
    width: 21px;
    height: 21px;
    margin-right: 7px;
  }
}

.statistics__icon .icon {
  font-size: 16px;
  fill: #ffffff;
}

@media only screen and (max-width: 767px) {
  .statistics__icon .icon {
    font-size: 12px;
  }
}

.statistics__icon .icon-check {
  font-size: 8px;
}

@media only screen and (max-width: 767px) {
  .statistics__icon .icon-check {
    font-size: 7px;
  }
}

.statistics__icon .icon-close {
  font-size: 10px;
}

@media only screen and (max-width: 767px) {
  .statistics__icon .icon-close {
    font-size: 8px;
  }
}

.statistics__status {
  display: inline-block;
  min-width: 82px;
  padding: 0 7px;
  border-radius: 5px;
  text-align: center;
  line-height: 26px;
}

@media only screen and (max-width: 767px) {
  .statistics__status {
    width: 82px;
    padding: 0;
  }
}

.statistics__status_completed {
  background: rgba(61, 213, 152, 0.1);
  color: #3DD598;
}

.statistics__status_pickup {
  background: rgba(80, 181, 255, 0.1);
  color: #50B5FF;
}

.statistics__status_declined {
  background: rgba(252, 90, 90, 0.1);
  color: #FC5A5A;
}

body.dark .statistics__title {
  color: #FAFAFB;
}

body.dark .statistics__man {
  color: #FAFAFB;
}

body.dark .statistics__foot {
  border-color: #44444F;
}

body.dark .statistics__view {
  font-weight: 600;
  color: #FAFAFB;
}

body.dark .statistics__view:hover {
  color: #50B5FF;
}

@media only screen and (max-width: 767px) {
  body.dark .statistics__row {
    border-color: transparent;
  }
}

body.dark .statistics__row_head .statistics__cell {
  background: #292932;
  color: #92929D;
}

body.dark .statistics__cell {
  color: #FAFAFB;
}

body.dark .statistics__cell:nth-child(2) {
  color: #92929D;
}

body.dark .statistics__line {
  color: #FAFAFB;
}
