.sorting {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  @include x {
    margin-bottom: 25px; }
  &__title {
    margin-right: 30px;
    @include d {
      margin-right: 20px; }
    @include m {
      display: none; } }
  &__variants {
    display: flex;
    align-items: center;
    margin-right: auto; }
  &__variants &__text {
    margin-right: 5px;
    font-size: 18px;
    color: $gray;
    @include d {
      font-size: 16px; } }
  &__select {
    height: auto;
    padding: 0 22px 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%2392929d'/%3E%3C/svg%3E") no-repeat 100% 55% / 11px 7px;
    @include f;
    font-size: 18px;
    font-weight: 500;
    color: $dark4;
    cursor: pointer;
    @include d {
      font-size: 16px; }
    &::-ms-expand {
      display: none; }
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000; } }
  &__options {
    display: flex;
    align-items: center;
    margin-left: auto; }
  .dropdown {
    @include m {
      display: none; } }
  &__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    margin-left: 15px;
    border-radius: 10px;
    background: $white;
    font-size: 0;
    @include m {
      width: 88px;
      height: 32px;
      border-radius: 7px;
      font-size: 14px;
      color: $dark5; }
    .icon {
      font-size: 18px;
      fill: $gray;
      transition: fill .25s;
      &-filters {
        @include m {
          display: none; } }
      &-filters-1 {
        display: none;
        @include m {
          display: inline-block;
          margin-right: 12px;
          font-size: 10px;
          color: $gray; } } }
    &:hover .icon {
      fill: $gray-light; } }
  &__view {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    margin-left: 15px;
    @include d {
      display: none; } }
  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    background: $white;
    font-size: 0;
    transition: background .25s;
    .icon {
      font-size: 16px;
      fill: $gray;
      transition: fill .25s; }
    &:hover {
      background: lighten($gray-light, 20); }
    &.active {
      background: $gray-light;
      .icon {
        fill: $white; } }
    @include nl {
      border-right: 1px solid #F1F1F5; } } }

@include dark {
  .sorting {
    &__title {
      color: $wh; }
    &__variants .sorting__text {
      color: $dark5; }
    &__select {
      color: $gray-light; }
    &__filters {
      background: $dark3;
      color: $wh; }
    &__box {
      background: $dark3;
      border-color: $dark4;
      .icon {
        fill: $gray; }
      &:hover {
        background: lighten($dark4, 4); }
      &.active {
        background: $dark4;
        .icon {
          fill: $gray; } } } } }



